define("mdeditor/pods/components/object/md-citation/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XWLuYtam",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"layout/md-card\",null,[[\"title\",\"collapsible\",\"collapsed\",\"profilePath\",\"required\",\"muted\",\"spotlightEnabled\",\"btnText\",\"btnClass\",\"btnAction\",\"btnIcon\"],[[24,[\"title\"]],true,false,[24,[\"profilePath\"]],[24,[\"required\"]],[24,[\"muted\"]],[24,[\"spotlightEnabled\"]],\"Edit\",\"success\",[28,\"action\",[[23,0,[]],[24,[\"editCitation\"]],[28,\"dasherize\",[[24,[\"title\"]]],null]],null],\"pencil\"]],{\"statements\":[[0,\"\\n\"],[1,[28,\"object/md-citation/preview/body\",null,[[\"citation\",\"profilePath\"],[[24,[\"citation\"]],[24,[\"profilePath\"]]]]],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card-footer\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"name\",\"button\"],[12,\"class\",\"btn btn-success pull-right\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"editCitation\"]],[28,\"dasherize\",[[24,[\"title\"]]],null]],null]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Edit Citation\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-citation/preview/template.hbs"
    }
  });

  _exports.default = _default;
});