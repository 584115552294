define("mdeditor/pods/components/control/md-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uo+34krd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"fa-icon\",[\"spinner\"],[[\"pulse\",\"size\"],[true,[24,[\"size\"]]]]],false],[0,\"\\n\"],[7,\"h3\",true],[11,\"class\",[29,[\"md-spinner-text size-\",[22,\"size\"]]]],[8],[0,\"\\n    \"],[1,[22,\"text\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});