define("mdeditor/pods/components/object/md-graphic-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uwd1yQG9",
    "block": "{\"symbols\":[\"editing\",\"&default\"],\"statements\":[[4,\"object/md-object-table\",null,[[\"items\",\"header\",\"buttonText\",\"templateClass\",\"previewTemplate\",\"profilePath\",\"attributes\"],[[24,[\"model\"]],[24,[\"label\"]],[24,[\"buttonText\"]],[24,[\"templateClass\"]],[24,[\"previewTemplate\"]],[24,[\"profilePath\"]],[24,[\"attributes\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"value\",\"label\",\"placeholder\",\"required\",\"profilePath\"],[[23,1,[\"fileName\"]],\"Name\",\"File Name\",true,[28,\"concat\",[[24,[\"profilePath\"]],\".fileName\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"input/md-input\",null,[[\"value\",\"label\",\"placeholder\",\"profilePath\"],[[23,1,[\"fileType\"]],\"File Type\",\"Enter the file format(s) of the logo.\",[28,\"concat\",[[24,[\"profilePath\"]],\".fileType\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"input/md-textarea\",null,[[\"maxlength\",\"value\",\"label\",\"placeholder\",\"profilePath\"],[500,[23,1,[\"fileDescription\"]],\"Description\",\"Description of the file: Less than 500 characters\",[28,\"concat\",[[24,[\"profilePath\"]],\".fileDescription\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"object/md-online-resource-array\",null,[[\"shadow\",\"model\",\"ellipsis\",\"imagePicker\",\"profilePath\"],[false,[23,1,[\"fileUri\"]],true,true,[28,\"concat\",[[24,[\"profilePath\"]],\".onlineResource\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-graphic-array/template.hbs"
    }
  });

  _exports.default = _default;
});