define("mdeditor/pods/record/show/edit/distribution/distributor/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "96lBRIRc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Distributor #\"],[1,[22,\"distributorId\"],false],[0,\" in \"],[7,\"span\",true],[10,\"class\",\"text-success\"],[8],[0,\"Distribution #\"],[1,[22,\"distributionId\"],false],[9],[0,\"\\n\"],[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\",\"tooltip\",\"tipClass\",\"tipSide\"],[\"btn btn-xs btn-danger\",[28,\"route-action\",[\"deleteDistributor\"],null],\"Delete Distributor?\",\"danger\",\"bottom\"]],{\"statements\":[[0,\"    \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\" Delete\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-distributor\",null,[[\"model\",\"distributorId\",\"profilePath\",\"editTransferOption\"],[[24,[\"model\"]],[24,[\"distributorId\"]],\"record.distribution.distributor\",[28,\"route-action\",[\"editTransferOption\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"text\",\"icon\",\"type\",\"route\"],[\"Back to Distribution List\",\"arrow-left\",\"primary\",\"record.show.edit.distribution\"]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"refresh\",\"scrollInit\",\"setScrollTo\"],[[24,[\"refresh\"]],[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/distribution/distributor/index/template.hbs"
    }
  });

  _exports.default = _default;
});