define("mdeditor/pods/components/object/md-online-resource-array/md-image-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CZxCEduD",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-preview-image text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"uri\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[24,[\"item\",\"uri\"]]],[10,\"class\",\"img-responsive img-thumbnail\"],[10,\"alt\",\"Image Preview\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    URI Not Defined\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[7,\"dl\",true],[10,\"class\",\"dl-horizontal\"],[8],[0,\"\\n  \"],[7,\"dt\",true],[8],[0,\"Name\"],[9],[0,\"\\n  \"],[7,\"dd\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wrap\"],[8],[1,[28,\"html-safe\",[[28,\"if\",[[24,[\"item\",\"name\"]],[24,[\"item\",\"name\"]],\"<em>Not Defined</em>\"],null]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"dt\",true],[8],[0,\"URI\"],[9],[0,\"\\n  \"],[7,\"dd\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"wrap\"],[8],[1,[28,\"html-safe\",[[28,\"if\",[[24,[\"item\",\"uri\"]],[24,[\"item\",\"uri\"]],\"<em>Not Defined</em>\"],null]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-online-resource-array/md-image-preview/template.hbs"
    }
  });

  _exports.default = _default;
});