define("mdeditor/pods/contact/new/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      let rec = this.store.createRecord('contact');
      this.replaceWith('contact.new.id', rec.id);
    }

  });

  _exports.default = _default;
});