define("mdeditor/togeojson", ["exports", "@mapbox/togeojson"], function (_exports, _togeojson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "toGeoJSON", {
    enumerable: true,
    get: function get() {
      return _togeojson.default;
    }
  });
});