define("mdeditor/pods/dictionaries/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const columns = [{
    propertyName: 'title',
    title: 'Title'
  }, {
    propertyName: 'dictionaryId',
    title: 'ID',
    isHidden: true
  }, {
    propertyName: 'json.dataDictionary.subject',
    title: 'Subject'
  }];

  var _default = Ember.Route.extend({
    slider: Ember.inject.service(),

    model() {
      //return this.store.peekAll('contact');
      return this.modelFor('application').findBy('modelName', 'dictionary');
    },

    columns: columns,
    actions: {
      getColumns() {
        return this.columns;
      },

      showSlider(rec, evt) {
        let slider = this.slider;
        evt.stopPropagation();
        this.controller.set('errorTarget', rec);
        slider.set('fromName', 'md-slider-error');
        slider.toggleSlider(true);
        return false;
      }

    }
  });

  _exports.default = _default;
});