define("mdeditor/pods/components/object/md-date-array/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      if (Ember.isNone(Ember.get(this, 'value'))) {
        Ember.set(this, 'value', []);
      }
    },

    /**
     * See [md-array-table](md-array-table.html#property_templateClass).
     *
     * @property templateClass
     * @type Ember.Object
     */
    templateClass: Ember.Object.extend({
      init() {
        this._super(...arguments);

        this.setProperties({
          date: null,
          dateType: null
        });
      }

    })
  });

  _exports.default = _default;
});