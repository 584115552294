define("mdeditor/pods/components/control/md-record-table/buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LxkS0rCu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"control/md-record-table/buttons/show\",null,[[\"record\"],[[24,[\"record\"]]]]],false],[0,\"\\n\"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-success\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"editItem\",[24,[\"record\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Edit\\n\"],[9],[0,\"\\n\"],[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\"],[\"btn btn-sm btn-danger\",[28,\"action\",[[23,0,[]],\"deleteItem\",[24,[\"record\"]],[24,[\"index\"]],[24,[\"isSelected\"]],[24,[\"clickOnRow\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\" Delete\\n\"]],\"parameters\":[]},null],[1,[28,\"control/md-json-button\",null,[[\"class\",\"json\",\"title\"],[\"btn-sm btn-primary\",[24,[\"record\",\"cleanJson\"]],[24,[\"record\",\"title\"]]]]],false],[0,\"\\n\"],[1,[28,\"control/md-status\",null,[[\"model\",\"isBtn\",\"hideSlider\",\"showSlider\"],[[24,[\"record\"]],true,true,[28,\"action\",[[23,0,[]],\"showSlider\",[24,[\"record\"]]],null]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-record-table/buttons/template.hbs"
    }
  });

  _exports.default = _default;
});