define("mdeditor/pods/components/layout/md-wrap/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Component used as a wrapper, data-spy enabled.
     *
     * ```handlebars
     * \{{#layout/md-wrap
     *   data-spy="Wrap"
     *   shadow=true
     * }}
     *   Content
     * {{/layout/md-wrap}}
     * ```
     * @module mdeditor
     * @submodule components-layout
     * @class md-wrap
     * @constructor
     */
    attributeBindings: ['data-spy']
  });

  _exports.default = _default;
});