define("mdeditor/pods/record/show/edit/taxonomy/collection/system/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4k2SCYo",
    "block": "{\"symbols\":[\"system\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"Editing Taxonomic System #\"],[1,[22,\"systemId\"],false],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[28,\"compute\",[[28,\"route-action\",[\"parentModel\"],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"with\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"object/md-taxonomy/collection/system\",null,[[\"model\",\"profilePath\"],[[23,1,[]],\"record.taxonomy.collection.taxonomicSystem\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Back to Collection\",\"arrow-left\",\"btn-primary btn-lg\",[28,\"route-action\",[\"goBack\"],null]]]]]]],false],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/taxonomy/collection/system/index/template.hbs"
    }
  });

  _exports.default = _default;
});