define("mdeditor/helpers/get-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDash = getDash;
  _exports.default = void 0;

  function getDash(params
  /*, hash*/
  ) {
    let obj = params[0];
    let prop = params[1].trim();
    let val = null;

    if (obj) {
      val = Ember.get(obj, prop);
    }

    return val || "--";
  }

  var _default = Ember.Helper.helper(getDash);

  _exports.default = _default;
});