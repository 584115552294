define("mdeditor/pods/components/object/md-keyword-list/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module mdeditor
   * @submodule components-object
   */
  var _default = Ember.Component.extend({
    readOnly: Ember.computed('model.thesaurus.identifier.0.identifier', function () {
      return this.get('model.thesaurus.identifier.0.identifier') !== 'custom';
    }),
    actions: {
      addKeyword(model) {
        this.addKeyword(model);
      },

      deleteKeyword(model, object) {
        this.deleteKeyword(model, object);
      },

      hideThesaurus(el) {
        (0, _jquery.default)(el).closest('.md-keywords-container').toggleClass('hide-thesaurus');
      }

    }
  });

  _exports.default = _default;
});