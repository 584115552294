define("mdeditor/pods/record/show/edit/constraint/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q7afsy7p",
    "block": "{\"symbols\":[\"editing\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Constraints\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"object/md-object-table\",null,[[\"items\",\"collasped\",\"header\",\"buttonText\",\"previewTemplate\",\"addSubbar\",\"attributes\"],[[24,[\"model\",\"json\",\"metadata\",\"resourceInfo\",\"constraint\"]],false,\"Constraints\",\"Add Constraint\",[24,[\"previewTemplate\"]],\"md-subbar-extra\",\"type\"]],{\"statements\":[[0,\"  \"],[1,[28,\"object/md-constraint\",null,[[\"model\",\"profilePath\"],[[23,1,[]],\"record.constraints\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/constraint/index/template.hbs"
    }
  });

  _exports.default = _default;
});