define("mdeditor/pods/components/input/md-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Input, edit, display a single item
     *
     * ```handlebars
     * \{{input/md-input
     *    value=val
     *    model=null
     *    valuePath=null
     *    label="Name"
     *    placeholder="Enter name."
     *    required=false
     *  }}
     * ```
     *
     * @class md-input
     * @constructor
     */
    init() {
      this._super(...arguments);

      let model = this.model;
      let valuePath = this.valuePath;

      if (Ember.isBlank(model) !== Ember.isBlank(valuePath)) {
        (true && !(false) && Ember.assert("You must supply both model and valuePath to ".concat(this.toString(), " or neither.")));
      }

      if (!Ember.isBlank(model)) {
        if (this.get("model.".concat(valuePath)) === undefined) {
          Ember.debug("model.".concat(valuePath, " is undefined in ").concat(this.toString(), ".")); //Ember.run.once(()=>model.set(valuePath, ""));
        }

        if (this.type === 'number') {
          let attribute = "model.".concat(valuePath);
          Ember.defineProperty(this, 'value', Ember.computed(attribute, {
            get() {
              let val = this.get(attribute);
              return val ? val.toString() : '';
            },

            set(key, value) {
              let parse = this.step ? parseFloat : parseInt;
              this.set(attribute, parse(value, 10));
              return value;
            }

          }));
        } else {
          Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(valuePath)));
        }

        Ember.defineProperty(this, 'validation', Ember.computed.alias("model.validations.attrs.".concat(valuePath)).readOnly());
        Ember.defineProperty(this, 'required', Ember.computed('validation.options.presence{presence,disabled}', 'disabled', function () {
          return !this.disabled && this.get('validation.options.presence.presence') && !this.get('validation.options.presence.disabled');
        }).readOnly());
        Ember.defineProperty(this, 'notValidating', Ember.computed.not('validation.isValidating').readOnly());
        Ember.defineProperty(this, 'hasContent', Ember.computed.notEmpty('value').readOnly());
        Ember.defineProperty(this, 'hasWarnings', Ember.computed.notEmpty('validation.warnings').readOnly());
        Ember.defineProperty(this, 'isValid', Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly());
        Ember.defineProperty(this, 'shouldDisplayValidations', Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly());
        Ember.defineProperty(this, 'showErrorClass', Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly());
        Ember.defineProperty(this, 'showErrorMessage', Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly());
        Ember.defineProperty(this, 'showWarningMessage', Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly());
      }
    },

    classNames: ['md-input'],
    classNameBindings: ['label:form-group', 'required'],
    attributeBindings: ['data-spy'],

    /**
     * Value of the input.
     * Value sets the initial value and returns the edited result.
     * This property is overridden if valuePath and model are supplied.
     *
     * @property value
     * @type String
     * @required
     */

    /**
     * Type of data represented by the value string.
     * HTML5 types may be specified ('text', 'number', etc.)
     *
     * @property type
     * @type String
     * @default text
     */
    type: 'text',

    /**
     * The form label to display
     *
     * @property label
     * @type String
     * @default null
     */
    label: null,

    /**
     * Whether a value is required
     *
     * @property required
     * @type Boolean
     * @default false
     */
    required: false,

    /**
     * Whether a input is disabled
     *
     * @property disabled
     * @type Boolean
     * @default false
     */
    disabled: false,

    /**
     * Maximum number of characters for each input string.
     * If no maxlength is specified the length will not be restricted
     *
     * @property maxlength
     * @type Number
     * @default null
     */
    maxlength: null,

    /**
     * Text displayed in empty inputs
     *
     * @property placeholder
     * @type String
     * @default null
     */
    placeholder: null,

    /**
     * CSS class to set on the input control
     *
     * @property class
     * @type String
     * @default 'form-control'
     */
    inputClass: 'form-control',

    /**
     * The model or object containing the input value. Only needed for validation.
     *
     * @property model
     * @type {Object}
     * @default undefined
     * @readOnly
     */

    /**
     * The path of the input value. Only needed for validation.
     *
     * @property valuePath
     * @type {String}
     * @default ''
     * @readOnly
     */
    valuePath: ''
  });

  _exports.default = _default;
});