define("mdeditor/pods/components/object/md-funding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lC4TxWp4",
    "block": "{\"symbols\":[\"editing\"],\"statements\":[[4,\"object/md-object-table\",null,[[\"items\",\"header\",\"data-spy\",\"shadow\",\"buttonText\",\"ellipsis\",\"attributes\",\"profilePath\",\"collapsible\",\"isCollapsed\",\"tipPath\",\"tipModel\",\"required\",\"verticalButtons\"],[[24,[\"model\",\"allocation\"]],\"Allocation\",\"Allocation\",true,\"Add Allocation\",true,\"amount,currency,matching\",[28,\"concat\",[[24,[\"profilePath\"]],\".allocation\"],null],false,false,\"allocation\",[23,0,[]],[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"allocation\"],null],\"isInvalid\"],null],true]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"object/md-allocation\",null,[[\"model\",\"profilePath\"],[[23,1,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".allocation\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"timePeriod\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-time-period\",null,[[\"model\",\"required\",\"profilePath\"],[[24,[\"model\",\"timePeriod\"]],[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"timePeriod\"],null],\"isInvalid\"],null],[28,\"concat\",[[24,[\"profilePath\"]],\".timePeriod\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"input/md-textarea\",null,[[\"maxlength\",\"value\",\"label\",\"data-spy\",\"placeholder\",\"profilePath\"],[500,[24,[\"model\",\"description\"]],\"Description\",\"Description\",\"Description: Less than 500 characters\",[28,\"concat\",[[24,[\"profilePath\"]],\".description\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-funding/template.hbs"
    }
  });

  _exports.default = _default;
});