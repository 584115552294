define("mdeditor/pods/components/object/md-bbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4r+KTdNc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"layout/md-card\",null,[[\"collapsible\",\"collapsed\",\"profilePath\",\"data-spy\",\"shadow\"],[false,false,[24,[\"profilePath\"]],false,false]],{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-6\"],[8],[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"type\",\"step\",\"model\",\"valuePath\",\"label\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"number\",\"0.0001\",[23,0,[]],\"north\",\"North\",\"Enter north latitude.\",[28,\"concat\",[[24,[\"profilePath\"]],\".north\"],null],true]]],false],[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"type\",\"step\",\"model\",\"valuePath\",\"label\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"number\",\"0.0001\",[23,0,[]],\"east\",\"East\",\"Enter east longitude.\",[28,\"concat\",[[24,[\"profilePath\"]],\".east\"],null],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-6\"],[8],[0,\"\\n\\n    \"],[1,[28,\"input/md-input\",null,[[\"type\",\"step\",\"model\",\"valuePath\",\"label\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"number\",\"0.0001\",[23,0,[]],\"south\",\"South\",\"Enter south latitude.\",[28,\"concat\",[[24,[\"profilePath\"]],\".south\"],null],true]]],false],[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"type\",\"step\",\"model\",\"valuePath\",\"label\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"number\",\"0.0001\",[23,0,[]],\"west\",\"West\",\"Enter west longitude.\",[28,\"concat\",[[24,[\"profilePath\"]],\".west\"],null],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-bbox/template.hbs"
    }
  });

  _exports.default = _default;
});