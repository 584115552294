define("mdeditor/pods/components/control/md-contact-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    /**
     * mdEditor Component that accepts a contact identifier and returns the
     * formatted link element.
     *
     * @class md-contact-link
     * @module mdeditor
     * @submodule components-control
     * @constructor
     */
    didReceiveAttrs() {
      //Inline link title comes first, if present.
      let block = !this.block ? [this.get('contact.title')] : [];
      let params = Ember.get(this, 'params');
      let add = block.concat(['contact.show', this.get('contact.id')]);
      Ember.set(this, 'params', params ? add.concat(params) : add);

      this._super(...arguments);
    },

    store: Ember.inject.service(),

    /**
     * The contacts service
     *
     * @property contacts
     * @type {Ember.Service}
     * @readOnly
     */
    contacts: Ember.inject.service(),

    /**
     * The contact identifier
     *
     * @property contactId
     * @type {String}
     * @required
     */

    /**
     * Render as block
     *
     * @property block
     * @type {Boolean}
     * @required
     */

    /**
     * The contact record
     *
     * @property contact
     * @type {String}
     * @readOnly
     * @category computed
     * @requires contactId
     */
    contact: Ember.computed('contactId', function () {
      let rec = this.store.peekAll('contact').findBy('json.contactId', this.contactId);
      return rec;
    }).readOnly()
  });

  _exports.default = _default;
});