define("mdeditor/pods/components/object/md-taxonomy/collection/system/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'citation': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'citation', Ember.getWithDefault(model, 'citation', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the domain.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the domain.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    citation: Ember.computed.alias('model.citation')
  });

  _exports.default = _default;
});