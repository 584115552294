define("mdeditor/pods/settings/route", ["exports", "mdeditor/models/setting"], function (_exports, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    settings: Ember.inject.service(),
    publish: Ember.inject.service(),

    /**
     * The profile service
     *
     * @return {Ember.Service} profile
     */
    profile: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      const links = [{
        title: 'Main',
        target: 'settings.main',
        tip: 'Main application settings'
      }, {
        title: 'Profiles',
        target: 'settings.profile',
        tip: 'Custom profile settings'
      }, {
        title: 'Validation',
        target: 'settings.validation',
        tip: 'Custom validation settings'
      }];
      controller.set('links', links);
    },

    actions: {
      clearLocalStorage() {
        window.localStorage.clear();
        this.transitionTo('application');
        window.location.reload();
      },

      save() {
        this.settings.data.save();
      },

      catalogs() {
        return this.get('publish.catalogs');
      },

      resetMdTranslatorAPI() {
        let url = Ember.get(_setting.default, 'attributes').get('mdTranslatorAPI').options.defaultValue;
        let model = Ember.get(this.controller, 'model');
        model.set('mdTranslatorAPI', url);
      }

    }
  });

  _exports.default = _default;
});