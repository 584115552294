define("mdeditor/pods/components/object/md-domainitem/preview/component", ["exports", "mdeditor/pods/components/object/md-domainitem/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_component.Validations, {
    tagName: '',
    model: Ember.computed.alias('item'),
    name: Ember.computed.alias('model.name'),
    value: Ember.computed.alias('model.value'),
    definition: Ember.computed.alias('model.definition')
  });

  _exports.default = _default;
});