define("mdeditor/pods/components/md-models-table/component", ["exports", "ember-models-table/components/models-table", "mdeditor/pods/components/md-models-table/themes/bootstrap3"], function (_exports, _modelsTable, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTable.default.extend({
    init() {
      this.pageSizeValues = [10, 25, 50, 500];
      this.filteringIgnoreCase = true;

      this._super(...arguments);
    },

    themeInstance: _bootstrap.default.create()
  });

  _exports.default = _default;
});