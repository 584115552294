define("mdeditor/pods/components/object/md-locale-array/component", ["exports", "mdeditor/pods/components/object/md-locale/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['data-spy'],

    /**
     * See [md-array-table](md-array-table.html#property_templateClass).
     *
     * @property templateClass
     * @type Ember.Object
     */
    templateClass: _component.Template
  });

  _exports.default = _default;
});