define("mdeditor/pods/record/show/edit/lineage/lineageobject/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit.main'));
      this.controller.set('lineageId', Ember.get(this.controllerFor('record.show.edit.lineage.lineageobject'), 'lineageId'));
    },
    actions: {
      editCitation(index) {
        this.transitionTo('record.show.edit.lineage.lineageobject.citation', index).then(function () {
          this.setScrollTo('citation');
        }.bind(this));
      },

      editSource(index) {
        this.transitionTo('record.show.edit.lineage.lineageobject.source', index).then(function () {
          this.setScrollTo('source');
        }.bind(this));
      },

      editProcessStep(index) {
        this.transitionTo('record.show.edit.lineage.lineageobject.step', index).then(function () {
          this.setScrollTo('process-step');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});