define("mdeditor/pods/components/object/md-srs/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'refType': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.notEmpty('model.model.referenceSystemIdentifier.identifier')
    })],
    'refSystem': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.notEmpty('model.model.referenceSystemType')
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');

      if (model) {
        Ember.run.once(this, function () {
          Ember.set(model, 'referenceSystemIdentifier', Ember.getWithDefault(model, 'referenceSystemIdentifier', {}));
        });
      }
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    classNames: ['form'],
    refSystem: Ember.computed.alias('model.referenceSystemIdentifier.identifier'),
    refType: Ember.computed.alias('model.referenceSystemType')
  });

  _exports.default = _default;
});