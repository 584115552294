define("mdeditor/pods/components/control/md-contact-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    /**
     * mdEditor Component that accepts a contact identifier and returns the
     * contact title or yields the contact in block form.
     *
     * @class md-contact-title
     * @module mdeditor
     * @submodule components-control
     * @constructor
     */

    /**
     * The contact identifier
     *
     * @property contactId
     * @type {String}
     * @required
     */

    /**
     * description
     *
     * @property contact
     * @type {String}
     * @readOnly
     * @category computed
     * @requires contactId
     */
    contact: Ember.computed('contactId', function () {
      let rec = this.store.peekAll('contact').findBy('json.contactId', this.contactId);
      return rec;
    }).readOnly()
  });

  _exports.default = _default;
});