define("mdeditor/pods/dictionary/show/edit/citation/identifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "swtVgcHh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Identifier: \"],[1,[24,[\"model\",\"identifier\"]],false],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n  \"],[1,[28,\"object/md-identifier\",null,[[\"class\",\"model\",\"profilePath\",\"data-spy\"],[\"\",[24,[\"model\"]],\"dictionary.citation.identifier\",\"Identifier\"]]],false],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"text\",\"icon\",\"route\"],[\"Back to Citation\",\"arrow-left\",\"dictionary.show.edit.citation\"]]]]]],false],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-dict-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/edit/citation/identifier/template.hbs"
    }
  });

  _exports.default = _default;
});