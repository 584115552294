define("mdeditor/pods/components/object/md-process-step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'timePeriod', Ember.getWithDefault(model, 'timePeriod', {}));
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'stepId', Ember.getWithDefault(model, 'stepId', Ember.get(this, 'itemId')));
        Ember.set(model, 'timePeriod', Ember.getWithDefault(model, 'timePeriod', {}));
        Ember.set(model, 'scope', Ember.getWithDefault(model, 'scope', {}));
        Ember.set(model, 'reference', Ember.getWithDefault(model, 'reference', []));
        Ember.set(model, 'processor', Ember.getWithDefault(model, 'processor', []));
        Ember.set(model, 'stepSource', Ember.getWithDefault(model, 'stepSource', []));
        Ember.set(model, 'stepProduct', Ember.getWithDefault(model, 'stepProduct', []));
      });
    },

    tagName: 'form',

    /**
     * The string representing the path in the profile object for the processStep.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model for the processStep.
     *
     * @property model
     * @type {Object}
     * @required
     */
    sourceTemplate: Ember.Object.extend()
  });

  _exports.default = _default;
});