define("mdeditor/pods/components/object/md-associated/preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.profilePath = this.profilePath || 'preview';
    },

    store: Ember.inject.service(),
    classNameBindings: ['muted:text-muted'],

    /**
     * Whether to render the text muted.
     *
     * @property muted
     * @type {Boolean}
     * @default "true"
     */
    muted: true,
    citation: Ember.computed('item', 'item.mdRecordId', function () {
      if (!this.get('item.mdRecordId')) {
        return this.get('item.resourceCitation');
      }

      let store = this.store;
      let linked = store.peekAll('record').filterBy('recordId', Ember.get(this, 'item.mdRecordId')).get('firstObject.json.metadata.resourceInfo.citation');
      return linked || this.get('item.resourceCitation');
    }),
    metadataIdentifier: Ember.computed('item.{metadataCitation.identifier,mdRecordId}', function () {
      if (!this.get('item.mdRecordId')) {
        return this.get('item.metadataCitation.identifier.0');
      }

      let store = this.store;
      return store.peekAll('record').filterBy('recordId', Ember.get(this, 'item.mdRecordId')).get('firstObject.json.metadata.metadataInfo.metadataIdentifier');
    })
  });

  _exports.default = _default;
});