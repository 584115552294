define("mdeditor/pods/not-found/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.Object.create({
        path: params.path
      });
    },

    init() {
      this._super(...arguments);

      this.breadCrumb = {
        title: 'Page Not Found',
        linkable: false
      };
    },

    redirect() {
      var url = this.router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }

  });

  _exports.default = _default;
});