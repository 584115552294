define("mdeditor/mixins/hash-poll", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.pollInterval = void 0;
  const pollInterval = 750; // time in milliseconds

  _exports.pollInterval = pollInterval;

  var _default = Ember.Mixin.create({
    settings: Ember.inject.service(),

    afterModel(model) {
      this._super(...arguments);

      if (this.get('settings.data.autoSave')) {
        model.set('jsonRevert', model.serialize().data.attributes.json);
      }

      this.pollTask.perform();
      return model;
    },

    deactivatePoll: Ember.on('deactivate', function () {
      this.pollTask.cancelAll();
    }),

    poll() {
      const model = this.currentRouteModel();
      return new Ember.RSVP.Promise(function (resolve) {
        if (model) {
          model.notifyPropertyChange('currentHash');
        }

        resolve(true);
      });
    },

    pollTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        yield this.poll();
        yield (0, _emberConcurrency.timeout)(pollInterval);
      }
    }).restartable()
  });

  _exports.default = _default;
});