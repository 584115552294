define("mdeditor/services/cleaner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    clean(obj, options) {
      const opt = Ember.assign({
        target: {},
        preserveArrays: true,
        //preserveObjects: true,
        preserveRootOnly: true
      }, options);

      if (Ember.isBlank(obj)) {
        if (Ember.isArray(obj) && opt.preserveArrays) {
          return [];
        }

        return;
      }

      if (/string|number|boolean/.test(typeof obj)) {
        return obj;
      }

      let acc = opt.target;
      return Object.keys(obj).reduce((result, key) => {
        //reject private property
        if (key.match(/^_/)) {
          return result;
        }

        if (Ember.isArray(obj[key])) {
          if (opt.preserveArrays === false && obj[key].length === 0) {
            return result;
          }

          let resultArray = [];
          obj[key].forEach(itm => {
            let type = Ember.isArray(itm) ? [] : typeof itm === 'object' ? {} : null;
            let cleanItem = this.clean(itm, {
              target: type,
              preserveArrays: opt.preserveRootOnly ? false : opt.preserveArrays
            });

            if (Ember.isBlank(cleanItem) || typeof cleanItem === 'object' && Object.keys(cleanItem).length === 0) {
              return;
            } //console.info(cleanItem);


            resultArray.push(cleanItem); //resultArray[idx] = cleanItem;
          });

          if (opt.preserveArrays === false && resultArray.length < 1) {
            return result;
          }

          result[key] = resultArray;
          return result;
        }

        if (Ember.isBlank(obj[key])) {
          //if(obj[key] === undefined) {
          return result;
        }

        if (Ember.typeOf(obj[key]) === 'object' || Ember.typeOf(obj[key]) === 'instance') {
          let objOpt = Ember.assign(opt, {
            preserveArrays: !opt.preserveRootOnly,
            target: {}
          });
          const res = this.clean(obj[key], objOpt);

          if (Object.keys(res).length > 0) {
            result[key] = res;
          }
        } else if (obj[key] !== '') {
          result[key] = obj[key];
        }

        return result;
      }, acc);
    }

  });

  _exports.default = _default;
});