define("mdeditor/pods/components/object/md-source/preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {
  //   Validations
  // } from '../component';
  var _default = Ember.Component.extend(
  /*Validations,*/
  {
    tagName: '',
    model: Ember.computed.alias('item'),
    name: Ember.computed.alias('model.description')
  });

  _exports.default = _default;
});