define("mdeditor/pods/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SLL9FIDT",
    "block": "{\"symbols\":[\"spy\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row md-section-secondary\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-9 col-md-offset-1\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-control-sidebar col-sm-3 col-md-2 hidden-print\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"Action Button Controls\"],[8],[0,\"\\n      \"],[1,[28,\"multiple-from-elsewhere\",null,[[\"name\"],[\"md-subbar-settings\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"from-elsewhere\",null,[[\"name\"],[\"md-scroll-spy-settings\"]],{\"statements\":[[0,\"        \"],[1,[28,\"component\",[[23,1,[]]],[[\"offset\"],[145]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"nav-secondary\",[28,\"component\",[\"layout/md-nav-secondary\"],[[\"navLinks\",\"isStaticLinks\"],[[24,[\"links\"]],true]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/settings/template.hbs"
    }
  });

  _exports.default = _default;
});