define("mdeditor/pods/record/show/edit/taxonomy/collection/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('collectionId', Ember.get(this.controllerFor('record.show.edit.taxonomy.collection'), 'collectionId'));
    },
    actions: {
      toList() {
        this.transitionTo('record.show.edit.taxonomy');
      },

      addTaxa() {
        this.controller.model.taxonomicClassification.pushObject({
          _edit: true
        });
      },

      addITIS() {
        this.transitionTo('record.show.edit.taxonomy.collection.itis');
      },

      editSystem(index) {
        this.transitionTo('record.show.edit.taxonomy.collection.system', index).then(function () {
          this.setScrollTo('system');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});