define("mdeditor/ember-json-tree/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/components/nestable-draggable-object.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/nestable-draggable-object.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/tree-branch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/tree-branch.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/tree-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/tree-label.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/tree-leaf.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/tree-leaf.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/tree-search.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/tree-search.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/tree-view.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/tree-view.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/make-range.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/make-range.js should pass ESLint\n\n');
  });
});