define("mdeditor/pods/components/input/md-select-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mCClHfti",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"class\",\"navbar-form form-inline\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group-sm\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"navbar-text control-label\"],[8],[0,\"Profile\"],[9],[0,\"\\n    \"],[1,[28,\"input/md-codelist\",null,[[\"change\",\"class\",\"create\",\"tooltip\",\"icon\",\"allowClear\",\"mdCodeName\",\"valuePath\",\"value\",\"placeholder\",\"searchEnabled\",\"matchTriggerWidth\",\"calculatePosition\",\"label\"],[[28,\"action\",[[23,0,[]],\"updateProfile\",[24,[\"value\"]]],null],\"select-profile\",false,true,true,false,\"profile\",\"code\",[24,[\"value\"]],\"Choose profile\",false,true,[24,[\"calculatePosition\"]],false]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-select-profile/template.hbs"
    }
  });

  _exports.default = _default;
});