define("mdeditor/pods/components/object/md-taxonomy/classification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MnpI7PsS",
    "block": "{\"symbols\":[\"class\",\"index\"],\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"order\",[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-taxonomy/classification/taxon\",null,[[\"model\",\"parentItem\",\"top\",\"index\",\"dragging\",\"preview\",\"profilePath\"],[[23,1,[]],[24,[\"parentItem\"]],[28,\"unless\",[[24,[\"parentItem\"]],[24,[\"model\"]]],null],[23,2,[]],[24,[\"dragging\"]],[24,[\"preview\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".taxon\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"alert alert-info no-margin\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"No \"],[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"Classification\"],[9],[0,\" found.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-taxonomy/classification/template.hbs"
    }
  });

  _exports.default = _default;
});