define("mdeditor/pods/components/layout/md-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYQi9Wbg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"footer-container\"],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[7,\"a\",true],[10,\"class\",\"btn btn-xs btn-warning\"],[10,\"href\",\"https://github.com/adiwg/mdEditor/issues/new\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[1,[28,\"fa-icon\",[\"github\"],null],false],[0,\" Report Issue\\n\\n    \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\"],[\"Report bugs, Request features - GitHub account required.\",\"top\"]]],false],[0,\"\\n  \"],[9],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"AutoSave:\\n      \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"settings\",\"data\",\"autoSave\"]],\"text-success\",\"text-warning\"],null]],[8],[0,\"\\n        \"],[1,[28,\"if\",[[24,[\"settings\",\"data\",\"autoSave\"]],\"On\",\"Off\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/md-footer/template.hbs"
    }
  });

  _exports.default = _default;
});