define("mdeditor/pods/components/object/md-date/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    date: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    dateType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });

  var _default = Ember.Component.extend(Validations, {
    init() {
      this._super(...arguments); // if(isNone(get(this, 'model'))) {
      //   set(this, 'model', {});
      // }

    },

    tagName: '',
    date: Ember.computed.alias('model.date'),
    dateType: Ember.computed.alias('model.dateType')
  });

  _exports.default = _default;
});