define("mdeditor/pods/components/input/md-date-range/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import moment from 'moment';
  const Validations = (0, _emberCpValidations.buildValidations)({
    'start': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.end'),
      ignoreBlank: true
    })],
    'end': [(0, _emberCpValidations.validator)('date', {
      onOrAfter: Ember.computed.alias('model.start'),
      isWarning: true
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.start'),
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    /**
     * Date range with start date and end date fields.
     *
     * ```handlebars
     * \{{input/md-date-range
     *   startDateTime
     *   endDateTime=false
     * }}
     * ```
     *
     * @class md-date-range
     * @extends Ember.Component
     * @constructor
     */
    classNameBindings: ['formInline'],

    /**
     * If true, render the fields inline
     *
     * @property startDateTime
     * @type {Boolean}
     * @default true
     */
    formInline: true,

    /**
     * The value for the start datetime
     *
     * @property startDateTime
     * @type {String|Date|moment}
     * @default moment().hour(0).second(0).minute(0)
     * @required
     */
    //startDateTime: moment().hour(0).second(0).minute(0),

    /**
     * The value for the end datetime
     *
     * @property endDateTime
     * @type {String|Date|moment}
     * @default moment().hour(0).second(0).minute(0)
     * @required
     */
    //  endDateTime: moment().hour(0).second(0).minute(0)
    start: Ember.computed('startDateTime', {
      get() {
        let dt = Ember.get(this, 'startDateTime');
        return dt === undefined ? null : dt;
      },

      set(key, value) {
        Ember.run.once(this, function () {
          Ember.set(this, 'startDateTime', value);
          return value;
        });
      }

    }),
    end: Ember.computed('endDateTime', {
      get() {
        let dt = Ember.get(this, 'endDateTime');
        return dt === undefined ? null : dt;
      },

      set(key, value) {
        Ember.run.once(this, function () {
          Ember.set(this, 'endDateTime', value);
          return value;
        });
      }

    })
  });

  _exports.default = _default;
});