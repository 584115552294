define("mdeditor/pods/components/models-table/row-expand/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9IGxu2OU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[11,\"colspan\",[24,[\"visibleProcessedColumns\",\"length\"]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"expandedRowComponent\",\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"sendAction\",\"themeInstance\",\"expandRow\",\"collapseRow\"],[[24,[\"expandedRowComponent\"]],[24,[\"record\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"index\"]],[24,[\"clickOnRow\"]],[24,[\"sendAction\"]],[24,[\"themeInstance\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"expandedRowComponent\"]]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"sendAction\",\"themeInstance\",\"expandRow\",\"collapseRow\"],[[24,[\"record\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"index\"]],[24,[\"clickOnRow\"]],[24,[\"sendAction\"]],[24,[\"themeInstance\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/models-table/row-expand/template.hbs"
    }
  });

  _exports.default = _default;
});