define("mdeditor/pods/components/object/md-entity/component", ["exports", "mdeditor/pods/components/object/md-attribute/component", "ember-cp-validations", "uuid/v4"], function (_exports, _component, _emberCpValidations, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'codeName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'definition': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    init() {
      this._super(...arguments);

      (true && !(Ember.get(this, 'dictionary')) && Ember.assert("You must supply a dictionary for ".concat(this.toString(), "."), Ember.get(this, 'dictionary')));
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'entityId', Ember.getWithDefault(model, 'entityId', (0, _v.default)()));
        Ember.set(model, 'alias', Ember.getWithDefault(model, 'alias', []));
        Ember.set(model, 'primaryKeyAttributeCodeName', Ember.getWithDefault(model, 'primaryKeyAttributeCodeName', []));
        Ember.set(model, 'index', Ember.getWithDefault(model, 'index', []));
        Ember.set(model, 'attribute', Ember.getWithDefault(model, 'attribute', []));
        Ember.set(model, 'foreignKey', Ember.getWithDefault(model, 'foreignKey', []));
        Ember.set(model, 'entityReference', Ember.getWithDefault(model, 'entityReference', []));
      });
    },

    /**
     * The string representing the path in the profile object for the entity.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The parent dictionary object for this entity used to lookup references.
     *
     * @property dictionary
     * @type {Object}
     * @required
     */

    /**
     * The object to use as the data model for the entity.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    foreignKeyTemplate: Ember.Object.extend((0, _emberCpValidations.buildValidations)({
      'referencedEntityCodeName': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })],
      'localAttributeCodeName': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('array-required', {
        track: []
      })],
      'referencedAttributeCodeName': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('array-required', {
        track: []
      })]
    }), {
      init() {
        this._super(...arguments);

        this.set('localAttributeCodeName', []);
        this.set('referencedAttributeCodeName', []);
      }

    }),
    indexTemplate: Ember.Object.extend((0, _emberCpValidations.buildValidations)({
      'codeName': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })],
      'allowDuplicates': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })],
      'attributeCodeName': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('array-required', {
        track: []
      })]
    }), {
      init() {
        this._super(...arguments);

        this.set('attributeCodeName', []);
        this.set('allowDuplicates', false);
      }

    }),
    attributeTemplate: _component.Template,
    //entityId: alias('model.entityId'),
    codeName: Ember.computed.alias('model.codeName'),
    description: Ember.computed.alias('model.description'),
    entities: Ember.computed.alias('dictionary.entity'),
    attributes: Ember.computed.alias('model.attribute'),
    attributeList: Ember.computed('attributes.{@each.codeName,[]}', function () {
      let attr = Ember.get(this, 'model.attribute');

      if (attr) {
        return attr.map(attr => {
          return {
            codeId: Ember.get(attr, 'codeName'),
            codeName: Ember.get(attr, 'codeName'),
            tooltip: Ember.get(attr, 'definition')
          };
        });
      }

      return [];
    }),
    entityList: Ember.computed('entities.{@each.entityId,@each.codeName}', function () {
      return Ember.get(this, 'entities').map(attr => {
        if (Ember.get(attr, 'entityId')) {
          return {
            codeId: Ember.get(attr, 'entityId'),
            codeName: Ember.get(attr, 'codeName'),
            tooltip: Ember.get(attr, 'definition')
          };
        }
      });
    }),

    /**
     * The passed down editCitation method.
     *
     * @method editCitation
     * @param {Number} id
     * @required
     */

    /**
     * The passed down editAttribute method.
     *
     * @method editAttribute
     * @param {Number} id
     * @required
     */
    actions: {
      getEntityAttributes(id) {
        let entity = Ember.A(this.get('dictionary.entity')).findBy('entityId', id);

        if (entity) {
          let a = Ember.get(entity, 'attribute').map(attr => {
            return {
              codeId: Ember.get(attr, 'codeName'),
              codeName: Ember.get(attr, 'codeName'),
              tooltip: Ember.get(attr, 'definition')
            };
          });
          return a;
        }

        return [];
      },

      editCitation(id) {
        this.editCitation(id);
      },

      editAttribute(id) {
        this.editAttribute(id);
      }

    }
  });

  _exports.default = _default;
});