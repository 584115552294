define("mdeditor/components/sb-tree", ["exports", "mdeditor-sciencebase/components/sb-tree"], function (_exports, _sbTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sbTree.default;
    }
  });
});