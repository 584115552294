define("mdeditor/pods/components/layout/md-nav-secondary/link/component", ["exports", "liquid-fire/components/liquid-measured"], function (_exports, _liquidMeasured) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',

    didInsertElement() {
      this._super(...arguments);

      let width = (0, _liquidMeasured.measure)(this.$()).width;
      if (width === this.link.width || this.link.isOverflow) return;
      Ember.run.scheduleOnce('afterRender', () => {
        //next(this, () => {
        let sliced = this.nav.links.slice(0, this.index + 1);
        this.set('link.width', width);
        this.link.set('linkWidth', sliced.reduce((a, b) => {
          return a + b.width;
        }, this.nav.navPadding)); //});
      });
    }

  });

  _exports.default = _default;
});