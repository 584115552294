define("mdeditor/helpers/bbox-to-poly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bboxToPoly = bboxToPoly;
  _exports.default = void 0;

  function bboxToPoly(params
  /*, hash*/
  ) {
    let bbox = params[0];

    if (!(bbox.southLatitude && bbox.westLongitude && bbox.northLatitude && bbox.eastLongitude)) {
      return null;
    }

    return [[bbox.southLatitude, bbox.westLongitude], [bbox.northLatitude, bbox.westLongitude], [bbox.northLatitude, bbox.eastLongitude], [bbox.southLatitude, bbox.eastLongitude]];
  }

  var _default = Ember.Helper.helper(bboxToPoly);

  _exports.default = _default;
});