define("mdeditor/pods/dictionary/show/edit/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.dataDictionary');
      Ember.set(model, 'citation', Ember.getWithDefault(model, 'citation', {}));
      Ember.set(model, 'responsibleParty', Ember.getWithDefault(model, 'responsibleParty', {}));
      Ember.set(model, 'subject', Ember.getWithDefault(model, 'subject', []));
      Ember.set(model, 'recommendedUse', Ember.getWithDefault(model, 'recommendedUse', []));
      Ember.set(model, 'locale', Ember.getWithDefault(model, 'locale', []));
      Ember.set(model, 'domain', Ember.getWithDefault(model, 'domain', []));
      Ember.set(model, 'entity', Ember.getWithDefault(model, 'entity', []));
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: () => this,
        cancelScope: this
      });
    },

    actions: {
      editCitation(scrollTo) {
        this.transitionTo('dictionary.show.edit.citation').then(function () {
          this.setScrollTo(scrollTo);
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});