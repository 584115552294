define("mdeditor/pods/components/control/md-indicator/related/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+x4rthUm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"control/md-indicator\",null,[[\"title\",\"icon\",\"type\",\"note\",\"event\",\"values\",\"route\",\"model\",\"linkText\",\"popoverHideDelay\",\"popperContainer\"],[[24,[\"title\"]],[24,[\"icon\"]],[24,[\"type\"]],[24,[\"note\"]],[24,[\"event\"]],[24,[\"values\"]],[24,[\"route\"]],[24,[\"model\"]],[24,[\"linkText\"]],[24,[\"popoverHideDelay\"]],[24,[\"popperContainer\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"route\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"models\",\"length\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"models\",\"class\"],[[24,[\"route\"]],[24,[\"models\"]],[28,\"concat\",[\"btn btn-sm btn-\",[24,[\"type\"]]],null]]],{\"statements\":[[0,\"          \"],[1,[22,\"linkText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[[28,\"concat\",[\"btn btn-sm btn-\",[24,[\"type\"]]],null],[24,[\"route\"]]]],{\"statements\":[[0,\"          \"],[1,[22,\"linkText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-indicator/related/template.hbs"
    }
  });

  _exports.default = _default;
});