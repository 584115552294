define("mdeditor/services/profile", ["exports", "ember-ajax/request", "ember-concurrency", "ember-ajax/errors", "semver", "mdprofiles"], function (_exports, _request, _emberConcurrency, _errors, _semver, _mdprofiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.libraries.register('mdProfiles', _mdprofiles.default.version);

  const coreProfiles = _mdprofiles.default.asArray();
  /**
   * Profile service
   *
   * Service that provides profile configurations for metadata records.
   *
   * @module mdeditor
   * @submodule service
   * @class profile
   */


  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.profileRecords = this.store.peekAll('profile'); //this.customProfiles = this.get('store').peekAll('custom-profile');

      this.coreProfiles = coreProfiles;
    },

    profiles: Ember.computed.union('profileRecords', 'coreProfiles'),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Task that fetches the definition. Returns a Promise the yields the response.
     *
     * @method fetchDefinition
     * @param {String} uri The uri of the definition
     * @async
     * @return {Promise} The request response
     */
    fetchDefinition: (0, _emberConcurrency.task)(function* (uri) {
      try {
        yield (0, _emberConcurrency.timeout)(1000);
        let response = yield (0, _request.default)(uri);

        if (response && !_semver.default.valid(response.version)) {
          throw new Error("Invalid version");
        }

        return response;
      } catch (error) {
        if ((0, _errors.isNotFoundError)(error)) {
          this.flashMessages.danger("Could not load profile definition from ".concat(uri, ". Definition not found."));
        } else {
          this.flashMessages.danger("Could not load profile definition from \"".concat(uri, "\". Error: ").concat(error.message));
        }
      }
    }).drop(),

    /**
     * Task that checks the for updates at each `record.uri`.
     *
     * @method checkForUpdates
     * @param {Array} records Array of records to check
     * @async
     * @return {Promise} The request response
     */
    checkForUpdates: (0, _emberConcurrency.task)(function* (records) {
      yield (0, _emberConcurrency.timeout)(1000);
      yield (0, _emberConcurrency.all)(records.map(itm => {
        if (itm.validations.attrs.uri.isInvalid) {
          this.flashMessages.warning("Did not load definition for \"".concat(itm.title, "\". URL is Invalid."));
          return;
        }

        return (0, _request.default)(itm.uri).then(response => {
          // `response` is the data from the server
          if (_semver.default.valid(response.version)) {
            itm.set('remoteVersion', response.version);
          } else {
            throw new Error("Invalid version");
          }

          return response;
        }).catch(error => {
          if ((0, _errors.isNotFoundError)(error)) {
            this.flashMessages.danger("Could not load definition for \"".concat(itm.title, "\". Definition not found."));
          } else {
            this.flashMessages.danger("Could not load definition for \"".concat(itm.title, "\". Error: ").concat(error.message));
          }
        });
      }));
    }).drop()
  });

  _exports.default = _default;
});