define("mdeditor/pods/components/layout/md-nav-sidebar/component", ["exports", "mdeditor/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['md-sidebar-wrapper'],
    classNameBindings: ['showHelp:help'],
    showHelp: false,
    prerelease: Ember.computed(function () {
      let version = this.version;

      if (version.substring(0, 3) === "0.0") {
        return 'alpha';
      }

      if (version.substring(0, 1) === "0" && version.substring(0, 3) > 0) {
        return 'beta';
      }
    }),
    version: Ember.computed(function () {
      let version = _environment.default.APP.version;
      return version.substring(0, version.indexOf('+'));
    }),
    actions: {
      toggleHelp() {
        this.toggleProperty('showHelp');
      },

      toggleSidebar() {
        (0, _jquery.default)('#md-wrapper').toggleClass('toggled'); //hack to force reflow

        (0, _jquery.default)('#md-navbar-main-collapse ul').hide().show(0);
      }

    }
  });

  _exports.default = _default;
});