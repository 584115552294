define("mdeditor/pods/record/show/edit/extent/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuUx0LsW",
    "block": "{\"symbols\":[\"extent\",\"index\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Extents\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"json\",\"metadata\",\"resourceInfo\",\"extent\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"object/md-extent\",null,[[\"title\",\"extent\",\"index\",\"profilePath\",\"deleteExtent\",\"editFeatures\",\"addExtent\"],[\"Extent\",[23,1,[]],[23,2,[]],\"record.extent\",[28,\"route-action\",[\"deleteExtent\"],null],[28,\"route-action\",[\"editFeatures\"],null],[28,\"route-action\",[\"addExtent\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"No Extents found.\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"addExtent\"],null]]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Add Extent\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Add Extent\",\"plus\",\"btn-lg btn-success\",[28,\"route-action\",[\"addExtent\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"refresh\",\"scrollInit\",\"setScrollTo\"],[[24,[\"refreshSpy\"]],[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/extent/index/template.hbs"
    }
  });

  _exports.default = _default;
});