define("mdeditor/pods/components/object/md-distribution/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'distributor': [(0, _emberCpValidations.validator)('array-required', {
      track: ['distributor'],
      disabled: Ember.computed.notEmpty('model.description'),
      message: 'At least one distributor is required if description is empty.'
    })],
    'description': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.notEmpty('model.distributor'),
      message: 'A description is required if a distributor is not entered.'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      Ember.run.once(this, function () {
        Ember.set(this.model, 'distributor', Ember.getWithDefault(this.model, 'distributor', []));
      });
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */

    /**
     * The passed down addDistribution method.
     *
     * @method addDistribution
     * @required
     */

    /**
     * The passed down editDistribution method.
     *
     * @method editDistribution
     * @param {Number} index
     * @required
     */

    /**
     * The passed down deleteDistribution method.
     *
     * @method deleteDistribution
     * @param {Number} index
     * @required
     */
    attributeBindings: ['data-spy'],
    tagName: 'section',
    distributor: Ember.computed.alias('model.distributor'),
    description: Ember.computed.alias('model.description'),
    distributorRequired: Ember.computed.not('validations.attrs.distributor.options.array-required.disabled'),
    actions: {
      editDistributor(index) {
        this.editDistributor(index);
      },

      deleteDistribution(index) {
        this.deleteDistribution(index);
      },

      addDistribution() {
        this.addDistribution();
      }

    }
  });

  _exports.default = _default;
});