define("mdeditor/pods/components/control/md-record-table/buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['md-dashboard-buttons'],
    actions: {
      deleteItem(item, index, isSelected, clickOnRow) {
        if (isSelected) {
          clickOnRow(index, item);
        }

        this._deleteItem(item);
      },

      editItem(item, evt) {
        evt.stopPropagation();
        this.router.transitionTo("".concat(item.constructor.modelName, ".show.edit"), item);
        return false;
      },

      showSlider(rec, evt) {
        this.column.showSlider(rec, evt);
      }

    },

    _deleteItem(item) {
      item.destroyRecord().then(() => {
        item.unloadRecord();
      });
    }

  });

  _exports.default = _default;
});