define("mdeditor/pods/dictionary/show/edit/domain/edit/item/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    beforeModel() {
      this.set('domainId', this.paramsFor('dictionary.show.edit.domain.edit').domain_id);
    },

    model(params) {
      this.set('itemId', params.item_id);
      return this.setupModel();
    },

    breadCrumb: Ember.computed('itemId', function () {
      return {
        title: 'Item ' + this.itemId
      };
    }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments); //let parent = this.controllerFor('dictionary.show.edit.domain.edit.index');


      this.controller.set('parentModel', this.modelFor('dictionary.show.edit'));
      this.controller.set('domainId', this.domainId);
      this.controller.set('itemId', this.itemId);
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let itemId = this.itemId;
      let model = this.modelFor('dictionary.show.edit');
      let objects = model.get('json.dataDictionary.domain.' + this.domainId + '.domainItem');
      let resource = itemId && Ember.isArray(objects) ? objects.objectAt(itemId) : undefined; //make sure the domain item exists

      if (Ember.isEmpty(resource)) {
        this.flashMessages.warning('No Domain Item found! Re-directing to list...');
        this.replaceWith('dictionary.show.edit.domain');
        return;
      }

      return resource;
    },

    actions: {
      backToDomain() {
        this.transitionTo('dictionary.show.edit.domain.edit', this.domainId);
      }

    }
  });

  _exports.default = _default;
});