define("mdeditor/pods/components/layout/nav/record/nav-main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zTzFvsBc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"record.show.translate\",[24,[\"model\"]]]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"fa fa-retweet\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"md-nav-text\"],[8],[0,\"Translate\"],[9],[0,\"\\n    \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\",\"tooltipClass\"],[\"Translate\",\"bottom\",\"ember-tooltip md-nav-tip\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"divider-vertical\"],[8],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[8],[0,\"\\n\"],[1,[28,\"from-elsewhere\",null,[[\"name\"],[\"record-nav\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/nav/record/nav-main/template.hbs"
    }
  });

  _exports.default = _default;
});