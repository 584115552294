define("mdeditor/pods/components/control/md-edit-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ip+tV7ZU",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"control/md-record-table\",null,[[\"class\",\"actionsColumn\",\"selectRowOnClick\",\"data\",\"dataColumns\",\"expandedItems\",\"columnSets\",\"pageSize\",\"expandedRowComponent\"],[\"md-edit-table\",[24,[\"actionsColumn\"]],[24,[\"selectRowOnClick\"]],[24,[\"data\"]],[24,[\"dataColumns\"]],[24,[\"expandedItems\"]],[24,[\"columnSets\"]],[24,[\"pageSize\"]],[28,\"component\",[\"md-models-table/components/row-body\"],[[\"spotlighted\",\"rowBodyComponent\"],[[24,[\"spotlightRow\"]],[24,[\"rowBodyComponent\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-edit-table/template.hbs"
    }
  });

  _exports.default = _default;
});