define("mdeditor/models/record", ["exports", "ember-copy", "ember-data", "uuid/v4", "mdeditor/models/base", "ember-cp-validations", "mdeditor/config/environment"], function (_exports, _emberCopy, _emberData, _v, _base, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      defaultProfileId
    }
  } = _environment.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'recordId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'json.metadata.resourceInfo.resourceType': [(0, _emberCpValidations.validator)('array-valid'), (0, _emberCpValidations.validator)('array-required', {
      track: ['type']
    })],
    'json.metadata.resourceInfo.pointOfContact': {
      disabled: Ember.computed.alias('model.isNew'),
      validators: [(0, _emberCpValidations.validator)('array-valid'), (0, _emberCpValidations.validator)('array-required', {
        track: ['type']
      })]
    },
    // 'json.resourceInfo.abstract': validator('presence', {
    //   presence: true,
    //   ignoreBlank: true
    // }),
    'json.metadata.resourceInfo.citation.title': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }) // 'json.metadata.resourceInfo.citation': validator('length', {
    //   min: 1
    // }),
    // 'json.metadata.resourceInfo.status': validator('length', {
    //   min: 1
    // }),
    // 'json.metadata.resourceInfo.pointOfContact': validator('length', {
    //   min: 1
    // }),
    // 'json.metadata.resourceInfo.defaultResourceLocale': validator('length', {
    //   min: 1
    // })

  });

  const Record = _base.default.extend(Validations, _emberCopy.Copyable, {
    /**
     * Record(metadata) model
     *
     * @class record
     * @constructor
     * @extends base
     * @module mdeditor
     * @submodule data-models
     */
    profile: _emberData.default.attr('string', {
      defaultValue: defaultProfileId
    }),
    json: _emberData.default.attr('json', {
      defaultValue() {
        const obj = Ember.Object.create({
          schema: {
            name: 'mdJson',
            version: '2.6.0'
          },
          contact: [],
          metadata: {
            metadataInfo: {
              metadataIdentifier: {
                identifier: (0, _v.default)(),
                namespace: 'urn:uuid'
              },
              metadataContact: [],
              defaultMetadataLocale: {}
            },
            resourceInfo: {
              resourceType: [{}],
              citation: {
                title: null,
                date: []
              },
              pointOfContact: [],
              abstract: '',
              shortAbstract: '',
              status: [],
              defaultResourceLocale: {// characterSet: UTF-8,
                // country: USA,
                // language: eng
              },
              timePeriod: {
                periodName: []
              },
              extent: [],
              keyword: []
            }
          },
          metadataRepository: [],
          dataDictionary: []
        });
        return obj;
      }

    }),
    dateUpdated: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    title: Ember.computed.alias('json.metadata.resourceInfo.citation.title'),
    icon: Ember.computed('json.metadata.resourceInfo.resourceType.firstObject.type', function () {
      const type = this.get('json.metadata.resourceInfo.resourceType.0.type') || '';
      const list = Ember.getOwner(this).lookup('service:icon');
      return type ? list.get(type) || list.get('default') : list.get('defaultFile');
    }),
    recordId: Ember.computed.alias('json.metadata.metadataInfo.metadataIdentifier.identifier'),
    recordIdNamespace: Ember.computed.alias('json.metadata.metadataInfo.metadataIdentifier.namespace'),
    parentIds: Ember.computed.alias('json.metadata.metadataInfo.parentMetadata.identifier'),
    hasParent: Ember.computed('parentIds.[]', function () {
      let ids = this.parentIds;
      let allRecords = this.store.peekAll('record');
      let records = allRecords.rejectBy('hasSchemaErrors');

      if (!ids) {
        return false;
      }

      return ids.find(id => {
        return records.findBy('recordId', id.identifier) ? true : false;
      });
    }),
    defaultParent: Ember.computed('hasParent', function () {
      let id = this.get('hasParent.identifier');
      let allRecords = this.store.peekAll('record');

      if (!id) {
        return undefined;
      }

      return allRecords.findBy('recordId', id);
    }),
    defaultType: Ember.computed.alias('json.metadata.resourceInfo.resourceType.firstObject.type'),

    /**
     * The trimmed varsion of the recordId.
     *
     * @property shortId
     * @type {String}
     * @readOnly
     * @category computed
     * @requires recordId
     */
    shortId: Ember.computed('recordId', function () {
      const recordId = this.recordId;

      if (recordId) {
        let index = recordId.indexOf('-');
        return recordId.substring(0, index > -1 ? index : 8);
      }

      return recordId;
    }),

    /**
     * A list of schema errors return by the validator.
     *
     * @property hasSchemaErrors
     * @type {Array}
     * @readOnly
     * @category computed
     * @requires status
     */
    schemaErrors: Ember.computed('hasDirtyHash', 'customSchemas.[]', function () {
      let mdjson = this.mdjson;
      let errors = [];
      let result = mdjson.validateRecord(this).errors;

      if (result) {
        errors.pushObject({
          title: 'Default Record Validation',
          errors: result
        });
      }

      this.customSchemas.forEach(schema => {
        const validator = schema.validator;

        if (!validator) {
          return;
        }

        if (validator.validate(schema.rootSchema, mdjson.formatRecord(this))) {
          return;
        }

        errors.pushObject({
          title: schema.title,
          errors: validator.errors
        });
      });
      return errors;
    }),
    formatted: Ember.computed.alias('_formatted'),

    copy() {
      let current = this.cleanJson;
      let json = Ember.Object.create(current);
      let name = current.metadata.resourceInfo.citation.title;
      json.set('metadata.resourceInfo.citation.title', "Copy of ".concat(name));
      json.set('metadata.resourceInfo.resourceType', Ember.getWithDefault(json, 'metadata.resourceInfo.resourceType', [{}]));
      json.set('metadata.metadataInfo.metadataIdentifier', {
        identifier: (0, _v.default)(),
        namespace: 'urn:uuid'
      });
      return this.store.createRecord('record', {
        json: json
      });
    }

  });

  Object.defineProperty(Record.prototype, '_formatted', {
    get() {
      return this.mdjson.formatRecord(this);
    }

  });
  var _default = Record;
  _exports.default = _default;
});