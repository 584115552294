define("mdeditor/pods/components/object/md-taxonomy/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    title: Ember.computed('model.taxonomicSystem.0.citation.title', function () {
      let title = this.get('model.taxonomicSystem.0.citation.title');
      let index = this.index;
      return "Collection #".concat(index) + (title ? ": ".concat(title) : '');
    }),
    actions: {
      editCollection(id) {
        this.set('scrollTo', "collection-".concat(id));
        this.router.transitionTo('record.show.edit.taxonomy.collection.index', id);
      },

      deleteCollection(id) {
        let taxa = this.get('record.json.metadata.resourceInfo.taxonomy');
        taxa.removeAt(id);
      }

    }
  });

  _exports.default = _default;
});