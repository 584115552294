define("mdeditor/pods/components/layout/md-breadcrumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gO7BvOt4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"ol\",true]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/md-breadcrumb/template.hbs"
    }
  });

  _exports.default = _default;
});