define("mdeditor/pods/dictionary/show/edit/domain/edit/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fK0hOMnv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Domain Item \"],[4,\"if\",[[24,[\"model\",\"name\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"em\",true],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    #\"],[1,[22,\"itemId\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-domainitem\",null,[[\"class\",\"model\",\"profilePath\"],[\"\",[24,[\"model\"]],\"dictionary.domain.domainItem\"]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"text\",\"icon\",\"type\",\"click\"],[\"Back to Domain\",\"arrow-left\",\"primary\",[28,\"route-action\",[\"backToDomain\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-dict-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/edit/domain/edit/item/template.hbs"
    }
  });

  _exports.default = _default;
});