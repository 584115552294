define("mdeditor/pods/components/object/md-source/component", ["exports", "ember-cp-validations", "uuid/v4"], function (_exports, _emberCpValidations, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'description': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'sourceId', Ember.getWithDefault(model, 'sourceId', (0, _v.default)()));
        Ember.set(model, 'sourceCitation', Ember.getWithDefault(model, 'sourceCitation', {}));
        Ember.set(model, 'metadataCitation', Ember.getWithDefault(model, 'metadataCitation', []));
        Ember.set(model, 'spatialResolution', Ember.getWithDefault(model, 'spatialResolution', {}));
        Ember.set(model, 'referenceSystem', Ember.getWithDefault(model, 'referenceSystem', {}));
        Ember.set(model, 'referenceSystem.referenceSystemIdentifier', Ember.getWithDefault(model, 'referenceSystem.referenceSystemIdentifier', {}));
        Ember.set(model, 'sourceProcessStep', Ember.getWithDefault(model, 'sourceProcessStep', []));
        Ember.set(model, 'scope', Ember.getWithDefault(model, 'scope', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the domain.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the domain.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    domainId: Ember.computed.alias('model.domainId'),
    codeName: Ember.computed.alias('model.codeName'),
    description: Ember.computed.alias('model.description')
  });

  _exports.default = _default;
});