define("mdeditor/pods/contact/show/route", ["exports", "ember-copy", "mdeditor/mixins/scroll-to"], function (_exports, _emberCopy, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    flashMessages: Ember.inject.service(),
    model: function model(params) {
      let rec = this.store.peekRecord('contact', params.contact_id);
      return rec;
    },
    actions: {
      saveContact: function saveContact() {
        let model = this.currentRouteModel();
        model.save().then(() => {
          //this.refresh();
          //this.setModelHash();
          this.flashMessages.success("Saved Contact: ".concat(model.get('title'))); //this.transitionTo('contacts');
        });
      },
      destroyContact: function destroyContact() {
        let model = this.currentRouteModel();
        model.destroyRecord().then(() => {
          this.flashMessages.success("Deleted Contact: ".concat(model.get('title')));
          this.replaceWith('contacts');
        });
      },
      cancelContact: function cancelContact() {
        let model = this.currentRouteModel();
        let message = "Cancelled changes to Contact: ".concat(model.get('title'));

        if (this.get('settings.data.autoSave')) {
          let json = model.get('jsonRevert');

          if (json) {
            model.set('json', JSON.parse(json));
            this.flashMessages.warning(message);
          }

          return;
        }

        model.reload().then(() => {
          this.flashMessages.warning(message);
        });
      },
      copyContact: function copyContact() {
        this.flashMessages.success("Copied Contact: ".concat(this.currentRouteModel().get('title')));
        this.transitionTo('contact.new.id', (0, _emberCopy.copy)(this.currentRouteModel()));
      }
    }
  });

  _exports.default = _default;
});