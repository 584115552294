define("mdeditor/shapefile", ["exports", "shapefile"], function (_exports, _shapefile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "shapefile", {
    enumerable: true,
    get: function get() {
      return _shapefile.default;
    }
  });
});