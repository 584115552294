define("mdeditor/pods/components/control/md-json-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    slider: Ember.inject.service(),
    tagName: 'button',
    classNames: ['btn'],
    attributeBindings: ['type'],
    type: 'button',
    text: 'Preview JSON',
    icon: 'binoculars',
    json: Ember.computed(function () {
      return {};
    }),
    hideSlider: true,
    propagateClick: false,

    click(evt) {
      //this.set('preview', true);
      if (!this.propagateClick) {
        evt.stopPropagation();
      }

      this.showSlider();
    },

    _close() {
      this.set('preview', false);
      this.set('hideSlider', true);
    },

    showSlider() {
      let slider = this.slider;
      slider.set('fromName', 'md-slider-json');
      slider.set('onClose', this._close);
      slider.set('context', this);
      slider.toggleSlider(true);
      this.set('hideSlider', false);
    },

    actions: {
      close() {
        this._close;
      },

      showSlider() {
        this.showSlider();
      }

    }
  });

  _exports.default = _default;
});