define("mdeditor/pods/record/show/edit/metadata/identifier/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    model() {
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let model = this.modelFor('record.show.edit'); //make sure the identifier exists

      model.set('json.metadata.metadataInfo.metadataIdentifier', Ember.getWithDefault(model, 'json.metadata.metadataInfo.metadataIdentifier', {}));
      return Ember.get(model, 'json.metadata.metadataInfo.metadataIdentifier');
    }

  });

  _exports.default = _default;
});