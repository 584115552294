define("mdeditor/pods/components/layout/nav/dictionary/nav-main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nK3VPbaY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"divider-vertical\"],[8],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[8],[0,\"\\n\"],[1,[28,\"from-elsewhere\",null,[[\"name\"],[\"dictionary-nav\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/nav/dictionary/nav-main/template.hbs"
    }
  });

  _exports.default = _default;
});