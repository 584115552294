define("mdeditor/pods/components/control/md-repo-link/component", ["exports", "mdeditor/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      repository,
      version
    }
  } = _environment.default;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    classNames: ['md-fa-link'],

    /**
     * Application repository URL.
     *
     * @property repository
     * @type string
     */
    repository,

    /**
     * Link target, open in new window by default.
     *
     * @property target
     * @type string
     */
    target: '_blank',

    /**
     * Application version.
     *
     * @property version
     * @type string
     */
    version,

    /**
     * The commit hash reference.
     *
     * @property hash
     * @type {Ember.computed}
     * @return string
     */
    hash: Ember.computed('version', function () {
      let idx = this.version.indexOf('+') + 1;
      return version.substring(idx);
    }),

    /**
     * The url link
     *
     * @property href
     * @type {Ember.computed}
     * @return string
     */
    href: Ember.computed('repository', 'hash', function () {
      let repo = this.repository;
      let hash = this.hash;
      return "".concat(repo, "/tree/").concat(hash);
    })
  });

  _exports.default = _default;
});