define("mdeditor/pods/components/object/md-citation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatCitation = void 0;

  const formatCitation = function formatCitation(model) {
    Ember.set(model, 'responsibleParty', Ember.getWithDefault(model, 'responsibleParty', []));
    Ember.set(model, 'date', Ember.getWithDefault(model, 'date', []));
    Ember.set(model, 'alternateTitle', Ember.getWithDefault(model, 'alternateTitle', []));
    Ember.set(model, 'presentationForm', Ember.getWithDefault(model, 'presentationForm', []));
    Ember.set(model, 'onlineResource', Ember.getWithDefault(model, 'onlineResource', []));
    Ember.set(model, 'identifier', Ember.getWithDefault(model, 'identifier', []));
    Ember.set(model, 'otherCitationDetails', Ember.getWithDefault(model, 'otherCitationDetails', []));
    Ember.set(model, 'graphic', Ember.getWithDefault(model, 'graphic', []));
    Ember.set(model, 'series', Ember.getWithDefault(model, 'series', {}));
    return model;
  };

  _exports.formatCitation = formatCitation;
  const theComp = Ember.Component.extend({
    /**
     * mdEditor class for input and edit of mdJSON 'citation' objects.
     *
     * ```handlebars
     * \{{object/md-citation
     *  model=citation
     *  profilePath="path"
     *  simpleIdentifier=false
     *  embedded=false
     * }}
     * ```
     * @module mdeditor
     * @submodule components-object
     * @class md-citation
     * @constructor
     */
    didReceiveAttrs() {
      this._super(...arguments); //let model = getWithDefault(this, 'model', {}) || {};


      Ember.run.once(this, function () {
        this.set('model', Ember.getWithDefault(this, 'model', {}));
        formatCitation(this.model);
      });
    },

    tagName: 'form',

    /**
     * The string representing the path in the profile object for the citation.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model for the citation.
     *
     * @property model
     * @type {Object}
     * @required
     */

    /**
     * Indicates whether the citation is embedded and should prevent recursion.
     *
     * @property embedded
     * @type {Boolean}
     * @default "false"
     */
    embedded: false,

    /**
     * The data-spy title, set to false to hide.
     *
     * @property data-spy
     * @type {String}
     * @default "Citation"
     */
    'data-spy': 'Citation',

    /**
     * Indicates whether the citation identifier Component should be rendered using
     * the inline form: md-identifier-object-table.
     *
     * @property simpleIdentifier
     * @type {Boolean}
     * @default "false"
     */
    simpleIdentifier: false
  });
  _exports.default = theComp;
});