define("mdeditor/pods/record/show/edit/funding/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ikWmMYj+",
    "block": "{\"symbols\":[\"allocation\",\"index\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Funding\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"json\",\"metadata\",\"funding\"]]],null,{\"statements\":[[4,\"layout/md-object-container\",null,[[\"title\",\"index\",\"editItem\",\"deleteItem\"],[\"Funding Period\",[23,2,[]],[28,\"route-action\",[\"editAllocation\"],null],[28,\"route-action\",[\"deleteAllocation\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"object/md-funding/preview\",null,[[\"item\",\"profilePath\"],[[23,1,[]],\"record.funding\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"No Funding Periods found.\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addAllocation\"]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Add Funding Period\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"clickText\",\"clickIcon\",\"clickType\",\"click\"],[\"Add Funding Period\",\"plus\",\"success\",[28,\"route-action\",[\"addAllocation\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"refresh\",\"scrollInit\",\"setScrollTo\"],[[24,[\"refresh\"]],[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/funding/index/template.hbs"
    }
  });

  _exports.default = _default;
});