define("mdeditor/pods/components/object/md-online-resource-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7nLfy2Y",
    "block": "{\"symbols\":[\"editing\",\"&default\"],\"statements\":[[4,\"object/md-object-table\",null,[[\"items\",\"header\",\"shadow\",\"buttonText\",\"ellipsis\",\"previewTemplate\",\"profilePath\",\"templateClass\",\"attributes\"],[[24,[\"model\"]],[24,[\"label\"]],[24,[\"shadow\"]],\"Add Resource\",[24,[\"ellipsis\"]],[24,[\"previewTemplate\"]],[24,[\"profilePath\"]],[24,[\"templateClass\"]],[24,[\"attributes\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"object/md-online-resource\",null,[[\"imagePicker\",\"model\",\"profilePath\"],[[24,[\"imagePicker\"]],[23,1,[]],[24,[\"profilePath\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-online-resource-array/template.hbs"
    }
  });

  _exports.default = _default;
});