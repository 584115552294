define("mdeditor/pods/record/show/edit/associated/resource/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sliderColumns = [{
    propertyName: 'recordId',
    title: 'ID'
  }, {
    propertyName: 'title',
    title: 'Title'
  }, {
    propertyName: 'defaultType',
    title: 'Type'
  }];

  var _default = Ember.Route.extend(_scrollTo.default, {
    slider: Ember.inject.service(),
    sliderColumns: sliderColumns,
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('resourceId', Ember.get(this.controllerFor('record.show.edit.associated.resource'), 'resourceId'));
    },
    actions: {
      insertResource(selected) {
        let slider = this.slider;
        let rec = selected.get('firstObject');

        if (rec) {
          let resource = this.currentRouteModel();
          Ember.set(resource, 'mdRecordId', Ember.get(rec, 'recordId'));
        } //this.controller.set('slider', false);


        slider.toggleSlider(false);
        selected.clear();
      },

      selectResource() {
        let slider = this.slider; //this.controller.set('slider', true);

        slider.toggleSlider(true);
      },

      sliderData() {
        return this.store.peekAll('record').filterBy('recordId');
      },

      sliderColumns() {
        return this.sliderColumns;
      },

      editLinked(rec) {
        this.transitionTo('record.show.edit', rec.get('id'));
      }

    }
  });

  _exports.default = _default;
});