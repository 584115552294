define("mdeditor/pods/components/input/md-select-profile/component", ["exports", "ember-basic-dropdown/utils/calculate-position"], function (_exports, _calculatePosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
    * Input that displays available record profiles.
    *
    * @module mdeditor
    * @submodule components-input
    * @class input/md-select-profile
    * @constructor
    */
    profile: Ember.inject.service('custom-profile'),

    /**
     * Update the record profile
     *
     * @method   action.updateProfile
     * @param  {String} profile The new profile.
     */
    updateProfile(profile) {
      this.profile.set('active', profile);
      this.record.save();
    },

    /**
    * Calculate the width of the input.
    *
    * @method calculatePosition
    * @private
    * @return {String}
    */
    calculatePosition() {
      let originalValue = (0, _calculatePosition.default)(...arguments);
      originalValue.style['min-width'] = originalValue.style.width + 'px';
      originalValue.style.width = 'auto';
      originalValue.style['max-width'] = '250px';
      return originalValue;
    },

    actions: {
      /**
       * Update the record profile
       *
       * @method   action.updateProfile
       * @param  {String} profile The new profile.
       */
      updateProfile(profile) {
        this.updateProfile(profile);
      }

    }
  });

  _exports.default = _default;
});