define("mdeditor/pods/components/control/md-crud-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'md-crud-buttons',
    settings: Ember.inject.service(),

    /**
     * Indicates whether to display the "Delete" button. If not defined, defaults
     * to "settings.data.showDelete".
     *
     * @property allowDelete
     * @type {Boolean}
     * @default "settings.showDelete"
     */

    /**
     * Indicates whether to display the "Copy" button. If not defined, defaults to
     * "settings.data.showDelete".
     *
     * @property allowCopy
     * @type {Boolean}
     * @default "settings.showDelete"
     */
    showDelete: Ember.computed('settings.showDelete', 'allowDelete', function () {
      return Ember.isEmpty(this.allowDelete) ? this.settings.data.showDelete : this.allowDelete;
    }),
    showCopy: Ember.computed('settings.showDelete', 'allowCopy', function () {
      return Ember.isEmpty(this.allowCopy) ? this.settings.data.showCopy : this.allowCopy;
    }),
    actions: {
      save: function save() {
        this.doSave();
      },
      cancel: function cancel() {
        this.doCancel();
      },
      delete: function _delete() {
        this.doDelete();
      },
      copy: function copy() {
        this.doCopy();
      }
    }
  });

  _exports.default = _default;
});