define("mdeditor/pods/record/show/edit/distribution/distributor/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('distributionId', params.distribution_id);
      this.set('distributorId', params.distributor_id);
      return this.setupModel();
    },

    // breadCrumb: computed('distributionId', function () {
    //   return {
    //     title: this.distributionId
    //     //title: `${get(this, 'distributionId')}: Distributors`
    //   };
    // }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments); //this.controller.set('parentModel', this.modelFor('record.show.edit.main'));


      this.controller.set('distributionId', this.distributionId);
      this.controller.set('distributorId', this.distributorId);
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let distributionId = this.distributionId;
      let distributorId = this.distributorId;
      let model = this.modelFor('record.show.edit');
      let objects = model.get('json.metadata.resourceDistribution');
      let distribution = distributionId && Ember.isArray(objects) ? Ember.A(objects).objectAt(distributionId) : undefined;
      let distributor = distribution && distributorId && Ember.isArray(distribution.distributor) ? Ember.A(distribution.distributor).objectAt(distributorId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(distributor)) {
        this.flashMessages.warning('No Distributor object found! Re-directing to Distribution List...');
        this.replaceWith('record.show.edit.distribution');
        return;
      }

      return distributor;
    }

  });

  _exports.default = _default;
});