define("mdeditor/pods/record/show/edit/distribution/index/route", ["exports", "jquery", "mdeditor/mixins/scroll-to"], function (_exports, _jquery, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      Ember.defineProperty(this.controller, 'refreshSpy', Ember.computed.alias('model.json.metadata.resourceDistribution.length'));
    },
    actions: {
      addDistribution() {
        let dists = this.currentRouteModel().get('json.metadata.resourceDistribution');
        dists.pushObject({});
        (0, _jquery.default)("html, body").animate({
          scrollTop: (0, _jquery.default)(document).height()
        }, "slow");
      },

      editDistributor(id, routeParams, scrollToId) {
        this.setScrollTo(scrollToId);
        this.transitionTo('record.show.edit.distribution.distributor', routeParams, id);
      },

      deleteDistribution(id) {
        let dists = this.currentRouteModel().get('json.metadata.resourceDistribution');
        dists.removeAt(id);
      }

    }
  });

  _exports.default = _default;
});