define("mdeditor/pods/components/object/md-identifier/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'identifier': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const theComp = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.getWithDefault(this, 'model', {}) || {};
      Ember.run.once(this, function () {
        Ember.set(model, 'authority', Ember.getWithDefault(model, 'authority', {}));
      });
    },

    classNames: ['md-identifier'],
    attributeBindings: ['data-spy'],

    /**
     * The identifier object to render
     *
     * @property model
     * @type {object}
     * @required
     */

    /**
     * Render short form of the identifier template, i.e. no authority
     *
     * @property short
     * @type {Boolean}
     */

    /**
     * Determines whether to render identifier field with confirmation button
     *
     * @property confirmIdentifier
     * @type {Boolean}
     */
    identifier: Ember.computed.alias('model.identifier')
  });
  _exports.default = theComp;
});