define("mdeditor/mixins/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      scrollTo: true
    },

    setScrollTo(scrollTo) {
      this.controller.set('scrollTo', scrollTo || '');
    },

    actions: {
      setScrollTo(scrollTo) {
        this.setScrollTo(scrollTo);
      }

    }
  });

  _exports.default = _default;
});