define("mdeditor/pods/components/control/md-record-table/component", ["exports", "mdeditor/pods/components/md-models-table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @module mdeditor
     * @submodule components-control
     */

    /**
     * Table used to display objects. Includes column to toggle selection for all
     * rows.
     *
     *```handlebars
     * \{{control/md-record-table
     *   data=model.data
     *   columns=model.columns
     *   select=callback
     * }}
     * ```
     *
     * @class md-select-table
     * @extends models-table
     */
    init() {
      this.dataColumns = this.dataColumns || [];
      this.filteringIgnoreCase = this.filteringIgnoreCase || true;
      this.multipleSelect = this.multipleSelect || true;
      Ember.defineProperty(this, 'columns', Ember.computed('dataColumns', 'checkColumn', function () {
        let chk = this.checkColumn;
        let action = this.actionsColumn;
        let cols = Ember.A().concat(this.dataColumns);

        if (chk) {
          cols = [chk].concat(cols);
        }

        if (action) {
          cols.push(action);
        }

        return cols;
      }));

      this._super(...arguments);
    },

    classNames: ['md-record-table'],

    /**
     * Property name used to identify selected records. Should begin with underscore.
     *
     * @property selectProperty
     * @type {String}
     * @default "_selected"
     * @static
     * @readOnly
     * @required
     */
    selectProperty: '_selected',

    /**
     * Array of table records
     *
     * @property data
     * @type {Array}
     * @default []
     * @required
     */

    /**
     * Array of column configs for the table.
     * See http://onechiporenko.github.io/ember-models-table
     *
     * ```javascript
     * [{
     *  propertyName: 'id',
     *  title: 'ID'
     * }, {
     *  title: '',
     *  template: 'components/leaflet-table/actions',
     *  className: 'text-center text-nowrap'
     * }]
     * ```
     *
     * @property dataColumns
     * @type {Array}
     * @required
     * @default []
     */

    /**
     * Column configs for the checkbox column.
     * See http://onechiporenko.github.io/ember-models-table
     *
     *
     * @property checkColumns
     * @type {Object}
     * @required
     */
    checkColumn: Ember.computed(function () {
      return {
        component: 'components/md-models-table/components/check',
        disableFiltering: true,
        mayBeHidden: false,
        componentForSortCell: 'components/md-models-table/components/check-all',
        className: 'text-center'
      };
    }),

    /**
     * Column configs for the action column.
     * See http://onechiporenko.github.io/ember-models-table
     *
     *
     * @property actionsColumn
     * @type {Object}
     * @required
     */
    actionsColumn: Ember.computed('allActions', function () {
      let all = this.allActions;
      return {
        title: 'Actions',
        className: 'md-actions-column',
        component: all ? 'control/md-record-table/buttons' : 'control/md-record-table/buttons/show',
        disableFiltering: !all,
        componentForFilterCell: all ? 'control/md-record-table/buttons/filter' : null,
        showSlider: this.showSlider
      };
    }),
    selectedItems: Ember.computed({
      get() {
        let prop = this.selectProperty;
        return this.data.filterBy(prop).toArray();
      },

      set(k, v) {
        if (!Ember.isArray(v)) {
          (true && Ember.warn('`selectedItems` must be an array.', false, {
            id: '#emt-selectedItems-array'
          }));
        }

        return Ember.A(v);
      }

    }),

    /**
     * Callback on row selection.
     *
     * @method select
     * @param {Object} rec Selected record.
     * @param {Number} index Selected row index.
     * @param {Array} selected Selected records.
     * @return {Array} Selected records.
     */
    select(rec, index, selected) {
      return selected;
    },

    actions: {
      clickOnRow(idx, rec) {
        this._super(...arguments);

        let prop = this.selectProperty;
        let sel = Ember.get(this, 'selectedItems');
        rec.toggleProperty(prop);
        this.select(rec, idx, sel);
      },

      toggleAllSelection() {
        //this._super(...arguments);
        let selectedItems = Ember.get(this, 'selectedItems');
        let data = Ember.get(this, 'data');
        const allSelectedBefore = Ember.get(selectedItems, 'length') === Ember.get(data, 'length');
        Ember.get(this, 'selectedItems').clear();

        if (!allSelectedBefore) {
          Ember.get(this, 'selectedItems').pushObjects(data.toArray());
        }

        this.userInteractionObserver();
        let selected = Ember.get(this, 'selectedItems');
        let prop = this.selectProperty; //let data = get(this, 'data');

        if (Ember.get(selected, 'length')) {
          selected.setEach(prop, true);
        } else {
          data.setEach(prop, false);
        }

        this.select(null, null, selected);
      }

    }
  });

  _exports.default = _default;
});