define("mdeditor/pods/components/object/md-profile/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VTb1F6b3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input/md-input\",null,[[\"model\",\"valuePath\",\"label\",\"placeholder\",\"showValidations\"],[[24,[\"record\"]],\"uri\",\"URL\",\"Enter URL of the profile.\",true]]],false],[0,\"\\n\\n\"],[1,[28,\"input/md-input\",null,[[\"value\",\"label\",\"placeholder\",\"showValidations\"],[[24,[\"record\",\"alias\"]],\"Alias\",\"Enter an alternative title.\",true]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"\"],[8],[0,\"Version\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[28,\"if\",[[24,[\"record\",\"localVersion\"]],[24,[\"record\",\"localVersion\"]],\"--\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"record\",\"hasUpdate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"label label-pill label-info\"],[8],[0,\"Update Available (\"],[1,[24,[\"record\",\"remoteVersion\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-profile/preview\",null,[[\"record\"],[[24,[\"record\"]]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-profile/form/template.hbs"
    }
  });

  _exports.default = _default;
});