define("mdeditor/pods/components/object/md-domain/component", ["exports", "ember-cp-validations", "uuid/v4"], function (_exports, _emberCpValidations, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'domainId': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'codeName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'description': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'domainId', Ember.getWithDefault(model, 'domainId', (0, _v.default)()));
        Ember.set(model, 'domainItem', Ember.getWithDefault(model, 'domainItem', []));
        Ember.set(model, 'domainReference', Ember.getWithDefault(model, 'domainReference', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the domain.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the domain.
     *
     * @property model
     * @type {Object}
     * @required
     */

    /**
     * The passed down editDomainItem method.
     *
     * @method editDomainItem
     * @param {Number} index
     * @required
     */

    /**
     * The passed down editCitation method.
     *
     * @method editCitation
     * @param {String} scrollTo
     * @required
     */
    tagName: 'form',
    domainId: Ember.computed.alias('model.domainId'),
    codeName: Ember.computed.alias('model.codeName'),
    description: Ember.computed.alias('model.description'),
    actions: {
      editDomainItem(id) {
        this.editDomainItem(id);
      },

      editCitation(scrollTo) {
        this.editCitation(scrollTo);
      }

    }
  });

  _exports.default = _default;
});