define("mdeditor/pods/record/show/edit/keywords/thesaurus/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    keyword: Ember.inject.service(),

    model(params) {
      this.set('thesaurusId', params.thesaurus_id);
      return this.setupModel();
    },

    setupModel() {
      let thesaurusId = Ember.get(this, 'thesaurusId') || this.controller.get('thesaurusId');
      let model = this.modelFor('record.show.edit.keywords');
      let thesaurus = model.get('json.metadata.resourceInfo.keyword').get(thesaurusId); //make sure the thesaurus still exists

      if (Ember.isEmpty(thesaurus)) {
        Ember.get(this, 'flashMessages').warning('No thesaurus found! Re-directing to list...');
        this.replaceWith('record.show.edit.keywords');
        return;
      }

      if (!Ember.isArray(thesaurus.keyword)) {
        Ember.set(thesaurus, 'keyword', Ember.A());
      }

      return Ember.Object.create({
        id: thesaurusId,
        keywords: thesaurus,
        model: model,
        path: "json.metadata.resourceInfo.keyword.".concat(thesaurusId),
        thesaurus: this.keyword.findById(thesaurus.thesaurus.identifier[0].identifier)
      });
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this,
        thesaurusId: this.thesaurusId
      });
    },
    actions: {
      selectKeyword(node, path) {
        let model = this.currentRouteModel();
        let keywords = model.get('model').get(model.get('path'));
        let kw = keywords.keyword;
        let target = kw.findBy('identifier', node.uuid);

        if (node.isSelected && target === undefined) {
          let pathStr = '';

          if (Ember.isArray(path)) {
            pathStr = path.reduce(function (prev, item) {
              prev = prev ? "".concat(prev, " > ").concat(item.label) : item.label;
              return prev;
            }, '');
          }

          kw.pushObject({
            identifier: node.uuid,
            keyword: keywords.fullPath && pathStr ? pathStr : node.label,
            path: pathStr.split(' > ').slice(0, pathStr.length - 1)
          });
        } else {
          kw.removeObject(target);
        }
      },

      removeKeyword() {
        this.send('deleteKeyword', ...arguments);
      },

      changeFullPath(evt) {
        let model = this.currentRouteModel();
        let keywords = model.get('model').get(model.get('path'));
        let kw = Ember.get(keywords, 'keyword');
        let val = evt.target.checked;
        Ember.set(keywords, 'fullPath', val);
        kw.forEach(function (curr) {
          if (val) {
            Ember.set(curr, 'keyword', curr.path.join(' > '));
          } else {
            let words = curr.keyword.split(' > ');
            Ember.set(curr, 'keyword', words[words.length - 1]);
          }
        });
      }

    }
  });

  _exports.default = _default;
});