define("mdeditor/ember-leaflet-table/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/feature-form.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/feature-form.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/leaflet-draw.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/leaflet-draw.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/leaflet-table-row-actions.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/leaflet-table-row-actions.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/leaflet-table.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/leaflet-table.hbs should pass TemplateLint.\n\n');
  });
});