define("mdeditor/pods/components/object/md-spatial-resolution/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'scaleFactor': {
      disabled: Ember.computed.alias('model.scaleDisabled'),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        integer: true,
        allowBlank: true,
        positive: true,
        gt: 0
      })]
    },
    'levelOfDetail': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.alias('model.levelDisabled')
    })],
    'measure': [(0, _emberCpValidations.validator)('dependent', {
      on: ['measureType', 'measureValue', 'measureUnit']
    })],
    'measureType': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'measureUnit': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'measureValue': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');

      if (model) {
        Ember.run.once(this, function () {
          Ember.set(model, 'measure', Ember.getWithDefault(model, 'measure', {}));
        });
      }
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    classNames: ['form'],
    scaleFactor: Ember.computed.alias('model.scaleFactor'),
    scaleDisabled: Ember.computed('model.levelOfDetail', 'measurePresent', function () {
      return !Ember.isEmpty(this.get('model.levelOfDetail')) || this.measurePresent;
    }),
    levelOfDetail: Ember.computed.alias('model.levelOfDetail'),
    levelDisabled: Ember.computed('model.scaleFactor', 'measurePresent', function () {
      let scaleFactor = this.get('model.scaleFactor');
      return !Ember.isEmpty(scaleFactor) && !Number.isNaN(scaleFactor) || this.measurePresent;
    }),
    measure: Ember.computed.alias('model.measure'),
    measureDisabled: Ember.computed('model.{scaleFactor,levelOfDetail}', function () {
      let scaleFactor = this.get('model.scaleFactor');
      return !Ember.isEmpty(scaleFactor) && !Number.isNaN(scaleFactor) || !Ember.isEmpty(this.get('model.levelOfDetail'));
    }),
    measureType: Ember.computed.alias('model.measure.type'),
    measureValue: Ember.computed.alias('model.measure.value'),
    measureUnit: Ember.computed.alias('model.measure.unitOfMeasure'),
    measurePresent: Ember.computed.or('measureType', 'measureUnit', 'measureValue'),
    typeOptions: Ember.computed(function () {
      return [{
        name: 'distance',
        value: 'distance'
      }, {
        name: 'length',
        value: 'length'
      }, {
        name: 'angle',
        value: 'angle'
      }, {
        name: 'measure',
        value: 'measure'
      }, {
        name: 'scale',
        value: 'scale'
      }];
    })
  });

  _exports.default = _default;
});