define("mdeditor/pods/record/show/edit/main/index/route", ["exports", "mdeditor/mixins/scroll-to", "mdeditor/pods/components/object/md-citation/component"], function (_exports, _scrollTo, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata.resourceInfo');
      Ember.set(model, 'timePeriod', Ember.getWithDefault(model, 'timePeriod', {}));
      Ember.set(model, 'defaultResourceLocale', Ember.getWithDefault(model, 'defaultResourceLocale', {}));
      Ember.set(model, 'pointOfContact', Ember.getWithDefault(model, 'pointOfContact', []));
      Ember.set(model, 'status', Ember.getWithDefault(model, 'status', []));
      Ember.set(model, 'citation', Ember.getWithDefault(model, 'citation', (0, _component.formatCitation)({})));
      Ember.set(model, 'credit', Ember.getWithDefault(model, 'credit', []));
      Ember.set(model, 'resourceType', Ember.getWithDefault(model, 'resourceType', []));
      Ember.set(model, 'resourceMaintenance', Ember.getWithDefault(model, 'resourceMaintenance', []));
      Ember.set(model, 'graphicOverview', Ember.getWithDefault(model, 'graphicOverview', []));
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('record.show.edit').setProperties({
        onCancel: () => this,
        cancelScope: this
      });
    },

    actions: {
      editCitation(scrollTo) {
        this.transitionTo('record.show.edit.main.citation').then(function () {
          this.setScrollTo(scrollTo);
        }.bind(this));
      },

      editId() {
        this.transitionTo('record.show.edit.metadata.identifier', {
          queryParams: {
            scrollTo: 'identifier'
          }
        });
      }

    }
  });

  _exports.default = _default;
});