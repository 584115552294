define("mdeditor/pods/record/show/edit/metadata/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata.metadataInfo');
      Ember.run.once(this, () => {
        Ember.set(model, 'metadataContact', Ember.getWithDefault(model, 'metadataContact', []));
        Ember.set(model, 'metadataDate', Ember.getWithDefault(model, 'metadataDate', []));
        Ember.set(model, 'metadataMaintenance', Ember.getWithDefault(model, 'metadataMaintenance', {}));
        Ember.set(model, 'metadataOnlineResource', Ember.getWithDefault(model, 'metadataOnlineResource', []));
        Ember.set(model, 'defaultMetadataLocale', Ember.getWithDefault(model, 'defaultMetadataLocale', {}));
        Ember.set(model, 'metadataIdentifier', Ember.getWithDefault(model, 'metadataIdentifier', {}));
        Ember.set(model, 'parentMetadata', Ember.getWithDefault(model, 'parentMetadata', {}));
        Ember.set(model, 'alternateMetadataReference', Ember.getWithDefault(model, 'alternateMetadataReference', []));
        Ember.set(m, 'json.metadataRepository', Ember.getWithDefault(m, 'json.metadataRepository', []));
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('record.show.edit').setProperties({
        onCancel: () => this,
        cancelScope: this
      });
    },

    actions: {
      editIdentifier() {
        this.transitionTo('record.show.edit.metadata.identifier').then(function () {
          this.setScrollTo('metadata-identifier');
        }.bind(this));
      },

      editAlternate(index) {
        this.transitionTo('record.show.edit.metadata.alternate.index', index).then(function () {
          this.setScrollTo('alternate-metadata');
        }.bind(this));
      },

      editParent() {
        this.transitionTo('record.show.edit.metadata.parent').then(function () {
          this.setScrollTo('parent-metadata');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});