define("mdeditor/pods/components/object/md-attribute/preview/component", ["exports", "mdeditor/pods/components/object/md-attribute/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_component.Validations, {
    tagName: '',
    model: Ember.computed.alias('item'),
    codeName: Ember.computed.alias('model.codeName'),
    dataType: Ember.computed.alias('model.dataType'),
    definition: Ember.computed.alias('model.definition'),
    allowNull: Ember.computed.alias('model.allowNull')
  });

  _exports.default = _default;
});