define("mdeditor/pods/components/object/md-phone-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lcFDdMvd",
    "block": "{\"symbols\":[\"phone\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"title\",\"value\",\"validation\",\"data-spy\",\"templateClass\"],[\"Name,Number,Services\",\"Phone\",[24,[\"value\"]],[24,[\"validation\"]],null,[24,[\"templateClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n      \"],[1,[28,\"input/md-input\",null,[[\"value\",\"placeholder\"],[[23,1,[\"item\",\"phoneName\"]],\"Name or location or phone\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n      \"],[1,[28,\"input/md-input\",null,[[\"valuePath\",\"model\",\"showValidations\",\"placeholder\"],[\"phoneNumber\",[23,1,[\"item\"]],true,\"Phone number\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"input/md-codelist-multi\",null,[[\"create\",\"tooltip\",\"icon\",\"disabled\",\"allowClear\",\"mdCodeName\",\"value\",\"placeholder\"],[true,true,false,[24,[\"disabled\"]],true,\"telephone\",[23,1,[\"item\",\"service\"]],\"Choose phone type\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-phone-array/template.hbs"
    }
  });

  _exports.default = _default;
});