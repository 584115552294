define("mdeditor/pods/components/object/md-taxonomy/collection/voucher/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Template = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'specimen': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'repository': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const Template = Ember.Object.extend(Validations, {
    init() {
      this._super(...arguments);

      this.set('repository', {});
      this.set('specimen', null);
    }

  });
  _exports.Template = Template;
  const theComp = Ember.Component.extend(Validations, {
    classNames: ['form'],
    specimen: Ember.computed.alias('model.specimen'),
    repository: Ember.computed.alias('model.repository'),

    didReceiveAttrs() {
      this._super(...arguments);

      let model = this.model;
      Ember.run.once(this, function () {
        Ember.set(model, 'repository', Ember.getWithDefault(model, 'repository', {}));
        Ember.set(model, 'specimen', Ember.getWithDefault(model, 'specimen', null));
      });
    },

    //attributeBindings: ['data-spy'],
    templateClass: Template
  });
  _exports.default = theComp;
});