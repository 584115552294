define("mdeditor/pods/record/show/translate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        writer: controller.writer || null,
        forceValid: controller.forceValid || false,
        showAllTags: controller.showAllTags || false
      });
    }

  });

  _exports.default = _default;
});