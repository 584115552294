define("mdeditor/pods/components/input/md-toggle/component", ["exports", "ember-toggle/components/x-toggle/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module mdeditor
   * @submodule components-input
   */
  var _default = _component.default.extend({
    /**
    * Custom toggle switch for boolean input
    *
    * @class md-toggle
    * @constructor
    * @extends ember-toggle/components/x-toggle
    */

    /**
     * Bound classes:
     *  - value
     *    - __true__: toggle-on
     *    - __false__: toggle-off
     *
     * @property classNameBindings
     * @type {Array}
     */
    classNameBindings: ['value:toggle-on:toggle-off']
  });

  _exports.default = _default;
});