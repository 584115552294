define("mdeditor/services/settings", ["exports", "mdeditor/config/environment", "mdeditor/models/setting"], function (_exports, _environment, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    },
    environment
  } = _environment.default;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    data: 'null',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      let me = this;
      let settings;
      let store = this.store;
      store.findAll('setting').then(function (s) {
        let rec = s.get('firstObject');
        settings = rec ? rec : store.createRecord('setting');

        if (settings.get('lastVersion') !== version) {
          settings.set('showSplash', environment !== 'test');
          settings.set('lastVersion', version);
        }

        Ember.set(settings, 'repositoryDefaults', Ember.getWithDefault(settings, 'repositoryDefaults', [])); //update mdTranslatorAPI if default is being used

        let isDefaultAPI = Ember.isEmpty(settings.get('mdTranslatorAPI')) || settings.get('mdTranslatorAPI').match('https://mdtranslator.herokuapp.com/api/v(.)/translator');

        if (isDefaultAPI) {
          settings.set('mdTranslatorAPI', _setting.defaultValues.mdTranslatorAPI);
        }

        settings.notifyPropertyChange('hasDirtyAttributes');

        if (!(me.get('isDestroyed') || me.get('isDestroying'))) {
          me.set('data', settings);
        }
      });
    },

    repositoryTemplate: Ember.Object.extend({
      init() {
        this._super(...arguments);
      }

    })
  });

  _exports.default = _default;
});