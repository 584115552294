define("mdeditor/pods/dictionary/show/edit/citation/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fqjJlSFC",
    "block": "{\"symbols\":[\"citation\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"Editing Citation\\n  \"],[7,\"span\",true],[10,\"class\",\"small\"],[8],[0,\"for \"],[7,\"em\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[9],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"with\",[[24,[\"model\",\"json\",\"dataDictionary\",\"citation\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-citation\",null,[[\"class\",\"model\",\"profilePath\",\"editIdentifier\"],[\"\",[23,1,[]],\"dictionary.citation\",[28,\"route-action\",[\"editIdentifier\"],null]]]],false],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-dict-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/edit/citation/index/template.hbs"
    }
  });

  _exports.default = _default;
});