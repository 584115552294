define("mdeditor/pods/record/show/translate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tyb/+45X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xxl-11 col-xxxl-10 col-xxl-offset-1\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Translate Record\"],[9],[0,\"\\n        \"],[1,[28,\"md-translate\",null,[[\"model\",\"store\",\"writer\",\"forceValid\",\"showAllTags\"],[[24,[\"model\"]],[24,[\"store\"]],[24,[\"writer\"]],[24,[\"forceValid\"]],[24,[\"showAllTags\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/translate/template.hbs"
    }
  });

  _exports.default = _default;
});