define("mdeditor/components/models-table/group-summary-row", ["exports", "ember-models-table/components/models-table/group-summary-row"], function (_exports, _groupSummaryRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _groupSummaryRow.default;
    }
  });
});