define("mdeditor/instance-initializers/route-publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let catalogs = appInstance.lookup('service:publish').get('catalogs'); // appInstance.inject('route', 'foo', 'service:foo');

    Ember.Router.map(function () {
      this.route('publish', function () {
        catalogs.forEach(itm => {
          this.route(itm.route);
        });
      });
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});