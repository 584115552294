define("mdeditor/services/spotlight", ["exports", "jquery", "ember-concurrency"], function (_exports, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    show: false,
    elementId: undefined,

    setTarget(id, onClose, scope) {
      let el = this.elementId;

      if (id === el) {
        this.close();
        return;
      }

      if (id && id !== el) {
        (0, _jquery.default)('#' + el).removeClass('md-spotlight-target');
      }

      Ember.setProperties(this, {
        show: true,
        elementId: id,
        onClose: onClose,
        scope: scope
      });
      (0, _jquery.default)('body').addClass('md-no-liquid');
      (0, _jquery.default)('#' + id).addClass('md-spotlight-target');
    },

    closeTask: (0, _emberConcurrency.task)(function* () {
      let id = this.elementId;
      let onClose = this.onClose;
      (0, _jquery.default)('.md-spotlight-overlay').addClass('fade-out-fast');

      if (onClose) {
        onClose.call(this.scope || this);
      }

      yield (0, _emberConcurrency.timeout)(250);

      if (Ember.isPresent(id)) {
        (0, _jquery.default)('body').removeClass('md-no-liquid');
        (0, _jquery.default)('#' + id).removeClass('md-spotlight-target');
      }

      Ember.setProperties(this, {
        show: false,
        elementId: undefined,
        onClose: undefined,
        scope: undefined
      });
    }).drop(),

    close() {
      this.closeTask.perform();
    }

  });

  _exports.default = _default;
});