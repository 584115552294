define("mdeditor/mixins/object-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Use this mixin to apply 'templates' to an array of objects. Especially useful
     * when the object must support setting arrays that are not passed during
     * initialization.
     *
     * @class object-template
     * @constructor
     * @static
     */

    /**
     * Apply the 'template' to the object.
     *
     * @method applyTemplate
     * @param {Object} object The object to apply the template to.
     * @return {Ember.Object}
     */
    applyTemplate(object, defaults) {
      let value = object || {};
      let Template = this.templateClass;

      if (Template) {
        let owner = Ember.getOwner(this);
        return Ember.assign(Template.create(owner.ownerInjection(), defaults || {}), value);
      }

      return object;
    },

    /**
     * Apply the object 'template' to each object in the array.
     *
     * @method applyTemplateArray
     * @param {Array} propertyName The array of objects to apply the template to.
     * @return {Array}
     */
    applyTemplateArray(propertyName, defaults) {
      let property = this.get(propertyName);

      if (Ember.isArray(property)) {
        let Template = this.templateClass;

        if (Template) {
          let owner = Ember.getOwner(this);
          Ember.run.once(this, () => {
            property.forEach((item, idx, items) => {
              //items.removeAt(idx);
              let newItem = Ember.assign(Template.create(owner.ownerInjection(), defaults || {}), item); //items.insertAt(idx, newItem);

              items.set("".concat(idx), newItem);
            });
            this.notifyPropertyChange(propertyName);
          });
        }
      } else {
        Ember.run.once(this, () => {
          this.set(propertyName, Ember.A());
        });
      }

      return this.get(propertyName);
    }

  });

  _exports.default = _default;
});