define("mdeditor/pods/dictionary/show/edit/entity/edit/citation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init() {
      this._super(...arguments);

      this.breadCrumb = {
        title: 'Reference'
      };
    },

    beforeModel() {
      this.set('entityId', this.paramsFor('dictionary.show.edit.entity.edit').entity_id);
    },

    model(params) {
      this.set('citationId', params.citation_id);
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('dictionary.show.edit'));
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let citationId = this.citationId;
      let model = this.modelFor('dictionary.show.edit');
      let citations = model.get('json.dataDictionary.entity.' + this.entityId + '.entityReference');
      let citation = citationId && Ember.isArray(citations) ? citations.get(citationId) : undefined; //make sure the citation exists

      if (Ember.isEmpty(citation)) {
        this.flashMessages.warning('No Entity Reference found! Re-directing...');
        this.replaceWith('dictionary.show.edit.entity.edit');
        return;
      }

      return citation;
    },

    actions: {
      backToEntity() {
        this.transitionTo('dictionary.show.edit.entity.edit', this.entityId);
      }

    }
  });

  _exports.default = _default;
});