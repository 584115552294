define("mdeditor/models/dictionary", ["exports", "ember-copy", "ember-data", "uuid/v4", "mdeditor/models/base", "ember-cp-validations", "mdeditor/config/environment"], function (_exports, _emberCopy, _emberData, _v, _base, _emberCpValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      defaultProfileId
    }
  } = _environment.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'json.dictionaryId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'json.dataDictionary.citation.title': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'json.dataDictionary.subject': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('array-required', {
      track: []
    })]
  });
  const JsonDefault = Ember.Object.extend({
    init() {
      this._super(...arguments);

      this.setProperties({
        dictionaryId: (0, _v.default)(),
        dataDictionary: {
          citation: {
            title: null,
            date: [{
              date: new Date().toISOString(),
              dateType: 'creation'
            }]
          },
          description: '',
          subject: [],
          responsibleParty: {},
          domain: [],
          entity: []
        }
      });
    }

  });

  var _default = _base.default.extend(Validations, _emberCopy.Copyable, {
    /**
     * Dictionary model
     *
     * @class dictionary
     * @constructor
     * @extends base
     * @module mdeditor
     * @submodule data-models
     */
    init() {
      this._super(...arguments);

      this.on('didLoad', this, this.assignId);
    },

    profile: _emberData.default.attr('string', {
      defaultValue: defaultProfileId
    }),
    json: _emberData.default.attr('json', {
      defaultValue() {
        return JsonDefault.create();
      }

    }),
    dateUpdated: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    title: Ember.computed.alias('json.dataDictionary.citation.title'),
    dictionaryId: Ember.computed.alias('json.dictionaryId'),
    icon: 'book',

    /**
     * A list of schema errors return by the validator.
     *
     * @property schemaErrors
     * @type {Array}
     * @readOnly
     * @category computed
     * @requires status
     */
    schemaErrors: Ember.computed('hasDirtyHash', 'customSchemas.[]', function () {
      let mdjson = this.mdjson;
      let errors = [];
      let result = mdjson.validateDictionary(this).errors;

      if (result) {
        errors.pushObject({
          title: 'Default Dictionary Validation',
          errors: result
        });
      }

      this.customSchemas.forEach(schema => {
        const validator = schema.validator;

        if (validator.validate(schema.rootSchema, this.cleanJson)) {
          return;
        }

        errors.pushObject({
          title: schema.title,
          errors: validator.errors
        });
      });
      return errors;
    }),

    assignId(force) {
      if (force || !this.dictionaryId) {
        this.set('json.dictionaryId', (0, _v.default)());
      }
    },

    copy() {
      let current = this.cleanJson;
      let json = Ember.Object.create(current);
      let name = current.dataDictionary.citation.title;
      json.set('dataDictionary.citation.title', "Copy of ".concat(name));
      this.assignId(true);
      return this.store.createRecord('dictionary', {
        json: json
      });
    }

  });

  _exports.default = _default;
});