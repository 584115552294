define("mdeditor/pods/components/object/md-attribute/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhbI7916",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"property\"],[8],[0,\"\\n  \"],[1,[28,\"input/md-input\",null,[[\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\"],[[23,0,[]],\"codeName\",\"Descriptive name for the attribute(column).\",[28,\"concat\",[[24,[\"profilePath\"]],\".attribute.codeName\"],null],true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"property\"],[8],[0,\"\\n  \"],[1,[28,\"input/md-codelist\",null,[[\"create\",\"tooltip\",\"icon\",\"allowClear\",\"showValidations\",\"mdCodeName\",\"model\",\"path\",\"placeholder\",\"profilePath\"],[true,true,false,true,true,\"dataType\",[23,0,[]],\"dataType\",\"The datatype for the attribute(column).\",[28,\"concat\",[[24,[\"profilePath\"]],\".attribute.dataType\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"property\"],[8],[0,\"\\n  \"],[1,[28,\"input/md-input\",null,[[\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\"],[[23,0,[]],\"definition\",\"A brief definition of the attribute(column).\",[28,\"concat\",[[24,[\"profilePath\"]],\".attribute.definition\"],null],true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"property text-center\"],[8],[0,\"\\n  \"],[1,[28,\"input/md-boolean\",null,[[\"required\",\"value\",\"profilePath\"],[true,[24,[\"model\",\"allowNull\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".attribute.allowNull\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-attribute/preview/template.hbs"
    }
  });

  _exports.default = _default;
});