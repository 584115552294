define("mdeditor/pods/components/control/md-spinner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-center', 'md-spinner']
    /**
    * The size of the FontAwesome icon.
    *
    * @property size
    * @type {String}
    * @default "false"
    * @optional
    */

    /**
    * The the text to show below the spinner.
    *
    * @property text
    * @type {String}
    * @optional
    */

  });

  _exports.default = _default;
});