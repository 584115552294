define("mdeditor/pods/components/object/md-extent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AP3UYUAX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layout/md-object-container\",null,[[\"title\",\"index\",\"deleteItem\",\"profilePath\"],[[24,[\"title\"]],[24,[\"index\"]],[24,[\"deleteExtent\"]],[24,[\"profilePath\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"input/md-textarea\",null,[[\"value\",\"rows\",\"maxHeight\",\"placeholder\",\"label\",\"isCollapsible\",\"profilePath\"],[[24,[\"extent\",\"description\"]],3,300,\"Description of extent\",\"Extent Description\",true,[28,\"concat\",[[24,[\"profilePath\"]],\".description\"],null]]]],false],[0,\"\\n\\n  \"],[1,[28,\"object/md-extent/spatial\",null,[[\"extent\",\"index\",\"editFeatures\",\"profilePath\"],[[24,[\"extent\"]],[24,[\"index\"]],[24,[\"editFeatures\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".geographic\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-extent/template.hbs"
    }
  });

  _exports.default = _default;
});