define("mdeditor/templates/components/leaflet-table/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WyCAeU8y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-success\"],[12,\"title\",\"Zoom to Feature\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"zoomTo\",[24,[\"record\"]]]],[8],[1,[28,\"fa-icon\",[\"search\"],null],false],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-info\"],[12,\"title\",\"Edit Properties\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"showForm\"]],[24,[\"record\"]]]],[8],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-danger\"],[12,\"title\",\"Delete Feature\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"deleteFeature\",[24,[\"record\"]]]],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/templates/components/leaflet-table/actions.hbs"
    }
  });

  _exports.default = _default;
});