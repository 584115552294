define("mdeditor/pods/components/object/md-distribution/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBPyiaRt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layout/md-object-container\",null,[[\"title\",\"index\",\"profilePath\",\"deleteItem\"],[\"Distribution\",[24,[\"index\"]],[24,[\"profilePath\"]],[28,\"action\",[[23,0,[]],\"deleteDistribution\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[28,\"input/md-textarea\",null,[[\"valuePath\",\"model\",\"rows\",\"maxHeight\",\"showValidations\",\"placeholder\",\"label\",\"profilePath\"],[\"description\",[23,0,[]],2,300,true,\"Describe the distribution of the resource\",\"Description\",[28,\"concat\",[[24,[\"profilePath\"]],\".description\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[28,\"input/md-textarea\",null,[[\"value\",\"rows\",\"maxHeight\",\"placeholder\",\"label\",\"profilePath\"],[[24,[\"model\",\"liablityStatement\"]],2,300,\"Statement of the liability assumed by the provider of the resource\",\"Liablity Statement\",[28,\"concat\",[[24,[\"profilePath\"]],\".liablityStatement\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"object/md-objectroute-table\",null,[[\"attributes\",\"items\",\"required\",\"tipPath\",\"tipModel\",\"header\",\"shadow\",\"buttonText\",\"ellipsis\",\"previewTemplateTable\",\"editItem\",\"profilePath\",\"verticalButtons\",\"hideIndex\",\"condensed\",\"editOnAdd\",\"scrollToId\",\"data-spy\",\"routeParams\"],[\"Contacts\",[24,[\"distributor\"]],[24,[\"distributorRequired\"]],\"distributor\",[23,0,[]],\"Distributors\",false,\"Add Distributor\",true,\"object/md-distributor/preview\",[24,[\"editDistributor\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".distributor\"],null],false,false,false,true,[28,\"concat\",[\"md-distributor-\",[24,[\"index\"]]],null],false,[24,[\"index\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-distribution/template.hbs"
    }
  });

  _exports.default = _default;
});