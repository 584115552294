define("mdeditor/pods/record/show/edit/distribution/distributor/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    // breadCrumb: computed('controller.distributorId', function () {
    //   return {
    //     title: `Distributor ${this.controller.distributorId}`
    //     //title: `${get(this, 'distributionId')}: Distributors`
    //   };
    // }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('distributionId', Ember.get(this.controllerFor('record.show.edit.distribution.distributor'), 'distributionId'));
      this.controller.set('distributorId', Ember.get(this.controllerFor('record.show.edit.distribution.distributor'), 'distributorId'));
    },
    actions: {
      deleteDistributor(id) {
        let model = this.controller.parentModel.get('json.metadata.resourceDistribution')[this.controller.distributionId].distributor;
        model.removeAt(id || parseInt(this.controller.distributorId, 10));
        this.transitionTo('record.show.edit.distribution', {
          queryParams: {
            scrollTo: "distribution-".concat(this.controller.distributionId)
          }
        });
      },

      editTransferOption(id, routeParams, scrollToId) {
        this.setScrollTo(scrollToId);
        this.transitionTo('record.show.edit.distribution.distributor.transfer', this.controller.distributionId, this.controller.distributorId, id);
      }

    }
  });

  _exports.default = _default;
});