define("mdeditor/pods/components/object/md-taxonomy/collection/system/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V37Y4ibD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input/md-input\",null,[[\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\",\"required\"],[[23,0,[]],\"title\",\"Descriptive name for the collection taxonomic system.\",[28,\"concat\",[[24,[\"profilePath\"]],\".citation.title\"],null],true,true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input/md-textarea\",null,[[\"value\",\"profilePath\",\"placeholder\",\"maxrows\",\"rows\"],[[24,[\"model\",\"modifications\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".modifications\"],null],\"A brief description about the source dataset used in creating the data.\",3,2]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-taxonomy/collection/system/preview/template.hbs"
    }
  });

  _exports.default = _default;
});