define("mdeditor/pods/components/object/md-taxonomy/collection/component", ["exports", "ember-cp-validations", "mdeditor/pods/components/object/md-taxonomy/collection/voucher/component"], function (_exports, _emberCpValidations, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Template = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'title': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'taxonomicSystem': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    // 'identificationProcedure': [
    //   validator('presence', {
    //     presence: true,
    //     ignoreBlank: true
    //   })
    // ],
    'taxonomicClassification': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const TemplateClass = Ember.Object.extend(Validations, {
    init() {
      this._super(...arguments);

      Ember.set(this, 'taxonomicSystem', []);
      Ember.set(this, 'identificationReference', []);
      Ember.set(this, 'observer', []);
      Ember.set(this, 'voucher', []);
      Ember.set(this, 'taxonomicClassification', []);
    }

  });
  _exports.Template = TemplateClass;
  const theComp = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'taxonomicClassification', Ember.getWithDefault(model, 'taxonomicClassification', []));
        Ember.set(model, 'taxonomicSystem', Ember.getWithDefault(model, 'taxonomicSystem', []));
        Ember.set(model, 'identificationReference', Ember.getWithDefault(model, 'identificationReference', []));
        Ember.set(model, 'observer', Ember.getWithDefault(model, 'observer', []));
        Ember.set(model, 'voucher', Ember.getWithDefault(model, 'voucher', []));
      });
    },

    voucherTemplate: _component.Template,

    /**
     * The string representing the path in the profile object for the collection.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the collection.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    taxonomicSystem: Ember.computed.alias('model.taxonomicSystem'),
    title: Ember.computed.alias('model.taxonomicSystem.firstObject.citation.title'),
    identificationProcedure: Ember.computed.alias('model.identificationProcedure'),
    taxonomicClassification: Ember.computed.alias('model.taxonomicClassification'),
    systemTemplate: Ember.Object.extend({
      init() {
        this._super(...arguments);

        this.set('citation', {});
      }

    })
  });
  _exports.default = theComp;
});