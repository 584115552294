define("mdeditor/pods/components/object/md-distributor/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3DPMB8sQ",
    "block": "{\"symbols\":[\"dist\",\"party\",\"contact\",\"index\",\"&default\"],\"statements\":[[4,\"with\",[[24,[\"item\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"wrap\"],[8],[0,\"\\n\"],[4,\"with\",[[23,1,[\"contact\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"html-safe\",[[28,\"if\",[[23,2,[\"role\"]],[23,2,[\"role\"]],\"<em>No Roles assigned.</em>\"],null]],null],false],[0,\"\\n        (\\n\"],[4,\"each\",[[23,2,[\"party\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"if\",[[23,4,[]],\", \"],null],false],[0,\" \"],[1,[28,\"control/md-contact-link\",null,[[\"contactId\"],[[23,3,[\"contactId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3,4]},{\"statements\":[[0,\"            \"],[7,\"em\",true],[10,\"class\",\"text-muted\"],[8],[0,\"No Contacts assigned.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        )\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"        \"],[7,\"em\",true],[10,\"class\",\"text-muted\"],[8],[0,\"No responsibility assigned.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[0,\"\\n  \"],[14,5],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-distributor/preview/template.hbs"
    }
  });

  _exports.default = _default;
});