define("mdeditor/pods/components/object/md-repository-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KxNV9Kso",
    "block": "{\"symbols\":[\"meta\",\"&default\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"value\",\"plain\",\"data-spy\",\"title\",\"profile\",\"templateClass\"],[\"Repository, Collection Title\",[24,[\"value\"]],[24,[\"plain\"]],[24,[\"data-spy\"]],\"Metadata Repository\",[24,[\"profile\"]],[24,[\"repositoryTemplate\"]]]],{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"input/md-codelist\",null,[[\"value\",\"create\",\"tooltip\",\"icon\",\"mdCodeName\",\"closeOnSelect\",\"placeholder\",\"profilePath\",\"change\"],[[23,1,[\"item\",\"repository\"]],true,true,false,\"metadataRepository\",true,\"Select or enter a repository.\",[28,\"concat\",[[24,[\"profilePath\"]],\".repository\"],null],[28,\"action\",[[23,0,[]],\"lookupTitle\",[23,1,[\"item\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"type\",\"value\",\"profilePath\",\"placeholder\"],[\"text\",[23,1,[\"item\",\"citation\",\"title\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".collectionTitle\"],null],\"Text string used to identify a set of resources in the repository.\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-repository-array/template.hbs"
    }
  });

  _exports.default = _default;
});