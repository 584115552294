define("mdeditor/pods/components/object/md-online-resource/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.regex = _exports.Validations = void 0;
  const regex = new RegExp("([A-Za-z][A-Za-z0-9+\\-.]*):(?:(//)(?:((?:[A-Za-z0-9\\-._~!$&'()*+,;=:]|%[0-9A-Fa-f]{2})*)@)?((?:\\[(?:(?:(?:(?:[0-9A-Fa-f]{1,4}:){6}|::(?:[0-9A-Fa-f]{1,4}:){5}|(?:[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,1}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){3}|(?:(?:[0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){2}|(?:(?:[0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}:|(?:(?:[0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})?::)(?:[0-9A-Fa-f]{1,4}:[0-9A-Fa-f]{1,4}|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(?:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})?::)|[Vv][0-9A-Fa-f]+\\.[A-Za-z0-9\\-._~!$&'()*+,;=:]+)\\]|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[A-Za-z0-9\\-._~!$&'()*+,;=]|%[0-9A-Fa-f]{2})*))(?::([0-9]*))?((?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|/((?:(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)?)|((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|)(?:\\?((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?(?:\\#((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?");
  _exports.regex = regex;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'uri': [(0, _emberCpValidations.validator)('format', {
      regex: regex,
      isWarning: true,
      message: 'This field should be a valid, resolvable uri.'
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const theComp = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      Ember.run.once(this, () => {
        let plain = this.model;

        if (plain && !Ember.get(plain, 'validations')) {
          const Model = Ember.Object.extend(Validations, plain);
          const owner = Ember.getOwner(this);
          let model = Model.create(owner.ownerInjection(), plain);
          this.set('model', model);
        }
      });
    },

    flashMessages: Ember.inject.service(),
    classNames: ['md-online-resource'],
    attributeBindings: ['data-spy'],

    /**
     * Display the image picker and preview
     *
     * @property imagePicker
     * @type {Boolean}
     * @default undefined
     */
    actions: {
      handleFile(file) {
        if (file.size > 50000) {
          Ember.get(this, 'flashMessages').danger("The image exceeded the maximum size of 50KB: ".concat(file.size, " bytes.\n            Please use an online URL to load the image."));
        } else {
          let model = this.model;
          Ember.set(model, 'name', file.name);
          Ember.set(model, 'uri', file.data);

          if (file.size > 25000) {
            Ember.get(this, 'flashMessages').warning("The image exceeded the recommended size of 25KB: ".concat(file.size, " bytes"));
          } //reset the input field
          //this.$('.import-file-picker input:file').val('');

        }
      }

    }
  });
  _exports.default = theComp;
});