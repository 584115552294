define("mdeditor/pods/components/object/md-spatial-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'spatialReferenceSystem', Ember.getWithDefault(model, 'spatialReferenceSystem', []));
        Ember.set(model, 'spatialRepresentationType', Ember.getWithDefault(model, 'spatialRepresentationType', []));
        Ember.set(model, 'spatialResolution', Ember.getWithDefault(model, 'spatialResolution', []));
      });
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form'
  });

  _exports.default = _default;
});