define("mdeditor/pods/record/show/edit/metadata/parent/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(model) {
      this._super(...arguments);

      if (Ember.isNone(Ember.get(model, 'json.metadata.metadataInfo.parentMetadata'))) {
        this.flashMessages.warning('No Parent Citation found! Re-directing to Metadata...');
        this.replaceWith('record.show.edit.metadata', {
          queryParams: {
            scrollTo: 'parent-metadata'
          }
        });
      }

      return model;
    },

    actions: {
      editIdentifier(index) {
        this.transitionTo('record.show.edit.metadata.parent.identifier', index).then(function () {
          this.setScrollTo('identifier');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});