define("mdeditor/pods/record/show/edit/taxonomy/collection/itis/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init() {
      this._super(...arguments);

      this.breadCrumb = {
        "title": "ITIS"
      };
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('collectionId', Ember.get(this.controllerFor('record.show.edit.taxonomy.collection'), 'collectionId'));
    },
    actions: {
      toCollection() {
        this.transitionTo('record.show.edit.taxonomy.collection');
      }

    }
  });

  _exports.default = _default;
});