define("mdeditor/helpers/md-markdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mdMarkdown = mdMarkdown;
  _exports.default = void 0;
  const marked = window.marked;

  function mdMarkdown(params
  /*, hash*/
  ) {
    marked.setOptions({
      renderer: new marked.Renderer(),
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false
    });

    if (params[0]) {
      return Ember.String.htmlSafe(marked(params[0]));
    }

    return params[1] || 'No text supplied.';
  }

  var _default = Ember.Helper.helper(mdMarkdown);

  _exports.default = _default;
});