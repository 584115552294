define("mdeditor/pods/components/object/md-locale-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4C5OLYH",
    "block": "{\"symbols\":[\"locale\",\"item\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"title\",\"required\",\"plain\",\"value\",\"validation\",\"data-spy\",\"templateClass\"],[\"Language,Character Set,Country\",[24,[\"title\"]],[24,[\"required\"]],[24,[\"plain\"]],[24,[\"value\"]],[24,[\"validation\"]],false,[24,[\"templateClass\"]]]],{\"statements\":[[4,\"with\",[[23,1,[\"item\"]]],null,{\"statements\":[[0,\"     \"],[7,\"td\",true],[8],[0,\"\\n       \"],[1,[28,\"input/md-codelist\",null,[[\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"showValidations\",\"placeholder\"],[\"language\",[23,2,[]],true,true,false,[24,[\"disabled\"]],\"language\",true,\"Select or enter a language code.\"]]],false],[0,\"\\n     \"],[9],[0,\"\\n     \"],[7,\"td\",true],[8],[0,\"\\n       \"],[1,[28,\"input/md-codelist\",null,[[\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"showValidations\",\"placeholder\"],[\"characterSet\",[23,2,[]],false,true,false,[24,[\"disabled\"]],\"characterSet\",true,\"Select character set.\"]]],false],[0,\"\\n     \"],[9],[0,\"\\n     \"],[7,\"td\",true],[8],[0,\"\\n       \"],[1,[28,\"input/md-codelist\",null,[[\"value\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"placeholder\"],[[23,2,[\"country\"]],false,true,false,[24,[\"disabled\"]],\"countries\",\"Select country code.\"]]],false],[0,\"\\n     \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-locale-array/template.hbs"
    }
  });

  _exports.default = _default;
});