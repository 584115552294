define("mdeditor/pods/components/control/subbar-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z0LIiMYv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"Action Button Controls\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"click\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"name\",\"button\"],[11,\"class\",[29,[\"btn btn-lg \",[28,\"concat\",[\"btn-\",[24,[\"clickButtonType\"]]],null],\" btn-block md-btn-responsive\"]]],[11,\"click\",[22,\"click\"]],[11,\"disabled\",[22,\"disabled\"]],[10,\"type\",\"button\"],[8],[1,[28,\"fa-icon\",[[24,[\"clickButtonIcon\"]]],null],false],[0,\" \"],[1,[22,\"clickTxt\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"route\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[28,\"concat\",[\"btn btn-lg btn-\",[24,[\"btnType\"]],\" btn-block md-btn-responsive\"],null],[24,[\"route\"]]]],{\"statements\":[[0,\" \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],null],false],[0,\" \"],[1,[22,\"text\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/subbar-link/template.hbs"
    }
  });

  _exports.default = _default;
});