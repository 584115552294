define("mdeditor/initializers/leaflet", ["exports", "mdeditor/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* global L */
  function initialize() {
    if (_environment.default.environment === 'production') {
      L.Icon.Default.imagePath = _environment.default.rootURL + '/assets/images/';
    }

    if (_environment.default.environment === 'development') {
      L.Icon.Default.imagePath = '/assets/images/';
    }
  }

  var _default = {
    name: 'leaflet',
    initialize
  };
  _exports.default = _default;
});