define("mdeditor/pods/components/control/md-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GhWSwcbv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"hasDirtyHash\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"role\",\"button\"],[10,\"class\",\"md-status-icon\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"saveRecord\"],null]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"exclamation-circle\"],[[\"class\"],[[28,\"if\",[[24,[\"isBtn\"]],[28,\"concat\",[\"btn btn-danger btn-\",[24,[\"btnSize\"]]],null],\"md-error\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\",\"tooltipClass\"],[\"This record has been modified! Cick to save.\",\"top\",\"ember-tooltip md-tooltip danger\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"hasSchemaErrors\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"role\",\"button\"],[10,\"class\",\"md-status-icon\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showSlider\"],null]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],[[\"class\"],[[28,\"if\",[[24,[\"isBtn\"]],[28,\"concat\",[\"btn btn-warning btn-\",[24,[\"btnSize\"]]],null],\"md-warning\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\",\"tooltipClass\"],[\"This record has errors! Click to view.\",\"right\",\"ember-tooltip md-tooltip warning\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideSlider\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-slider-error\",[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"concat\",[\"Viewing errors for: \",[24,[\"model\",\"title\"]]],null],[28,\"component\",[\"control/md-errors\"],[[\"errors\"],[[24,[\"model\",\"schemaErrors\"]]]]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-status/template.hbs"
    }
  });

  _exports.default = _default;
});