define("mdeditor/pods/record/show/edit/taxonomy/collection/system/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('systemId', params.system_id);
      this.set('collectionId', this.paramsFor('record.show.edit.taxonomy.collection').collection_id);
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let systemId = this.systemId;
      let collectionId = this.collectionId;
      let model = this.modelFor('record.show.edit');
      let systems = model.get('json.metadata.resourceInfo.taxonomy.' + collectionId + '.taxonomicSystem');
      let system = systemId && Ember.isArray(systems) ? systems.get(systemId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(system)) {
        this.flashMessages.warning('No Taxonomic System found! Re-directing...');
        this.replaceWith('record.show.edit.taxonomy.collection');
        return;
      }

      return system;
    },

    actions: {
      parentModel() {
        return this.modelFor('record.show.edit');
      }

    }
  });

  _exports.default = _default;
});