define("mdeditor/pods/components/control/md-definition/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D2DUT3dN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"dt\",true],[11,\"class\",[22,\"titleClass\"]],[8],[1,[28,\"if\",[[24,[\"title\"]],[24,[\"title\"]],\"--\"],null],false],[9],[0,\"\\n\"],[7,\"dd\",true],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"text\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"em\",true],[10,\"class\",\"text-muted\"],[8],[1,[22,\"empty\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-definition/template.hbs"
    }
  });

  _exports.default = _default;
});