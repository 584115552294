define("mdeditor/pods/record/show/edit/taxonomy/collection/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('collectionId', params.collection_id);
      return this.setupModel();
    },

    breadCrumb: Ember.computed('collectionId', function () {
      let index = this.collectionId;
      return {
        title: "Collection ".concat(index > 0 ? index : '') //title: `${get(this, 'collectionId')}: Distributors`

      };
    }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments); //this.controller.set('parentModel', this.modelFor('record.show.edit.main'));


      this.controller.set('collectionId', this.collectionId);
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let collectionId = this.collectionId;
      let model = this.modelFor('record.show.edit');
      let objects = model.get('json.metadata.resourceInfo.taxonomy');
      let resource = collectionId && Ember.isArray(objects) ? Ember.A(objects).objectAt(collectionId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(resource)) {
        this.flashMessages.warning('No Collection object found! Re-directing to list...');
        this.replaceWith('record.show.edit.taxonomy');
        return;
      }

      return resource;
    }

  });

  _exports.default = _default;
});