define("mdeditor/pods/components/object/md-medium/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(function () {
        Ember.set(model, 'mediumSpecification', Ember.getWithDefault(model, 'mediumSpecification', {}));
        Ember.set(model, 'identifier', Ember.getWithDefault(model, 'identifier', {}));
        Ember.set(model, 'mediumFormat', Ember.getWithDefault(model, 'mediumFormat', []));
      });
    },

    tagName: 'form',

    /**
     * The profile path for the component
     *
     * @property profilePath
     * @type {String}
     */
    title: Ember.computed.alias('model.mediumSpecification.title')
  });

  _exports.default = _default;
});