define("mdeditor/pods/components/object/md-resource-type-array/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'type': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend({
    /**
     * mdEditor class for input and edit of mdJSON 'phone' object.
     * The class manages the maintenance of an array of phone objects.
     *
     * @class md-phone-array
     * @module mdeditor
     * @submodule components-object
     * @constructor
     */
    attributeBindings: ['data-spy'],

    /**
     * See [md-array-table](md-array-table.html#property_templateClass).
     *
     * @property templateClass
     * @type Ember.Object
     */
    templateClass: Ember.Object.extend(Validations, {
      init() {
        this._super(...arguments);
      }

    })
  });

  _exports.default = _default;
});