define("mdeditor/pods/settings/validation/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    schemas: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    columns: [{
      propertyName: 'title',
      title: 'Title'
    }, {
      propertyName: 'formattedType',
      title: 'Type',
      filterWithSelect: true
    }, {
      propertyName: 'uri',
      title: 'URL',
      break: true
    }, {
      propertyName: 'description',
      title: 'Description',
      truncate: true,
      isHidden: true
    }, {
      propertyName: 'formattedGlobal',
      title: 'Global?',
      filterWithSelect: true
    }],
    columnSets: [{
      label: 'URL',
      showColumns: ['title', 'uri']
    }],
    badges: [{
      type: 'info',
      icon: 'info-circle',
      tip: 'Update available.',
      isVisible: 'hasUpdate'
    }],
    schema: null,

    /**
    * Indicates whether the save button should be disabled
    *
    * @property disableSave
    * @type {Boolean}
    * @readOnly
    * @category computed
    * @requires schema.validations.isInvalid,task.isRunning
    */
    disableSave: Ember.computed.or('schema.validations.isInvalid', 'task.isRunning'),
    checkForUpdates: (0, _emberConcurrency.task)(function* () {
      yield this.schemas.checkForUpdates.perform(this.model);
    }),
    actions: {
      addSchema() {
        this.set('schema', this.store.createRecord('schema'));
      },

      editSchema(index, record) {
        this.set('schema', record);
      },

      saveSchema() {
        let schema = this.schema;
        return this.schema.save().then(rec => {
          let fetched = this.schemas.fetchSchemas.perform(rec.uri);
          this.set('task', fetched);
          return fetched.then(val => {
            schema.set('customSchemas', val);
            schema.set('version', val[0].schema.version);
            schema.set('remoteVersion', schema.version);
            this.flashMessages.success("Downloaded ".concat(val.length, " schemas."));
          });
        }).catch(e => {
          this.flashMessages.warning(e.message);
        });
      },

      cancelEdit() {
        let record = this.schema;
        this.set('schema', null);
        record.rollbackAttributes();
      },

      fetchSchemas(url) {
        this.schemas.fetchSchemas(url);
      }

    }
  });

  _exports.default = _default;
});