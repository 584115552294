define("mdeditor/pods/components/control/subbar-citation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hQVwh2+2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"Citation Button Controls\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-info btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"selectResource\"]]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"check\"],null],false],[0,\" Select a Record\\n    \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"tooltipClass\",\"side\"],[\"Select a record to copy into the association.\\n        Note: This will only copy information.\",\"ember-tooltip md-tooltip primary\",\"left\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-lg btn-primary btn-block md-btn-responsive\",[24,[\"route\"]]]],{\"statements\":[[0,\" \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],null],false],[0,\" \"],[1,[22,\"text\"],false]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/subbar-citation/template.hbs"
    }
  });

  _exports.default = _default;
});