define("mdeditor/pods/dictionary/show/edit/entity/edit/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    beforeModel() {
      this.set('entityId', this.paramsFor('dictionary.show.edit.entity.edit').entity_id);
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      let parent = this.controllerFor('dictionary.show.edit.entity.edit');
      this.controller.set('parentModel', this.modelFor('dictionary.show.edit'));
      this.controller.set('entityId', Ember.get(parent, 'entityId'));
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: parent.get('setupModel'),
        cancelScope: this
      });
    },
    actions: {
      editCitation(id) {
        this.transitionTo('dictionary.show.edit.entity.edit.citation', id).then(function () {
          this.setScrollTo('entity-reference');
        }.bind(this));
      },

      editAttribute(id) {
        this.transitionTo('dictionary.show.edit.entity.edit.attribute.index', id).then(function () {
          this.setScrollTo('md-attribute-' + id);
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});