define("mdeditor/pods/components/md-models-table/components/check-all/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleAllSelection() {
        this.toggleAllSelection();
      }

    }
  });

  _exports.default = _default;
});