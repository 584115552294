define("mdeditor/initializers/local-storage-export", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const exportSelected = function exportSelected(store, types, options) {
    // merge defaults
    options = Ember.assign({
      json: true,
      download: false,
      filename: 'ember-data.json',
      filterIds: null
    }, options || {});
    let json, data;
    let filter = typeof options.filterIds === 'object' ? options.filterIds : null; // collect data

    data = types.reduce((records, type) => {
      const adapter = store.adapterFor((0, _emberInflector.singularize)(type));

      const url = adapter.buildURL(type),
            exportData = adapter._handleGETRequest(url);

      records.data = records.data.concat(exportData.filter(itm => filter[(0, _emberInflector.singularize)(type)].indexOf(itm.id) !== -1));
      return records;
    }, {
      data: []
    });

    if (options.json || options.download) {
      json = JSON.stringify(data);
    }

    if (options.json) {
      data = json;
    }

    if (options.download) {
      window.saveAs(new Blob([json], {
        type: 'application/json;charset=utf-8'
      }), options.filename);
    }

    return new Ember.RSVP.Promise(resolve => {
      Ember.run(null, resolve, data);
    }, 'DS: LocalStorageAdapter#exportData');
  };

  function initialize() {
    _emberData.default.Store.reopen({
      exportSelectedData(types, options) {
        return exportSelected(this, types, options);
      }

    });
  }

  var _default = {
    name: 'local-storage-export',
    after: 'ember-data',
    initialize: initialize
  };
  _exports.default = _default;
});