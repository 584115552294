define("mdeditor/services/patch", ["exports", "mdjson-schemas/resources/js/schemas"], function (_exports, _schemas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    applyModelPatch(record) {
      let type = record.constructor.modelName;

      switch (type) {
        case 'contact':
          record.get('json.address').forEach(itm => {
            let oldAdm = Ember.get(itm, 'adminstrativeArea');

            if (oldAdm) {
              Ember.set(itm, 'administrativeArea', oldAdm);
              Ember.set(itm, 'adminstrativeArea', null);
            }
          });
          record.set('json.memberOfOrganization', Ember.A(record.get('json.memberOfOrganization')).uniq());
          record.save().then(function () {
            record.notifyPropertyChange('currentHash');
          });
          break;

        case 'record':
          {
            //fix lineage
            let lineage = record.get('json.metadata.resourceLineage');

            if (Ember.isArray(lineage)) {
              lineage.forEach(itm => {
                let source = Ember.get(itm, 'source');

                if (Ember.isArray(source)) {
                  source.forEach(src => {
                    Ember.set(src, 'description', Ember.getWithDefault(src, 'description', Ember.get(src, 'value')));
                    Ember.set(src, 'value', null);
                  });
                  record.save().then(function () {
                    record.notifyPropertyChange('currentHash');
                  });
                }

                let step = Ember.get(itm, 'processStep');

                if (Ember.isArray(step)) {
                  step.forEach(step => {
                    let source = Ember.get(step, 'stepSource');

                    if (Ember.isArray(source)) {
                      source.forEach(src => {
                        Ember.set(src, 'description', Ember.getWithDefault(src, 'description', Ember.get(src, 'value')));
                        Ember.set(src, 'value', null);
                      });
                      record.save().then(function () {
                        record.notifyPropertyChange('currentHash');
                      });
                    }
                  });
                }
              });
            } //fix taxonomy


            let taxonomy = record.get('json.metadata.resourceInfo.taxonomy');

            if (taxonomy) {
              if (!Ember.isArray(taxonomy)) {
                taxonomy = [taxonomy];
                record.set('json.metadata.resourceInfo.taxonomy', taxonomy);
              }

              taxonomy.forEach(itm => {
                let classification = Ember.get(itm, 'taxonomicClassification');

                if (classification && !Ember.isArray(classification)) {
                  let fixNames = taxon => {
                    taxon.taxonomicName = taxon.taxonomicName || taxon.latinName;
                    taxon.taxonomicLevel = taxon.taxonomicLevel || taxon.taxonomicRank;

                    if (Ember.isArray(taxon.subClassification)) {
                      taxon.subClassification.forEach(t => fixNames(t));
                    }
                  };

                  fixNames(classification);
                  Ember.set(itm, 'taxonomicClassification', [classification]);
                  let refs = Ember.get(itm, 'identificationReference');

                  if (Ember.isArray(refs)) {
                    let fixedRefs = [];
                    refs.forEach(ref => {
                      fixedRefs.pushObject({
                        "identifier": [ref]
                      });
                    });
                    Ember.set(itm, 'identificationReference', fixedRefs);
                  }
                }
              });
            } //fix srs identifiers


            let srs = record.get('json.metadata.resourceInfo.spatialReferenceSystem');

            if (srs) {
              srs.forEach(itm => {
                let projObj = Ember.get(itm, 'referenceSystemParameterSet.projection');
                let geoObj = Ember.get(itm, 'referenceSystemParameterSet.geodetic');
                let vertObj = Ember.get(itm, 'referenceSystemParameterSet.verticalDatum');

                if (projObj) {
                  let {
                    projection,
                    projectionName,
                    projectionIdentifier
                  } = projObj;

                  if (!projectionIdentifier || projection) {
                    Ember.set(projObj, 'projectionIdentifier', {
                      identifier: projection,
                      name: projectionName
                    });
                    Ember.setProperties(projObj, {
                      projection: null,
                      projectionName: null
                    });
                  }
                }

                if (geoObj && (geoObj.datumName || geoObj.ellipsoidName)) {
                  if (geoObj.datumName) {
                    Ember.set(geoObj, 'datumIdentifier', {
                      identifier: geoObj.datumName
                    });
                  }

                  if (geoObj.ellipsoidName) {
                    Ember.set(geoObj, 'ellipsoidIdentifier', {
                      identifier: geoObj.ellipsoidName
                    });
                  }

                  Ember.setProperties(geoObj, {
                    datumName: null,
                    ellipsoidName: null
                  });
                }

                if (vertObj && vertObj.datumName) {
                  if (vertObj.datumName) {
                    Ember.set(vertObj, 'datumIdentifier', {
                      identifier: vertObj.datumName
                    });
                  }

                  Ember.set(vertObj, 'datumName', null);
                }
              });
            } //fix transfer format edition


            let distribution = record.get('json.metadata.resourceDistribution');

            if (distribution) {
              distribution.forEach(itm => {
                if (itm.distributor) {
                  itm.distributor.forEach(itm => {
                    if (itm.transferOption) {
                      itm.transferOption.forEach(itm => {
                        if (itm.distributionFormat) {
                          itm.distributionFormat.forEach(format => {
                            if (format.amendmentNumber && format.formatSpecification && !format.formatSpecification.edition) {
                              Ember.set(format, 'formatSpecification.edition', format.amendmentNumber);
                              return;
                            }

                            if (format.amendmentNumber && !format.formatSpecification) {
                              Ember.set(format, 'formatSpecification', {
                                edition: format.amendmentNumber
                              });
                              return;
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            } //fix allocation comment


            let funding = record.get('json.metadata.funding');

            if (funding) {
              funding.forEach(itm => {
                if (itm.allocation) {
                  itm.allocation.forEach(itm => {
                    if (itm.description && !itm.comment) {
                      Ember.set(itm, 'comment', itm.description);
                      Ember.set(itm, 'description', null);
                    }
                  });
                }
              });
            } //fix metadataRepository title


            let repo = record.get('json.metadataRepository');

            if (Ember.isArray(repo)) {
              repo.forEach(itm => {
                let titles = Ember.get(itm, 'citation.titles');

                if (titles) {
                  Ember.set(itm, 'citation.title', Ember.getWithDefault(itm, 'citation.titles', Ember.get(itm, 'title')));
                  Ember.set(itm, 'citation.titles', null);
                  record.save().then(function () {
                    record.notifyPropertyChange('currentHash');
                  });
                }
              });
            }

            record.set('json.schema.version', _schemas.default.schema.version);
            record.save().then(function () {
              record.notifyPropertyChange('currentHash');
            });
            break;
          }
      }
    }

  });

  _exports.default = _default;
});