define("mdeditor/pods/components/control/md-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    slider: Ember.inject.service(),
    tagName: 'span',
    classNames: ['md-status'],

    /**
     * Model to display status for.
     *
     * @property model
     * @type {DS.model}
     * @required
     */
    isBtn: false,
    hideSlider: false,
    btnSize: 'sm',

    showSlider() {
      let slider = this.slider;
      slider.set('fromName', 'md-slider-error');
      slider.toggleSlider(true);
    },

    actions: {
      showSlider(evt) {
        this.showSlider(evt);
      },

      saveRecord(evt) {
        let model = this.model;
        evt.stopPropagation();
        model.save().then(() => {
          this.flashMessages.success("Saved Record: ".concat(model.get('title')));
        });
      }

    }
  });

  _exports.default = _default;
});