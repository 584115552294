define("mdeditor/models/contact", ["exports", "ember-copy", "ember-data", "uuid/v4", "validator", "mdeditor/models/base", "ember-cp-validations"], function (_exports, _emberCopy, _emberData, _v, _validator, _base, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JsonDefault = _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'json.contactId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'json.name': [(0, _emberCpValidations.validator)('format', {
      regex: /^\s+$/,
      inverse: true,
      isWarning: true,
      message: "Name should not be only white-space."
    }), (0, _emberCpValidations.validator)('presence', {
      disabled: Ember.computed.notEmpty('model.json.positionName'),
      presence: true
    })],
    'json.positionName': [(0, _emberCpValidations.validator)('format', {
      regex: /^\s+$/,
      inverse: true,
      isWarning: true,
      message: "Position Name should not be only white-space."
    }), (0, _emberCpValidations.validator)('presence', {
      disabled: Ember.computed.notEmpty('model.json.name'),
      presence: true
    })],
    'json.isOrganization': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });
  const JsonDefault = Ember.Object.extend({
    init() {
      this._super(...arguments);

      this.setProperties({
        'contactId': (0, _v.default)(),
        'isOrganization': false,
        'name': null,
        //'positionName': null,
        'memberOfOrganization': [],
        'logoGraphic': [],
        'phone': [],
        'address': [],
        'electronicMailAddress': [],
        'onlineResource': [],
        'hoursOfService': [] //'contactInstructions': null,
        //'contactType': null;

      });
    }

  });
  _exports.JsonDefault = JsonDefault;

  const Contact = _base.default.extend(Validations, _emberCopy.Copyable, {
    /**
     * Contact model
     *
     * @class contact
     * @constructor
     * @extends base
     * @uses Validations,Copyable
     * @mixin Ember.Copyable
     * @module mdeditor
     * @submodule data-models
     */
    contactsService: Ember.inject.service('contacts'),
    contacts: _emberData.default.hasMany('contact', {
      inverse: 'organizations'
    }),
    organizations: _emberData.default.hasMany('contact', {
      inverse: 'contacts'
    }),

    /**
     * The json object for the contact. The data for the contact is stored in this
     * object.
     *
     * @attribute json
     * @type {json}
     * @required
     */
    json: _emberData.default.attr('json', {
      defaultValue: function defaultValue() {
        return JsonDefault.create();
      }
    }),

    /**
     * The timestamp for the record
     *
     * @attribute dateUpdated
     * @type {date}
     * @default new Date()
     * @required
     */
    dateUpdated: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    name: Ember.computed.alias('json.name'),
    contactId: Ember.computed.alias('json.contactId'),

    /**
     * The formatted display string for the contact
     *
     * @property title
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.name, json.positionName
     */
    title: Ember.computed('json.{name,positionName,isOrganization}', function () {
      const json = this.json;
      return json.name || (json.isOrganization ? null : json.positionName);
    }),
    // /**
    //  * The formatted display string for the contact
    //  *
    //  * @property title
    //  * @type {String}
    //  * @readOnly
    //  * @category computed
    //  * @requires json.name, json.positionName
    //  */
    // updateMembers: Ember.observer('json.memberOfOrganization.[]',
    //   function () {
    //     //const me = this;
    //     const json = this.get('json');
    //     const value = json.memberOfOrganization;
    //
    //     let store = this.get('store');
    //     let contacts = store.peekAll('contact');
    //     let organizations = this.get('organizations')
    //       .clear();
    //
    //     value.forEach(function (id) {
    //       let rec = contacts.findBy('json.contactId', id);
    //
    //       if(rec) {
    //         organizations.pushObject(rec);
    //       }
    //       //rec.get('contacts').pushObject(me);
    //     });
    //
    //   }),

    /**
     * The type of contact
     *
     * @property type
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.isOrganization
     */
    type: Ember.computed('json.isOrganization', function () {
      return this.get('json.isOrganization') ? 'Organization' : 'Individual';
    }),

    /**
     * The display icon for the contact
     *
     * @property icon
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.isOrganization
     */
    icon: Ember.computed('json.isOrganization', function () {
      const name = this.get('json.isOrganization');
      return name ? 'users' : 'user';
    }),

    /**
     * The URI of the default logo for the contact.
     *
     * @property defaultLogo
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.logoGraphic.firstObject.fileUri.firstObject.uri
     */
    defaultLogo: Ember.computed('json.logoGraphic.firstObject.fileUri.firstObject.uri', 'defaultOrganization', function () {
      let uri = this.get('json.logoGraphic.firstObject.fileUri.firstObject.uri');

      if (uri) {
        return uri;
      }

      let orgId = Ember.get(this, 'defaultOrganization');

      if (orgId && orgId !== this.get('json.contactId')) {
        let contacts = this.get('contactsService.organizations');
        let org = contacts.findBy('json.contactId', orgId);

        if (org) {
          return Ember.get(org, 'defaultLogo');
        }
      }

      return null;
    }),

    /**
     * The id of the default organization for the contact.
     *
     * @property defaultOrganization
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.memberOfOrganization.[]
     */
    defaultOrganization: Ember.computed('json.memberOfOrganization.[]', function () {
      const json = this.json;
      let {
        memberOfOrganization
      } = json;
      return !Ember.isEmpty(memberOfOrganization) ? Ember.get(memberOfOrganization, '0') : null;
    }),
    defaultOrganizationName: Ember.computed('defaultOrganization', function () {
      let contacts = this.get('contactsService.organizations');
      let org = contacts.findBy('json.contactId', Ember.get(this, 'defaultOrganization'));
      return org ? Ember.get(org, 'name') : null;
    }),

    /**
     * The formatted (name or position) and organization(if any) of the contact.
     *
     * @property combinedName
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.name, json.isOrganization
     */
    combinedName: Ember.computed('name', 'json{isOrganization,positionName,memberOfOrganization[]}', function () {
      const json = this.json;
      let {
        name,
        positionName,
        isOrganization,
        memberOfOrganization
      } = json;
      let orgId = !Ember.isEmpty(memberOfOrganization) ? Ember.get(memberOfOrganization, '0') : null;
      let combinedName = name || positionName;
      let orgName;

      if (orgId) {
        let contacts = this.get('contactsService.organizations');
        let org = contacts.findBy('json.contactId', orgId);

        if (org) {
          orgName = Ember.get(org, 'name');
        }
      }

      if (orgName && !isOrganization) {
        return orgName += ": " + combinedName;
      }

      return combinedName;
    }),

    /**
     * The trimmed varsion of the contactId.
     *
     * @property shortId
     * @type {String}
     * @readOnly
     * @category computed
     * @requires json.contactId
     */
    shortId: Ember.computed('json.contactId', function () {
      const contactId = this.get('json.contactId');

      if (contactId && _validator.default.isUUID(contactId)) {
        let index = contactId.indexOf('-');
        return contactId.substring(0, index);
      }

      return contactId;
    }),

    /**
     * A list of schema errors return by the validator.
     *
     * @property schemaErrors
     * @type {Array}
     * @readOnly
     * @category computed
     * @requires status
     */
    schemaErrors: Ember.computed('hasDirtyHash', 'customSchemas.[]', function () {
      let mdjson = this.mdjson;
      let errors = [];
      let result = mdjson.validateContact(this).errors;

      if (result) {
        errors.pushObject({
          title: 'Default Contact Validation',
          errors: result
        });
      }

      this.customSchemas.forEach(schema => {
        const validator = schema.validator;

        if (validator.validate(schema.rootSchema, this.cleanJson)) {
          return;
        }

        errors.pushObject({
          title: schema.title,
          errors: validator.errors
        });
      });
      return errors;
    }),

    /**
     * Create a copy of the record in the store.
     *
     * @method copy
     * @chainable
     * @return {DS.Model}
     */
    copy() {
      let current = this.cleanJson;
      let json = Ember.Object.create(current);
      let {
        name,
        positionName,
        isOrganization
      } = current;
      json.setProperties({
        isOrganization: isOrganization,
        name: name ? "Copy of ".concat(name) : null,
        positionName: name ? positionName : "Copy of ".concat(positionName),
        contactId: (0, _v.default)()
      });
      return this.store.createRecord('contact', {
        json: json
      });
    }

  });

  _exports.default = Contact;
});