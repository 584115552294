define("mdeditor/pods/components/layout/md-nav-secondary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C6wo/z7M",
    "block": "{\"symbols\":[\"link\",\"index\"],\"statements\":[[7,\"ul\",true],[10,\"id\",\"md-navbar-secondary\"],[10,\"class\",\"nav nav-pills\"],[10,\"role\",\"navigation\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedLinks\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"hasOverflow\"]],[23,1,[\"isOverflow\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"secondary-nav-menu\",[28,\"component\",[\"layout/md-nav-secondary/link\"],[[\"isStaticLinks\",\"link\",\"index\",\"model\",\"nav\",\"navWidth\"],[[24,[\"isStaticLinks\"]],[23,1,[]],[23,2,[]],[24,[\"model\"]],[23,0,[]],[24,[\"navWidth\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"layout/md-nav-secondary/link\",null,[[\"isStaticLinks\",\"link\",\"index\",\"model\",\"nav\",\"navWidth\"],[[24,[\"isStaticLinks\"]],[23,1,[]],[23,2,[]],[24,[\"model\"]],[23,0,[]],[24,[\"navWidth\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasOverflow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"dropdown\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"dropdown-toggle\"],[10,\"data-toggle\",\"dropdown\"],[10,\"role\",\"button\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"More \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n        \"],[1,[28,\"multiple-from-elsewhere\",null,[[\"name\"],[\"secondary-nav-menu\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/md-nav-secondary/template.hbs"
    }
  });

  _exports.default = _default;
});