define("mdeditor/pods/components/object/md-domainitem/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Template = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'value': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'definition': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const TemplateClass = Ember.Object.extend(Validations, {
    init() {
      this._super(...arguments);

      Ember.set(this, 'reference', {});
    }

  });
  _exports.Template = TemplateClass;
  const theComp = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'reference', Ember.getWithDefault(model, 'reference', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the domain.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the domain.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    name: Ember.computed.alias('model.name'),
    value: Ember.computed.alias('model.value'),
    definition: Ember.computed.alias('model.definition')
  });
  _exports.default = theComp;
});