define("mdeditor/pods/components/object/md-constraint/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'classification': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'type': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'useLimitation', Ember.getWithDefault(model, 'useLimitation', []));
        Ember.set(model, 'graphic', Ember.getWithDefault(model, 'graphic', []));
        Ember.set(model, 'responsibleParty', Ember.getWithDefault(model, 'responsibleParty', []));
        Ember.set(model, 'legal', Ember.getWithDefault(model, 'legal', {
          accessConstraint: [],
          useConstraint: [],
          otherConstraint: []
        }));
        Ember.set(model, 'security', Ember.getWithDefault(model, 'security', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    type: Ember.computed.alias('model.type'),
    useRequired: Ember.computed.equal('type', 'use'),
    securityRequired: Ember.computed.equal('type', 'security'),
    legalRequired: Ember.computed.equal('type', 'legal'),
    classification: Ember.computed.alias('model.security.classification'),
    // measureUnit: alias('model.measure.unitOfMeasure'),
    // measurePresent: or('measureType','measureUnit','measureValue'),
    typeOptions: Ember.computed(function () {
      return [{
        name: 'use',
        value: 'use'
      }, {
        name: 'legal',
        value: 'legal'
      }, {
        name: 'security',
        value: 'security'
      }];
    })
  });

  _exports.default = _default;
});