define("mdeditor/pods/record/show/edit/main/citation/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    // afterModel() {
    //   this.setupModel();
    // },
    //
    // setupController: function () {
    //   // Call _super for default behavior
    //   this._super(...arguments);
    //
    //   this.controllerFor('record.show.edit')
    //     .setProperties({
    //       onCancel: this.setupModel
    //     });
    // },
    //
    // setupModel() {
    //   // let model = this.currentRouteModel();
    //   // let citation = get(model, 'json.metadata.resourceInfo.citation');
    //   //
    //   // set(citation, 'responsibleParty', getWithDefault(citation,
    //   //   'responsibleParty', []));
    //   // set(citation, 'presentationForm', getWithDefault(citation,
    //   //   'presentationForm', []));
    //   // set(citation, 'onlineResource', getWithDefault(citation,
    //   //   'onlineResource', []));
    //   // set(citation, 'identifier', getWithDefault(citation, 'identifier', []));
    //   // set(citation, 'graphic', getWithDefault(citation, 'graphic', []));
    //   // set(citation, 'series', getWithDefault(citation, 'series', {}));
    // },
    actions: {
      editIdentifier(index) {
        this.transitionTo('record.show.edit.main.citation.identifier', index).then(function () {
          this.setScrollTo('identifier');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});