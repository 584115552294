define("mdeditor/pods/record/show/edit/documents/citation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: Ember.computed('citationId', function () {
      return {
        title: Ember.get(this, 'citationId'),
        linkable: true
      };
    }),

    model(params) {
      this.set('citationId', params.citation_id);
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments); //this.controller.set('parentModel', this.modelFor('record.show.edit.main'));


      this.controller.set('citationId', Ember.get(this, 'citationId'));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let citationId = Ember.get(this, 'citationId');
      let model = this.modelFor('record.show.edit');
      let objects = model.get('json.metadata.additionalDocumentation');
      let resource = citationId && Ember.isArray(objects) ? Ember.A(objects).objectAt(citationId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(resource)) {
        Ember.get(this, 'flashMessages').warning('No Document object found! Re-directing to list...');
        this.replaceWith('record.show.edit.documents');
        return;
      }

      return resource;
    }

  });

  _exports.default = _default;
});