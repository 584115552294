define("mdeditor/pods/components/control/md-record-table/buttons/filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    showButton: Ember.computed('selectedItems.[]', function () {
      return this.get('selectedItems.length') > 1;
    }),

    deleteSelected(records) {
      records.forEach(rec => {
        rec.destroyRecord().then(rec => {
          rec.unloadRecord();
          Ember.run.once(() => {
            records.removeObject(rec);
            this.flashMessages.danger("Deleted ".concat(rec.constructor.modelName, " \"").concat(rec.get('title'), "\"."));
          });
        });
      });
    },

    actions: {
      deleteSelected(records) {
        this.deleteSelected(records);
      }

    }
  });

  _exports.default = _default;
});