define("mdeditor/pods/components/object/md-associated/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'associationType': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'scope', Ember.getWithDefault(model, 'scope', {}));
        Ember.set(model, 'resourceType', Ember.getWithDefault(model, 'resourceType', []));
        Ember.set(model, 'resourceCitation', Ember.getWithDefault(model, 'resourceCitation', {}));
        Ember.set(model, 'metadataCitation', Ember.getWithDefault(model, 'metadataCitation', {}));
      });
    },

    tagName: 'form',

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    associationType: Ember.computed.alias('model.associationType'),
    linkedRecord: Ember.computed('model.mdRecordId', function () {
      let store = this.store;
      return store.peekAll('record').filterBy('recordId', Ember.get(this, 'model.mdRecordId')).get('firstObject');
    }),
    linkedAssociation: Ember.computed('linkedRecord.json.metadata.associatedResource.[]', function () {
      let ar = this.get('linkedRecord.json.metadata.associatedResource');

      if (!ar) {
        return null;
      }

      return ar.findBy('mdRecordId', this.recordId);
    }),
    linkedAssociationType: Ember.computed('linkedAssociation.associationType', {
      get() {
        return this.get('linkedAssociation.associationType');
      },

      set(key, value) {
        let assoc = this.linkedAssociation;
        let model = this.linkedRecord;

        if (!assoc) {
          Ember.set(model, 'json.metadata.associatedResource', Ember.getWithDefault(model, 'json.metadata.associatedResource', []));
          model.get('json.metadata.associatedResource').pushObject({
            mdRecordId: Ember.get(this, 'recordId'),
            associationType: value
          });
          model.notifyPropertyChange('hasDirtyHash');
          return value;
        }

        Ember.set(assoc, 'associationType', value);
        model.notifyPropertyChange('hasDirtyHash');
        return value;
      }

    }),

    editLinked(record) {
      return record;
    }

  });

  _exports.default = _default;
});