define("mdeditor/pods/record/show/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dcj4FDDe",
    "block": "{\"symbols\":[\"spy\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row md-section-secondary\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-record-content col-sm-10\"],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"md-control-sidebar col-sm-2 hidden-print\"],[8],[0,\"\\n      \"],[1,[28,\"control/md-crud-buttons\",null,[[\"model\",\"doSave\",\"doCancel\",\"doDelete\",\"doCopy\"],[[24,[\"model\"]],[28,\"route-action\",[\"saveRecord\"],null],[28,\"route-action\",[\"cancelRecord\"],null],[28,\"route-action\",[\"destroyRecord\"],null],[28,\"route-action\",[\"copyRecord\"],null]]]],false],[0,\"\\n\"],[0,\"      \"],[7,\"hr\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"from-elsewhere\",null,[[\"name\"],[\"md-subbar-extra\"]]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"Action Button Controls\"],[8],[0,\"\\n        \"],[1,[28,\"multiple-from-elsewhere\",null,[[\"name\"],[\"md-subbar-buttons\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"from-elsewhere\",null,[[\"name\"],[\"md-scroll-spy-record-edit\"]],{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[23,1,[]]],[[\"offset\"],[145]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"record-nav\",[28,\"component\",[\"input/md-select-profile\"],[[\"value\",\"record\"],[[24,[\"model\",\"profile\"]],[24,[\"model\"]]]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"nav-secondary\",[28,\"component\",[\"layout/md-nav-secondary\"],[[\"model\"],[[24,[\"model\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/template.hbs"
    }
  });

  _exports.default = _default;
});