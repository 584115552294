define("mdeditor/pods/contact/show/edit/route", ["exports", "mdeditor/mixins/hash-poll"], function (_exports, _hashPoll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_hashPoll.default, {});

  _exports.default = _default;
});