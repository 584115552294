define("mdeditor/pods/components/control/md-record-table/buttons/custom/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Button style.
     *
     * @property column.buttonConfig.style
     * @type {String}
     */

    /**
     * Text to display in the button.
     *
     * @property column.buttonConfig.title
     * @type {String}
     */

    /**
     * The record passed to the component.
     *
     * @property record
     * @type {Object}
     */

    /**
     * Action to run on button click
     *
     * @property column.buttonConfig.action
     * @param {Object} record The row record
     */
    action() {}

  });

  _exports.default = _default;
});