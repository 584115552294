define("mdeditor/pods/components/control/subbar-importcsv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k09m7TB4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-info btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"doImport\"],[[\"target\"],[[24,[\"actionContext\"]]]]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"sign-in\"],null],false],[0,\" Do Import\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-danger btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancelImport\"],[[\"target\"],[[24,[\"actionContext\"]]]]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\" Cancel Import\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/subbar-importcsv/template.hbs"
    }
  });

  _exports.default = _default;
});