define("mdeditor/pods/components/object/md-citation/preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Title for the card
     *
     * @property title
     * @type {String}
     * @default "Citation"
     * @required
     */
    title: "Citation",

    /**
     * Indicates if object is required.
     *
     * @property required
     * @type {Boolean}
     * @default "false"
     */
    required: false,

    /**
     * Indicates if object text is muted.
     *
     * @property muted
     * @type {Boolean}
     * @default "true"
     */
    muted: true,

    /**
     * Passed in action.
     *
     * @method editCitation
     * @param {String} scrollTo Identifier to scrollTo
     * @required
     * @return {undefined}
     */
    actions: {
      editCitation(scrollTo) {
        this.editCitation(scrollTo);
      }

    }
  });

  _exports.default = _default;
});