define("mdeditor/templates/components/file-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SV+MVQ3L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"dropzone\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"file-picker__dropzone\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"preview\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"file-picker__preview\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"progress\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showProgress\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isProgressSupported\"]]],null,{\"statements\":[[0,\"      \"],[7,\"progress\",true],[11,\"value\",[22,\"progressValue\"]],[10,\"max\",\"100\"],[10,\"class\",\"file-picker__progress\"],[8],[1,[22,\"progress\"],false],[0,\" %\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"file-picker__progress\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"file-picker__progress__value\"],[11,\"style\",[22,\"progressStyle\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"accept\",\"multiple\",\"class\"],[\"file\",[24,[\"file\"]],[24,[\"accept\"]],[24,[\"multiple\"]],\"file-picker__input\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/templates/components/file-picker.hbs"
    }
  });

  _exports.default = _default;
});