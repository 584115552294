define("mdeditor/pods/dictionary/show/edit/entity/edit/citation/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    actions: {
      editIdentifier(index) {
        this.transitionTo('dictionary.show.edit.entity.edit.citation.identifier', index).then(function () {
          this.setScrollTo('entity-identifier');
        }.bind(this));
      }

    },

    backToEntity() {
      this.transitionTo('dictionary.show.edit.entity.edit', this.entityId);
    }

  });

  _exports.default = _default;
});