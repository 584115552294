define("mdeditor/pods/components/layout/md-nav-main/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*didInsertElement: function () {
      this.$('[data-toggle="tooltip"]')
        .tooltip();
    },*/
    actions: {
      toggleSidebar() {
        (0, _jquery.default)('#md-wrapper').toggleClass('toggled'); //hack to force reflow

        (0, _jquery.default)('#md-navbar-main-collapse ul').hide().show(0);
      }

    }
  });

  _exports.default = _default;
});