define("mdeditor/pods/settings/main/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    model() {
      return this.settings.get('data');
    }

  });

  _exports.default = _default;
});