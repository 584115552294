define("mdeditor/pods/components/object/md-extent/spatial/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isNaN: isNan
  } = Number;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let geo = Ember.get(this, 'extent.geographicExtent.0');
      Ember.run.once(function () {
        Ember.set(geo, 'boundingBox', Ember.getWithDefault(geo, 'boundingBox', {}));
      });
    },

    isTrulyNone(val) {
      return Ember.isNone(val) || isNan(val);
    },

    bboxPoly: Ember.computed('bbox', 'bbox.{northLatitude,southLatitude,eastLongitude,westLongitude}', function () {
      let bbox = this.bbox;

      if (!bbox) {
        return null;
      }

      if (this.isTrulyNone(bbox.southLatitude) || this.isTrulyNone(bbox.westLongitude) || this.isTrulyNone(bbox.northLatitude) || this.isTrulyNone(bbox.eastLongitude)) {
        return null;
      }

      return [[bbox.southLatitude, bbox.westLongitude], [bbox.northLatitude, bbox.westLongitude], [bbox.northLatitude, bbox.eastLongitude], [bbox.southLatitude, bbox.eastLongitude]];
    }),
    bboxPolyObserver: Ember.observer('bboxPoly', function () {
      let map = this.map;
      let bbox = this.bboxPoly;

      if (map && bbox) {
        this.setupMap({
          target: map
        });
      }
    }),
    bbox: Ember.computed.alias('extent.geographicExtent.0.boundingBox'),
    geographicExtent: Ember.computed.alias('extent.geographicExtent.0'),
    hasBbox: Ember.computed.or('extent.geographicExtent.0.boundingBox.{northLatitude,southLatitude,eastLongitude,westLongitude}'),
    geographicElement: Ember.computed.alias('extent.geographicExtent.0.geographicElement'),
    showMap: Ember.computed.or('bboxPoly', 'geographicElement'),

    setupMap(m) {
      let map = m.target;
      let geo = this.geographicElement || [];
      let bbox = this.bboxPoly;
      let features;
      features = bbox ? geo.concat([L.rectangle(bbox).toGeoJSON()]) : [].concat(geo);
      let bounds = L.geoJson(features).getBounds();
      map.fitBounds(bounds);
      this.set('map', map);
    },

    deleteFeatures() {
      this.set('geographicElement', []);
    },

    actions: {
      calculateBox() {
        let geo = this.geographicElement;

        if (!(geo && geo.length)) {
          return null;
        }

        let bounds = L.geoJson(geo).getBounds();
        let bbox = this.bbox;
        Ember.setProperties(bbox, {
          northLatitude: bounds.getNorth(),
          southLatitude: bounds.getSouth(),
          eastLongitude: bounds.getEast(),
          westLongitude: bounds.getWest()
        });
      },

      clearBox() {
        Ember.setProperties(this.bbox, {
          northLatitude: null,
          southLatitude: null,
          eastLongitude: null,
          westLongitude: null
        });
      },

      editFeatures(index) {
        this.editFeatures(index);
      },

      deleteFeatures() {
        this.deleteFeatures();
      }

    }
  });

  _exports.default = _default;
});