define("mdeditor/pods/components/object/md-domainitem/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oTtoAxTY",
    "block": "{\"symbols\":[\"citation\"],\"statements\":[[1,[28,\"input/md-input\",null,[[\"label\",\"data-spy\",\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"Name\",\"Name\",[23,0,[]],\"name\",\"Descriptive name for the domain value.\",[28,\"concat\",[[24,[\"profilePath\"]],\".name\"],null],true]]],false],[0,\"\\n\\n\"],[1,[28,\"input/md-input\",null,[[\"label\",\"data-spy\",\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"Value\",\"Value\",[23,0,[]],\"value\",\"Value of the domain item.\",[28,\"concat\",[[24,[\"profilePath\"]],\".value\"],null],true]]],false],[0,\"\\n\\n\"],[1,[28,\"input/md-textarea\",null,[[\"label\",\"data-spy\",\"model\",\"valuePath\",\"placeholder\",\"profilePath\",\"showValidations\"],[\"Definition\",\"Definition\",[23,0,[]],\"definition\",\"A brief definition for the domain item.\",[28,\"concat\",[[24,[\"profilePath\"]],\".definition\"],null],true]]],false],[0,\"\\n\\n\"],[4,\"with\",[[24,[\"model\",\"reference\"]]],null,{\"statements\":[[4,\"layout/md-card\",null,[[\"title\",\"collapsible\",\"collapsed\",\"profilePath\",\"data-spy\",\"shadow\"],[\"Item Reference\",true,false,[28,\"concat\",[[24,[\"profilePath\"]],\".reference\"],null],\"Item Reference\",true]],{\"statements\":[[0,\"    \"],[1,[28,\"object/md-citation\",null,[[\"model\",\"profilePath\",\"simpleIdentifier\",\"embedded\"],[[23,1,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".reference\"],null],true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-domainitem/template.hbs"
    }
  });

  _exports.default = _default;
});