define("mdeditor/pods/components/md-translate/component", ["exports", "moment", "mdeditor/models/setting"], function (_exports, _moment, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const errorLevels = {
    'OK': 0,
    'NOTICE': 1,
    'WARNING': 2,
    'ERROR': 3
  };
  const errorClasses = ['success', 'info', 'warning', 'danger'];

  var _default = Ember.Component.extend({
    classNames: ['row'],
    cleaner: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    mdjson: Ember.inject.service(),
    settings: Ember.inject.service(),
    ajax: Ember.inject.service(),

    /**
     * Indicates whether empty tags should be written to the translated output
     *
     * @property showAllTags
     * @type {Boolean}
     * @default "false"
     */
    showAllTags: false,

    /**
     * Indicates whether to force writer to meet the output standard
     *
     * @property forceValid
     * @type {Boolean}
     * @default "false"
     */
    forceValid: false,
    writer: null,
    writerOptions: Ember.computed(function () {
      return [{
        name: 'FGDC CSDGM',
        value: 'fgdc',
        type: 'application/xml',
        tip: 'Federal Geographic Data Committee Content Standard for Digital Geospatial Metadata'
      }, {
        name: 'HTML',
        value: 'html',
        type: 'text/html',
        tip: 'HTML "human-readable" and printable report of the metadata content'
      }, {
        name: 'ISO 19115-1',
        value: 'iso19115_1',
        type: 'application/xml',
        tip: 'International Standards Organization Geographic Information - Metadata 19115-1:2014'
      }, {
        name: 'ISO 19115-2',
        value: 'iso19115_2',
        type: 'application/xml',
        tip: 'International Standards Organization Geographic Information - Metadata 19115-2:2009'
      }, {
        name: 'ISO 19110',
        value: 'iso19110',
        type: 'application/xml',
        tip: 'International Standards Organization Geographic Information - Feature Catalogue 19110:2005'
      }, {
        name: 'sbJSON',
        value: 'sbJson',
        type: 'application/json',
        tip: 'USGS ScienceBase metadata format'
      }];
    }),
    result: null,
    errorLevel: null,
    errors: null,
    xhrError: null,
    isLoading: false,
    subTitle: null,
    errorClass: Ember.computed('errorLevel', 'errors', function () {
      return errorClasses[Ember.get(this, 'errorLevel')];
    }),
    errorTitle: Ember.computed('errorLevel', 'errors', function () {
      let type = ['Success', 'Notice', 'Warning', 'Error'];
      return type[Ember.get(this, 'errorLevel')];
    }),
    errorSubTitle: Ember.computed('subTitle', function () {
      let err = Ember.get(this, 'errors');

      if (err.length) {
        return Ember.get(this, 'errorTitle') + ' ocurred during translation.';
      }

      return null;
    }),
    writeObj: Ember.computed('writer', function () {
      return Ember.get(this, 'writerOptions').findBy('value', Ember.get(this, 'writer'));
    }),
    writerType: Ember.computed('writeObj', function () {
      let obj = Ember.get(this, 'writeObj');
      return obj ? obj.type.split('/')[1] : null;
    }),
    isJson: Ember.computed.equal('writerType', 'json'),
    defaultAPI: _setting.defaultValues.mdTranslatorAPI,
    apiURL: Ember.computed.or('settings.data.mdTranslatorAPI', 'defaultAPI'),
    isHtml: Ember.computed('writerType', function () {
      //IE does not supoprt srcdoc, so default to non-html display
      return Ember.get(this, 'writerType') === 'html' && 'srcdoc' in document.createElement('iframe');
    }),
    messages: Ember.computed.alias('errors'),

    _clearResult() {
      Ember.set(this, 'result', null);
      Ember.set(this, 'subtitle', null);
      Ember.set(this, 'errors', null);
      Ember.set(this, 'xhrError', null);
    },

    actions: {
      translate() {
        let mdjson = this.mdjson;
        let url = this.apiURL;
        let cmp = this;

        this._clearResult();

        Ember.set(this, 'isLoading', true);
        this.ajax.request(url, {
          type: 'POST',
          data: {
            //file: JSON.stringify(cleaner.clean(json)),
            file: mdjson.formatRecord(Ember.get(this, 'model'), true),
            reader: 'mdJson',
            writer: Ember.get(this, 'writer'),
            showAllTags: Ember.get(this, 'showAllTags'),
            forceValid: Ember.get(this, 'forceValid'),
            validate: 'normal',
            format: 'json'
          },
          context: this
        }).then(function (response) {
          Ember.set(cmp, 'isLoading', false);
          let level = Math.max(...[response.readerExecutionStatus, response.readerStructureStatus, response.readerValidationStatus, response.writerStatus].map(itm => errorLevels[itm]));
          Ember.set(cmp, 'errorLevel', level);
          Ember.set(cmp, 'errors', response.readerExecutionMessages.concat(response.readerStructureMessages, response.readerValidationMessages.length ? response.readerValidationMessages[0] : response.readerValidationMessages, response.writerMessages).map(itm => itm.split(':')));
          Ember.set(cmp, 'result', response.writerOutput);

          if (!response.success) {
            Ember.get(cmp, 'flashMessages').danger('Translation error!');
          }
        }, response => {
          let error = "mdTranslator Server error:\n          ".concat(response.status, ": ").concat(response.statusText);
          Ember.set(cmp, 'errorLevel', 3);
          Ember.set(cmp, 'isLoading', false);
          Ember.set(cmp, 'xhrError', error);
          Ember.get(cmp, 'flashMessages').danger(error);
        });
      },

      saveResult() {
        let title = Ember.get(this, 'model.title');
        let result = Ember.get(this, 'result');
        let writer = Ember.get(this, 'writeObj');
        window.saveAs(new Blob([result], {
          type: "".concat(writer.type, ";charset=utf-8")
        }), "".concat(title, "_").concat((0, _moment.default)().format('YYYYMMDD'), ".").concat(Ember.get(this, 'writerType')));
      },

      clearResult() {
        this._clearResult();
      },

      prettifyJson() {
        let promise = new Ember.RSVP.Promise((resolve, reject) => {
          let parsed = JSON.parse(Ember.get(this, 'result'));

          if (parsed) {
            resolve(parsed);
          } else {
            reject('JSON not valid');
          }
        });
        promise.then(obj => {
          Ember.set(this, 'result', JSON.stringify(obj, null, 2));
        }).catch(error => {
          //console.log(error);
          Ember.get(this, 'flashMessages').danger(error.message);
        });
      },

      errorClass(level) {
        return errorClasses[errorLevels[level]] || 'primary';
      },

      formatMessage(message) {
        return message ? message.trim().replace(/^([A-Z]{2,})/g, match => match.toLowerCase()) : 'context not provided';
      }

    }
  });

  _exports.default = _default;
});