define("mdeditor/pods/components/object/md-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYjX9o0o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"input/md-datetime\",null,[[\"valuePath\",\"model\",\"showValidations\",\"required\",\"forceDateOutput\",\"profilePath\"],[\"date\",[23,0,[]],true,true,true,[28,\"concat\",[[24,[\"profilePath\"]],\".date\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input/md-codelist\",null,[[\"create\",\"required\",\"tooltip\",\"icon\",\"disabled\",\"allowClear\",\"showValidations\",\"mdCodeName\",\"profilePath\",\"path\",\"model\",\"placeholder\"],[true,true,true,false,[24,[\"disabled\"]],true,true,\"dateType\",[28,\"concat\",[[24,[\"profilePath\"]],\".dateType\"],null],\"dateType\",[23,0,[]],\"Choose date type\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input/md-input\",null,[[\"value\",\"profilePath\",\"placeholder\"],[[24,[\"model\",\"description\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".description\"],null],\"Describe the date.\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-date/template.hbs"
    }
  });

  _exports.default = _default;
});