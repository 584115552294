define("mdeditor/pods/components/control/md-itis/component", ["exports", "moment", "mdeditor/pods/components/object/md-citation/component"], function (_exports, _moment, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.selected = [];
      (true && !(this.taxonomy) && Ember.assert('No taxonomy object supplied', this.taxonomy));
    },

    tagName: 'form',
    classNames: ['md-itis'],
    itis: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    searchString: null,
    kingdom: null,
    total: null,
    isLoading: false,
    limit: 25,
    resultTitle: Ember.computed('limit', 'total', 'searchResult.[]', function () {
      let total = this.total;
      let result = this.get('searchResult.length');
      let limit = this.limit;
      return total <= limit ? result : "".concat(result, " of ").concat(total);
    }),
    notFound: Ember.computed('searchResult', function () {
      let result = this.searchResult;
      return Ember.isArray(result) && result.length === 0;
    }),
    found: Ember.computed.or('selected.length', 'searchResult.length'),

    submit() {
      let itis = this.itis;
      this.set('isLoading', true);
      this.set('searchResult', null);
      itis.sendQuery(this.searchString, this.kingdom, this.limit).then(response => {
        if (!response) {
          return;
        }

        let docs = response.response.docs;
        let data = docs.map(doc => itis.parseDoc(doc));
        this.set('searchResult', data);
        this.set('total', response.response.numFound);
        this.set('isLoading', false);
      });
    },

    actions: {
      search() {
        this.submit.call(this);
      },

      selectItem(item) {
        item.set('animate', true);
        item.set('selected', true);
        Ember.run.later(this, function () {
          this.searchResult.removeObject(item);
          this.selected.pushObject(item);
        }, 250);
      },

      deselectItem(item) {
        item.set('selected', false);
        Ember.run.later(this, function () {
          this.selected.removeObject(item);
          this.searchResult.pushObject(item);
        }, 250);
      },

      importTaxa(taxa) {
        let taxonomy = this.taxonomy;
        let itisCitation = this.get('itis.citation');
        let classification = Ember.set(taxonomy, 'taxonomicClassification', Ember.getWithDefault(taxonomy, 'taxonomicClassification', []));
        let systems = Ember.set(taxonomy, 'taxonomicSystem', Ember.getWithDefault(taxonomy, 'taxonomicSystem', [{
          citation: {}
        }]));
        let system = systems.findBy('citation.title', Ember.get(itisCitation, 'title'));
        let allTaxa = taxa.reduce((acc, itm) => acc.pushObjects(itm.taxonomy), []);
        let today = (0, _moment.default)().format('YYYY-MM-DD');
        let dateObj = {
          date: today,
          dateType: 'transmitted',
          description: 'Taxa imported from ITIS'
        };
        allTaxa.forEach(itm => this.itis.mergeTaxa(itm, classification));

        if (!system) {
          itisCitation.get('date').pushObject(dateObj);
          systems.pushObject({
            citation: itisCitation
          });
        } else {
          let citation = Ember.set(system, 'citation', Ember.getWithDefault(system, 'citation', {}));
          (0, _component.formatCitation)(citation);
          let date = Ember.A(Ember.get(citation, 'date'));

          if (!date.findBy('date', today)) {
            date.pushObject(dateObj);
          }
        }

        this.flashMessages.success("Successfully imported ".concat(allTaxa.length, " taxa from ITIS."));
      }

    }
  });

  _exports.default = _default;
});