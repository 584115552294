define("mdeditor/models/profile", ["exports", "ember-data", "mdeditor/models/schema", "ember-cp-validations"], function (_exports, _emberData, _schema, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // [{
  //   "id": "full",
  //   "namespace": "org.adiwg.profile",
  //   "alternateId": [""],
  //   "title": "Full",
  //   "description": "Evey supported component",
  //   "version": "0.0.0",
  //   "components": {
  //     "record": {},
  //     "contact": {},
  //     "dictionary": {}
  //   },
  //   "nav": {
  //     "record": [{
  //       "title": "",
  //       "target": "",
  //       "tip": ""
  //     }]
  //   }
  // }]
  const Validations = (0, _emberCpValidations.buildValidations)({
    'config': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'The definition has not been downloaded.'
    }),
    'uri': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      regex: _schema.regex,
      isWarning: false,
      message: 'This field should be a valid, resolvable URL.'
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    /**
     * Profile model
     *
     * @class profile
     * @constructor
     * @extends DS.Model
     * @module mdeditor
     * @submodule data-models
     */
    init() {
      this._super(...arguments);

      this.updateSettings;
    },

    uri: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    altDescription: _emberData.default.attr('string'),
    remoteVersion: _emberData.default.attr('string'),
    config: _emberData.default.attr('json'),
    title: Ember.computed.or('alias', 'config.title'),
    identifier: Ember.computed.alias('config.identifier'),
    namespace: Ember.computed.alias('config.namespace'),
    description: Ember.computed.or('altDescription', 'config.description'),
    localVersion: Ember.computed.alias('config.version'),
    components: Ember.computed.alias('config.components'),
    nav: Ember.computed.alias('config.nav'),
    hasUpdate: Ember.computed('localVersion', 'remoteVersion', _schema.checkVersion),

    /* eslint-disable ember/no-observers */
    updateSettings: Ember.observer('hasDirtyAttributes', 'alias', 'uri', 'altDescription', 'remoteVersion', 'config', function () {
      if (this.isNew || this.isEmpty || this.isDeleted) {
        return;
      }

      if (this.hasDirtyAttributes) {
        this.set('dateUpdated', new Date());
        Ember.run.once(this, function () {
          this.save();
        });
      }
    })
    /* eslint-enable ember/no-observers */

  });

  _exports.default = _default;
});