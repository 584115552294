define("mdeditor/pods/components/object/md-bbox/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'north': [(0, _emberCpValidations.validator)('number', {
      allowNone: false,
      gte: -90,
      lte: 90
    })],
    'south': [(0, _emberCpValidations.validator)('number', {
      allowNone: false,
      gte: -90,
      lte: 90
    }), (0, _emberCpValidations.validator)('number', {
      lte: Ember.computed.readOnly('model.north')
    })],
    'east': [(0, _emberCpValidations.validator)('number', {
      allowNone: false,
      gte: -180,
      lte: 180
    })],
    'west': [(0, _emberCpValidations.validator)('number', {
      allowNone: false,
      gte: -180,
      lte: 180
    }), (0, _emberCpValidations.validator)('number', {
      lte: Ember.computed.readOnly('model.east')
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    classNames: ['form'],
    north: Ember.computed.alias('model.northLatitude'),
    south: Ember.computed.alias('model.southLatitude'),
    east: Ember.computed.alias('model.eastLongitude'),
    west: Ember.computed.alias('model.westLongitude') // btnText: computed('isTruelyValid', function() {
    //   let text = this.get('validations.isTruelyValid') ? ''
    //   this.set('btnText', )
    // }),

  });

  _exports.default = _default;
});