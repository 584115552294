define("mdeditor/pods/components/object/md-spatial-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qWd4db/x",
    "block": "{\"symbols\":[\"editing\",\"editing\",\"&default\"],\"statements\":[[1,[28,\"input/md-codelist-multi\",null,[[\"value\",\"create\",\"tooltip\",\"icon\",\"mdCodeName\",\"closeOnSelect\",\"placeholder\",\"label\",\"profilePath\"],[[24,[\"model\",\"spatialRepresentationType\"]],true,true,false,\"spatialRepresentation\",false,\"Select method used to represent geographic information\",\"Spatial Representation Type\",[28,\"concat\",[[24,[\"profilePath\"]],\".spatialRepresentationType\"],null]]]],false],[0,\"\\n\\n\"],[4,\"object/md-object-table\",null,[[\"items\",\"header\",\"buttonText\",\"ellipsis\",\"profilePath\",\"attributes\"],[[24,[\"model\",\"spatialReferenceSystem\"]],\"Spatial Reference System\",\"Add Reference System\",true,[28,\"concat\",[[24,[\"profilePath\"]],\".spatialReferenceSystem\"],null],\"referenceSystemType,referenceSystemIdentifier.identifier\"]],{\"statements\":[[0,\"  \"],[1,[28,\"object/md-srs\",null,[[\"model\",\"profilePath\"],[[23,2,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".spatialReferenceSystem\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"object/md-object-table\",null,[[\"items\",\"header\",\"buttonText\",\"ellipsis\",\"profilePath\",\"attributes\"],[[24,[\"model\",\"spatialResolution\"]],\"Spatial Resolution\",\"Add Spatial Resolution\",true,[28,\"concat\",[[24,[\"profilePath\"]],\".spatialResolution\"],null],\"scaleFactor,levelOfDetail,measure.type\"]],{\"statements\":[[0,\"  \"],[1,[28,\"object/md-spatial-resolution\",null,[[\"model\",\"profilePath\"],[[23,1,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".spatialResolution\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-spatial-info/template.hbs"
    }
  });

  _exports.default = _default;
});