define("mdeditor/pods/components/control/md-record-table/buttons/custom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q9XgWurF",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[4,\"with\",[[24,[\"column\",\"buttonConfig\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"class\",[29,[\"btn btn-sm btn-\",[28,\"if\",[[23,1,[\"style\"]],[23,1,[\"style\"]],\"primary\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[\"action\"]],[24,[\"record\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"binoculars\"],null],false],[0,\" \"],[1,[23,1,[\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-record-table/buttons/custom/template.hbs"
    }
  });

  _exports.default = _default;
});