define("mdeditor/pods/record/show/edit/metadata/alternate/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fkxjn5Q7",
    "block": "{\"symbols\":[\"citation\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"col-xxl-11 col-xxxl-10 col-xxl-offset-1 section-header\"],[8],[0,\"Editing Alternate Metadata Reference\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[28,\"compute\",[[28,\"route-action\",[\"parentModel\"],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"with\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"object/md-citation\",null,[[\"class\",\"model\",\"profilePath\",\"editIdentifier\"],[\"col-xxl-11 col-xxxl-10 col-xxl-offset-1\",[23,1,[]],\"record.metadata.alternateMetadataReference\",[28,\"route-action\",[\"editIdentifier\"],null]]]],false],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"text\",\"icon\",\"route\"],[\"Back to Metadata\",\"arrow-left\",\"record.show.edit.metadata\"]]]]]],false],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/metadata/alternate/index/template.hbs"
    }
  });

  _exports.default = _default;
});