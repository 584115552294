define("mdeditor/pods/components/input/md-input-confirm/component", ["exports", "mdeditor/pods/components/input/md-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * Input, edit, display a single item
     *
     * @class md-input-confirm
     * @extends md-input
     * @constructor
     */
    classNameBindings: ['required'],
    disabled: true,
    isDisabled: Ember.computed('disabled', function () {
      return this.disabled;
    }),
    actions: {
      allowEdit() {
        this.set('disabled', false);
        this.element.querySelector('input').focus();
      },

      inputBlur() {
        this.set('disabled', true);
      }

    }
  });

  _exports.default = _default;
});