define("mdeditor/pods/dictionary/show/edit/domain/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('domainId', params.domain_id);
      return this.setupModel();
    },

    breadCrumb: Ember.computed('domainId', function () {
      return {
        title: this.domainId
      };
    }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('setupModel', this.setupModel);
      this.controller.set('domainId', this.domainId);
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let domainId = this.domainId;
      let model = this.modelFor('dictionary.show.edit');
      let objects = model.get('json.dataDictionary.domain');
      let resource = domainId && Ember.isArray(objects) ? objects.objectAt(domainId) : undefined; //make sure the domain exists

      if (Ember.isEmpty(resource)) {
        this.flashMessages.warning('No Domain object found! Re-directing to list...');
        this.replaceWith('dictionary.show.edit.domain');
        return;
      }

      return resource;
    }

  });

  _exports.default = _default;
});