define("mdeditor/models/schema", ["exports", "ember-data", "ember-cp-validations", "semver", "ajv", "ajv-errors", "ajv/lib/refs/json-schema-draft-04"], function (_exports, _emberData, _emberCpValidations, _semver, _ajv, ajvErrors, draft4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.checkVersion = _exports.regex = void 0;
  const ajvOptions = {
    verbose: true,
    allErrors: true,
    jsonPointers: true,
    removeAdditional: false,
    schemaId: 'auto'
  };
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  _exports.regex = regex;

  const checkVersion = function checkVersion() {
    if (!this.localVersion && this.remoteVersion) {
      return true;
    }

    return this.remoteVersion ? _semver.default.gt(this.remoteVersion, this.localVersion) : false;
  };

  _exports.checkVersion = checkVersion;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'title': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'description': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'schemaType': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      description: 'This value',
      in: ['record', 'contact', 'dictionary']
    })],
    'uri': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      regex: regex,
      isWarning: false,
      message: 'This field should be a valid, resolvable URL.'
    })],
    'customSchemas': [(0, _emberCpValidations.validator)('array-valid'), (0, _emberCpValidations.validator)('array-required', {
      track: ['type'],
      isWarning: true
    })]
  });

  const theComp = _emberData.default.Model.extend(Validations, {
    /**
     * Schema model
     *
     * @class schema
     * @constructor
     * @extends DS.Model
     * @module mdeditor
     * @submodule data-models
     */
    init() {
      this._super(...arguments);

      this.schemaValidator = ajvErrors(new _ajv.default(ajvOptions));
      this.schemaValidator.addMetaSchema(draft4);
      this.updateSettings;
    },

    flashMessages: Ember.inject.service(),
    title: _emberData.default.attr('string'),
    uri: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    schemaType: _emberData.default.attr('string'),
    version: _emberData.default.attr('string'),
    remoteVersion: _emberData.default.attr('string'),
    isGlobal: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    customSchemas: _emberData.default.attr('json', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    status: Ember.computed('validations.isInvalid', function () {
      return this.validations.isInvalid ? 'warning' : 'success';
    }),
    formattedType: Ember.computed('schemaType', function () {
      return this.schemaType === 'record' ? 'Metadata' : Ember.String.capitalize(this.schemaType || 'Unknown');
    }),
    formattedGlobal: Ember.computed('isGlobal', function () {
      return this.isGlobal ? 'Yes' : 'No';
    }),

    /**
     * The timestamp for the record
     *
     * @attribute dateUpdated
     * @type {date}
     * @default new Date()
     * @required
     */
    dateUpdated: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    localVersion: Ember.computed.or('version', 'rootSchema.version'),
    hasUpdate: Ember.computed('version', 'remoteVersion', 'customSchemas.0.version', checkVersion),
    rootSchema: Ember.computed('customSchemas.firstObject.schema', function () {
      return this.customSchemas.get('firstObject.schema');
    }),
    validator: Ember.computed('isGlobal', 'customSchemas', function () {
      if (!this.isGlobal && !this.get('customSchemas.length')) {
        return;
      }

      this.schemaValidator.removeSchema();
      let valid = this.customSchemas.every(schema => {
        return this.schemaValidator.validateSchema(schema.schema);
      });

      if (valid) {
        return this.schemaValidator.addSchema(this.customSchemas.mapBy('schema'));
      }

      this.flashMessages.danger("Could not load schemas for ".concat(this.title, ". Schemas provided did not validate."));
    }),

    /* eslint-disable ember/no-observers */
    updateSettings: Ember.observer('hasDirtyAttributes', 'title', 'uri', 'description', 'schemaType', 'remoteVersion', 'schemaType', 'isGlobal', 'customSchemas.[]', function () {
      if (this.isNew || this.isEmpty || this.isDeleted) {
        return;
      }

      if (this.hasDirtyAttributes) {
        this.set('dateUpdated', new Date());
        Ember.run.once(this, function () {
          this.save();
        });
      }
    })
    /* eslint-enable ember/no-observers */

  });

  _exports.default = theComp;
});