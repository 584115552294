define("mdeditor/pods/components/models-table/cell-content-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DSgSwcxQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"column\",\"truncate\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"word-limit\",[[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null]],[[\"limit\",\"wordLength\"],[[24,[\"column\",\"wordLimit\"]],[24,[\"column\",\"wordLength\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"column\",\"break\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"word-break-all inline-block\"],[8],[0,\"\\n    \"],[1,[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/models-table/cell-content-display/template.hbs"
    }
  });

  _exports.default = _default;
});