define("mdeditor/pods/export/route", ["exports", "moment", "mdeditor/mixins/scroll-to", "ember-inflector"], function (_exports, _moment, _scrollTo, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const modelTypes = ['records', 'contacts', 'dictionaries', 'settings', 'schemas', 'custom-profiles', 'profiles'];

  var _default = Ember.Route.extend(_scrollTo.default, {
    mdjson: Ember.inject.service(),
    settings: Ember.inject.service(),

    model() {
      //const store = this.get('store');
      return Ember.Object.create({
        records: this.modelFor('application'),
        settings: this.get('settings.data')
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      Ember.defineProperty(this.controller, 'hasSelected', Ember.computed('model.{records.0.@each._selected,records.1.@each._selected,records.2.@each._selected,settings._selected}', function () {
        return this.store.peekAll('record').filterBy('_selected').get('length') + this.store.peekAll('contact').filterBy('_selected').get('length') + this.store.peekAll('dictionary').filterBy('_selected').get('length') + this.store.peekAll('setting').filterBy('_selected').get('length') > 0;
      }));
      Ember.defineProperty(this.controller, 'hasSelectedRecords', Ember.computed('model.records.0.@each._selected', function () {
        return this.store.peekAll('record').filterBy('_selected').get('length') > 0;
      }));
    },

    columns: Ember.Object.create({
      record: [{
        propertyName: 'title',
        title: 'Title'
      }, {
        propertyName: 'defaultType',
        title: 'Type'
      }, {
        propertyName: 'recordId',
        title: 'ID'
      }],
      contact: [{
        propertyName: 'title',
        title: 'Title'
      }, {
        propertyName: 'defaultOrganization',
        title: 'Organization'
      }, {
        propertyName: 'json.electronicMailAddress.firstObject',
        title: 'E-mail'
      }, {
        propertyName: 'contactId',
        title: 'ID'
      }],
      dictionary: [{
        propertyName: 'title',
        title: 'Title'
      }, {
        propertyName: 'defaultType',
        title: 'Type'
      }, {
        propertyName: 'dictionaryId',
        title: 'ID'
      }]
    }),
    actions: {
      exportData() {
        this.store.exportData(modelTypes, {
          download: true,
          filename: "mdeditor-".concat((0, _moment.default)().format('YYYYMMDD-HHMMSS'), ".json")
        });
      },

      exportSelectedData(asMdjson) {
        let store = this.store;

        if (asMdjson) {
          let records = store.peekAll('record').filterBy('_selected').map(itm => {
            return Ember.get(this, 'mdjson').formatRecord(itm);
          });
          window.saveAs(new Blob([JSON.stringify(records)], {
            type: 'application/json;charset=utf-8'
          }), "mdjson-".concat((0, _moment.default)().format('YYYYMMDD-HHMMSS'), ".json"));
        } else {
          let filterIds = {};
          modelTypes.forEach(type => {
            let t = (0, _emberInflector.singularize)(type);
            filterIds[t] = store.peekAll(t).filterBy('_selected').mapBy('id');
          }); //export schemas with settings

          if (filterIds.setting.length) {
            filterIds['schema'] = store.peekAll('schema').mapBy('id');
            filterIds['profile'] = store.peekAll('profile').mapBy('id');
            filterIds['custom-profile'] = store.peekAll('custom-profile').mapBy('id');
          }

          store.exportSelectedData(modelTypes, {
            download: true,
            filename: "mdeditor-".concat((0, _moment.default)().format('YYYYMMDD-HHMMSS'), ".json"),
            filterIds: filterIds
          });
        }
      },

      getColumns(type) {
        return Ember.get(this, 'columns').get(type);
      },

      hasSelected() {
        return Ember.get(this, 'hasSelected');
      }

    }
  });

  _exports.default = _default;
});