define("mdeditor/services/keyword", ["exports", "gcmd-keywords", "mdkeywords", "mdcodes/resources/js/iso_topicCategory"], function (_exports, _gcmdKeywords, _mdkeywords, _iso_topicCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import NGGDPP from '../data/sciencebase-nggdpp-vocabulary';
  let service = Ember.Object.create({
    thesaurus: Ember.A(),

    findById(id) {
      return this.thesaurus.find(function (t) {
        return t.citation.identifier[0].identifier === id;
      });
    },

    fetchSciencebaseVocab() {
      if (service.get('thesaurus').find(item => item.label === 'NGGDPP Vocabulary')) return Promise.resolve();
      console.log('Fetching sciencebase vocab');
      return fetch('https://timothypage.github.io/vocab-harvester/vocabulary.json').then(response => response.json()).then(NGGDPP => {
        service.get('thesaurus').pushObject({
          citation: {
            date: [{
              date: '2022-09',
              dateType: 'revision'
            }],
            title: 'NGGDPP',
            edition: 'ISO 19115-2:2020',
            onlineResource: [{
              uri: 'https://www.sciencebase.gov/vocab/category/4f4e475ee4b07f02db47df09'
            }],
            identifier: [{
              identifier: 'NGGDPP Vocabulary'
            }]
          },
          keywords: NGGDPP,
          keywordType: 'NGGDPPkeywords',
          label: 'NGGDPP Vocabulary'
        });
      }).catch(err => console.log('Request Failed', err));
    }

  });
  let type = {
    'Platforms': 'platform',
    'Instruments': 'instrument'
  };
  Object.keys(_gcmdKeywords.GCMD).forEach(function (key) {
    if (Array.isArray(_gcmdKeywords.GCMD[key])) {
      service.get('thesaurus').pushObject({
        citation: {
          date: [{
            date: _gcmdKeywords.GCMD.version.date,
            dateType: 'revision'
          }],
          title: 'Global Change Master Directory (GCMD) ' + _gcmdKeywords.GCMD[key][0].label,
          edition: 'Version ' + _gcmdKeywords.GCMD.version.edition,
          onlineResource: [{
            uri: 'https://earthdata.nasa.gov/gcmd-forum'
          }],
          identifier: [{
            identifier: _gcmdKeywords.GCMD[key][0].uuid
          }]
        },
        keywords: _gcmdKeywords.GCMD[key][0].children,
        keywordType: type[_gcmdKeywords.GCMD[key][0].label] || 'theme',
        label: 'GCMD ' + _gcmdKeywords.GCMD[key][0].label
      });
    }
  });

  let isoKeywords = _iso_topicCategory.default.codelist.map(topic => {
    return {
      label: topic.codeName,
      definition: topic.description,
      uuid: topic.code
    };
  });

  service.get('thesaurus').pushObject({
    citation: {
      date: [{
        date: '2014-04',
        dateType: 'revision'
      }],
      title: 'ISO 19115 Topic Category',
      edition: 'ISO 19115-1:2014',
      onlineResource: [{
        uri: 'https://doi.org/10.18123/D6RP4M'
      }],
      identifier: [{
        identifier: 'ISO 19115 Topic Category'
      }]
    },
    keywords: isoKeywords,
    keywordType: 'isoTopicCategory',
    label: 'ISO Topic Category'
  });
  service.get('thesaurus').pushObjects(_mdkeywords.default.asArray());

  var _default = Ember.Service.extend(service);

  _exports.default = _default;
});