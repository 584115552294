define("mdeditor/ember-leaflet-table/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/components/feature-form.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/feature-form.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/feature-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/feature-group.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/feature-table.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/feature-table.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/leaflet-draw.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/leaflet-draw.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/leaflet-table-row-actions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/leaflet-table-row-actions.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/leaflet-table-row.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/leaflet-table-row.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/leaflet-table.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/leaflet-table.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/lgeojson-layer.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/lgeojson-layer.js should pass ESLint\n\n');
  });
});