define("mdeditor/pods/record/show/edit/taxonomy/collection/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZumqXaVW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Taxonomy Collection #\"],[1,[22,\"collectionId\"],false],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-taxonomy/collection\",null,[[\"model\",\"id\",\"profilePath\",\"editSystem\"],[[24,[\"model\"]],[24,[\"collectionId\"]],\"record.taxonomy.collection\",[28,\"route-action\",[\"editSystem\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-buttons\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Add New Taxa\",\"plus\",\"btn-info btn-lg\",[28,\"route-action\",[\"addTaxa\"],null]]]]]]],false],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-buttons\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Add Taxa from ITIS\",\"plus\",\"btn-success btn-lg\",[28,\"route-action\",[\"addITIS\"],null]]]]]]],false],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-buttons\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Back to List\",\"arrow-left\",\"btn-primary btn-lg\",[28,\"route-action\",[\"toList\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/taxonomy/collection/index/template.hbs"
    }
  });

  _exports.default = _default;
});