define("mdeditor/pods/translate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v9LM7r1W",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"style\",true],[10,\"media\",\"screen\"],[8],[0,\"\\n    .construction {\\n        width: 50vh;\\n        min-width:240px;\\n        font-size:5vh;\\n        border: 2vh solid #333333;\\n        margin: 10vh auto;\\n        padding: 3vh;\\n        border-radius: 5vh;\\n    }\\n\"],[9],[0,\"\\n\"],[4,\"layout/md-card\",null,[[\"class\",\"shadow\"],[\"construction card-inverse card-warning\",false]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],[[\"size\"],[2]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"b\",true],[8],[0,\"Page Under \"],[7,\"br\",true],[8],[9],[0,\"Construction\"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/translate/template.hbs"
    }
  });

  _exports.default = _default;
});