define("mdeditor/utils/md-interpolate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseArgs = _exports.interpolate = void 0;

  let interpolate = function interpolate(str, obj) {
    let parts = str.split(/\$\{(?!\d)[^0-9][a-zA-Z0-9.]+\}/);
    let args = parseArgs(str);
    let parameters = args.map(argument => obj[argument] || (obj[argument] === undefined ? "" : obj[argument]));
    return String.raw({
      raw: parts
    }, ...parameters);
  };

  _exports.interpolate = interpolate;

  let parseArgs = function parseArgs(str) {
    let args = str.match(/[^{}]+(?=})/g) || [];
    return args;
  };

  _exports.parseArgs = parseArgs;
});