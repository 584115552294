define("mdeditor/pods/dictionary/show/edit/domain/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "saiiMxD4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Domains\\n  \"],[7,\"span\",true],[10,\"class\",\"small\"],[8],[0,\"for \"],[7,\"em\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[9],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-objectroute-table\",null,[[\"items\",\"header\",\"shadow\",\"buttonText\",\"ellipsis\",\"attributes\",\"editItem\",\"verticalButtons\",\"profilePath\",\"hideIndex\",\"condensed\"],[[24,[\"model\",\"json\",\"dataDictionary\",\"domain\"]],\"Domain\",true,\"Add Domain\",true,\"domainId,codeName,description\",[28,\"route-action\",[\"editDomain\"],null],true,\"dictionary.domain\",false,false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/edit/domain/index/template.hbs"
    }
  });

  _exports.default = _default;
});