define("mdeditor/pods/components/object/md-keyword-citation/component", ["exports", "mdeditor/pods/components/object/md-online-resource/component", "ember-cp-validations"], function (_exports, _component, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'onlineResource': [(0, _emberCpValidations.validator)('format', {
      regex: _component.regex,
      isWarning: true,
      message: 'This field should be a valid, resolvable uri.',
      dependentKeys: ['onlineResource', 'model.thesaurus.onlineResource.0.uri']
    })],
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });

  var _default = Ember.Component.extend(Validations, {
    disabled: Ember.computed('model.thesaurus.identifier.0.identifier', function () {
      return this.get('model.thesaurus.identifier.0.identifier') !== 'custom';
    }),
    title: Ember.computed.alias('model.thesaurus.title'),
    onlineResource: Ember.computed('model.thesaurus.onlineResource.0.uri', {
      get() {
        return this.get('model.thesaurus.onlineResource.0.uri');
      },

      set(key, value) {
        let ol = this.get('model.thesaurus.onlineResource');

        if (!Ember.isArray(ol)) {
          this.set('model.thesaurus.onlineResource', [{}]);
        }

        this.set('model.thesaurus.onlineResource.0.uri', value);
        return value;
      }

    }),
    date: Ember.computed('model.thesaurus.date.0.date', {
      get() {
        return this.get('model.thesaurus.date.0.date');
      },

      set(key, value) {
        let ol = this.get('model.thesaurus.date');

        if (!Ember.isArray(ol)) {
          this.set('model.thesaurus.date', [{}]);
        }

        this.set('model.thesaurus.date.0.date', value);
        return value;
      }

    }),
    dateType: Ember.computed('model.thesaurus.date.0.dateType', {
      get() {
        return this.get('model.thesaurus.date.0.dateType');
      },

      set(key, value) {
        let ol = this.get('model.thesaurus.date');

        if (!Ember.isArray(ol)) {
          this.set('model.thesaurus.date', [{}]);
        }

        this.set('model.thesaurus.date.0.dateType', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});