define("mdeditor/pods/components/object/md-documentation/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'resourceType': [(0, _emberCpValidations.validator)('array-required', {
      track: ['type']
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'resourceType', Ember.getWithDefault(model, 'resourceType', []));
        Ember.set(model, 'citation', Ember.A(Ember.getWithDefault(model, 'citation', [{}])));
      });
    },

    tagName: 'form',

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    citation: Ember.computed.alias('model.citation'),
    resourceType: Ember.computed.alias('model.resourceType')
  });

  _exports.default = _default;
});