define("mdeditor/pods/dictionary/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5jc5Oo1o",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"nav-main\",[28,\"component\",[\"layout/nav/dictionary/nav-main\"],[[\"model\"],[[24,[\"model\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/template.hbs"
    }
  });

  _exports.default = _default;
});