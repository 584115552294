define("mdeditor/app", ["exports", "mdeditor/resolver", "ember-load-initializers", "mdeditor/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let App;
  let events = {
    // add support for the blur event
    blur: 'blur' //Ember.MODEL_FACTORY_INJECTIONS = true;

  };
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    customEvents: events
  }); // window.mdProfile = {
  //   // record:{},contact:{},dictionary:{}
  // };

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix); //for bootstrap

  Ember.LinkComponent.reopen({
    attributeBindings: ['data-toggle', 'data-placement']
  }); //for crumbly

  Ember.Route.reopen({
    //breadCrumb: null
    currentRouteModel: function currentRouteModel() {
      return this.modelFor(this.routeName);
    }
  }); //for profiles

  Ember.Component.reopen({
    init() {
      this._super(...arguments);

      let profile = Ember.get(this, 'profile');
      let path = Ember.get(this, 'profilePath');
      let visibility = Ember.get(this, 'visibility');
      let isVisible = Ember.isNone(visibility) ? true : visibility;

      if (path !== undefined) {
        (true && !(path.charAt(0) !== '.') && Ember.assert("".concat(path, " is not a profile path!"), path.charAt(0) !== '.')); // generate profile definition
        // path.split('.').reduce((acc, curr, idx) => {
        //   let pp = idx ? `${acc}.${curr}` : curr;
        //   window.console.log(pp);
        //   if(!get(window.mdProfile, pp)) {
        //     set(window.mdProfile, pp, {
        //       //visible: true
        //     });
        //   }
        //   return pp;
        // }, '');

        Ember.defineProperty(this, 'isVisible', Ember.computed('profile.active', function () {
          if (!profile.activeComponents) {
            return isVisible;
          }

          return Ember.getWithDefault(profile.activeComponents, path, isVisible);
        }));
      }
    }

  });
  var _default = App;
  /**
  * Models for the mdEditor data store
  
  * @main data-models
  * @module mdeditor
  * @submodule data-models
  * @category docs
  */

  /**
   * Components used to create objects or arrays of objects.
   *
   * @module mdeditor
   * @submodule components-object
   * @main components-object
   * @category docs
   */

  /**
   * Components used to input scalar or arrays of scalar values.
   *
   * @module mdeditor
   * @submodule components-input
   * @main components-input
   * @category docs
   */

  /**
   * Components used as UI controls.
   *
   * @module mdeditor
   * @submodule components-control
   * @main components-control
   * @category docs
   */

  /**
   * Mixins.
   *
   * @module mdeditor
   * @submodule mixins
   * @main mixins
   * @category docs
   */

  _exports.default = _default;
});