define("mdeditor/ember-json-tree/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/nestable-draggable-object.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/nestable-draggable-object.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tree-branch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tree-branch.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tree-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tree-label.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tree-leaf.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tree-leaf.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tree-search.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tree-search.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tree-view.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tree-view.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/make-range.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/make-range.js should pass ESLint\n\n');
  });
});