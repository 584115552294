define("mdeditor/services/custom-profile", ["exports", "mdeditor/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The default profile identifier
   *
   * @property defaultProfileId
   * @type {String}
   * @default "mdeditor.config.environment.config.defaultProfileId"
   * @static
   * @readOnly
   */
  const {
    APP: {
      defaultProfileId
    }
  } = _environment.default;
  /**
   * Custom Profile service
   *
   * Service that provides custom profile configurations.
   *
   * @module mdeditor
   * @submodule service
   * @class custom-profile
   */

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.customProfiles = this.store.peekAll('custom-profile');
    },

    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    definitions: Ember.inject.service('profile'),

    /**
     * String identifying the active profile
     *
     * @property active
     * @type {String}
     */
    active: null,

    /**
     * Array of all available profiles
     *
     * @property profiles
     * @type {Array}
     * @category computed
     * @required customProfiles,coreProfiles
     */
    profiles: Ember.computed.union('customProfiles', 'coreProfiles'),

    /**
     * Array of available coreProfile definitions
     *
     * @property coreProfiles
     * @type {Array}
     * @category computed
     * @required definitions.coreProfiles
     */
    coreProfiles: Ember.computed.map('definitions.coreProfiles', function (itm) {
      return {
        id: itm.namespace + '.' + itm.identifier,
        title: itm.title,
        description: itm.description,
        definition: itm
      };
    }),

    /**
     * Available profiles mapped by profile id
     *
     * @property mapById
     * @type {Array}
     * @category computed
     * @required profiles.[]
     */
    mapById: Ember.computed('profiles.[]', function () {
      return this.profiles.reduce(function (map, profile) {
        map[profile.id] = profile;
        return map;
      }, {});
    }),

    /**
     * Available profiles mapped by profile alternate id
     *
     * @property mapByAltId
     * @type {Array}
     * @category computed
     * @required profiles.[]
     */
    mapByAltId: Ember.computed('profiles.[]', function () {
      return this.profiles.reduce(function (map, profile) {
        let alt = Ember.get(profile, 'definition.alternateId');

        if (Ember.isEmpty(alt)) {
          return map;
        }

        alt.forEach(a => map[a] = profile.id);
        return map;
      }, {});
    }),

    /**
     * The defaultProfile definition
     *
     * @property defaultProfile
     * @type {Object}
     * @category computed
     * @required mapById
     */
    defaultProfile: Ember.computed('mapById', function () {
      return this.mapById[defaultProfileId];
    }),

    /**
     * The current component profile definition
     *
     * @property activeComponents
     * @type {Object}
     * @category computed
     * @required active
     */
    activeComponents: Ember.computed('active', function () {
      let comp = Ember.get(this.getActiveProfile(), 'definition.components');
      return comp || this.defaultProfile.definition.components;
    }),

    /**
     * The currently active schemas
     *
     * @property activeSchemas
     * @type {Array}
     * @category computed
     * @required active
     */
    activeSchemas: Ember.computed('active', function () {
      return this.getActiveProfile().schemas;
    }),

    /**
     * Get the active profile.
     *
     * @method getActiveProfile
     * @return {Object} The profile definition
     */
    getActiveProfile() {
      const active = this.active;
      const profile = active && typeof active === 'string' ? active : defaultProfileId;
      const selected = this.mapById[profile];

      if (selected) {
        return selected;
      }

      const alternate = this.mapById[this.mapByAltId[profile]];

      if (alternate) {
        this.flashMessages.info("\"".concat(active, "\" identified as an alternate profile. Using \"").concat(alternate.title, "\" profile. To make this permanent, select \"").concat(alternate.title, "\" from the Profile list."), {
          sticky: true
        });
        return alternate;
      }

      this.flashMessages.warning("Profile \"".concat(active, "\" not found. Using default profile."), {
        sticky: true
      });
      return this.defaultProfile;
    }

  });

  _exports.default = _default;
});