define("mdeditor/pods/dictionary/show/edit/entity/import/route", ["exports", "uuid/v4"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('entity', Ember.Object.create({
        entityId: (0, _v.default)(),
        attribute: []
      }));
    },

    /**
     * The template object for columns
     *
     * @property columnObject
     * @type {Ember.Object}
     * @static
     * @readOnly
     */
    columnObject: Ember.Object.extend({
      domain: null,
      import: true,
      range: false,
      importName: null,
      importType: null,
      min: Ember.computed.min('domain'),
      max: Ember.computed.max('domain'),
      domainWarn: Ember.computed.gt('domain.length', 50),
      isNumber: Ember.computed.equal('dataType', 'number'),
      disableRange: Ember.computed.not('isNumber'),
      example: Ember.computed('domain', function () {
        return this.domain.slice(0, 10);
      })
    }),

    /**
     * Returns the dataType code value for the JavaScript type.
     *
     * @method columnType
     * @param {string} type The JavaScript type
     * @return {string}
     */
    columnType(type) {
      return {
        'string': 'character varying',
        'number': 'numeric',
        'boolean': 'boolean',
        'integer': 'integer'
      }[type] || 'unknown';
    },

    createAttribute(columnName, column) {
      let domain = Ember.get(column, 'hasDomain') ? Ember.Object.create({
        domainId: (0, _v.default)(),
        codeName: columnName,
        domainItem: Ember.get(column, 'domain').filter(i => Ember.isPresent(i)).map(itm => {
          return {
            name: itm.toString(),
            value: itm.toString(),
            definition: null
          };
        })
      }) : false;
      let attribute = {
        codeName: column.importName,
        dataType: column.importType,
        allowNull: column.allowNull,
        maxValue: column.get('range') ? column.get('max').toString() : null,
        minValue: column.get('range') ? column.get('min').toString() : null,
        domainId: domain ? Ember.get(domain, 'domainId') : null
      };
      return {
        attribute: attribute,
        domain: domain
      };
    },

    generateData() {
      let columns = this.get('controller.columns');
      let domains = [];
      let attributes = [];
      Object.keys(columns).forEach(columnName => {
        let col = columns[columnName];

        if (!col.import) {
          return;
        }

        let attr = this.createAttribute(columnName, col);
        attributes.pushObject(attr.attribute);

        if (attr.domain) {
          domains.pushObject(attr.domain);
        }
      });
      return {
        attributes: attributes,
        domains: domains
      };
    },

    actions: {
      cancelImport() {
        Ember.set(this, 'controller.columns', null);
        Ember.set(this, 'controller.processed', false);
      },

      goToEntity() {
        this.transitionTo('dictionary.show.edit.entity');
      },

      doImport() {
        let data = this.generateData();
        let entity = this.get('controller.entity');
        let dataDictionary = this.get('controller.model.json.dataDictionary');

        if (Ember.get(data, 'domains.length')) {
          Ember.set(dataDictionary, 'domain', Ember.getWithDefault(dataDictionary, 'domain', []));
          Ember.set(dataDictionary, 'domain', Ember.get(dataDictionary, 'domain').concat(data.domains));
        }

        Ember.set(dataDictionary, 'entity', Ember.getWithDefault(dataDictionary, 'entity', []));
        Ember.set(entity, 'attribute', data.attributes);
        Ember.get(dataDictionary, 'entity').push(entity);
        this.transitionTo('dictionary.show.edit.entity.edit', Ember.get(dataDictionary, 'entity.length') - 1);
        this.flashMessages.success('Entity imported from CSV!');
      },

      processData(data) {
        let template = this.columnObject;
        let typer = this.columnType;
        Ember.set(this, 'controller.processed', false);
        Ember.set(this, 'controller.columns', data.meta.fields.reduce(function (map, obj) {
          let type = Ember.typeOf(data.data[0][obj]);
          Ember.set(map, obj, template.create({
            dataType: type,
            domain: [],
            importName: obj,
            importType: typer(type)
          }));
          return map;
        }, Ember.Object.create({})));
      },

      reduceData(data) {
        let columns = Object.keys(this.get('controller.columns'));
        columns.forEach(columnName => {
          let path = 'controller.columns.' + columnName + '.domain';
          let existing = Ember.get(this, path); // if(get(existing,'length' )<= get(this,'maxDomain')){

          let unique = [...new Set(data.map(item => item[columnName]))];
          Ember.set(this, path, [...new Set([...existing, ...unique])]); // }
        });
      },

      processComplete() {
        let columns = this.get('controller.columns');
        let columnNames = Object.keys(columns);
        columnNames.forEach(columnName => {
          let col = columns[columnName];

          if (col.importType === 'numeric') {
            let isInteger = col.domain.any(itm => Number.isInteger(itm));
            col.importType = isInteger ? 'integer' : 'numeric';
          }

          col.allowNull = col.domain.any(itm => Ember.isBlank(itm));
        });
        Ember.set(this, 'controller.processed', true);
      }

    }
  });

  _exports.default = _default;
});