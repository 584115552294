define("mdeditor/components/leaflet-draw", ["exports", "ember-leaflet-table/components/leaflet-draw"], function (_exports, _leafletDraw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _leafletDraw.default;
    }
  });
});