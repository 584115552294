define("mdeditor/helpers/uc-words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ucWords = ucWords;
  _exports.default = void 0;

  function ucWords(params, hash) {
    var string = String(params[0]),
        force = hash.force === true ? true : false;

    if (force) {
      string = string.toLowerCase();
    }

    return string.replace(/(^|\s)[a-z\u00E0-\u00FC]/g, function ($1) {
      return $1.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(ucWords);

  _exports.default = _default;
});