define("mdeditor/ember-leaflet-table/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/feature-form.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/feature-form.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/feature-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/feature-group.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/feature-table.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/feature-table.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/leaflet-draw.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/leaflet-draw.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/leaflet-table-row-actions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/leaflet-table-row-actions.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/leaflet-table-row.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/leaflet-table-row.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/leaflet-table.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/leaflet-table.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/lgeojson-layer.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/lgeojson-layer.js should pass ESLint\n\n');
  });
  QUnit.test('app/csv2geojson.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/csv2geojson.js should pass ESLint\n\n');
  });
  QUnit.test('app/geojsoncoords.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/geojsoncoords.js should pass ESLint\n\n');
  });
  QUnit.test('app/shapefile.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/shapefile.js should pass ESLint\n\n');
  });
  QUnit.test('app/togeojson.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/togeojson.js should pass ESLint\n\n');
  });
  QUnit.test('app/uuid.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/uuid.js should pass ESLint\n\n');
  });
});