define("mdeditor/helpers/get-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperty = getProperty;
  _exports.default = void 0;

  function getProperty([obj, prop]) {
    let val = null;

    if (obj) {
      val = Ember.get(obj, prop.trim());
    }

    return val || Ember.String.htmlSafe("<em>Not Defined</em>");
  }

  var _default = Ember.Helper.helper(getProperty);

  _exports.default = _default;
});