define("mdeditor/services/schemas", ["exports", "json-schema-ref-parser", "ember-ajax/request", "ember-concurrency", "ember-ajax/errors", "semver"], function (_exports, _jsonSchemaRefParser, _request, _emberConcurrency, _errors, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const parser = new _jsonSchemaRefParser.default();

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      /**
       * Instance of JSON Schema $Ref Parser
       *
       * @method parser
       * @protected
       * @return {Object}
       */


      this.parser = parser;
      this.schemas = this.store.peekAll('schema');
    },

    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    globalSchemas: Ember.computed.filterBy('schemas', 'isGlobal'),
    fetchSchemas: (0, _emberConcurrency.task)(function* (url) {
      yield (0, _emberConcurrency.timeout)(1000);
      return yield this.parser.resolve(url).then($refs => {
        let paths = $refs.paths();
        let values = parser.$refs.values();
        return paths.map(path => {
          return {
            id: path,
            schema: values[path]
          };
        });
      });
    }).drop(),
    // compileSchemas(schemas) {
    //   let ajv = ajvErrors(new Ajv(options));
    //
    //
    //   ajv.addMetaSchema(draft4);
    //   ajv.addSchema(schemas);
    //
    //   return ajv;
    // },
    checkForUpdates: (0, _emberConcurrency.task)(function* (records) {
      yield (0, _emberConcurrency.timeout)(1000);
      yield (0, _emberConcurrency.all)(records.map(itm => {
        if (itm.validations.attrs.uri.isInvalid) {
          this.flashMessages.warning("Did not load schema for \"".concat(itm.title, "\". URL is Invalid."));
          return;
        }

        return (0, _request.default)(itm.uri).then(response => {
          // `response` is the data from the server
          if (_semver.default.valid(response.version)) {
            itm.set('remoteVersion', response.version);
          } else {
            throw new Error("Invalid version");
          }

          return response;
        }).catch(error => {
          if ((0, _errors.isNotFoundError)(error)) {
            this.flashMessages.danger("Could not load schema for \"".concat(itm.title, "\". Schema not found."));
          } else {
            this.flashMessages.danger("Could not load schema for \"".concat(itm.title, "\". Error: ").concat(error.message));
          }
        });
      }));
    }).drop()
  });

  _exports.default = _default;
});