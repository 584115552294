define("mdeditor/pods/components/object/md-source/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZI6Q591",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input/md-textarea\",null,[[\"value\",\"profilePath\",\"placeholder\",\"maxrows\"],[[24,[\"model\",\"description\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".description\"],null],\"A brief description about the source dataset used in creating the data.\",7]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-source/preview/template.hbs"
    }
  });

  _exports.default = _default;
});