define("mdeditor/pods/dictionary/show/edit/entity/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.dataDictionary');
      Ember.run.once(this, () => {
        Ember.set(model, 'entity', Ember.getWithDefault(model, 'entity', []));
      });
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('dictionary.show.edit.index'));
    },
    actions: {
      editEntity(id) {
        this.transitionTo('dictionary.show.edit.entity.edit', id);
      }

    }
  });

  _exports.default = _default;
});