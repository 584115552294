define("mdeditor/pods/dictionary/show/edit/domain/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.dataDictionary');
      Ember.set(model, 'domain', Ember.getWithDefault(model, 'domain', []));
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('dictionary.show.edit.index'));
    },
    actions: {
      editDomain(id) {
        this.transitionTo('dictionary.show.edit.domain.edit', id);
      }

    }
  });

  _exports.default = _default;
});