define("mdeditor/validators/array-required", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ArrayRequired = _base.default.extend({
    /**
    * Validation that checks array length
    *
    * @module mdeditor
    * @submodule validator
    * @class array-required
    * @extends BaseValidator
    * @example
    *   validator('array-required', {
          track: ['type']
        })
    */

    /**
     * Validate the array
     *
     * @method validate
     * @param {Array} value The array to test
     * @param {Object} options
     * @return {Mixed} True or error message
     *
     */
    validate(value, options) {
      if (Ember.isArray(value)) {
        if (value.length) {
          return true;
        }
      }

      options.item = this.options.description || this.options.attribute;
      return this.createErrorMessage('arrayRequired', value, options);
    }

  });

  ArrayRequired.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @property getDependentsFor
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor(attribute, options) {
      //return[];
      let track = [];
      let opts = Ember.get(options, 'track');
      (true && !(Ember.isArray(opts)) && Ember.assert("[validator:array-valid] [".concat(attribute, "] option 'track' must be an array"), Ember.isArray(opts)));
      if (!Ember.isArray(opts)) return track;
      opts.forEach(itm => {
        track.push("model.".concat(attribute, ".@each.").concat(itm));
      });
      return track;
    }

  });
  var _default = ArrayRequired;
  _exports.default = _default;
});