define("mdeditor/pods/components/object/md-party/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Template = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'role': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    '_contacts': (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'At least one contact is required.'
    })
  });
  _exports.Validations = Validations;
  const Template = Ember.Object.extend(Validations, {
    init() {
      this._super(...arguments);

      this.set('party', Ember.A());
      this.set('role', null);
    },

    _contacts: Ember.computed('party', {
      get() {
        let party = Ember.get(this, 'party');
        return party.mapBy('contactId');
      },

      set(key, value) {
        let map = value.map(itm => {
          return {
            contactId: itm
          };
        });
        Ember.set(this, 'party', map);
        return value;
      }

    })
  });
  _exports.Template = Template;
  const theComp = Ember.Component.extend(Validations, {
    _contacts: Ember.computed('model', {
      get() {
        let party = Ember.get(this, 'model.party');
        return party ? party.mapBy('contactId') : [];
      },

      set(key, value) {
        let map = value.map(itm => {
          return {
            contactId: itm
          };
        });
        Ember.set(this, 'model.party', map);
        return value;
      }

    }),
    role: Ember.computed.alias('model.role'),

    didReceiveAttrs() {
      this._super(...arguments);

      let model = this.model;
      Ember.run.once(this, function () {
        Ember.set(model, 'party', Ember.getWithDefault(model, 'party', []));
        Ember.set(model, 'role', Ember.getWithDefault(model, 'role', null));
      });
    },

    attributeBindings: ['data-spy'],
    templateClass: Template
  });
  _exports.default = theComp;
});