define("mdeditor/pods/dictionary/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init() {
      this._super(...arguments);

      this.breadCrumb = {
        title: 'Dictionary',
        linkable: false
      };
    }

  });

  _exports.default = _default;
});