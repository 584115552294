define("mdeditor/pods/settings/profile/manage/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    profile: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    columns: [{
      propertyName: 'title',
      title: 'Title'
    }, {
      propertyName: 'uri',
      title: 'URL',
      break: true
    }, {
      propertyName: 'description',
      title: 'Description',
      truncate: true,
      isHidden: true
    }],
    columnSets: [{
      label: 'URL',
      showColumns: ['title', 'uri']
    }],
    badges: [{
      type: 'info',
      icon: 'info-circle',
      tip: 'Update available.',
      isVisible: 'hasUpdate'
    }],
    definition: null,

    /**
     * Indicates whether the save button should be disabled
     *
     * @property disableSave
     * @type {Boolean}
     * @readOnly
     * @category computed
     * @requires definition.validations.isInvalid,task.isRunning
     */
    disableSave: Ember.computed.or('definition.validations.attrs.uri.isInvalid', 'task.isRunning'),
    checkForUpdates: (0, _emberConcurrency.task)(function* () {
      yield this.profile.checkForUpdates.perform(this.model);
    }),
    actions: {
      addDefinition() {
        this.set('definition', this.store.createRecord('profile'));
      },

      editDefinition(index, record) {
        this.set('definition', record);
      },

      saveDefinition() {
        let definition = this.definition;
        return definition.save().then(rec => {
          let fetched = this.profile.fetchDefinition.perform(rec.uri);
          this.set('task', fetched);
          fetched.then(val => {
            if (val) {
              definition.set('config', val);
              definition.set('remoteVersion', val.version);
              this.flashMessages.success("Downloaded profile definition: ".concat(val.title, "."));
            }
          });
        }).catch(e => {
          this.flashMessages.warning(e.message);
        });
      },

      cancelEdit() {
        let record = this.definition;
        this.set('definition', null);
        record.rollbackAttributes();
      },

      toProfile() {
        this.transitionToRoute('settings.profile');
      }

    }
  });

  _exports.default = _default;
});