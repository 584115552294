define("mdeditor/pods/components/layout/md-slider/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['md-slider'],
    classNameBindings: ['visible:in'],
    visible: false,

    didReceiveAttrs() {
      (0, _jquery.default)('body').toggleClass('slider', this.visible === true);
    },

    fromName: null,
    name: Ember.computed('fromName', function () {
      return this.fromName || 'md-slider-content';
    }),
    actions: {
      toggleVisibility() {
        this.toggleProperty('visible');

        if (!this.visible) {
          let context = this.get('context.isDestroying');
          this.set('fromName', null);

          if (!context) {
            this.onClose.call(this);
          }
        }
      }

    }
  });

  _exports.default = _default;
});