define("mdeditor/pods/components/object/md-date-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O54ZUhpE",
    "block": "{\"symbols\":[\"date\",\"&default\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"required\",\"title\",\"value\",\"plain\",\"validation\",\"profilePath\",\"data-spy\",\"templateClass\"],[\"Date,Date Type,Description\",[24,[\"required\"]],\"Date\",[24,[\"value\"]],[24,[\"plain\"]],[24,[\"validation\"]],[24,[\"profilePath\"]],[24,[\"data-spy\"]],[24,[\"templateClass\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"object/md-date\",null,[[\"model\",\"profilePath\"],[[23,1,[\"item\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".dateItem\"],null]]]],false],[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-date-array/template.hbs"
    }
  });

  _exports.default = _default;
});