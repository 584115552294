define("mdeditor/pods/components/md-title/component", ["exports", "ember-crumbly/components/bread-crumbs", "ember-cli-string-helpers/helpers/truncate", "mdeditor/pods/components/md-title/template"], function (_exports, _breadCrumbs, _truncate, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _breadCrumbs.default.extend({
    init() {
      this._super(...arguments);

      let applicationInstance = Ember.getOwner(this);
      this.set('applicationRoute', applicationInstance.lookup('route:application'));
      this.set('classNameBindings', []);
    },

    layout: _template.default,
    tagName: '',
    title: Ember.computed('routeHierarchy', function () {
      return this.routeHierarchy.reduce((val, itm) => {
        return val + (0, _truncate.truncate)([itm.title, 28, true]) + (itm.isTail ? '' : ' | ');
      }, '');
    })
  });

  _exports.default = _default;
});