define("mdeditor/pods/components/control/md-import-csv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEN3aj3A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isProcessing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[28,\"fa-icon\",[\"spinner\"],[[\"spin\"],[true]]],false],[0,\" Processing...\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-info\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"stopParsing\"]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"cross\"],[[\"spin\"],[true]]],false],[0,\"\\n    Stop\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"progress-bar\"],[10,\"role\",\"progressbar\"],[11,\"aria-valuenow\",[22,\"progress\"]],[10,\"aria-valuemin\",\"0\"],[10,\"aria-valuemax\",\"100\"],[11,\"style\",[22,\"barWidth\"]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[1,[22,\"progress\"],false],[0,\"% Complete\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"file-picker\",null,[[\"class\",\"fileLoaded\",\"preview\",\"accept\",\"readAs\"],[\"md-file-picker md-import-picker\",\"readData\",false,\".csv,.txt\",\"readAsText\"]],{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-lg btn-info btn-block\"],[10,\"type\",\"button\"],[8],[1,[28,\"fa-icon\",[\"bullseye\"],null],false],[0,\"\\n      Click or Drop a CSV here.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-import-csv/template.hbs"
    }
  });

  _exports.default = _default;
});