define("mdeditor/pods/components/object/md-attribute/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Template = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'codeName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'dataType': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'allowNull': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'definition': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });
  _exports.Validations = Validations;
  const TemplateClass = Ember.Object.extend(Validations, {
    init() {
      this._super(...arguments);

      Ember.set(this, 'allowNull', true);
      Ember.set(this, 'attributeReference', {});
      Ember.set(this, 'alias', []);
      Ember.set(this, 'valueRange', []);
      Ember.set(this, 'timePeriod', []);
    }

  });
  _exports.Template = TemplateClass;
  const theComp = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'allowNull', Ember.getWithDefault(model, 'allowNull', false));
        Ember.set(model, 'reference', Ember.getWithDefault(model, 'reference', {}));
        Ember.set(model, 'alias', Ember.getWithDefault(model, 'alias', []));
        Ember.set(model, 'valueRange', Ember.getWithDefault(model, 'valueRange', []));
        Ember.set(model, 'timePeriod', Ember.getWithDefault(model, 'timePeriod', []));
      });
    },

    /**
     * The string representing the path in the profile object for the domain.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the domain.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    codeName: Ember.computed.alias('model.codeName'),
    dataType: Ember.computed.alias('model.dataType'),
    definition: Ember.computed.alias('model.definition'),
    allowNull: Ember.computed.alias('model.allowNull'),
    domains: Ember.computed.alias('dictionary.domain'),
    domainList: Ember.computed('domains.{@each.domainId,@each.codeName}', function () {
      let domains = Ember.get(this, 'domains') || [];
      return domains.map(domain => {
        if (Ember.get(domain, 'domainId')) {
          return {
            codeId: Ember.get(domain, 'domainId'),
            codeName: Ember.get(domain, 'codeName'),
            tooltip: Ember.get(domain, 'description')
          };
        }
      });
    }),
    rangeTemplate: Ember.Object.extend((0, _emberCpValidations.buildValidations)({
      'minRangeValue': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })],
      'maxRangeValue': [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }), {
      init() {
        this._super(...arguments);
      }

    })
  });
  _exports.default = theComp;
});