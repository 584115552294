define("mdeditor/pods/record/show/edit/associated/resource/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eAM5N+et",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Associated Resource #\"],[1,[22,\"resourceId\"],false],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-associated\",null,[[\"model\",\"recordId\",\"profilePath\",\"editLinked\"],[[24,[\"model\"]],[24,[\"parentModel\",\"recordId\"]],\"record.associated\",[28,\"route-action\",[\"editLinked\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-citation\"],[[\"text\",\"icon\",\"route\",\"selectResource\"],[\"Back to Resource List\",\"arrow-left\",\"record.show.edit.associated\",[28,\"route-action\",[\"selectResource\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-slider-content\",[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"concat\",[\"Click row to select a resource: \",[24,[\"model\",\"associationType\"]]],null],[28,\"component\",[\"control/md-select-table\"],[[\"select\",\"data\",\"columns\"],[[28,\"route-action\",[\"insertResource\"],null],[28,\"compute\",[[28,\"route-action\",[\"sliderData\"],null]],null],[28,\"compute\",[[28,\"route-action\",[\"sliderColumns\"],null]],null]]]]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/associated/resource/index/template.hbs"
    }
  });

  _exports.default = _default;
});