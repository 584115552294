define("mdeditor/pods/record/show/edit/taxonomy/collection/system/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController: function setupController(controller) {
      // Call _super for default behavior
      this._super(...arguments);

      let systemId = this.paramsFor('record.show.edit.taxonomy.collection.system').system_id;
      controller.set('parentModel', this.modelFor('record.show.edit'));
      controller.set('systemId', systemId);
    },
    actions: {
      goBack() {
        this.transitionTo('record.show.edit.taxonomy.collection');
      }

    }
  });

  _exports.default = _default;
});