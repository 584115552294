define("mdeditor/pods/components/object/md-funding/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'allocation': [(0, _emberCpValidations.validator)('array-required', {
      track: ['allocation'],
      disabled: Ember.computed('model.timePeriod.{startDateTime,endDateTime}', function () {
        let tp = this.model.timePeriod;
        return Ember.isPresent(tp) && (tp.startDateTime || tp.endDateTime);
      })
    })],
    'timePeriod': {
      disabled: Ember.computed.notEmpty('model.allocation'),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('inline', {
        dependentKeys: ['model.timePeriod.startDateTime', 'model.timePeriod.endDateTime'],

        validate(value, options, model) {
          return model.get('timePeriod.startDateTime') || model.get('timePeriod.endDateTime') ? true : 'Time Period should have one of Start Date or End Date.';
        }

      })]
    }
  }, {
    message: 'Either an Allocation or valid Time Period is required.'
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'allocation', Ember.getWithDefault(model, 'allocation', []));
        Ember.set(model, 'timePeriod', Ember.getWithDefault(model, 'timePeriod', {}));
      });
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    tagName: 'form',
    allocation: Ember.computed.alias('model.allocation'),
    timePeriod: Ember.computed.alias('model.timePeriod')
  });

  _exports.default = _default;
});