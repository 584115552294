define("mdeditor/pods/components/object/md-allocation/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'amount': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'currency': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'currency', Ember.getWithDefault(model, 'currency', 'USD'));
        Ember.set(model, 'onlineResource', Ember.getWithDefault(model, 'onlineResource', []));
        Ember.set(model, 'responsibleParty', Ember.getWithDefault(model, 'responsibleParty', []));
      });
    },

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default 'false'
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    attributeBindings: ['data-spy'],
    'data-spy': 'Allocation',
    tagName: 'form',
    amount: Ember.computed.alias('model.amount'),
    currency: Ember.computed.alias('model.currency')
  });

  _exports.default = _default;
});