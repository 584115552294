define("mdeditor/pods/components/control/md-alert-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['alert', 'md-alert-table'],
    classNameBindings: ['alertType'],
    required: false,
    title: '',
    target: null,
    alertType: Ember.computed('required', function () {
      return 'alert-' + (this.required ? 'danger' : 'info');
    }),
    tipType: Ember.computed('required', function () {
      return this.required ? 'danger' : 'info';
    }),
    tipIcon: Ember.computed('required', function () {
      return this.required ? 'exclamation-circle' : 'info-circle';
    }),
    actions: {
      addItem(target) {
        this.addItem(target);
      }

    }
  });

  _exports.default = _default;
});