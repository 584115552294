define("mdeditor/pods/components/object/md-address/md-address-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9a5mJSU9",
    "block": "{\"symbols\":[\"dp\"],\"statements\":[[7,\"address\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"item\",\"deliveryPoint\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[1,[24,[\"item\",\"city\"]],false],[0,\", \"],[1,[24,[\"item\",\"administrativeArea\"]],false],[0,\" \"],[1,[24,[\"item\",\"postalCode\"]],false],[0,\"\\n  \"],[1,[24,[\"item\",\"country\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"addressType\"]]],null,{\"statements\":[[0,\"    \"],[7,\"strong\",true],[8],[1,[28,\"join\",[\", \",[24,[\"item\",\"addressType\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-address/md-address-block/template.hbs"
    }
  });

  _exports.default = _default;
});