define("mdeditor/services/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    dataset: 'globe',
    series: 'list-ol',
    nonGeographicDataset: 'bar-chart',
    feature: 'map-marker',
    software: 'desktop',
    service: 'exchange',
    model: 'cubes',
    tile: 'th-large',
    metadata: 'file-code-o',
    initiative: 'checklist',
    sample: 'flask',
    'document': 'file-o',
    repository: 'database',
    aggregate: 'sitemap',
    collection: 'files-o',
    coverage: 'th',
    application: 'android',
    sciencePaper: 'flask',
    userGuide: 'life-saver',
    dataDictionary: 'book',
    website: 'chrome',
    publication: 'file-text-o',
    report: 'file-text-o',
    awardInfo: 'file-o',
    collectionSite: 'map-marker',
    project: 'wrench',
    factSheet: 'file-o',
    tabularDataset: 'table',
    map: 'map-o',
    drawing: 'picture-o',
    photographicImage: 'camera',
    presentation: 'file-powerpoint-o',
    defaultFile: 'file-o',
    defaultList: 'caret-right',
    individuals: 'user',
    organizations: 'users'
  });

  _exports.default = _default;
});