define("mdeditor/pods/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gsbxGOT2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning page-header text-center\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],null],false],[0,\" 404 Not Found: \"],[1,[22,\"path\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Perhaps you have visited a link that has changed, or a record that no longer exists.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});