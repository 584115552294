define("mdeditor/helpers/present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.present = present;
  _exports.default = void 0;

  function present(params) {
    return Ember.isPresent(params[0]);
  }

  var _default = Ember.Helper.helper(present);

  _exports.default = _default;
});