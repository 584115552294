define("mdeditor/pods/components/input/md-markdown-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YjZZCwaM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"class\",\"md-card-chevron\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"collapsible\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"role\",\"button\"],[10,\"data-toggle\",\"collapse\"],[11,\"href\",[29,[\"#\",[28,\"concat\",[\"md-\",[24,[\"elementId\"]]],null]]]],[11,\"aria-expanded\",[28,\"if\",[[24,[\"collapsed\"]],\"false\",\"true\"],null]],[11,\"class\",[28,\"if\",[[24,[\"collapsed\"]],\"collapsed\"],null]],[11,\"aria-controls\",[28,\"concat\",[\"md-\",[24,[\"elementId\"]]],null]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"fa\"],[8],[9],[0,\"\\n        \"],[1,[22,\"label\"],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[11,\"id\",[28,\"concat\",[\"md-\",[24,[\"elementId\"]]],null]],[11,\"class\",[28,\"if\",[[24,[\"collapsed\"]],\"collapse\",\"in\"],null]],[8],[0,\"\\n  \"],[1,[28,\"simple-mde\",null,[[\"value\",\"options\",\"maxlength\",\"spellcheck\",\"disabled\",\"change\",\"currentEditor\"],[[24,[\"value\"]],[24,[\"options\"]],[24,[\"maxlength\"]],true,[24,[\"disabled\"]],[28,\"if\",[[24,[\"change\"]],[24,[\"change\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null]],null],[24,[\"editor\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-markdown-area/template.hbs"
    }
  });

  _exports.default = _default;
});