define("mdeditor/pods/record/show/edit/extent/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let model = this.modelFor('record.show.edit');
      let json = model.get('json');
      let info = json.metadata.resourceInfo;
      Ember.set(info, 'extent', Ember.getWithDefault(info, 'extent', Ember.A()));
      Ember.get(info, 'extent').forEach(itm => {
        Ember.set(itm, 'geographicExtent', Ember.getWithDefault(itm, 'geographicExtent', Ember.A()));
        Ember.set(itm, 'geographicExtent.0', Ember.getWithDefault(itm, 'geographicExtent.0', {}));
        Ember.set(itm, 'geographicExtent.0.boundingBox', Ember.getWithDefault(itm, 'geographicExtent.0.boundingBox', {}));
        Ember.set(itm, 'geographicExtent.0.identifier', Ember.getWithDefault(itm, 'geographicExtent.0.identifier', {}));
        Ember.set(itm, 'verticalExtent', Ember.getWithDefault(itm, 'verticalExtent', Ember.A()));
        Ember.set(itm, 'temporalExtent', Ember.getWithDefault(itm, 'temporalExtent', Ember.A()));
      });
      return model;
    },

    actions: {
      addExtent() {
        let extents = this.currentRouteModel().get('json.metadata.resourceInfo.extent');
        extents.pushObject({
          description: null,
          geographicExtent: [{
            description: null,
            containsData: true,
            boundingBox: {},
            geographicElement: Ember.A(),
            identifier: {}
          }],
          verticalExtent: Ember.A(),
          temporalExtent: Ember.A()
        });
        (0, _jquery.default)("html, body").animate({
          scrollTop: (0, _jquery.default)(document).height()
        }, "slow");
      },

      deleteExtent(id) {
        let extents = this.currentRouteModel().get('json.metadata.resourceInfo.extent');
        let extent = extents[id];
        extents.removeObject(extent);
      },

      editFeatures(id) {
        this.transitionTo({
          queryParams: {
            scrollTo: 'extent-' + id
          }
        });
        this.transitionTo('record.show.edit.extent.spatial', id);
      },

      toList() {
        this.transitionTo(this.routeName);
      }

    }
  });

  _exports.default = _default;
});