define("mdeditor/pods/components/object/md-distributor/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'role': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'contacts': (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'At least one contact is required.'
    })
  });

  var _default = Ember.Component.extend(Validations, {
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'contact', Ember.getWithDefault(model, 'contact', {
          role: null,
          party: []
        }));
        Ember.set(model, 'orderProcess', Ember.A(Ember.getWithDefault(model, 'orderProcess', [{}])));
        Ember.set(model, 'transferOption', Ember.A(Ember.getWithDefault(model, 'transferOption', [{}])));
      });
    },

    tagName: 'form',

    /**
     * The string representing the path in the profile object for the resource.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model for the resource.
     *
     * @property model
     * @type {Object}
     * @required
     */
    role: Ember.computed.alias('model.contact.role'),
    contacts: Ember.computed('model.contact.party', {
      get() {
        let party = Ember.get(this, 'model.contact.party');
        return party ? party.mapBy('contactId') : null;
      },

      set(key, value) {
        let map = value.map(itm => {
          return {
            contactId: itm
          };
        });
        Ember.set(this, 'model.contact.party', map);
        return value;
      }

    }),
    actions: {
      editTransferOption(index) {
        this.editTransferOption(index);
      }

    }
  });

  _exports.default = _default;
});