define("mdeditor/pods/components/object/md-locale/component", ["exports", "ember-cp-validations", "ember-copy"], function (_exports, _emberCpValidations, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'language': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'characterSet': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });
  _exports.Validations = Validations;
  const theComp = Ember.Component.extend(Validations, {
    settings: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      let model = this.model || {};
      let settings = Ember.get(this, 'settings.data');
      (true && !(!Ember.isNone(model)) && Ember.assert('Model passed to md-locale must be an object', !Ember.isNone(model)));

      if (Object.keys(model).length === 0) {
        Ember.run.once(() => {
          Ember.setProperties(model, {
            language: (0, _emberCopy.copy)(settings.get('language')),
            characterSet: (0, _emberCopy.copy)(settings.get('characterSet')),
            country: (0, _emberCopy.copy)(settings.get('country'))
          });
        });
      }
    },

    language: Ember.computed.alias('model.language'),
    characterSet: Ember.computed.alias('model.characterSet')
  });
  _exports.default = theComp;
});