define("mdeditor/pods/components/object/md-taxonomy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kXE8ebGU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layout/md-card\",null,[[\"title\",\"collapsible\",\"collapsed\",\"profilePath\",\"data-spy\",\"shadow\",\"spotlightEnabled\"],[[24,[\"title\"]],true,false,\"record.taxonomy\",[28,\"concat\",[\"Collection \",[24,[\"index\"]]],null],true,false]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"card-buttons\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"btn btn-md btn-success btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"editCollection\",[24,[\"index\"]]]],[8],[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Edit Collection\"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"btn btn-md btn-danger btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"deleteCollection\",[24,[\"index\"]]]],[8],[0,\"\\n              \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\" Delete Collection\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"layout/md-card\",null,[[\"collapsible\",\"collapsed\",\"shadow\",\"maximizable\",\"spotlightEnabled\",\"block\",\"profilePath\"],[true,false,false,false,false,false,\"record.taxonomy.classificationPreview\"]],{\"statements\":[[0,\"      \"],[1,[28,\"object/md-taxonomy/classification\",null,[[\"model\",\"preview\",\"profilePath\"],[[24,[\"model\",\"taxonomicClassification\"]],true,\"record.taxonomy.classificationPreview\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-taxonomy/template.hbs"
    }
  });

  _exports.default = _default;
});