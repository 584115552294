define("mdeditor/pods/components/object/md-party/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SRKQo0ZF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input/md-codelist\",null,[[\"label\",\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"showValidations\",\"placeholder\",\"profilePath\"],[\"Role\",\"role\",[23,0,[]],true,true,false,[24,[\"disabled\"]],\"role\",true,\"Select or enter a role\",[28,\"if\",[[24,[\"profilePath\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".role\"],null]],null]]]],false],[0,\"\\n\"],[1,[28,\"input/md-select-contacts\",null,[[\"label\",\"placeholder\",\"model\",\"path\",\"icon\",\"tooltip\",\"showValidations\",\"profilePath\"],[\"Contacts\",\"Select one or more contacts\",[23,0,[]],\"_contacts\",true,true,true,[28,\"if\",[[24,[\"profilePath\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".contacts\"],null]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-party/template.hbs"
    }
  });

  _exports.default = _default;
});