define("mdeditor/pods/records/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9x4wYeG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"text-info\"],[8],[1,[28,\"fa-icon\",[\"file-text\"],null],false],[0,\" Metadata Records\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"length\"]]],null,{\"statements\":[[4,\"layout/md-card\",null,[[\"collapsible\",\"collapsed\",\"shadow\"],[true,false,true]],{\"statements\":[[0,\"    \"],[1,[28,\"control/md-record-table\",null,[[\"data\",\"dataColumns\",\"allActions\",\"selectProperty\",\"showSlider\"],[[24,[\"model\"]],[28,\"compute\",[[28,\"route-action\",[\"getColumns\"],null],[24,[\"section\",\"meta\",\"type\"]]],null],true,\"_selectedFromList\",[28,\"route-action\",[\"showSlider\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"No Metadata Records found.\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-success btn-lg\",\"record.new\"]],{\"statements\":[[0,\"        \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Create a Metadata Record\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorTarget\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-slider-error\",[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"concat\",[\"Viewing errors for: \",[24,[\"errorTarget\",\"title\"]]],null],[28,\"component\",[\"control/md-errors\"],[[\"errors\"],[[24,[\"errorTarget\",\"schemaErrors\"]]]]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/records/template.hbs"
    }
  });

  _exports.default = _default;
});