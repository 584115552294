define("mdeditor/pods/record/show/edit/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect(model) {
      this.replaceWith('record.show.edit.main', model);
    }

  });

  _exports.default = _default;
});