define("mdeditor/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wfbdEGLi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-danger page-header text-center\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],null],false],[0,\" Application Error\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    The application has encountered an error, or a record that no longer exists.\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"lastError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      Message:\\n      \"],[7,\"a\",true],[10,\"data-toggle\",\"collapse\"],[10,\"href\",\".error-stack\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n        \"],[1,[24,[\"lastError\",\"message\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"error-stack collapse\"],[8],[0,\"\\n      \"],[7,\"pre\",true],[10,\"class\",\"inline-block text-left\"],[8],[0,\"        TRACE:\\n        \"],[1,[24,[\"lastError\",\"stack\"]],false],[0,\"      \"],[9],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});