define("mdeditor/pods/components/control/md-alert-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aW0KSzqJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h4\",true],[8],[0,\"No \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"required\"]],\"required\"],null]],[8],[1,[22,\"title\"],false],[9],[0,\" found.\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-success btn-md\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addItem\",[24,[\"target\"]]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Add \"],[1,[28,\"singularize\",[[24,[\"title\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"h4\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"tipMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"md-\",[22,\"tipType\"]]]],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",[[24,[\"tipIcon\"]]],null],false],[0,\"\\n\"],[4,\"ember-tooltip\",null,[[\"side\",\"tooltipClass\"],[\"right\",[28,\"concat\",[\"ember-tooltip md-tooltip \",[24,[\"tipType\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,\"tipMessage\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-alert-table/template.hbs"
    }
  });

  _exports.default = _default;
});