define("mdeditor/pods/record/show/edit/dictionary/route", ["exports", "uuid/v4"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init() {
      this._super(...arguments);

      this.breadCrumb = {
        title: 'Dictionaries'
      };
      this.columns = [{
        propertyName: 'title',
        title: 'Title'
      }, {
        propertyName: 'subject',
        title: 'Subject'
      }];
    },

    model() {
      //return this.store.peekAll('contact');
      let dicts = this.modelFor('application').findBy('modelName', 'dictionary');
      let rec = this.modelFor('record.show.edit');
      Ember.set(rec, 'json.mdDictionary', Ember.getWithDefault(rec, 'json.mdDictionary', []));
      let selected = rec.get('json.mdDictionary');
      return dicts.map(dict => {
        let json = Ember.get(dict, 'json');
        let id = Ember.get(json, 'dictionaryId');
        let data = Ember.get(json, 'dataDictionary');

        if (!id) {
          Ember.set(json, 'dictionaryId', (0, _v.default)());
          dict.save();
        }

        return Ember.Object.create({
          id: json.dictionaryId,
          title: Ember.get(data, 'citation.title'),
          description: data.description,
          subject: data.subject,
          selected: selected.includes(json.dictionaryId)
        });
      });
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      Ember.defineProperty(this.controller, 'selected', Ember.computed('model', function () {
        return this.model.filterBy('selected');
      }));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.refresh,
        cancelScope: this
      });
    },

    _select(obj) {
      let rec = this.modelFor('record.show.edit');
      let selected = rec.get('json.mdDictionary');

      if (obj.selected) {
        if (selected.indexOf(obj.id) === -1) {
          selected.pushObject(obj.id);
          this.controller.notifyPropertyChange('model');
          return;
        }
      }

      selected.removeObject(obj.id);
      this.controller.notifyPropertyChange('model');
    },

    actions: {
      getColumns() {
        return this.columns;
      },

      select(obj) {
        this._select(obj);
      },

      remove(obj) {
        Ember.set(obj, 'selected', false);

        this._select(obj);
      }

    }
  });

  _exports.default = _default;
});