define("mdeditor/pods/settings/profile/manage/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.findAll('profile');
    },

    profile: Ember.inject.service()
  });

  _exports.default = _default;
});