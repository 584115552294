define("mdeditor/pods/components/input/md-month/component", ["exports", "mdeditor/pods/components/input/md-datetime/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layoutName: 'components/input/md-datetime',
    format: 'MMMM',
    extraFormats: Ember.computed(function () {
      return ['MM', 'M', 'MMM'];
    }),
    showClear: false,
    useCurrent: false,
    showTodayButton: false
  });

  _exports.default = _default;
});