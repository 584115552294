define("mdeditor/pods/components/control/md-json-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KRM9htbZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"]]]],[8],[9],[0,\" \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"preview\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"control/md-json-viewer\",null,[[\"json\",\"modal\",\"close\"],[[24,[\"json\"]],true,[28,\"action\",[[23,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideSlider\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-slider-json\",[28,\"hash\",null,[[\"title\",\"body\"],[[28,\"concat\",[\"Viewing JSON for: \",[24,[\"title\"]]],null],[28,\"component\",[\"control/md-json-viewer\"],[[\"modal\",\"json\"],[false,[24,[\"json\"]]]]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-json-button/template.hbs"
    }
  });

  _exports.default = _default;
});