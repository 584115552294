define("mdeditor/pods/components/object/md-profile/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VwtXnyP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"\"],[8],[0,\"Title\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[1,[24,[\"config\",\"title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"\"],[8],[0,\"Description\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[1,[24,[\"config\",\"description\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"\"],[8],[0,\"Identifier\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[1,[24,[\"config\",\"identifier\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"\"],[8],[0,\"Namespace\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[1,[24,[\"config\",\"namespace\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-profile/preview/template.hbs"
    }
  });

  _exports.default = _default;
});