define("mdeditor/pods/record/show/edit/spatial/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+3gdlt6",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Spatial Information\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"with\",[[24,[\"model\",\"json\",\"metadata\",\"resourceInfo\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-spatial-info\",null,[[\"model\",\"profilePath\"],[[23,1,[]],\"record.spatial\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/spatial/index/template.hbs"
    }
  });

  _exports.default = _default;
});