define("mdeditor/pods/record/show/nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZmUVbhZt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"record.show.translate\"]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"fa fa-retweet\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"md-nav-text\"],[8],[0,\"Translate\"],[9],[0,\" \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\",\"tooltipClass\"],[\"Translate\",\"bottom\",\"ember-tooltip md-nav-tip\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"li\",true],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"form\",true],[10,\"class\",\"col-xxl-11 col-xxxl-10 col-xxl-offset-1\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"publish\"]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"fa fa-share-square-o\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"md-nav-text\"],[8],[0,\"Publish\"],[9],[0,\" \"],[1,[28,\"ember-tooltip\",null,[[\"text\",\"side\",\"tooltipClass\"],[\"Publish\",\"bottom\",\"ember-tooltip md-nav-tip\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/nav/template.hbs"
    }
  });

  _exports.default = _default;
});