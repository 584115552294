define("mdeditor/pods/components/input/md-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dkrO2SQE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"checkbox md-boolean\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"value\"]]]]],false],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"md-boolean-text\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-boolean/template.hbs"
    }
  });

  _exports.default = _default;
});