define("mdeditor/pods/dictionary/show/edit/domain/edit/citation/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    init() {
      this._super(...arguments);

      this.breadCrumb = {
        title: 'Reference'
      };
    },

    afterModel(model) {
      let domainId = this.paramsFor('dictionary.show.edit.domain.edit').domain_id;

      if (Ember.isEmpty(Ember.get(model, 'domainReference'))) {
        Ember.set(model, 'domainReference', {});
      }

      this.set('domainId', domainId);
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('dictionary.show.edit'));
    },
    actions: {
      backToDomain() {
        this.transitionTo('dictionary.show.edit.domain.edit', this.domainId);
      },

      editIdentifier(index) {
        this.transitionTo('dictionary.show.edit.domain.edit.citation.identifier', index).then(function () {
          this.setScrollTo('identifier');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});