define("mdeditor/pods/components/md-models-table/components/row-body/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['md-row-body'],
    spotlight: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.spotlight.setTarget(this.elementId, this.collapse, this);
      this.element.classList.add('fade-in-fast');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.spotlight.close();
    },

    collapse() {
      this.element.classList.add('fade-out-fast');
      this.collapseRow(this.index, this.record);
    }

  });

  _exports.default = _default;
});