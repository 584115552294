define("mdeditor/pods/record/show/edit/metadata/alternate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('citationId', params.citation_id);
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let citationId = this.citationId;
      let model = this.modelFor('record.show.edit');
      let citations = model.get('json.metadata.metadataInfo.alternateMetadataReference');
      let citation = citationId && Ember.isArray(citations) ? citations.get(citationId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(citation)) {
        this.flashMessages.warning('No citation found! Re-directing...');
        this.replaceWith('record.show.edit.metadata');
        return;
      }

      return citation;
    },

    actions: {
      parentModel() {
        return this.modelFor('record.show.edit');
      }

    }
  });

  _exports.default = _default;
});