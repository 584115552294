define("mdeditor/pods/components/control/md-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EtuohUT4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isShowing\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"targetAttachment\",\"attachment\",\"translucentOverlay\",\"containerClass\",\"overlayClass\",\"tetherTarget\",\"renderInPlace\"],[[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"middle center\",\"middle center\",true,\"md-modal-container\",\"md-modal-overlay\",[24,[\"target\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"md-modal-close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"closeModal\"]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"fa-stack\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle fa-stack-2x\"],[8],[9],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-times fa-stack-1x fa-inverse\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-modal-body\"],[8],[0,\"\\n      \"],[14,1],[0,\" \"],[1,[28,\"html-safe\",[[24,[\"message\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-modal-buttons pull-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showConfirm\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[1,[22,\"confirmLabel\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showCancel\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn btn-warning\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-modal/template.hbs"
    }
  });

  _exports.default = _default;
});