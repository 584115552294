define("mdeditor/pods/record/show/edit/taxonomy/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZwtEoxbj",
    "block": "{\"symbols\":[\"collection\",\"index\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Taxonomy\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"json\",\"metadata\",\"resourceInfo\",\"taxonomy\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-taxonomy\",null,[[\"model\",\"index\",\"scrollTo\",\"record\"],[[23,1,[]],[23,2,[]],[24,[\"scrollTo\"]],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"No taxonomic collections found.\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"addCollection\"],null]]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Add Collection\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Add Collection\",\"plus\",\"btn-success btn-lg\",[28,\"route-action\",[\"addCollection\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\",\"refresh\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null],[24,[\"model\",\"json\",\"metadata\",\"resourceInfo\",\"taxonomy\",\"length\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/taxonomy/index/template.hbs"
    }
  });

  _exports.default = _default;
});