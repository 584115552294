define("mdeditor/pods/components/object/md-documentation/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMO9oRLK",
    "block": "{\"symbols\":[\"model\",\"type\"],\"statements\":[[4,\"with\",[[24,[\"item\"]]],null,{\"statements\":[[0,\"  \"],[7,\"dl\",true],[10,\"class\",\"dl-horizontal\"],[8],[0,\"\\n    \"],[7,\"dt\",true],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"text-info\"],[8],[0,\"Document #\"],[1,[22,\"index\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"dd\",true],[8],[9],[0,\"\\n\"],[4,\"control/md-definition\",null,[[\"title\"],[\"Resource Type(s)\"]],{\"statements\":[[4,\"each\",[[23,1,[\"resourceType\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,2,[\"type\"]],false],[0,\":\\n\"],[4,\"if\",[[23,2,[\"name\"]]],null,{\"statements\":[[0,\"                \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"em\",true],[8],[0,\"Not named\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"                \"],[7,\"em\",true],[10,\"class\",\"text-muted\"],[8],[0,\"No types assigned!\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"object/md-citation/preview/body\",null,[[\"citation\"],[[28,\"object-at\",[0,[23,1,[\"citation\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-documentation/preview/template.hbs"
    }
  });

  _exports.default = _default;
});