define("mdeditor/pods/components/object/md-taxonomy/collection/voucher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yFbEPcPN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input/md-textarea\",null,[[\"value\",\"required\",\"rows\",\"profilePath\",\"label\",\"placeholder\",\"data-spy\",\"showValidations\"],[[24,[\"specimen\"]],true,3,[28,\"concat\",[[24,[\"profilePath\"]],\".specimen\"],null],\"Specimen\",\"Description of the type of specimen collected (e.g. 'herbarium specimens', 'blood samples', 'photographs', 'individuals', or 'batches').\",\"Specimen\",true]]],false],[0,\"\\n\\n\"],[7,\"label\",true],[10,\"class\",\"required\"],[8],[0,\"Repository\"],[9],[0,\"\\n\"],[4,\"layout/md-card\",null,[[\"required\",\"collapsible\",\"collapsed\",\"profilePath\",\"data-spy\",\"shadow\",\"spotlightEnabled\"],[true,false,false,[28,\"concat\",[[24,[\"profilePath\"]],\".repository\"],null],\"Repository\",false,false]],{\"statements\":[[0,\"  \"],[1,[28,\"object/md-party\",null,[[\"model\",\"profilePath\"],[[24,[\"repository\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".repository\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-taxonomy/collection/voucher/template.hbs"
    }
  });

  _exports.default = _default;
});