define("mdeditor/components/layer-control", ["exports", "ember-leaflet-layer-control/components/layer-control"], function (_exports, _layerControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _layerControl.default;
    }
  });
});