define("mdeditor/pods/record/show/edit/taxonomy/index/route", ["exports", "jquery", "mdeditor/mixins/scroll-to"], function (_exports, _jquery, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata.resourceInfo');
      Ember.set(model, 'taxonomy', Ember.getWithDefault(model, 'taxonomy', []));
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
    },
    actions: {
      editCollection(id) {
        this.setScrollTo("collection-".concat(id));
        this.transitionTo('record.show.edit.taxonomy.collection.index', id);
      },

      addCollection() {
        let taxa = this.currentRouteModel().get('json.metadata.resourceInfo.taxonomy');
        let collection = Ember.Object.create({}); // once(this, () => {

        taxa.pushObject(collection);
        this.setScrollTo("collection-".concat(taxa.length - 1));
        this.transitionTo('record.show.edit.taxonomy.collection.index', taxa.length - 1);
        (0, _jquery.default)("html, body").animate({
          scrollTop: (0, _jquery.default)(document).height()
        }, "slow"); // });
      },

      deleteCollection(id) {
        let taxa = this.currentRouteModel().get('json.metadata.resourceInfo.taxonomy');
        taxa.removeAt(id);
      }

    }
  });

  _exports.default = _default;
});