define("mdeditor/pods/components/md-models-table/components/row-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHHG4Dak",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"rowBodyComponent\"]]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"sendAction\",\"themeInstance\",\"expandRow\",\"collapseRow\"],[[24,[\"record\"]],[24,[\"visibleProcessedColumns\"]],[24,[\"index\"]],[24,[\"clickOnRow\"]],[24,[\"sendAction\"]],[24,[\"themeInstance\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/md-models-table/components/row-body/template.hbs"
    }
  });

  _exports.default = _default;
});