define("mdeditor/pods/components/input/md-date-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tTo28DrQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"class\",\"text-info required\"],[8],[0,\"Dates\"],[9],[0,\"\\n\"],[1,[28,\"input/md-datetime\",null,[[\"class\",\"valuePath\",\"model\",\"showValidations\",\"forceDateOutput\",\"label\",\"showClear\",\"format\",\"placeholder\",\"profilePath\"],[\"start\",\"start\",[23,0,[]],true,true,\"Start Date\",true,\"YYYY-MM-DD HH:mm:ss\",\"Enter start dateTime\",[28,\"concat\",[[24,[\"profilePath\"]],\".start\"],null]]]],false],[0,\"\\n\"],[1,[28,\"input/md-datetime\",null,[[\"class\",\"valuePath\",\"model\",\"showValidations\",\"forceDateOutput\",\"showClear\",\"label\",\"format\",\"placeholder\",\"profilePath\"],[\"end\",\"end\",[23,0,[]],true,true,true,\"End Date\",\"YYYY-MM-DD HH:mm:ss\",\"Enter end dateTime\",[28,\"concat\",[[24,[\"profilePath\"]],\".end\"],null]]]],false],[0,\"\\n\"],[1,[28,\"control/md-fiscalyear\",null,[[\"start\",\"end\",\"context\",\"profilePath\"],[\"start\",\"end\",[23,0,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".fiscalyear\"],null]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-date-range/template.hbs"
    }
  });

  _exports.default = _default;
});