define("mdeditor/pods/components/input/md-select-thesaurus/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * A select list control for displaying and selecting thesaurus entries from
     * the keyword service.
     *
     * @class md-select-thesaurus
     * @constructor
     */
    keyword: Ember.inject.service(),

    /**
     * This method is called after the thesaurus selection is updated. It should be
     * overridden.
     *
     * @method selectThesaurus
     * @param  {Object} selected  The selected thesaurus from the keyword service
     * @param  {Object} thesaurus The thesaurus for the keyword record
     */
    selectThesaurus() {},

    thesaurusList: Ember.computed('keyword.thesaurus.[]', function () {
      let list = this.keyword.thesaurus.map(k => {
        return Ember.Object.create({
          id: k.citation.identifier[0].identifier,
          label: k.label || k.citation.title || 'Keywords'
        });
      });
      list.unshift(Ember.Object.create({
        id: 'custom',
        label: 'Custom Thesaurus'
      }));
      return list;
    }),
    actions: {
      update(id, thesaurus) {
        let selected = this.keyword.findById(id);
        this.selectThesaurus(selected, thesaurus);
      }

    }
  });

  _exports.default = _default;
});