define("mdeditor/pods/record/show/edit/extent/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController(controller, model) {
      this._super(controller, model);

      Ember.defineProperty(this.controller, 'refreshSpy', Ember.computed.alias('model.json.metadata.resourceInfo.extent.length'));
    }

  });

  _exports.default = _default;
});