define("mdeditor/helpers/mod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mod = mod;
  _exports.default = void 0;

  function mod(params) {
    return params.reduce((a, b) => Number(a) % Number(b));
  }

  var _default = Ember.Helper.helper(mod);

  _exports.default = _default;
});