define("mdeditor/pods/components/control/subbar-citation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectResource() {
      return this;
    }

  });

  _exports.default = _default;
});