define("mdeditor/services/mdjson", ["exports", "ajv", "mdjson-schemas/resources/js/schemas", "mdeditor/pods/components/object/md-citation/component", "ajv/lib/refs/json-schema-draft-04"], function (_exports, _ajv, _schemas, _component, draft4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.libraries.register('mdJson-schemas', _schemas.default.schema.version);
  const validator = new _ajv.default({
    verbose: true,
    allErrors: true,
    jsonPointers: true,
    removeAdditional: false,
    meta: false,
    schemaId: 'id'
  }); //support draft-04

  validator.addMetaSchema(draft4);
  Object.keys(_schemas.default).forEach(function (key) {
    if (key === 'default') {
      return;
    }

    let val = _schemas.default[key];
    validator.addSchema(val, key);
  });
  const unImplemented = ['metadata.metadataInfo.otherMetadataLocale', 'metadata.resourceInfo.spatialRepresentation', ['metadata.resourceInfo.extent', 'verticalExtent'], ['metadata.resourceInfo.extent', 'temporalExtent'], 'metadata.resourceInfo.coverageDescription', //'metadata.resourceInfo.taxonomy',
  'metadata.resourceInfo.otherResourceLocale'];

  var _default = Ember.Service.extend({
    cleaner: Ember.inject.service(),
    contacts: Ember.inject.service(),
    store: Ember.inject.service(),

    injectCitations(json) {
      let assoc = json.metadata.associatedResource;

      if (assoc) {
        let refs = assoc.reduce((acc, itm) => {
          if (itm.mdRecordId) {
            acc.push(itm);
          }

          return acc;
        }, []);
        let records = this.store.peekAll('record').filterBy('recordId');
        refs.forEach(ref => {
          let record = records.findBy('recordId', ref.mdRecordId);

          if (record) {
            let info = Ember.get(record, 'json.metadata.metadataInfo') || {};
            let metadata = {
              'title': "Metadata for ".concat(Ember.get(record, 'title')),
              'responsibleParty': Ember.getWithDefault(info, 'metadataContact', []),
              'date': Ember.getWithDefault(info, 'metadataDate', []),
              'onlineResource': Ember.getWithDefault(info, 'metadataOnlineResource', []),
              'identifier': [Ember.getWithDefault(info, 'metadataIdentifier', {})]
            };
            let citation = Ember.get(record, 'json.metadata.resourceInfo.citation') || {};
            let resourceType = Ember.get(record, 'json.metadata.resourceInfo.resourceType') || [];
            Ember.set(ref, 'resourceCitation', Ember.Object.create((0, _component.formatCitation)(citation)));
            Ember.set(ref, 'metadataCitation', Ember.Object.create((0, _component.formatCitation)(metadata)));
            Ember.set(ref, 'resourceType', resourceType);
            Ember.set(ref, 'mdRecordId', null);
            return;
          }

          Ember.set(ref, 'mdRecordId', null);
        });
      }
    },

    injectDictionaries(rec, json) {
      let ids = rec.get('json.mdDictionary') || [];
      let arr = [];

      if (ids.length) {
        let dicts = this.store.peekAll('dictionary').filterBy('dictionaryId');
        ids.forEach(id => {
          let record = dicts.findBy('dictionaryId', id);

          if (record) {
            arr.pushObject(record.get('json.dataDictionary'));
          }
        });
      }

      Ember.set(json, 'dataDictionary', arr);
    },

    formatRecord(rec, asText) {
      let _contacts = [];
      let conts = this.contacts;

      const _replacer = function _replacer(key, value) {
        let check = {
          contactId: true,
          sourceId: true,
          recipientId: true
        };

        if (key === 'sourceId' && !('amount' in this || 'currency' in this)) {
          //console.log(this);
          return value;
        }

        if (check[key] && !_contacts.includes(value)) {
          let contact = conts.get('contacts').findBy('contactId', value);

          if (!contact) {
            return null;
          }

          let orgs = Ember.isArray(contact.get('json.memberOfOrganization')) ? contact.get('json.memberOfOrganization').slice(0) : null;

          _contacts.push(value);

          if (orgs && orgs.length) {
            orgs.forEach(itm => {
              let org = conts.get('contacts').findBy('contactId', itm);

              if (!org) {
                return;
              }

              if (!_contacts.includes(itm) && org) {
                _contacts.push(itm);
              }

              let iOrgs = org.get('json.memberOfOrganization');

              if (iOrgs.length) {
                iOrgs.forEach(iOrg => {
                  if (!_contacts.includes(iOrg)) {
                    orgs.push(iOrg);
                  }
                });
              }
            });
          }
        }

        return value;
      };

      let cleaner = this.cleaner;
      let clean = cleaner.clean(Ember.get(rec, 'json'));
      this.injectCitations(clean);
      this.injectDictionaries(rec, clean);
      let json = JSON.parse(JSON.stringify(cleaner.clean(clean), _replacer));
      let contacts = this.store.peekAll('contact').mapBy('json');
      json.contact = contacts.filter(item => {
        return _contacts.includes(Ember.get(item, 'contactId'));
      });

      if (unImplemented) {
        unImplemented.forEach(path => {
          let array = Ember.isArray(path);
          let target = array ? Ember.get(json, path[0]) : Ember.get(json, path);

          if (target) {
            if (array) {
              target.forEach(item => {
                Ember.set(item, path[1], undefined);
              });
              return;
            }

            Ember.set(json, path, undefined);
          }
        });
      }

      return asText ? JSON.stringify(cleaner.clean(json)) : cleaner.clean(json);
    },

    validateRecord(record) {
      validator.validate('schema', this.formatRecord(record));
      return validator;
    },

    validateContact(contact) {
      validator.validate('contact', contact.get('cleanJson'));
      return validator;
    },

    validateDictionary(dictionary) {
      validator.validate('dataDictionary', dictionary.get('cleanJson').dataDictionary);
      return validator;
    }

  });

  _exports.default = _default;
});