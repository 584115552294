define("mdeditor/adapters/application", ["exports", "ember-local-storage/adapters/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({// //timestamp updates
    // updateRecord(store, type, snapshot) {
    //   //let date = new Date();
    //
    //   //snapshot.record.set('dateUpdated', date.toISOString());
    //   return this._super.apply(this,[store, type, snapshot]);
    // }
  });

  _exports.default = _default;
});