define("mdeditor/pods/components/object/md-party-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98SvzGdG",
    "block": "{\"symbols\":[\"party\",\"item\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"title\",\"pluralTitle\",\"required\",\"plain\",\"value\",\"validation\",\"errorMessage\",\"tipPath\",\"tipModel\",\"data-spy\",\"profilePath\",\"templateClass\"],[\"Role,Contacts\",[24,[\"title\"]],[24,[\"pluralTitle\"]],[24,[\"required\"]],[24,[\"plain\"]],[24,[\"value\"]],[24,[\"validation\"]],[24,[\"errorMessage\"]],[24,[\"tipPath\"]],[24,[\"tipModel\"]],false,[24,[\"profilePath\"]],[24,[\"templateClass\"]]]],{\"statements\":[[4,\"with\",[[23,1,[\"item\"]]],null,{\"statements\":[[0,\"     \"],[7,\"td\",true],[8],[0,\"\\n       \"],[1,[28,\"input/md-codelist\",null,[[\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"showValidations\",\"placeholder\",\"profilePath\"],[\"role\",[23,2,[]],true,true,false,[24,[\"disabled\"]],\"role\",true,\"Select or enter a role\",[28,\"concat\",[[24,[\"profilePath\"]],\".role\"],null]]]],false],[0,\"\\n     \"],[9],[0,\"\\n     \"],[7,\"td\",true],[8],[0,\"\\n       \"],[1,[28,\"input/md-select-contacts\",null,[[\"placeholder\",\"model\",\"path\",\"icon\",\"tooltip\",\"showValidations\",\"profilePath\"],[\"Select one or more contacts\",[23,2,[]],\"_contacts\",true,true,true,[28,\"concat\",[[24,[\"profilePath\"]],\".contacts\"],null]]]],false],[0,\"\\n     \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-party-array/template.hbs"
    }
  });

  _exports.default = _default;
});