define("mdeditor/pods/components/object/md-taxonomy/collection/system/preview/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'title': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    tagName: '',
    model: Ember.computed.alias('item'),
    modifications: Ember.computed.alias('model.modifications'),
    title: Ember.computed.alias('model.citation.title')
  });

  _exports.default = _default;
});