define("mdeditor/pods/record/show/edit/keywords/index/route", ["exports", "ember-copy", "jquery", "mdeditor/mixins/scroll-to"], function (_exports, _emberCopy, _jquery, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { on } from '@ember/object/evented';
  var _default = Ember.Route.extend(_scrollTo.default, {
    keyword: Ember.inject.service(),

    model() {
      let model = this.modelFor('record.show.edit');
      let json = model.get('json');
      let info = json.metadata.resourceInfo;
      Ember.set(info, 'keyword', !info.hasOwnProperty('keyword') ? Ember.A() : Ember.A(info.keyword)); //check to see if custom list

      info.keyword.forEach(k => {
        Ember.set(k, 'thesaurus', Ember.getWithDefault(k, 'thesaurus', {}));
        Ember.set(k, 'thesaurus.identifier', Ember.getWithDefault(k, 'thesaurus.identifier', [{
          identifier: 'custom'
        }]));
        Ember.set(k, 'thesaurus.date', Ember.getWithDefault(k, 'thesaurus.date', [{}]));
        Ember.set(k, 'thesaurus.onlineResource', Ember.getWithDefault(k, 'thesaurus.onlineResource', [{}]));
      });
      return model;
    },

    actions: {
      getContext() {
        return this;
      },

      addThesaurus() {
        let the = this.currentRouteModel().get('json.metadata.resourceInfo.keyword');
        (0, _jquery.default)("html, body").animate({
          scrollTop: (0, _jquery.default)(document).height()
        }, "slow");
        the.pushObject({
          keyword: [],
          keywordType: 'theme',
          thesaurus: {
            identifier: [{
              identifier: null
            }]
          },
          fullPath: true
        });
        this.controller.set('refresh', the.get('length'));
        this.controller.set('scrollTo', 'thesaurus-' + (the.get('length') - 1));
      },

      deleteThesaurus(id) {
        let the = this.currentRouteModel().get('json.metadata.resourceInfo.keyword');
        the.removeAt(id);
        this.controller.set('refresh', the.get('length'));
      },

      editThesaurus(id) {
        this.transitionTo('record.show.edit.keywords.thesaurus', id);
      },

      selectThesaurus(selected, thesaurus) {
        if (selected) {
          Ember.set(thesaurus, 'thesaurus', (0, _emberCopy.copy)(selected.citation, true));

          if (selected.keywordType) {
            Ember.set(thesaurus, 'keywordType', selected.keywordType);
          }
        } else {
          Ember.set(thesaurus, 'thesaurus.identifier.0.identifier', 'custom');
        }
      }

    }
  });

  _exports.default = _default;
});