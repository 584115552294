define("mdeditor/pods/components/object/md-locale/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OchVCGXl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n    \"],[1,[28,\"input/md-codelist\",null,[[\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"label\",\"showValidations\",\"placeholder\",\"profilePath\"],[\"language\",[23,0,[]],true,true,false,[24,[\"disabled\"]],\"language\",\"Language\",true,\"Select or enter a language code.\",[28,\"concat\",[[24,[\"profilePath\"]],\".language\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n    \"],[1,[28,\"input/md-codelist\",null,[[\"path\",\"model\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"label\",\"showValidations\",\"placeholder\",\"profilePath\"],[\"characterSet\",[23,0,[]],false,true,false,[24,[\"disabled\"]],\"characterSet\",\"Character Set\",true,\"Select character set.\",[28,\"concat\",[[24,[\"profilePath\"]],\".characterSet\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n    \"],[1,[28,\"input/md-codelist\",null,[[\"value\",\"create\",\"tooltip\",\"icon\",\"disabled\",\"mdCodeName\",\"label\",\"placeholder\",\"profilePath\"],[[24,[\"model\",\"country\"]],false,true,false,[24,[\"disabled\"]],\"countries\",\"Country\",\"Select country code.\",[28,\"concat\",[[24,[\"profilePath\"]],\".country\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-locale/template.hbs"
    }
  });

  _exports.default = _default;
});