define("mdeditor/pods/components/input/md-input-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ffs/CnW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[24,[\"disabled\"]],\"input-group\"],null]],[8],[0,\"\\n\\n\"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"required\",\"type\",\"maxlength\",\"class\",\"disabled\",\"blur\"],[[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"required\"]],[24,[\"type\"]],[24,[\"maxlength\"]],[24,[\"inputClass\"]],[24,[\"isDisabled\"]],[28,\"action\",[[23,0,[]],\"inputBlur\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n\"],[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\"],[\"btn btn-warning\",[28,\"action\",[[23,0,[]],\"allowEdit\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Edit\\n\"],[4,\"if\",[[24,[\"confirmTip\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,null,{\"statements\":[[0,\"                \"],[1,[22,\"confirmTip\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-input-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});