define("mdeditor/pods/components/control/md-scroll-into-view/component", ["exports", "scroll-into-view-if-needed"], function (_exports, _scrollIntoViewIfNeeded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import InViewportMixin from 'ember-in-viewport';
  var _default = Ember.Component.extend({
    classNames: ['md-scroll-into-view'],

    didInsertElement() {
      this._super(...arguments);

      let el = document.getElementById(this.elementId);
      let boundary = document.querySelector("#".concat(this.elementId)).closest(".md-scroll-into-view:not(#".concat(this.elementId, ")"));
      (0, _scrollIntoViewIfNeeded.default)(el, {
        block: boundary ? 'center' : 'nearest',
        behavior: 'smooth' //boundary: boundary

      });
    }

  });

  _exports.default = _default;
});