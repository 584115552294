define("mdeditor/pods/components/control/md-scroll-spy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65i9TnYs",
    "block": "{\"symbols\":[\"link\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"setScrollTo\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"nav nav-pills nav-stacked\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"links\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[23,1,[\"embedded\"]],\"md-embedded\"],null]],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[29,[\"#\",[23,1,[\"id\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clickLink\"],null]],[8],[1,[23,1,[\"text\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-scroll-spy/template.hbs"
    }
  });

  _exports.default = _default;
});