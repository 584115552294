define("mdeditor/pods/components/object/md-identifier-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kfNU4fHB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"object/md-objectroute-table\",null,[[\"items\",\"header\",\"buttonText\",\"ellipsis\",\"templateClass\",\"editItem\",\"attributes\",\"profilePath\"],[[24,[\"model\"]],[24,[\"label\"]],\"Add Identifier\",[24,[\"ellipsis\"]],[24,[\"templateClass\"]],[24,[\"editItem\"]],[24,[\"attributes\"]],[24,[\"profilePath\"]]]]],false],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-identifier-array/template.hbs"
    }
  });

  _exports.default = _default;
});