define("mdeditor/pods/dictionary/show/edit/entity/edit/attribute/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      this.set('entityId', this.paramsFor('dictionary.show.edit.entity.edit').entity_id);
    },

    model(params) {
      this.set('attributeId', params.attribute_id);
      return this.setupModel();
    },

    // breadCrumb: computed('attributeId', function () {
    //   let model = get(this, 'currentRouteModel').call(this);
    //
    //   return {
    //     title: 'Attribute: ' + (get(model,'codeName') || get(this, 'attributeId'))
    //   };
    // }),
    setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('setupModel', this.setupModel);
    },

    setupModel() {
      let attributeId = this.attributeId;
      let model = this.modelFor('dictionary.show.edit');
      let objects = model.get('json.dataDictionary.entity.' + this.entityId + '.attribute');
      let resource = attributeId && Ember.isArray(objects) ? objects.objectAt(attributeId) : undefined; //make sure the entity item exists

      if (Ember.isEmpty(resource)) {
        this.flashMessages.warning('No Attribute found! Re-directing to Entity...');
        this.replaceWith('dictionary.show.edit.entity.edit');
        return;
      }

      return resource;
    },

    actions: {
      backToEntity() {
        this.transitionTo('dictionary.show.edit.entity.edit', this.entityId);
      },

      editIdentifier(index) {
        let model = this.currentRouteModel();
        this.transitionTo('dictionary.show.edit.entity.edit.attribute.identifier', Ember.get(model, 'attributeReference.identifier.' + index)).then(function () {
          this.setScrollTo('identifier');
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});