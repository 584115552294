define("mdeditor/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    clean: Ember.inject.service('cleaner'),

    deserialize(serialized) {
      let json = JSON.parse(serialized);

      if (Ember.isArray(json)) {
        return Ember.A(json);
      }

      return Ember.Object.create(json);
    },

    serialize(deserialized) {
      let target = Ember.isArray(deserialized) ? [] : {};
      return JSON.stringify(this.clean.clean(deserialized, {
        target: target
      }));
    }

  });

  _exports.default = _default;
});