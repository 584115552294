define("mdeditor/pods/components/object/md-time-period/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const timeUnit = [{
    name: 'year',
    value: 'year'
  }, {
    name: 'month',
    value: 'month'
  }, {
    name: 'day',
    value: 'day'
  }, {
    name: 'hour',
    value: 'hour'
  }, {
    name: 'minute',
    value: 'minute'
  }, {
    name: 'second',
    value: 'second'
  }];
  const durationUnit = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  const Validations = (0, _emberCpValidations.buildValidations)({
    'intervalAmount': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      //disabled: computed.notEmpty('model.endDateTime'),
      ignoreBlank: true
    })],
    'startDateTime': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.endDateTime'),
      ignoreBlank: true
    })],
    'endDateTime': [(0, _emberCpValidations.validator)('date', {
      onOrAfter: Ember.computed.alias('model.startDateTime'),
      isWarning: true
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.notEmpty('model.startDateTime'),
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    init() {
      this._super(...arguments);

      this.timeUnit = timeUnit;
      this.durationUnit = durationUnit;
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(this, function () {
        Ember.set(model, 'periodName', Ember.getWithDefault(model, 'periodName', []));
        Ember.set(model, 'timeInterval', Ember.getWithDefault(model, 'timeInterval', {}));
        Ember.set(model, 'duration', Ember.getWithDefault(model, 'duration', {})); // set(model, 'presentationForm', getWithDefault(model,
        //   'presentationForm', []));
        // set(model, 'onlineResource', getWithDefault(model,
        //   'onlineResource', []));

        Ember.set(model, 'identifier', Ember.getWithDefault(model, 'identifier', {})); // set(model, 'graphic', getWithDefault(model, 'graphic', []));
      });
    },

    tagName: 'form',

    /**
     * The profile path for the component
     *
     * @property profilePath
     * @type {String}
     */
    startDateTime: Ember.computed('model.startDateTime', {
      get() {
        return Ember.get(this, 'model.startDateTime');
      },

      set(key, value) {
        Ember.run.once(this, function () {
          Ember.set(this, 'model.startDateTime', value);
          return value;
        });
      }

    }),
    endDateTime: Ember.computed('model.endDateTime', {
      get() {
        return Ember.get(this, 'model.endDateTime');
      },

      set(key, value) {
        Ember.run.once(this, function () {
          Ember.set(this, 'model.endDateTime', value);
          return value;
        });
      }

    }),
    intervalAmount: Ember.computed.alias('model.timeInterval.interval'),
    identifier: Ember.computed.alias('model.identifier.identifier'),
    timeUnit: timeUnit,
    durationUnit: durationUnit
  });

  _exports.default = _default;
});