define("mdeditor/pods/components/control/md-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t9huYnNK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"fa-icon\",[[24,[\"icon\"]]],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"],[4,\"ember-popover\",null,[[\"popoverHideDelay\",\"popperContainer\",\"tooltipClass\",\"event\"],[[24,[\"popoverHideDelay\"]],[24,[\"popperContainer\"]],[28,\"concat\",[\"md-tooltip \",[24,[\"type\"]]],null],[24,[\"event\"]]]],{\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"title\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[8],[1,[22,\"interpolated\"],false],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});