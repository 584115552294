define("mdeditor/pods/components/input/md-select-thesaurus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rr7PKlPy",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[28,\"input/md-select\",null,[[\"placeholder\",\"disabled\",\"objectArray\",\"valuePath\",\"namePath\",\"value\",\"change\"],[\"Pick a thesaurus\",[24,[\"disabled\"]],[24,[\"thesaurusList\"]],\"id\",\"label\",[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"update\",[24,[\"value\"]],[24,[\"thesaurus\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/input/md-select-thesaurus/template.hbs"
    }
  });

  _exports.default = _default;
});