define("mdeditor/pods/components/control/subbar-spatial/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      zoomAll() {
        this.zoomAll();
      },

      deleteAllFeatures() {
        this.deleteAllFeatures();
      },

      exportGeoJSON() {
        this.exportGeoJSON();
      },

      uploadData() {
        this.uploadData();
      },

      toList() {
        this.toList();
      }

    }
  });

  _exports.default = _default;
});