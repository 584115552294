define("mdeditor/pods/record/show/edit/extent/spatial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pc0M0lEu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n    \"],[1,[28,\"leaflet-table\",null,[[\"layers\",\"setFeatureGroup\",\"handleResize\"],[[28,\"get\",[[24,[\"model\"]],[28,\"concat\",[\"json.metadata.resourceInfo.extent.\",[24,[\"extentId\"]],\".geographicExtent.0.geographicElement\"],null]],null],[28,\"route-action\",[\"setFeatureGroup\"],null],[28,\"route-action\",[\"handleResize\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-spatial\"],[[\"actionContext\",\"zoomAll\",\"deleteAllFeatures\",\"exportGeoJSON\",\"uploadData\",\"toList\"],[[23,0,[]],[28,\"route-action\",[\"zoomAll\"],null],[28,\"route-action\",[\"deleteAllFeatures\"],null],[28,\"route-action\",[\"exportGeoJSON\"],null],[28,\"route-action\",[\"uploadData\"],null],[28,\"route-action\",[\"toList\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/extent/spatial/template.hbs"
    }
  });

  _exports.default = _default;
});