define("mdeditor/pods/record/show/edit/lineage/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata');
      Ember.set(model, 'resourceLineage', Ember.getWithDefault(model, 'resourceLineage', []));
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
    },
    actions: {
      editLineage(id) {
        this.transitionTo('record.show.edit.lineage.lineageobject', id);
      } //,
      // templateClass() {
      //   return Ember.Object.extend({
      //     init() {
      //       this._super(...arguments);
      //       //this.set('authority', {});
      //     }
      //   });
      // }


    }
  });

  _exports.default = _default;
});