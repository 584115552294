define("mdeditor/pods/components/object/md-resource-type-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bQff8qEx",
    "block": "{\"symbols\":[\"type\",\"item\"],\"statements\":[[4,\"object/md-array-table\",null,[[\"columns\",\"title\",\"data-spy\",\"value\",\"validation\",\"plain\",\"required\",\"errorMessage\",\"tipPath\",\"tipModel\",\"profilePath\",\"templateClass\"],[\"Type, Name\",\"Resource Type\",false,[24,[\"value\"]],[24,[\"validation\"]],[24,[\"plain\"]],[24,[\"required\"]],[24,[\"errorMessage\"]],[24,[\"tipPath\"]],[24,[\"tipModel\"]],[24,[\"profilePath\"]],[24,[\"templateClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"with\",[[23,1,[\"item\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input/md-codelist\",null,[[\"create\",\"tooltip\",\"icon\",\"disabled\",\"allowClear\",\"mdCodeName\",\"showValidations\",\"profilePath\",\"model\",\"path\",\"placeholder\"],[true,true,true,[24,[\"disabled\"]],true,\"scope\",true,[28,\"concat\",[[24,[\"profilePath\"]],\".resourceType\"],null],[23,2,[]],\"type\",\"Choose type of resource\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"input/md-input\",null,[[\"value\",\"profilePath\",\"placeholder\"],[[23,1,[\"item\",\"name\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".name\"],null],\"Name of resource\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-resource-type-array/template.hbs"
    }
  });

  _exports.default = _default;
});