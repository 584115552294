define("mdeditor/pods/record/show/edit/lineage/lineageobject/citation/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    actions: {
      editIdentifier(index) {
        this.transitionTo('record.show.edit.lineage.lineageobject.citation.identifier', index).then(function () {
          this.setScrollTo('identifier');
        }.bind(this));
      },

      goBack() {
        this.transitionTo('record.show.edit.lineage.lineageobject');
      }

    }
  });

  _exports.default = _default;
});