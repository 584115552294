define("mdeditor/components/sb-publisher", ["exports", "mdeditor-sciencebase/components/sb-publisher"], function (_exports, _sbPublisher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sbPublisher.default;
    }
  });
});