define("mdeditor/mdeditor-sciencebase/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/sb-publisher.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sb-publisher.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/sb-settings.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sb-settings.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/sb-tree-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sb-tree-label.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/sb-tree.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/sb-tree.js should pass ESLint\n\n');
  });
  QUnit.test('app/instance-initializers/settings-sciencebase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/instance-initializers/settings-sciencebase.js should pass ESLint\n\n');
  });
  QUnit.test('app/routes/publish/sciencebase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/routes/publish/sciencebase.js should pass ESLint\n\n');
  });
  QUnit.test('app/templates/publish/sciencebase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/templates/publish/sciencebase.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/config.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/sb-tree-node.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/sb-tree-node.js should pass ESLint\n\n');
  });
});