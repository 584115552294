define("mdeditor/pods/components/object/md-documentation/preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['muted:text-muted'],

    /**
     * Whether to render the text muted.
     *
     * @property muted
     * @type {Boolean}
     * @default "true"
     */
    muted: true
  });

  _exports.default = _default;
});