define("mdeditor/helpers/add-em", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEm = addEm;
  _exports.default = void 0;

  function addEm(params) {
    return params.reduce((a, b) => Number(a) + Number(b));
  }

  var _default = Ember.Helper.helper(addEm);

  _exports.default = _default;
});