define("mdeditor/pods/record/show/edit/funding/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata');
      Ember.set(model, 'funding', Ember.A(Ember.getWithDefault(model, 'funding', [])));
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
    },
    actions: {
      editAllocation(id) {
        this.transitionTo('record.show.edit.funding.allocation', id);
      },

      addAllocation() {
        let funding = this.currentRouteModel().get('json.metadata.funding');
        let allocation = Ember.Object.create({}); // once(this, () => {

        funding.pushObject(allocation);
        this.setScrollTo("funding-period-".concat(funding.length - 1));
        this.transitionTo('record.show.edit.funding.allocation', funding.length - 1); // $("html, body").animate({
        //   scrollTop: $(document).height()
        // }, "slow");
        // });
      },

      deleteAllocation(id) {
        let all = this.currentRouteModel().get('json.metadata.funding');
        all.removeAt(id);
        this.controller.set('refresh', all.get('length'));
      }

    }
  });

  _exports.default = _default;
});