define("mdeditor/pods/import/route", ["exports", "jquery", "ember-local-storage/adapters/base", "uuid/v4", "mdeditor/mixins/scroll-to", "mdeditor/models/contact"], function (_exports, _jquery, _base, _v, _scrollTo, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const generateIdForRecord = _base.default.create().generateIdForRecord;

  var _default = Ember.Route.extend(_scrollTo.default, {
    flashMessages: Ember.inject.service(),
    jsonvalidator: Ember.inject.service(),
    settings: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.icons = {
        records: 'file',
        dictionaries: 'book',
        contacts: 'users',
        settings: 'gear'
      };
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('importUri', this.get('settings.data.importUriBase'));
      controller.set('apiURL', this.apiURL);
    },

    model() {
      return Ember.Object.create({
        files: false,
        merge: true
      });
    },

    apiURL: Ember.computed.or('settings.data.mdTranslatorAPI', 'defaultAPI'),

    getTitle(record) {
      let raw = record.attributes.json;
      let json = raw ? JSON.parse(raw) : null;

      switch (record.type) {
        case 'records':
          return Ember.getWithDefault(json, 'metadata.resourceInfo.citation.title', 'NO TITLE');

        case 'dictionaries':
          return Ember.getWithDefault(json, 'dataDictionary.citation.title', 'NO TITLE');

        case 'contacts':
          return json.name || 'NO NAME';

        case 'schemas':
          return record.attributes.title || 'NO TITLE';

        default:
          return 'N/A';
      }
    },

    formatMdJSON(json) {
      let {
        contact,
        dataDictionary
      } = json;
      let data = Ember.A();
      let template = Ember.Object.extend({
        init() {
          this._super(...arguments);

          Ember.set(this, 'id', generateIdForRecord());
        },

        attributes: Ember.computed(function () {
          return {
            json: null //,
            //date-updated: '2017-05-18T21:21:34.446Z'

          };
        }),
        type: null
      });

      if (contact) {
        contact.forEach(item => {
          data.pushObject(template.create({
            attributes: {
              json: JSON.stringify(Ember.assign(_contact.JsonDefault.create(), item))
            },
            type: 'contacts'
          }));
        });
      }

      if (Ember.get(json, 'metadata.metadataInfo.metadataIdentifier') === undefined) {
        json.metadata.metadataInfo.metadataIdentifier = {
          identifier: (0, _v.default)(),
          namespace: 'urn:uuid'
        };
      }

      data.pushObject(template.create({
        attributes: {
          json: JSON.stringify(json) //profile: 'full'

        },
        type: 'records'
      }));

      if (dataDictionary) {
        dataDictionary.forEach(item => {
          data.pushObject(template.create({
            attributes: {
              json: JSON.stringify({
                dataDictionary: item
              })
            },
            type: 'dictionaries'
          }));
        });
      }

      return data;
    },

    mapJSON(data) {
      let {
        json,
        route
      } = data;
      let files;

      if (Ember.isArray(data.json.data)) {
        files = this.mapEditorJSON(data);
      } else {
        //assume it's raw mdJSON for now
        files = this.mapMdJSON(data);
      }

      route.currentRouteModel().set('files', files);
      route.currentRouteModel().set('data', json.data);
    },

    mapMdJSON(data) {
      let map = Ember.A();

      if (Ember.isArray(data.json)) {
        data.json.forEach(item => {
          map = map.concat(this.formatMdJSON(item));
        });
      } else {
        map = map.concat(this.formatMdJSON(data.json));
      }

      Ember.set(data, 'json.data', map);
      return this.mapRecords(map);
    },

    mapRecords(records) {
      return records.reduce((map, item) => {
        if (!map[item.type]) {
          map[item.type] = [];
        }

        item.meta = {};
        item.meta.title = this.getTitle(item);
        item.meta.icon = this.icons[item.type];
        item.meta.export = true;
        map[item.type].push(Ember.Object.create(item));
        return map;
      }, {});
    },

    mapEditorJSON(data) {
      let {
        file,
        json
      } = data;
      let jv = Ember.get(this, 'jsonvalidator.validator');
      let valid = jv.validate('jsonapi', json);

      if (!valid) {
        throw new Error("".concat(file.name, " is not a valid mdEditor file."));
      }

      return this.mapRecords(json.data);
    },

    columns: Ember.computed(function () {
      let route = this;
      return [{
        propertyName: 'meta.title',
        title: 'Title'
      }, {
        propertyName: 'attributes.date-updated',
        title: 'Last Updated'
      }, {
        propertyName: 'id',
        title: 'ID'
      }, {
        title: 'Actions',
        component: 'control/md-record-table/buttons/custom',
        disableFiltering: true,
        buttonConfig: {
          title: 'Preview JSON',

          action(model) {
            route.showPreview.call(route, model);
          },

          iconPath: 'meta.icon'
        }
      }];
    }),

    showPreview(model) {
      let json = {};
      Ember.assign(json, model.attributes);

      if (model.attributes.json) {
        json.json = JSON.parse(model.attributes.json);
      }

      this.currentRouteModel().set('preview', {
        model: model,
        json: json
      });
    },

    actions: {
      getColumns() {
        return Ember.get(this, 'columns');
      },

      getIcon(type) {
        return this.icons[type];
      },

      readData(file) {
        let json;
        let url = this.apiURL;
        let controller = this.controller;
        let cmp = this;
        new Ember.RSVP.Promise((resolve, reject) => {
          if (file.type.match(/.*\/xml$/)) {
            Ember.set(controller, 'isTranslating', true);
            Ember.get(this, 'flashMessages').info("Translation service provided by ".concat(url, "."));
            this.ajax.request(url, {
              type: 'POST',
              data: {
                //file: JSON.stringify(cleaner.clean(json)),
                file: file.data,
                reader: 'fgdc',
                writer: 'mdJson',
                validate: 'normal',
                format: 'json'
              },
              context: cmp
            }).then(function (response) {
              Ember.set(controller, 'isTranslating', false);

              if (response.success) {
                resolve({
                  json: JSON.parse(response.writerOutput),
                  file: file,
                  route: cmp
                });
                return;
              }

              reject("Failed to translate file: ".concat(file.name, ". Is it valid FGDC CSDGM XML?"));
            }, response => {
              Ember.set(controller, 'isTranslating', false);
              reject("mdTranslator Server error: ".concat(response.status, ": ").concat(response.statusText, ". Is your file valid FGDC CSDGM XML?"));
            });
          } else {
            try {
              json = JSON.parse(file.data);
            } catch (e) {
              reject("Failed to parse file: ".concat(file.name, ". Is it valid JSON?"));
            }

            resolve({
              json: json,
              file: file,
              route: cmp
            });
          }
        }).then(data => {
          //determine file type and map
          cmp.mapJSON(data);
        }).catch(reason => {
          //catch any errors
          Ember.get(cmp, 'flashMessages').danger(reason);
          return false;
        }).finally(() => {
          (0, _jquery.default)('.import-file-picker input:file').val('');
        });
      },

      readFromUri() {
        let uri = this.controller.get('importUri');
        let controller = this.controller;
        let route = this;
        Ember.set(controller, 'isLoading', true);
        this.ajax.request(uri, {
          type: 'GET',
          context: this,
          dataType: 'text',
          crossDomain: true
        }).then(function (response) {
          if (response) {
            let json;
            new Ember.RSVP.Promise((resolve, reject) => {
              try {
                json = JSON.parse(response);
              } catch (e) {
                reject("Failed to parse data. Is it valid JSON?");
              }

              resolve({
                json: json,
                file: null,
                route: route
              });
            }).then(data => {
              //determine file type and map
              route.mapJSON(data);
            }).catch(reason => {
              //catch any errors
              Ember.get(controller, 'flashMessages').danger(reason);
              return false;
            }).finally(() => {
              Ember.set(controller, 'isLoading', false);
              (0, _jquery.default)('.md-import-picker input:file').val('');
            });
          } else {
            Ember.set(controller, 'errors', response.messages);
            Ember.get(controller, 'flashMessages').danger('Import error!');
          }
        }).catch(response => {
          let error = " Error retrieving the mdJSON: ".concat(response.status, ": ").concat(response.statusText);
          Ember.set(controller, 'xhrError', error);
          Ember.set(controller, 'isLoading', false);
          Ember.get(controller, 'flashMessages').danger(error);
        });
      },

      importData() {
        let store = this.store;
        let data = {
          data: this.currentRouteModel().get('data').filterBy('meta.export').rejectBy('type', 'settings')
        };
        store.importData(data, {
          truncate: !this.currentRouteModel().get('merge'),
          json: false
        }).then(() => {
          Ember.get(this, 'flashMessages').success("Imported data. Records were\n              ".concat(this.currentRouteModel().get('merge') ? 'merged' : 'replaced', "."), {
            extendedTimeout: 1500
          }); //this.transitionTo('dashboard');
        });
        let settingService = this.settings;
        let newSettings = this.currentRouteModel().get('data').filterBy('meta.export').findBy('type', 'settings');

        if (newSettings) {
          let settings = {
            data: [newSettings]
          };
          let destroys = [];
          store.findAll('setting').forEach(rec => {
            destroys.pushObject(rec.destroyRecord());
          });
          Ember.RSVP.allSettled(destroys).then(() => {
            store.importData(settings, {
              json: false
            }).then(() => {
              settingService.setup();
              Ember.get(this, 'flashMessages').success("Imported Settings.", {
                extendedTimeout: 1500
              });
            });
          });
        }
      },

      closePreview() {
        this.currentRouteModel().set('preview', false);
      },

      cancelImport() {
        this.currentRouteModel().set('files', false);
      }

    }
  });

  _exports.default = _default;
});