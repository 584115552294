define("mdeditor/pods/record/show/edit/documents/citation/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('citationId', Ember.get(this.controllerFor('record.show.edit.documents.citation'), 'citationId'));
    }
  });

  _exports.default = _default;
});