define("mdeditor/mdeditor-sciencebase/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/components/sb-publisher.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/sb-publisher.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/sb-settings.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/sb-settings.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/sb-tree-label.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/sb-tree-label.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/sb-tree.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/sb-tree.js should pass ESLint\n\n');
  });
  QUnit.test('addon/instance-initializers/settings-sciencebase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/instance-initializers/settings-sciencebase.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/publish/sciencebase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/publish/sciencebase.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/config.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/sb-tree-node.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/sb-tree-node.js should pass ESLint\n\n');
  });
});