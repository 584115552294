define("mdeditor/pods/components/object/md-objectroute-table/component", ["exports", "mdeditor/pods/components/object/md-object-table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * The route used to edit items
     *
     * @property itemRoute
     * @type {String}
     * @required
     */

    /**
     * Comma separated string of addtional route parameters
     *
     * @property routeParams
     * @type {String}
     * @default undefined
     */

    /**
     * Indicates whether to show alert if no items are present
     *
     * @property alertIfEmpty
     * @type {Boolean}
     * @default "true"
     */
    alertIfEmpty: true,

    /**
     * Indicates whether to immediately navigate to the edit route on add
     *
     * @property editOnAdd
     * @type {Boolean}
     * @default "true"
     */
    editOnAdd: true,

    /**
     * Method used to load form for editing item. Should be overidden.
     *
     * @method editItem
     */
    editItem() {
      return this;
    },

    editBtnText: 'More...',
    layoutName: 'components/object/md-object-table',
    actions: {
      addItem: function addItem() {
        const Template = this.templateClass;
        const owner = Ember.getOwner(this);
        let editItem = this.editItem;
        let items = this.items;
        let itm = Ember.typeOf(Template) === 'class' ? Template.create(owner.ownerInjection()) : Ember.Object.create({});

        if (Ember.isBlank(editItem)) {
          (true && !(false) && Ember.assert("You must supply an editItem method to ".concat(this.toString(), ".")));
        }

        items.pushObject(itm);

        if (this.editOnAdd) {
          editItem(items.indexOf(itm), this.routeParams, "".concat(this.scrollToId, "-").concat(this.items.length - 1));
        }
      },
      editItem: function editItem(items, index, scrollTo) {
        this.editItem(index, this.routeParams, scrollTo);
      }
    }
  });

  _exports.default = _default;
});