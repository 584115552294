define("mdeditor/pods/components/control/md-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J0wP6pRQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],[[\"spin\"],[[24,[\"iconSpin\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ember-tooltip\",null,[[\"tooltipClass\",\"text\",\"side\"],[[28,\"concat\",[\"ember-tooltip md-tooltip \",[24,[\"tipClass\"]]],null],[24,[\"tooltip\"]],[24,[\"tipSide\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-button/template.hbs"
    }
  });

  _exports.default = _default;
});