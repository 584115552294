define("mdeditor/pods/components/object/md-schema/form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const typeOptions = [{
    name: 'Metadata',
    value: 'record' //tip: 'tooltip'

  }, {
    name: 'Contact',
    value: 'contact' //tip: 'tooltip'

  }, {
    name: 'Dictionary',
    value: 'dictionary' //tip: 'tooltip'

  }];

  var _default = Ember.Component.extend({
    typeOptions: typeOptions
  });

  _exports.default = _default;
});