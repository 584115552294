define("mdeditor/pods/record/show/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Route.extend(_scrollTo.default, {
    actions: {
      linkTo(route) {
        this.transitionTo(route);
      },

      setupMap(features, m) {
        let map = m.target;
        let bounds = L.geoJson(features).getBounds();
        map.fitBounds(bounds);
      }

    }
  });

  _exports.default = _default;
});