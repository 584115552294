define("mdeditor/pods/dictionary/show/edit/route", ["exports", "mdeditor/mixins/hash-poll", "mdeditor/mixins/cancel"], function (_exports, _hashPoll, _cancel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_hashPoll.default, _cancel.default, {
    /**
     * The profile service
     *
     * @return {Ember.Service} profile
     */
    profile: Ember.inject.service('custom-profile'),

    /**
     * The route activate hook, sets the profile.
     */
    afterModel(model) {
      this._super(...arguments);

      this.profile.set('active', model.get('profile'));
    },

    actions: {
      /**
       * Update the dictionary profile
       *
       * @param  {String} profile The new profile.
       */
      saveDictionary: function saveDictionary() {
        let model = this.currentRouteModel();
        model.save().then(() => {
          this.flashMessages.success("Saved Dictionary: ".concat(model.get('title')));
        });
      },
      cancelDictionary: function cancelDictionary() {
        let model = this.currentRouteModel();
        let message = "Cancelled changes to Dictionary: ".concat(model.get('title'));

        if (this.get('settings.data.autoSave')) {
          let json = model.get('jsonRevert');

          if (json) {
            model.set('json', JSON.parse(json));
            this.doCancel();
            this.flashMessages.warning(message);
          }

          return;
        }

        model.reload().then(() => {
          this.doCancel();
          this.flashMessages.warning(message);
        });
      }
    }
  });

  _exports.default = _default;
});