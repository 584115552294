define("mdeditor/pods/record/show/edit/distribution/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata');
      Ember.set(model, 'resourceDistribution', Ember.getWithDefault(model, 'resourceDistribution', []));
    }

  });

  _exports.default = _default;
});