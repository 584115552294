define("mdeditor/pods/publish/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    publish: Ember.inject.service('publish'),
    model: function model() {
      return this.get('publish.catalogs');
    }
  });

  _exports.default = _default;
});