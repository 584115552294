define("mdeditor/pods/record/show/edit/constraint/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(m) {
      this._super(...arguments);

      let model = Ember.get(m, 'json.metadata.resourceInfo');
      Ember.set(model, 'constraint', Ember.getWithDefault(model, 'constraint', []));
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
    }
  });

  _exports.default = _default;
});