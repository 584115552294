define("mdeditor/pods/components/control/md-record-table/buttons/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9t9t8syH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-sm btn-info\",[28,\"concat\",[[24,[\"record\",\"constructor\",\"modelName\"]],\".show\"],null],[24,[\"record\",\"id\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"fa-icon\",[\"eye\"],null],false],[0,\" Show\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-record-table/buttons/show/template.hbs"
    }
  });

  _exports.default = _default;
});