define("mdeditor/pods/components/ember-tooltip/component", ["exports", "ember-tooltips/components/ember-tooltip"], function (_exports, _emberTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberTooltip.default.extend({
    popperContainer: 'body'
  });

  _exports.default = _default;
});