define("mdeditor/pods/components/layout/md-nav-secondary/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LaRnfYIY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isStaticLinks\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link.class\",[24,[\"link\",\"target\"]]]],{\"statements\":[[1,[24,[\"link\",\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"link\",\"tip\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"effect\",\"side\",\"delay\",\"tooltipClass\"],[\"slide\",\"bottom\",500,\"ember-tooltip md-tooltip info\"]],{\"statements\":[[0,\"      \"],[1,[24,[\"link\",\"tip\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"link.class\",[24,[\"link\",\"target\"]],[24,[\"model\"]]]],{\"statements\":[[1,[24,[\"link\",\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"link\",\"tip\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"effect\",\"side\",\"delay\",\"tooltipClass\"],[\"slide\",\"bottom\",500,\"ember-tooltip md-tooltip info\"]],{\"statements\":[[0,\"          \"],[1,[24,[\"link\",\"tip\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/md-nav-secondary/link/template.hbs"
    }
  });

  _exports.default = _default;
});