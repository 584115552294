define("mdeditor/pods/components/control/md-fiscalyear/component", ["exports", "mdeditor/pods/components/input/md-select/component", "mdeditor/pods/components/input/md-select/template", "moment"], function (_exports, _component, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    settings: Ember.inject.service('settings'),
    classNames: ['md-fiscalyear'],
    objectArray: Ember.computed(function () {
      return Array.apply(0, Array(12)).map(function (element, index) {
        return {
          year: index + ((0, _moment.default)().year() - 10)
        };
      });
    }),
    label: 'Pick Fiscal Year',
    valuePath: 'year',
    namePath: 'year',
    tooltip: false,
    searchEnabled: true,
    placeholder: 'Pick a Fiscal Year',
    create: true,
    disabled: Ember.computed('settings.data.fiscalStartMonth', function () {
      return !this.get('settings.data.fiscalStartMonth');
    }),

    change() {
      let val = this.value;
      let month = parseInt(this.get('settings.data.fiscalStartMonth'), 10) - 1;
      let dt = month <= 6 ? (0, _moment.default)(val, 'YYYY') : (0, _moment.default)(val, 'YYYY').subtract(1, 'year');
      let start = dt.month(month).startOf('month'); //let end = moment(val, 'YYYY').month('September').endOf('month');

      let end = start.clone().add(11, 'months').endOf('month');
      let context = this.context;
      this.setProperties({
        end: end,
        start: start
      });

      if (context) {
        //have to set values using datetimepicker
        context.$('.start .date').data("DateTimePicker").date(start);
        context.$('.end .date').data("DateTimePicker").date(end);
      }

      this.set('value', null);
    }

  });

  _exports.default = _default;
});