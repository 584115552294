define("mdeditor/pods/components/input/md-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Input, edit, display a multi-line, expandable, text area.
     *
     * @class md-textarea
     * @constructor
     */
    init() {
      this._super(...arguments);

      let model = this.model;
      let valuePath = this.valuePath;

      if (Ember.isBlank(model) !== Ember.isBlank(valuePath)) {
        (true && !(false) && Ember.assert("You must supply both model and valuePath to ".concat(this.toString(), " or neither.")));
      }

      if (!Ember.isBlank(model)) {
        if (this.get("model.".concat(valuePath)) === undefined) {
          Ember.debug("model.".concat(valuePath, " is undefined in ").concat(this.toString(), ".")); //Ember.run.once(()=>model.set(valuePath, ""));
        }

        Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(valuePath)));
        Ember.defineProperty(this, 'validation', Ember.computed.alias("model.validations.attrs.".concat(valuePath)).readOnly());
        Ember.defineProperty(this, 'required', Ember.computed('validation.options.presence{presence,disabled}', 'disabled', function () {
          return !this.disabled && this.get('validation.options.presence.presence') && !this.get('validation.options.presence.disabled');
        }).readOnly());
        Ember.defineProperty(this, 'notValidating', Ember.computed.not('validation.isValidating').readOnly());
        Ember.defineProperty(this, 'hasContent', Ember.computed.notEmpty('value').readOnly());
        Ember.defineProperty(this, 'hasWarnings', Ember.computed.notEmpty('validation.warnings').readOnly());
        Ember.defineProperty(this, 'isValid', Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly());
        Ember.defineProperty(this, 'shouldDisplayValidations', Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly());
        Ember.defineProperty(this, 'showErrorClass', Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly());
        Ember.defineProperty(this, 'showErrorMessage', Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly());
        Ember.defineProperty(this, 'showWarningMessage', Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly());
      }
    },

    attributeBindings: ['data-spy'],
    classNames: ['md-textarea'],
    classNameBindings: ['label:form-group', 'required', 'embedded:md-embedded'],

    /**
     * Initial value, returned value.
     *
     * @property value
     * @type String
     * @return String
     * @required
     */

    /**
     * Form label for textarea
     *
     * @property label
     * @type String
     * @default null
     */
    label: null,

    /**
     * The string to display when no option is selected.
     *
     * @property placeholder
     * @type String
     * @default 'Select one option'
     */
    placeholder: "Select one option",

    /**
     * Indicates whether the value is required
     *
     * @property required
     * @type Boolean
     * @default false
     */
    required: false,

    /**
     * Maximum number of characters allowed.
     * If maxlength is not provided the number of characters will
     * not be restricted.
     *
     * @property maxlength
     * @type Number
     * @default null
     */
    maxlength: null,

    /**
     * Enable auto-resizing of the textarea
     *
     * @property autoresize
     * @type Boolean
     * @default true
     */
    autoresize: true,

    /**
     * Toggle expand state
     *
     * @property isExpanded
     * @type Boolean
     * @default true
     */
    isExpanded: true,

    /**
     * Enable collapse of the textarea
     *
     * @property isCollapsible
     * @type Boolean
     * @default false
     */
    isCollapsible: false,

    /**
     * Set the maximum width of the resizeable element in pixels.
     * If maxwidth is not provided width will not be restricted.
     *
     * @property maxwidth
     * @type Number
     * @default null
     */
    maxwidth: null,

    /**
     * Set the maximum height of the resizable element in pixels.
     * If maxheight is not provided height will not be restricted.
     *
     * @property maxheight
     * @type {Number}
     * @default null
     */
    maxheight: null,

    /**
     * Set the minimum number of rows for the element.
     * Recommended for textareas.
     *
     * @property rows
     * @type Number
     * @default 2
     */
    rows: 2,

    /**
     * Set the maximum number of rows for the element.
     * Recommended for textareas.
     *
     * @property maxrows
     * @type Number
     * @default 10
     */
    maxrows: 10,

    /**
     * Class to set on the textarea
     *
     * @property inputClass
     * @type {string}
     * @default 'form-control'
     */
    inputClass: 'form-control',

    _didInsertArea() {
      this.scheduleMeasurement();
    }

  });

  _exports.default = _default;
});