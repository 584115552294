define("mdeditor/pods/components/md-models-table/components/check/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qci7ovKc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"isSelected\"]],[24,[\"themeInstance\",\"select-row\"]],[24,[\"themeInstance\",\"deselect-row\"]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clickOnRow\",[24,[\"index\"]],[24,[\"record\"]]],null]],[10,\"role\",\"checkbox\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/md-models-table/components/check/template.hbs"
    }
  });

  _exports.default = _default;
});