define("mdeditor/pods/components/object/md-repository-array/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    repositoryTemplate: Ember.Object.extend({
      init() {
        this._super(...arguments);

        this.set('citation', {});
      }

    }),
    actions: {
      lookupTitle(value) {
        let defs = this.get('settings.data.repositoryDefaults');
        let titles = defs.filterBy('repository', value.repository);

        if (Ember.get(titles, 'length')) {
          Ember.set(value, 'citation.title', Ember.get(titles.objectAt(0), 'title'));
        }
      }

    }
  });

  _exports.default = _default;
});