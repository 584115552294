define("mdeditor/pods/components/control/md-errors/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['md-error-list']
    /**
    *  Error objects to render grouped by validation schema or profile. The group
    *  object must have a `title` and `errors` property. Error objects iwthin the
    *  group must have a `message` property. `dataPath` is optional.
    *
    * @property errors
    * @type {Array}
    * @required
    */

  });

  _exports.default = _default;
});