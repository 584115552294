define("mdeditor/data/sciencebase-nggdpp-vocabulary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "uuid": "5c050c63-147f-5196-92d8-705d776e4153",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "collection",
    "definition": "",
    "children": [{
      "uuid": "4b12677b-d32a-5c02-a290-c1a30806e10f",
      "broader": "5c050c63-147f-5196-92d8-705d776e4153",
      "label": "category",
      "definition": "",
      "children": [{
        "uuid": "f998722c-29ce-5126-9601-683e0c502e37",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "2-D and 3-D seismic reflection"
      }, {
        "uuid": "9ab3e0d1-b627-5378-b7bd-e91cba68a1c7",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Aerial photographs"
      }, {
        "uuid": "63f77e50-1ae6-526e-a2b8-df2430aebd78",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Aeromagnetic database"
      }, {
        "uuid": "e33b893a-cd32-5559-9062-89ef2e9b5a89",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Auger samples"
      }, {
        "uuid": "2f432b88-122c-5b9f-adec-a0696372f575",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Bibliographic Database"
      }, {
        "uuid": "587fb8a8-e5b2-5e17-892c-d2364492810d",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Borehole geophysical logs"
      }, {
        "uuid": "a8f28627-d9a9-574b-add2-199f454fda04",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Chattanooga Shale Uranium & Oil Shale Reports"
      }, {
        "uuid": "ae25bebe-4316-530a-9c97-0e306be1cac1",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Chips"
      }, {
        "uuid": "bb107544-e6d2-5069-b27c-2d3ebb385deb",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Analyses"
      }, {
        "uuid": "aaa20eb0-7391-5a7f-b478-dad98bc1ad76",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Exploration Core Hole Logs"
      }, {
        "uuid": "2908ac93-e5ab-5dff-9ff0-ae20bcca5caa",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Exploration Records"
      }, {
        "uuid": "6b38b4c1-e11a-55be-a2f4-b8e4e97cbc10",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Field Measured Sections"
      }, {
        "uuid": "ba0e10f7-b19b-52a5-a571-76bebfb0b44e",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Geology Maps"
      }, {
        "uuid": "053eaf0e-0c3e-534b-863b-b8021c566bf5",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Mine Maps"
      }, {
        "uuid": "2653b5e6-6663-5389-953c-856f618980fa",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Outcrop measurments"
      }, {
        "uuid": "0a0fb7b5-f799-5c9c-8c11-b719a65f4191",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Quality Analyses"
      }, {
        "uuid": "c65b7f7c-e812-58fc-8c70-72b71587b023",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Reports"
      }, {
        "uuid": "04c22947-e321-57e1-9599-d51d7072bb5c",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Samples"
      }, {
        "uuid": "919c5fac-9c77-5cab-95ca-2ebebd646297",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Coal Strip Logs"
      }, {
        "uuid": "fe096cca-db99-53f9-ae27-21bd2d4936ca",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "DOE Oak Ridge Reports"
      }, {
        "uuid": "985e5043-8111-545b-a7e3-f3b0656834b4",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Drill stem & other tests"
      }, {
        "uuid": "d9586b5c-045f-515c-9ea8-38219dff9e97",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Drilling/completion reports"
      }, {
        "uuid": "56abca60-3549-5d8c-b3c6-828868c9b600",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Field notes"
      }, {
        "uuid": "894c067c-d121-59cb-ae27-a84ead7411ee",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Fluid samples"
      }, {
        "uuid": "58fc48e2-534b-5120-9fd1-3f7b2b73842a",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geochemical samples"
      }, {
        "uuid": "00453aff-0b33-5421-9fa5-ea7ba8f40a13",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geologic Images"
      }, {
        "uuid": "f8270d85-16d1-58dc-bc8b-7e9d6ffe9979",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geological mapping data"
      }, {
        "uuid": "ca8a9c1d-bf32-5c51-9164-f63865ce0da0",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geophysical Data"
      }, {
        "uuid": "9e9359b2-8a56-5979-ab48-5a3d62977112",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geotechnical drilling"
      }, {
        "uuid": "c50f7eb5-597a-5547-9c3a-08e0bebc92e0",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geotechnical Engineering Reports"
      }, {
        "uuid": "e57597d0-e807-59f4-bfcc-bee33b809ccf",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Geothermal Data"
      }, {
        "uuid": "b62ebdc9-e5e6-578e-bef7-c9fe70437001",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Gravity database"
      }, {
        "uuid": "05dfb1e2-8706-5dc4-a542-599c27026c2a",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Hand samples"
      }, {
        "uuid": "35c92dc7-7a97-50b8-81d1-ddad14d32741",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Highway Maps"
      }, {
        "uuid": "283d0359-2769-5349-8aa3-c54aacca051c",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Individual specimen"
      }, {
        "uuid": "f6f30f82-9bd7-5c83-af4c-75aeba635cd8",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Inventory of mineral resources sites"
      }, {
        "uuid": "86226ad1-cd60-573e-8acf-8ef597a6830e",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Iron Mining / Resource Maps"
      }, {
        "uuid": "4954adce-4680-5fe0-8852-55d5634eb497",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Karst database"
      }, {
        "uuid": "3543faaf-59e8-519a-a07a-d377bca193d1",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Limestone Samples"
      }, {
        "uuid": "19ff83c3-5e36-5e68-83e3-fd3623788262",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Lithology logs"
      }, {
        "uuid": "b9f92a15-66f7-541d-ada3-a8408c26a8e5",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Maps"
      }, {
        "uuid": "326c017b-9524-54f1-8f0e-c88a5a89582d",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Mineral exploration files"
      }, {
        "uuid": "b532b314-748c-5d7b-b860-e758c20618f0",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Mineral Resources"
      }, {
        "uuid": "1309e690-d039-5ece-b406-93eae4d8324e",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Mines and Prospects Database"
      }, {
        "uuid": "901cec94-38ba-59e4-bc9b-6e8920ca02ef",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Mud (gas) logs"
      }, {
        "uuid": "97db0f2f-28c2-5bdf-9f73-8d7b01522ea7",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Oil and Gas Well Data File"
      }, {
        "uuid": "fa9ccbba-255e-5a8f-8a57-e3504542c62b",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Oil and Gas Wells Logs"
      }, {
        "uuid": "52feb96d-c30b-580c-aca8-ed7ee7140b49",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Outcrop Locations"
      }, {
        "uuid": "10c19b0d-4ad7-512c-a517-780bed8a34a9",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Paleomagnetic data"
      }, {
        "uuid": "39fc3eee-18e1-5fd2-b722-1a61e1fb7cd0",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Paleontological samples"
      }, {
        "uuid": "3e7346a3-e944-528e-91fc-65e36a47aafd",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Paper reports"
      }, {
        "uuid": "cd4b2154-3276-5c73-afeb-c5a3935901a8",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Petrophysical Data"
      }, {
        "uuid": "d98f8fa7-5449-5b6c-8a00-155353cbb94f",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Photographs"
      }, {
        "uuid": "afac180f-885e-5155-81e3-d60d5c5fd506",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Physical Geoscience"
      }, {
        "uuid": "78b39eb0-84e2-596c-a2e3-7873af811b2b",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Production history"
      }, {
        "uuid": "4b2cd4de-4d44-5c6e-a4ca-a6bfc7efc9dd",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Production history/Potential Fields/Drill Stem and other tests"
      }, {
        "uuid": "13544666-44df-5d33-a707-42569d03c360",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Radiometric Age Data"
      }, {
        "uuid": "a16a57e8-445f-5b52-8f1c-c874117a0130",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Rock cores"
      }, {
        "uuid": "69804e15-ba7a-5f6a-81a2-57fb54d15087",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Rock cores, thin and polished sections, hand samples, paper reports."
      }, {
        "uuid": "dbeb29b9-0166-544b-95b0-d50c5e0b5b36",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Rock cuttings"
      }, {
        "uuid": "98bdd73e-1866-525a-8245-4a2e0852b6eb",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Rock properties database"
      }, {
        "uuid": "02a3f965-3c23-523d-9ab0-48f9d5b232b6",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Routine analysis data"
      }, {
        "uuid": "11550d67-5d0b-5ea5-8163-c3fc6e7680cb",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Scout tickets"
      }, {
        "uuid": "97b98ec8-daa7-56a6-96fb-9dbbe4212b19",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Sediment cores"
      }, {
        "uuid": "5e86d6ef-a8db-5053-ba2b-47a158ec9361",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Sediment samples"
      }, {
        "uuid": "a8ab3633-fb5a-5509-b42c-cb5486c01cdd",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Sediment textural and lithological data"
      }, {
        "uuid": "4fff21ed-82d0-5787-9235-b38be031d710",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Seismic data"
      }, {
        "uuid": "78bc2352-87aa-59fe-a1d4-f9b4a89ac402",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Sidewall cores"
      }, {
        "uuid": "0aecbe27-a50d-5968-af2b-4680ef15ae52",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Slide Photographs"
      }, {
        "uuid": "02925ae6-b197-5626-b0cb-b31236d81780",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Source-rock maturity analysis"
      }, {
        "uuid": "b88fd59f-c447-533f-b702-41627529d45e",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Special analysis data"
      }, {
        "uuid": "456356ef-4130-5eee-ab25-3706a2b887b2",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Spring locations"
      }, {
        "uuid": "081ee12b-ae69-5aea-9b77-40f33fcc12d2",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Stratigraphic horizons"
      }, {
        "uuid": "7bd26d6b-9bb1-5ffa-ad46-40541bd5a305",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Surface & airborne data"
      }, {
        "uuid": "7aa486b4-7a51-585c-9d42-d69937e7df74",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Thin sections"
      }, {
        "uuid": "c3d870b5-c12e-59dd-b1de-394580380f38",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Thin sections and polished sections"
      }, {
        "uuid": "c3eaae0a-5f90-5e0d-b5d7-b43b095e092b",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Thin sections|Paleo slides"
      }, {
        "uuid": "60e05876-e856-50da-bae5-f5858037cea2",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "TN County Mineral Resource Files"
      }, {
        "uuid": "979d330e-3df6-55c6-baef-8b462cbef250",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "TN Valley Authority Mineral Res. File"
      }, {
        "uuid": "f6859afe-ebed-5db3-bd11-92721a5cbf60",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "TN Valley Authority Reports"
      }, {
        "uuid": "9001433e-9928-5a46-9caa-6c9ec4401b3e",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Type stratigraphic sections"
      }, {
        "uuid": "7d950a94-8434-5f28-a86a-542375f42168",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Vertical seismic profiles"
      }, {
        "uuid": "bad7f805-def0-5216-8456-9747be186b7d",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Videotapes"
      }, {
        "uuid": "8a40193b-8384-58c5-8aaf-2f75d696bd68",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Water well sites"
      }, {
        "uuid": "daa5227f-bd7c-59c2-ab25-9216c425e8ad",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Well Completion Reports"
      }, {
        "uuid": "bf116193-422f-5ff4-b3ce-249c8470afc2",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Well construction reports"
      }, {
        "uuid": "ccd38356-5bcb-562e-a795-d6a8eb9d026a",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Well Logs"
      }, {
        "uuid": "2f2beb29-d193-54a9-934f-34acce0a0902",
        "broader": "4b12677b-d32a-5c02-a290-c1a30806e10f",
        "label": "Zinc Mining Maps & Reports"
      }]
    }, {
      "uuid": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
      "broader": "5c050c63-147f-5196-92d8-705d776e4153",
      "label": "primaryPurpose",
      "definition": "",
      "children": [{
        "uuid": "b2bd279c-0869-524c-b7c9-5ad96905dcd3",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Engineering"
      }, {
        "uuid": "ba2292da-6dbf-557f-99d5-2284d37f2369",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Hazard Mitigation"
      }, {
        "uuid": "95fa527f-eb2d-52b1-916f-d7bb645332e8",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Land Management"
      }, {
        "uuid": "b46d9b24-b4ae-59e1-ab39-5f6992c3b8f9",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Mineral Exploration"
      }, {
        "uuid": "30445314-46f2-5b52-9970-a20514926211",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Oil & Gas Exploration"
      }, {
        "uuid": "744f84dc-0875-59df-bbfa-e1278fd16fed",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Reference"
      }, {
        "uuid": "4fb97c89-e8f4-52b9-ac87-ca5ffb625c8d",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Research"
      }, {
        "uuid": "1f0db8cb-35dd-51fe-ae0e-34fbd63cbfb9",
        "broader": "5b3fa48e-ba31-5fae-9fcb-a5844fc2e7d3",
        "label": "Teaching"
      }]
    }, {
      "uuid": "f95e1585-8f3f-5be7-a0b6-229c47f92049",
      "broader": "5c050c63-147f-5196-92d8-705d776e4153",
      "label": "type",
      "definition": "",
      "children": [{
        "uuid": "ee04525b-99e4-55d9-a7c7-8ed26f02232e",
        "broader": "f95e1585-8f3f-5be7-a0b6-229c47f92049",
        "label": "Derived and Indirect Geoscience Data"
      }, {
        "uuid": "1550a706-5261-53fa-ac8d-813a5e29d66f",
        "broader": "f95e1585-8f3f-5be7-a0b6-229c47f92049",
        "label": "Geological"
      }, {
        "uuid": "0bbc8ea8-84d9-5d73-9041-89ad9e13c698",
        "broader": "f95e1585-8f3f-5be7-a0b6-229c47f92049",
        "label": "Geophysical"
      }, {
        "uuid": "3d474a3e-dd35-5f09-a890-3dc65ecfb284",
        "broader": "f95e1585-8f3f-5be7-a0b6-229c47f92049",
        "label": "Physical Geoscience"
      }]
    }, {
      "uuid": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
      "broader": "5c050c63-147f-5196-92d8-705d776e4153",
      "label": "unit",
      "definition": "",
      "children": [{
        "uuid": "07bdcfc4-7f51-507a-a6b3-80c2f161c0bf",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Age Date Records"
      }, {
        "uuid": "dfd93c2a-972d-59ad-a7b6-022f0ba496c4",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Analyses"
      }, {
        "uuid": "8531ee73-860e-5e39-bd78-94124cae248d",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Bottles"
      }, {
        "uuid": "78520fa7-bf79-56e5-aff6-006155c8fe45",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Boxes"
      }, {
        "uuid": "6bf0d3d5-72ff-578d-9294-f1a15450b8c7",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Cubic Feet"
      }, {
        "uuid": "46dec9f0-b878-52fa-ab1a-8557b6f9c253",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Data Reports"
      }, {
        "uuid": "6de17dd4-1432-59f9-b69a-df7cd249d0fa",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Drawers"
      }, {
        "uuid": "cf7f1473-b4c0-5952-8760-a1fb92d9ae7f",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Drill Holes"
      }, {
        "uuid": "e70d44cb-e97d-5404-b5fc-90db0b7521ea",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "each"
      }, {
        "uuid": "62fe90eb-38ba-53e0-8044-96454b88847e",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Feet"
      }, {
        "uuid": "1d58e3fc-5883-5d2c-9558-17262e8a55c3",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Individual Samples"
      }, {
        "uuid": "8e803e76-349c-5f8c-bcbe-18cf54fee2eb",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Individual Specimens"
      }, {
        "uuid": "d91fda22-9bf0-528e-bdb0-d8461d579df1",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Items"
      }, {
        "uuid": "1f825dab-df4a-58bc-a7c9-9b2425733ad4",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Linear Feet"
      }, {
        "uuid": "c7b0cb0a-63e6-564e-8b34-73e7992d15ca",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Localities"
      }, {
        "uuid": "24ba8782-a142-5348-960c-93861a14b258",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Logs"
      }, {
        "uuid": "5b8de258-9bee-5a92-a717-4474d7655569",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Maps"
      }, {
        "uuid": "bb0a9b67-3110-5992-aef4-3a1855b2bf48",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Megabytes"
      }, {
        "uuid": "6d50462b-17e8-5858-9835-33964fc86202",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Other"
      }, {
        "uuid": "c4b0d149-3983-5413-a684-c2239de21b7d",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Pages"
      }, {
        "uuid": "80d55501-76bb-5744-9bce-181ebb88d2a7",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Photographs"
      }, {
        "uuid": "c9d4f76a-74d8-5ad6-84d4-b82b5bb70324",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Polished Pellets"
      }, {
        "uuid": "ab9e64a6-2df9-5f53-a5d0-682a1acccf4d",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Processed Samples"
      }, {
        "uuid": "d242a90a-073b-5e07-8d47-89659d3c2756",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Prospects"
      }, {
        "uuid": "1e79c930-f66c-5ad3-b32e-5a464731b01f",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Raster Images"
      }, {
        "uuid": "268d1a23-fd64-5324-bc92-b8d31a59f996",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Sample Analyses"
      }, {
        "uuid": "4b5c5cb0-1bdc-5a6e-b891-8c414648988f",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Sites"
      }, {
        "uuid": "71f5a885-3e6b-5954-a34f-8838ac7eee69",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Tapes"
      }, {
        "uuid": "2e43c21e-8cc7-5437-81de-d3148a16a92d",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Tubes"
      }, {
        "uuid": "6c05603d-035e-5e9d-b49c-b42b57b9409e",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Volumes"
      }, {
        "uuid": "a85e5ef2-3d45-51dd-abdc-56d09c482ca9",
        "broader": "aaaf25ad-9f8f-553a-935a-3b2a6c8d91c7",
        "label": "Well Logs"
      }]
    }, {
      "uuid": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
      "broader": "5c050c63-147f-5196-92d8-705d776e4153",
      "label": "userGroup",
      "definition": "",
      "children": [{
        "uuid": "7a979554-b3b7-53c2-bcf4-ccb27abf1984",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "General Public"
      }, {
        "uuid": "b4859552-6417-5964-a2a8-d4d9868b118d",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "K-12"
      }, {
        "uuid": "74a14e82-19eb-5008-b6f9-6b244b67d9d6",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "Other Government Agencies"
      }, {
        "uuid": "7516f8cf-15e6-55d3-ac28-bb8cf31bf76b",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "Private Sector"
      }, {
        "uuid": "bfbb2d41-4033-547d-87e4-087de76f7ed4",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "Professional Researchers"
      }, {
        "uuid": "d3faae66-cd16-52b0-b4fe-bdbbac0f458d",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "Regulatory Agencies"
      }, {
        "uuid": "85f6d217-84cf-5853-8185-a0a208050d9b",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "Universities"
      }, {
        "uuid": "c3b5c78d-31c5-5f27-aba0-6219ff0e08f1",
        "broader": "fe4ca2cb-7411-57e9-b610-1fcaaac414a5",
        "label": "University Students"
      }]
    }]
  }, {
    "uuid": "0c0497f6-c17e-57ad-befe-c1c39634344c",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "Collection health",
    "definition": "Code lists for collection health indicators adopted from Favret and others, 2008, http://favret.aphidnet.org/pubs/Favret_et-al_2008b.pdf",
    "children": [{
      "uuid": "7b983864-d492-57b3-9290-b9db7aeb9020",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Arrangement Level",
      "definition": "",
      "children": [{
        "uuid": "cd535704-67fb-5d91-858c-200615c815bc",
        "broader": "7b983864-d492-57b3-9290-b9db7aeb9020",
        "label": "Level_1",
        "definition": "Mixed taxa stored in the same vial, jar, unit tray, slide, etc. Annelid slides made from same collection are in different boxes."
      }, {
        "uuid": "88a7a195-31cf-57f6-857f-b377899eb974",
        "broader": "7b983864-d492-57b3-9290-b9db7aeb9020",
        "label": "Level_2",
        "definition": "Specimens crowded. Species sharing trays, or taxa scattered in two or more places. Arrangement is only at a higher taxonomic level. More than one annelid sample for collection site is stored in the same box."
      }, {
        "uuid": "ecd5acd0-4384-59d7-a8f5-98006c8317bf",
        "broader": "7b983864-d492-57b3-9290-b9db7aeb9020",
        "label": "Level_3",
        "definition": "Specimens arranged alphabetically by family, genus, and species, or, if arranged phylogenetically, with an alphabetical cross-referenced list. Annelid slides arranged in boxes according to collection event and/or locality."
      }, {
        "uuid": "37d2be88-b6db-5ef3-8f18-5e768514fd8e",
        "broader": "7b983864-d492-57b3-9290-b9db7aeb9020",
        "label": "Level_4",
        "definition": "Specimens arranged geographically within a taxon, or arranged numerically by catalog number if specimens have been databased."
      }]
    }, {
      "uuid": "1ccbbc27-f2f6-54a8-b3bd-2ac8567eb7f6",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Computerization Level",
      "definition": "",
      "children": [{
        "uuid": "7ad9b411-bdaf-507f-9499-52430b1243c1",
        "broader": "1ccbbc27-f2f6-54a8-b3bd-2ac8567eb7f6",
        "label": "Level_1",
        "definition": "Level 1 is not defined."
      }, {
        "uuid": "3cbc88ed-fee5-5c40-a5a2-2df7cfd572f1",
        "broader": "1ccbbc27-f2f6-54a8-b3bd-2ac8567eb7f6",
        "label": "Level_2",
        "definition": "No computerization at all."
      }, {
        "uuid": "0bfaca80-e623-5c66-89ba-1f658b6155d4",
        "broader": "1ccbbc27-f2f6-54a8-b3bd-2ac8567eb7f6",
        "label": "Level_3",
        "definition": "All [herbarium, mollusk, and vertebrate] specimens databased. Taxonomic information of other groups electronically inventoried, but specimens themselves not yet databased and assigned catalog numbers."
      }, {
        "uuid": "a4a341d0-bd89-5e8d-b888-369a6e921785",
        "broader": "1ccbbc27-f2f6-54a8-b3bd-2ac8567eb7f6",
        "label": "Level_4",
        "definition": "All localities geo-referenced and stored electronically. Invertebrates databased at the level of storage unit (pin, vial, jar, slide)."
      }]
    }, {
      "uuid": "1b88f42c-2ccf-5bcf-bf0a-aafcb9ada3fd",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Condition Of Labels",
      "definition": "",
      "children": [{
        "uuid": "128dcaee-d95b-526c-a771-bf8a90935ef4",
        "broader": "1b88f42c-2ccf-5bcf-bf0a-aafcb9ada3fd",
        "label": "Level_1",
        "definition": "Labels are faded to illegible, crumbling, or missing. Labels have become detached from the specimen."
      }, {
        "uuid": "7c4330c5-f67e-5849-a2ec-24baf7acca47",
        "broader": "1b88f42c-2ccf-5bcf-bf0a-aafcb9ada3fd",
        "label": "Level_2",
        "definition": "Labels are partially faded, laser-printed in fluid or in pencil, or on non-archival paper"
      }, {
        "uuid": "24439569-e281-5b5f-bc69-7b42965fd7e6",
        "broader": "1b88f42c-2ccf-5bcf-bf0a-aafcb9ada3fd",
        "label": "Level_3",
        "definition": "Labels are readily legible, printed with non-bleeding (if in fluid) archival paper and ink."
      }]
    }, {
      "uuid": "5c4be9f3-83f7-5f5c-9a1b-c57e8361723e",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Conservation Status (dry)",
      "definition": "",
      "children": [{
        "uuid": "d7335d61-6354-574d-afd9-54b63a0a43e4",
        "broader": "5c4be9f3-83f7-5f5c-9a1b-c57e8361723e",
        "label": "Level_1",
        "definition": "Shells have Byne’s disease. Specimens (of any kind) have signs of pest infestation. Insect specimens have fallen off of pin. Specimens are damaged to the point of being unusable"
      }, {
        "uuid": "d91e7ad6-d214-5917-9e1f-430476e2720c",
        "broader": "5c4be9f3-83f7-5f5c-9a1b-c57e8361723e",
        "label": "Level_2",
        "definition": "Specimens are damaged: broken into multiple pieces, with past pest damage, loose teeth or bones. Insect pins are broken or significantly bent."
      }, {
        "uuid": "11dcd74a-dc44-55b2-95af-d153d2e8812f",
        "broader": "5c4be9f3-83f7-5f5c-9a1b-c57e8361723e",
        "label": "Level_3",
        "definition": "All specimens are intact and stable."
      }]
    }, {
      "uuid": "9bd350d7-0ce7-5268-a26a-e02b90d381c4",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Conservation Status (fluid)",
      "definition": "",
      "children": [{
        "uuid": "9221b2f8-9b42-56d3-9c9b-45246429486c",
        "broader": "9bd350d7-0ce7-5268-a26a-e02b90d381c4",
        "label": "Level_1",
        "definition": "Specimens are desiccated. Fluid does not completely cover specimen(s). Alcohol is opaque."
      }, {
        "uuid": "5f87166d-d430-52de-8682-1d13d88d397e",
        "broader": "9bd350d7-0ce7-5268-a26a-e02b90d381c4",
        "label": "Level_2",
        "definition": "Fluid level is low, but completely covers specimens. Alcohol is dark"
      }, {
        "uuid": "4ba4f0b4-4738-55ad-abeb-a0dc4779bf67",
        "broader": "9bd350d7-0ce7-5268-a26a-e02b90d381c4",
        "label": "Level_3",
        "definition": "Fluid is topped-off and relatively clear"
      }]
    }, {
      "uuid": "b1213a23-86b5-5f22-b4b2-54187d8346b1",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Conservation Status (slide)",
      "definition": "",
      "children": [{
        "uuid": "8128bbf7-0875-55c0-9045-584301519938",
        "broader": "b1213a23-86b5-5f22-b4b2-54187d8346b1",
        "label": "Level_1",
        "definition": "Slide or cover slip is broken. Mounting medium is crystallized, running, or has receded up to specimen."
      }, {
        "uuid": "22ef6347-5edb-516a-b6cb-aea5fa113489",
        "broader": "b1213a23-86b5-5f22-b4b2-54187d8346b1",
        "label": "Level_2",
        "definition": "Aqueous mounting medium is not sealed (ringed) under cover slip. Mounting medium has receded. Cover slip or slide is cracked."
      }, {
        "uuid": "b0462053-f134-5543-ad2e-bde654db7b5a",
        "broader": "b1213a23-86b5-5f22-b4b2-54187d8346b1",
        "label": "Level_3",
        "definition": "Slide in good condition. Mounted in Canada balsam or cover slip has been sealed (ringed)."
      }]
    }, {
      "uuid": "913a430a-aaae-59a7-bc25-7386c60ca4bf",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Container Condition (dry)",
      "definition": "",
      "children": [{
        "uuid": "42300dc0-2b86-5d73-9d35-ea00df2b8ecd",
        "broader": "913a430a-aaae-59a7-bc25-7386c60ca4bf",
        "label": "Level_1",
        "definition": "Specimens in old cardboard boxes, cigar boxes, pill boxes, or paper bags. Specimens not stored in unit trays. Plants mounted on cardboard with rubber cement"
      }, {
        "uuid": "1f593d5a-4a85-52d7-a4c6-372abad63fc4",
        "broader": "913a430a-aaae-59a7-bc25-7386c60ca4bf",
        "label": "Level_2",
        "definition": "Specimens stored in new cardboard boxes or zip-lock bags. Vertebrate trays are unlined. Skulls or skeletal material are in substandard containers. Insects pinned in hard-bottom unit trays. Plants pressed in newspaper. Fungi kept in packets when they should be in boxes, or glued to paper in the packets."
      }, {
        "uuid": "b634529d-bef6-5ac6-b5ea-82daf65fd3c7",
        "broader": "913a430a-aaae-59a7-bc25-7386c60ca4bf",
        "label": "Level_3",
        "definition": "Unit trays are archival. Insects pinned in foam-bottom trays. Vertebrate trays are lined with acid-free paper. Plants and fungi are in/on acid paper/packet/box withElmer’s or other non-archival glue, or lacking fragment folders."
      }, {
        "uuid": "dbdf0b0f-16f3-5bc8-a7d7-6f9ee2668adb",
        "broader": "913a430a-aaae-59a7-bc25-7386c60ca4bf",
        "label": "Level_4",
        "definition": " Plants and fungi in/on acid free paper/packet/box, fixed with acid free glue, and with fragment folders present."
      }]
    }, {
      "uuid": "21c1bd37-8c86-5e17-9988-f62ed4ed2b7f",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Container Condition (fluid)",
      "definition": "",
      "children": [{
        "uuid": "0d786105-c1f3-5a68-85d9-52c75819d0c0",
        "broader": "21c1bd37-8c86-5e17-9988-f62ed4ed2b7f",
        "label": "Level_1",
        "definition": "Vial stoppers are cracked, broken, swollen, or disintegrating. Stoppers are made of cork. Vials are loose on shelf, or banded together, and not in vial rack. Jar lids are old and rusted (if metal), or are BakeliteH lids (which crack easily). Jar seals are missing, cracked, or shrinking. Five-gallon buckets have poor seals or loose lids."
      }, {
        "uuid": "e3baa7c4-7528-50b4-a939-5899e400cc1d",
        "broader": "21c1bd37-8c86-5e17-9988-f62ed4ed2b7f",
        "label": "Level_2",
        "definition": "Hardened but intact vial stoppers. Vials aligned in wire-sided racks. Jar lids are metal or with non-polyethylene jar seals. Large specimens are stored in 5-gallon buckets."
      }, {
        "uuid": "4cdd15ca-001b-56d0-916a-c5c794eeef4a",
        "broader": "21c1bd37-8c86-5e17-9988-f62ed4ed2b7f",
        "label": "Level_3",
        "definition": "Vials have good quality stoppers. Vial racks are solid with no risk of vial loss. Jars are bail-topped with polyethylene gaskets, or have polypropylene lids. Large specimens are stored in archival barrels with clamping sealing mechanisms."
      }]
    }, {
      "uuid": "49f89480-d1ce-5cae-ad69-726158b3ded7",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Container Condition (slide)",
      "definition": "",
      "children": [{
        "uuid": "47e450dc-dd92-5d75-9932-06ae18a3c0db",
        "broader": "49f89480-d1ce-5cae-ad69-726158b3ded7",
        "label": "Level_1",
        "definition": "Slides not in slide box or tray. Slide box broken."
      }, {
        "uuid": "c35c4e23-d278-55a0-a155-5b588dddb08a",
        "broader": "49f89480-d1ce-5cae-ad69-726158b3ded7",
        "label": "Level_2",
        "definition": "Slide box not standard 100-slide box. Slides in trays are not protected by envelope or thick labels, which prevent the crushing of the cover slip on one slide by the adjoining slide."
      }, {
        "uuid": "91c9460d-b4cb-532f-803d-3b9c7524c5f2",
        "broader": "49f89480-d1ce-5cae-ad69-726158b3ded7",
        "label": "Level_3",
        "definition": "Good slide boxes or trays with rust-free hinges and substantial closure clasps."
      }, {
        "uuid": "74c61908-67a3-5cbf-8739-f8210e35ae47",
        "broader": "49f89480-d1ce-5cae-ad69-726158b3ded7",
        "label": "Level_4",
        "definition": "Tray slides stored flat."
      }]
    }, {
      "uuid": "548b2031-66de-5b63-b9a9-ecd16679451e",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Data Quality",
      "definition": "",
      "children": [{
        "uuid": "1b68f5d9-37b2-550d-9316-acd4a813a11f",
        "broader": "548b2031-66de-5b63-b9a9-ecd16679451e",
        "label": "Level_1",
        "definition": "Data are in codes or missing entirely."
      }, {
        "uuid": "2d20d42e-c3e6-5fca-9d97-3fe14fbf0541",
        "broader": "548b2031-66de-5b63-b9a9-ecd16679451e",
        "label": "Level_2",
        "definition": "Some data are missing but can be inferred. Specimen containers (vials, jars, or slides) lack determination labels."
      }, {
        "uuid": "686470ed-079e-5eeb-8465-90a1d307765e",
        "broader": "548b2031-66de-5b63-b9a9-ecd16679451e",
        "label": "Level_3",
        "definition": "All data fields are complete for all groups except pinned insects may have determination labels missing."
      }, {
        "uuid": "3d1ad90a-751f-5140-a95c-2b48b5fcead8",
        "broader": "548b2031-66de-5b63-b9a9-ecd16679451e",
        "label": "Level_4",
        "definition": "Localities fully geo-referenced. All species-level insect pins have determination labels."
      }]
    }, {
      "uuid": "db38096e-46b6-5c30-ab97-37507480e55c",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Identification Level",
      "definition": "",
      "children": [{
        "uuid": "377a120c-1c0e-53ae-9f31-29a337a4838e",
        "broader": "db38096e-46b6-5c30-ab97-37507480e55c",
        "label": "Level_1",
        "definition": "All specimens undetermined and major groups mixed."
      }, {
        "uuid": "150cd13d-a11d-534b-b62d-e63dd0277a12",
        "broader": "db38096e-46b6-5c30-ab97-37507480e55c",
        "label": "Level_2",
        "definition": "Specimens determined to order or family (depending of the size of the group). Not all annelid slides in a slide box fully determined. All other groups determined to the family or genus level."
      }, {
        "uuid": "25fc854c-79be-5249-99e5-24f86fd40bdb",
        "broader": "db38096e-46b6-5c30-ab97-37507480e55c",
        "label": "Level_3",
        "definition": "Specimens determined to the genus or family level. All other groups determined to species."
      }, {
        "uuid": "09575f89-59d9-53c9-8d73-0486b0a2a112",
        "broader": "db38096e-46b6-5c30-ab97-37507480e55c",
        "label": "Level_4",
        "definition": "Specimens determined to the species level. All other groups determined to species or (often) subspecies and verified by a specialist."
      }]
    }, {
      "uuid": "b27abedf-1e87-5240-8757-ff72d0c227d6",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Processing State (dry)",
      "definition": "",
      "children": [{
        "uuid": "d9dba53e-400e-5edb-9ba1-6712ae46a195",
        "broader": "b27abedf-1e87-5240-8757-ff72d0c227d6",
        "label": "Level_1",
        "definition": "Bulk insect specimens papered, or in jars, boxes, or cotton. Unsorted botanical specimens in newspaper or paper bags (backlog)."
      }, {
        "uuid": "14e2753d-78cd-5a91-b2ae-19a724cee79f",
        "broader": "b27abedf-1e87-5240-8757-ff72d0c227d6",
        "label": "Level_2",
        "definition": "Insect specimens pinned, but improperly mounted on pin or point. Mollusk and vertebrate samples not cleaned, cataloged, or numbered. Botanical material mounted to herbarium sheets with labels, but without accession numbers."
      }, {
        "uuid": "a985d0f4-3a00-530f-bea8-f1f83d8681b7",
        "broader": "b27abedf-1e87-5240-8757-ff72d0c227d6",
        "label": "Level_3",
        "definition": "Insects properly pinned, pointed, or enveloped. Vertebrate and mollusk specimens cleaned, cataloged and numbered. Herbarium sheets in folders with all labels and accession numbers."
      }]
    }, {
      "uuid": "6e24f1d4-ec95-5987-b505-944314c9d1da",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Processing State (fluid)",
      "definition": "",
      "children": [{
        "uuid": "b0ca35e7-9419-53f9-9ce8-e543d2ebe8f4",
        "broader": "6e24f1d4-ec95-5987-b505-944314c9d1da",
        "label": "Level_1",
        "definition": "Specimens stored in bulk and unprocessed. Unsorted samples stored in Whirlpac bags, Nalgene or other bottles, jars, or in the freezer."
      }, {
        "uuid": "3a34bf79-bb72-52ce-8de5-f2a7bb9693a6",
        "broader": "6e24f1d4-ec95-5987-b505-944314c9d1da",
        "label": "Level_2",
        "definition": "Mixed field sample, rinsed, stored in clean alcohol, in standard quality storage containers."
      }, {
        "uuid": "878336c0-90ac-51cd-8798-9c7ea0bb62af",
        "broader": "6e24f1d4-ec95-5987-b505-944314c9d1da",
        "label": "Level_3",
        "definition": "Vertebrate samples sorted and tagged. Mollusk shells and soft body tissue separated. Insect specimens stored in proper vials with cotton and micro-vials, if necessary."
      }]
    }, {
      "uuid": "9dde5ff9-9333-5c21-bbc9-0e92c4cc5aaa",
      "broader": "0c0497f6-c17e-57ad-befe-c1c39634344c",
      "label": "Favret Processing State (slide)",
      "definition": "",
      "children": [{
        "uuid": "bbf29767-8f07-5c6c-8971-d259239a4105",
        "broader": "9dde5ff9-9333-5c21-bbc9-0e92c4cc5aaa",
        "label": "Level_1",
        "definition": "As soon as specimens are slide-mounted they are already semi-processed, so there is no Level 1."
      }, {
        "uuid": "40ea93e9-f5f4-5ff6-bec8-a53aa32b9ce8",
        "broader": "9dde5ff9-9333-5c21-bbc9-0e92c4cc5aaa",
        "label": "Level_2",
        "definition": "Specimens were not cleared prior to mounting, or were improperly oriented on slide."
      }, {
        "uuid": "c0933406-ebcb-521f-9d30-0960925df739",
        "broader": "9dde5ff9-9333-5c21-bbc9-0e92c4cc5aaa",
        "label": "Level_3",
        "definition": "Specimens properly cleared and oriented on slide."
      }]
    }]
  }, {
    "uuid": "8852b546-ab74-5569-81f7-9aa9aabce5c7",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "Collection Theme",
    "definition": "Type of geoscientific collection.",
    "children": [{
      "uuid": "13859768-c7c3-505b-ac83-6d696fb7eb36",
      "broader": "8852b546-ab74-5569-81f7-9aa9aabce5c7",
      "label": "Biological Collection",
      "definition": "Biological sample collection",
      "children": []
    }, {
      "uuid": "1969febc-71be-5120-9bdd-667281364402",
      "broader": "8852b546-ab74-5569-81f7-9aa9aabce5c7",
      "label": "Geological Collection",
      "definition": "Geological sample collection",
      "children": []
    }, {
      "uuid": "9bf421cd-9f84-582a-9402-0e37f5c51a03",
      "broader": "8852b546-ab74-5569-81f7-9aa9aabce5c7",
      "label": "Paleontological Collection",
      "definition": "Paleontological sample collection",
      "children": []
    }, {
      "uuid": "c285bb24-a004-5638-ba12-b9dc4e88a096",
      "broader": "8852b546-ab74-5569-81f7-9aa9aabce5c7",
      "label": "Water Collection",
      "definition": "Water sample collection",
      "children": []
    }]
  }, {
    "uuid": "71a99733-f4fd-530d-ab35-4cad6a9df747",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "DataCite",
    "definition": "",
    "children": [{
      "uuid": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
      "broader": "71a99733-f4fd-530d-ab35-4cad6a9df747",
      "label": "Datacite Contributor Type",
      "definition": "",
      "children": [{
        "uuid": "d03323ff-942c-5a98-a54f-db20ecbccfff",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "ContactPerson",
        "definition": "The contributor is a ContactPerson"
      }, {
        "uuid": "858c6273-b013-5366-969a-917a3e120dee",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "DataCollector",
        "definition": "The contributor is a DataCollector"
      }, {
        "uuid": "3c90fdf3-365e-5b89-ac0c-e81567133a18",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "DataCurator",
        "definition": "The contributor is a DataCurator"
      }, {
        "uuid": "a86c6e4a-382f-59a2-b29f-365aa5e444a6",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "DataManager",
        "definition": "The contributor is a DataManager"
      }, {
        "uuid": "7b56ecb8-2e21-5cbc-83cc-f79636fa586b",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Distributor",
        "definition": "The contributor is a Distributor"
      }, {
        "uuid": "1503aa3f-cfc6-59cc-953b-c47772e47d31",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Editor",
        "definition": "The contributor is a Editor"
      }, {
        "uuid": "a1de1adf-8646-533e-a61d-997fc7b7652c",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "HostingInstitution",
        "definition": "The contributor is a HostingInstitution"
      }, {
        "uuid": "946efd95-5d17-5d5d-ad5b-ee18be9b56a7",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Other",
        "definition": "The contributor is a Other"
      }, {
        "uuid": "5f3cbba6-eb80-587c-b74f-4e65f4bd9af5",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Producer",
        "definition": "The contributor is a Producer"
      }, {
        "uuid": "d6f5af7f-19b6-52bc-b4bf-0826cd61a72b",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "ProjectLeader",
        "definition": "The contributor is a ProjectLeader"
      }, {
        "uuid": "3c4a58b4-3adb-557c-80c9-c7c3d9b476bd",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "ProjectManager",
        "definition": "The contributor is a ProjectManager"
      }, {
        "uuid": "6807e2ba-e9b0-59b8-916b-7d9095a928be",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "ProjectMember",
        "definition": "The contributor is a ProjectMember"
      }, {
        "uuid": "1107a16d-dc37-5526-8baf-c3097c44ca74",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "RegistrationAgency",
        "definition": "The contributor is a RegistrationAgency"
      }, {
        "uuid": "6bc94e14-eea0-5616-b59f-0f967791d969",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "RegistrationAuthority",
        "definition": "The contributor is a RegistrationAuthority"
      }, {
        "uuid": "0179abf3-147b-5182-ad33-1a7ec9468720",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "RelatedPerson",
        "definition": "The contributor is a RelatedPerson"
      }, {
        "uuid": "18e5e7f0-445b-5cc8-beba-6a9f9c968672",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Researcher",
        "definition": "The contributor is a Researcher"
      }, {
        "uuid": "de91e8a7-99c3-57e7-8e69-9670535af239",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "ResearchGroup",
        "definition": "The contributor is a ResearchGroup"
      }, {
        "uuid": "70e8e8cd-a68a-5b0f-b46b-65634dfbf78f",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "RightsHolder",
        "definition": "The contributor is a RightsHolder"
      }, {
        "uuid": "728cb232-7038-51a9-8a14-7743037179c6",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Sponsor",
        "definition": "The contributor is a Sponsor"
      }, {
        "uuid": "1fef60ee-c784-5a1c-ac68-8a454d9fc2f0",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "Supervisor",
        "definition": "The contributor is a Supervisor"
      }, {
        "uuid": "8844c101-8fcb-52ce-833f-526fb6656a7a",
        "broader": "2edd5aab-a8a1-5944-848f-16cccf88a85c",
        "label": "WorkPackageLeader",
        "definition": "The contributor is a WorkPackageLeader"
      }]
    }, {
      "uuid": "168447c0-34f1-59b4-8619-22414b21b574",
      "broader": "71a99733-f4fd-530d-ab35-4cad6a9df747",
      "label": "Datacite Description Type",
      "definition": "",
      "children": [{
        "uuid": "f553e5de-81ed-55ba-9e92-17301dee3cab",
        "broader": "168447c0-34f1-59b4-8619-22414b21b574",
        "label": "Abstract",
        "definition": "The description is an Abstract"
      }, {
        "uuid": "0a5b33b5-9b95-565b-b5a6-283a421362c5",
        "broader": "168447c0-34f1-59b4-8619-22414b21b574",
        "label": "Methods",
        "definition": "The description describes Methods"
      }, {
        "uuid": "8065a0b9-a9b0-52d6-8cb6-a727f98a53ee",
        "broader": "168447c0-34f1-59b4-8619-22414b21b574",
        "label": "Other",
        "definition": "The description is not a standard type"
      }, {
        "uuid": "a9c98903-2de3-57fc-ba4c-f25966804420",
        "broader": "168447c0-34f1-59b4-8619-22414b21b574",
        "label": "TableOfContents",
        "definition": "The description is a TableOfContents"
      }, {
        "uuid": "58a25dcd-4df9-5157-bc70-ec401580d0c8",
        "broader": "168447c0-34f1-59b4-8619-22414b21b574",
        "label": "TechnicalInfo",
        "definition": "The description is TechnicalInfo"
      }]
    }, {
      "uuid": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
      "broader": "71a99733-f4fd-530d-ab35-4cad6a9df747",
      "label": "Datacite Relation Type",
      "definition": "",
      "children": [{
        "uuid": "72cea9c0-23ad-5c2d-91a2-954170aecc02",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Cites",
        "definition": "The resource being registered Cites the related resource."
      }, {
        "uuid": "d7fbcad1-b786-5509-9c7c-b5ec9b233031",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Compiles",
        "definition": "The resource being registered Compiles the related resource."
      }, {
        "uuid": "fa2b50f3-37f9-562e-a671-c7414c77f414",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Continues",
        "definition": "The resource being registered Continues the related resource."
      }, {
        "uuid": "a42ec73c-e7af-5262-930e-916a2f9b74e1",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Describes",
        "definition": "The resource being registered Describes the related resource."
      }, {
        "uuid": "f7f12ca6-bb85-5ae4-8c1f-6fac932f737d",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Documents",
        "definition": "The resource being registered Documents the related resource."
      }, {
        "uuid": "853f1605-47e8-5119-a3e1-c7c818ef4177",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "HasMetadata",
        "definition": "The resource being registered HasMetadata for the related resource."
      }, {
        "uuid": "7723e4ac-bd6e-57d0-ae30-d90d564543a5",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "HasPart",
        "definition": "The resource being registered HasPart the related resource."
      }, {
        "uuid": "96d9bdb3-f0c5-5e0c-a9eb-5e52fcd4e40f",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "HasVersion",
        "definition": "The resource being registered HasVersion the related resource."
      }, {
        "uuid": "c08493ee-4fb6-5741-bb53-3114657f68a0",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsCitedBy",
        "definition": "The resource being registered IsCitedBy the related resource."
      }, {
        "uuid": "94e657b9-0670-52cd-bdfe-fa2487190fc3",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsCompiledBy",
        "definition": "The resource being registered IsCompiledBy the related resource."
      }, {
        "uuid": "e72f8b25-23f9-5103-9f3e-d897ff906e6c",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsContinuedBy",
        "definition": "The resource being registered IsContinuedBy the related resource."
      }, {
        "uuid": "5a91c1ce-6184-596f-9dee-beb0a7a7e2cf",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsDerivedFrom",
        "definition": "The resource being registered IsDerivedFrom the related resource."
      }, {
        "uuid": "8d9549f8-8ef3-58a8-9d6b-8d454093b490",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsDescribedBy",
        "definition": "The resource being registered IsDescribedBy the related resource."
      }, {
        "uuid": "bda6cd23-6344-5f0f-8665-264eb0a69c05",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsDocumentedBy",
        "definition": "The resource being registered IsDocumentedBy the related resource."
      }, {
        "uuid": "087674d6-15df-5c51-b2a1-6b1ac742a8a2",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsIdenticalTo",
        "definition": "The resource being registered IsIdenticalTo the related resource."
      }, {
        "uuid": "587db141-e552-5f45-9e01-1d5f67e9f0a7",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsMetadataFor",
        "definition": "The resource being registered IsMetadataFor the related resource."
      }, {
        "uuid": "5869f363-386e-5023-903f-527c3ce2795e",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsNewVersionOf",
        "definition": "The resource being registered IsNewVersionOf the related resource."
      }, {
        "uuid": "294f93c3-96cd-5e8c-8b34-885ca3e4ed7d",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsObsoletedBy",
        "definition": "The resource being registered IsObsoletedBy the related resource."
      }, {
        "uuid": "a18cc35b-a586-5148-86b6-c4a0196c2d18",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsOriginalFormOf",
        "definition": "The resource being registered IsOriginalFormOf the related resource."
      }, {
        "uuid": "5ec91989-601a-5685-8fdf-50f32d67a1a2",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsPartOf",
        "definition": "The resource being registered IsPartOf the related resource."
      }, {
        "uuid": "99d7500b-6630-5783-97cd-f64fee0ca9d2",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsPreviousVersionOf",
        "definition": "The resource being registered IsPreviousVersionOf the related resource."
      }, {
        "uuid": "d59d4426-4568-5093-bcd8-eab0ccd3ad74",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsPublishedIn",
        "definition": "The resource being registered IsPublishedIn the related resource."
      }, {
        "uuid": "b6827bda-c928-59a6-9770-4ef9bb500b9a",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsReferencedBy",
        "definition": "The resource being registered IsReferencedBy the related resource."
      }, {
        "uuid": "e3af8f02-da69-507a-be64-667aaa23780f",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsRequiredBy",
        "definition": "The resource being registered IsRequiredBy the related resource."
      }, {
        "uuid": "404ba100-ddda-5a2a-8cc0-7a6165ddf2e3",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsReviewedBy",
        "definition": "The resource being registered IsReviewedBy the related resource."
      }, {
        "uuid": "c038a4fc-439e-5afe-9101-9d00f01779ff",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsSourceOf",
        "definition": "The resource being registered IsSourceOf the related resource."
      }, {
        "uuid": "04fa6348-62db-5186-ba71-8eeb8fd48567",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsSupplementedBy",
        "definition": "The resource being registered IsSupplementedBy the related resource."
      }, {
        "uuid": "bbb72352-d69d-55ca-8b23-645b6586c1c6",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsSupplementTo",
        "definition": "The resource being registered IsSupplementTo the related resource."
      }, {
        "uuid": "3d8a6913-28b3-5846-b41b-430dfd622745",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsVariantFormOf",
        "definition": "The resource being registered IsVariantFormOf the related resource."
      }, {
        "uuid": "aa6de5b4-6e2b-5e65-94ca-3f9f6ad1687f",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "IsVersionOf",
        "definition": "The resource being registered IsVersionOf the related resource."
      }, {
        "uuid": "ce6f111d-248f-5fde-949a-f58b4d2c3a2c",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Obsoletes",
        "definition": "The resource being registered Obsoletes the related resource."
      }, {
        "uuid": "0683db13-5f73-5713-a042-32d3c4764c66",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "References",
        "definition": "The resource being registered References the related resource."
      }, {
        "uuid": "825fc668-bf04-5204-9050-5096d1e981e2",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Requires",
        "definition": "The resource being registered Requires the related resource."
      }, {
        "uuid": "5b869899-168a-5197-815a-f52a04e81291",
        "broader": "ec1be3b3-bec2-58d8-8483-7d3a9a2ed4da",
        "label": "Reviews",
        "definition": "The resource being registered Reviews the related resource."
      }]
    }, {
      "uuid": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
      "broader": "71a99733-f4fd-530d-ab35-4cad6a9df747",
      "label": "Datacite Resource Type General",
      "definition": "",
      "children": [{
        "uuid": "25f27e82-e3fb-5178-b427-416e43e598aa",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Audiovisual",
        "definition": "The resource is an Audiovisual "
      }, {
        "uuid": "55a71012-5661-5434-bb4a-2827bf1633c0",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Book",
        "definition": "The resource is a Book"
      }, {
        "uuid": "e7c36782-11be-5741-85a7-7bf09a11b04f",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "BookChapter",
        "definition": "The resource is a Book Chapter"
      }, {
        "uuid": "41bab223-bf27-5ba9-8d8c-fe3fed70d054",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Collection",
        "definition": "The resource is a Collection"
      }, {
        "uuid": "8f7e111c-273b-5635-b488-59da94a0f0b5",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "ComputationalNotebook",
        "definition": "The resource is a Computational Notebook"
      }, {
        "uuid": "81358d21-4836-5b9a-b345-10b2aca2d0ca",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "ConferencePaper",
        "definition": "The resource is a Conference Paper"
      }, {
        "uuid": "3811c6dc-0cc3-5d31-b94b-bf2a48ded722",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "ConferenceProceeding",
        "definition": "The resource is a Conference Proceeding"
      }, {
        "uuid": "8b760751-d058-53b5-bb58-715b6356aeb1",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "DataPaper",
        "definition": "The resource is a Data Paper"
      }, {
        "uuid": "232a55c5-83ff-5482-a0a3-7fa16b7c8514",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Dataset",
        "definition": "The resource is a Dataset"
      }, {
        "uuid": "41aab432-05f2-57a8-8ed6-27df3e6847b9",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Dissertation",
        "definition": "The resource is a Dissertation"
      }, {
        "uuid": "f0a81014-7219-52c3-bba8-2ebadbb68b2f",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Event",
        "definition": "The resource is an Event"
      }, {
        "uuid": "98606a45-4197-5e7f-9fc4-784ac5e3bede",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Image",
        "definition": "The resource is an Image"
      }, {
        "uuid": "288c421b-5e13-5f64-b99b-4c0cb6917eeb",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "InteractiveResource",
        "definition": "The resource is an Interactive Resource"
      }, {
        "uuid": "7528dc52-9c13-5061-b0ba-96eb865e4cf8",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Journal",
        "definition": "The resource is a Journal"
      }, {
        "uuid": "1d3a3687-1c97-5384-b520-f88b3630c878",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "JournalArticle",
        "definition": "The resource is a Journal Article"
      }, {
        "uuid": "6fcb4047-7496-5444-99a6-1902d6f7db08",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Model",
        "definition": "The resource is a Model"
      }, {
        "uuid": "7505ad7e-2218-57fa-b525-9a61a2e7be97",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Other",
        "definition": "The resource type is not included in this list"
      }, {
        "uuid": "54527dc4-314e-5f28-aa09-41bffe0f5393",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "OutputManagementPlan",
        "definition": "The resource is an Output Management Plan"
      }, {
        "uuid": "d036e216-b6f4-5794-9442-5312057addbe",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "PeerReview",
        "definition": "The resource is a PeerReview"
      }, {
        "uuid": "70577f97-f9c5-586c-8c7e-8d5d37314e0f",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "PhysicalObject",
        "definition": "The resource is a Physical Object"
      }, {
        "uuid": "3e96ee68-315d-5000-97c6-666dd8d4c8a9",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Preprint",
        "definition": "The resource is a Preprint"
      }, {
        "uuid": "25d48804-5379-5e08-b140-47759956117e",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Report",
        "definition": "The resource is a Report"
      }, {
        "uuid": "a6ce6a6f-99a9-59b7-84da-f598f5695c86",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Service",
        "definition": "The resource is a Service"
      }, {
        "uuid": "50a19fb1-e29e-5de1-a7b8-da669007fab6",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Software",
        "definition": "The resource is Software"
      }, {
        "uuid": "dcd3540b-d854-5096-928b-81639fd71b6b",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Sound",
        "definition": "The resource is a Sound"
      }, {
        "uuid": "5eed60ad-2dda-514d-9d1d-052b74edba31",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Standard",
        "definition": "The resource is a Standard"
      }, {
        "uuid": "fe0be128-da9e-5f3f-8e96-7e0032fa749b",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Text",
        "definition": "The resource is Text"
      }, {
        "uuid": "42c821be-b77b-5606-b48b-6429e5c716ac",
        "broader": "dd030592-0be9-5022-ae8b-dc8fd3cdd697",
        "label": "Workflow",
        "definition": "The resource is a Workflow"
      }]
    }]
  }, {
    "uuid": "a8e481d9-403d-5a03-9b46-0a7a909aae84",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "Hazardous Materials",
    "definition": "",
    "children": [{
      "uuid": "e76e4902-93d0-5aac-9f2e-b770ac5d26e7",
      "broader": "a8e481d9-403d-5a03-9b46-0a7a909aae84",
      "label": "False",
      "definition": "Hazardous materials are not known to exist in collection."
    }, {
      "uuid": "f4d9b9d3-fb5b-5136-9088-9837f5ca50f3",
      "broader": "a8e481d9-403d-5a03-9b46-0a7a909aae84",
      "label": "True",
      "definition": "Hazardous materials are known to exist in collection."
    }]
  }, {
    "uuid": "bbccbffc-9289-55af-86f8-06c091670257",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "Known To Contain Types",
    "definition": "",
    "children": [{
      "uuid": "a27d4244-410e-5073-85f0-29b757dee774",
      "broader": "bbccbffc-9289-55af-86f8-06c091670257",
      "label": "False",
      "definition": "Type specimens are not known to exist in collection."
    }, {
      "uuid": "1aef3f91-10f0-51db-b190-3097294ccae3",
      "broader": "bbccbffc-9289-55af-86f8-06c091670257",
      "label": "True",
      "definition": "Type specimens are known to exist in collection."
    }]
  }, {
    "uuid": "5e42d141-0195-5780-93ff-d599a26a169c",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "nggdpp_collection_types",
    "definition": "Terms denoting the type/function of collection items in the National Digital Catalog",
    "children": [{
      "uuid": "3257855c-38e8-5315-a64a-0b7c45b64b65",
      "broader": "5e42d141-0195-5780-93ff-d599a26a169c",
      "label": "ndc_collection",
      "definition": "Item that represents a logical collection of physical data items managed by an organization contributing to the National Digital Catalog. These are core metadata items for which we expect to find full metadata describing the collection and a method of accessing the contents in the collection."
    }, {
      "uuid": "4caf3b75-0099-53ac-aeb5-6f204dda88f3",
      "broader": "5e42d141-0195-5780-93ff-d599a26a169c",
      "label": "ndc_folder",
      "definition": "Denotes a ScienceBase Item that functions as an organizational folder within the National Digital Catalog."
    }, {
      "uuid": "2f2a6948-1cff-54a4-9629-1b06092dee2c",
      "broader": "5e42d141-0195-5780-93ff-d599a26a169c",
      "label": "ndc_organization",
      "definition": "Item that represents a contributing organization. Used to set permissions in ScienceBase to allow management access by members of the organization."
    }]
  }, {
    "uuid": "178a5b9a-0eb0-5962-b60d-d6acce288618",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "NGGDPPCollectionTheme",
    "definition": "",
    "children": [{
      "uuid": "6c4e7413-f552-5761-bd2c-e871f6479487",
      "broader": "178a5b9a-0eb0-5962-b60d-d6acce288618",
      "label": "Biological Collection"
    }, {
      "uuid": "b78c116a-121c-55ab-9f0e-6b232a06a119",
      "broader": "178a5b9a-0eb0-5962-b60d-d6acce288618",
      "label": "Geological Collection"
    }, {
      "uuid": "3abd06f1-a7af-5eca-af31-a40c0fb0214c",
      "broader": "178a5b9a-0eb0-5962-b60d-d6acce288618",
      "label": "Paleontological Collection"
    }, {
      "uuid": "04bbefaf-a982-50c8-ab80-89b48d7a6a89",
      "broader": "178a5b9a-0eb0-5962-b60d-d6acce288618",
      "label": "Water Collection"
    }]
  }, {
    "uuid": "4c20b77f-39d4-553e-9715-464e5862a8d7",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "Observational Data Model (Version 2)",
    "definition": "Observation Data Model (ODM2) Vocabularies\nSee https://doi.org/10.1016/j.envsoft.2016.01.010 for paper on ODM2 and http://vocabulary.odm2.org/ for vocabularies.",
    "children": [{
      "uuid": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Actiontype",
      "definition": "",
      "children": [{
        "uuid": "c696ec98-bb61-5d5c-ab83-9c04d5a7ec7b",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "cruise",
        "definition": "A specialized form of an expedition action that involves an ocean-going vessel. Cruise actions are typically parents to other related Actions."
      }, {
        "uuid": "f5a95c37-ac75-5099-9328-64354231523c",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "dataRetrieval",
        "definition": "The act of retrieving data from a datalogger deployed at a monitoring site."
      }, {
        "uuid": "a1a882b6-f7cd-52a4-824d-84410ac20d88",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "derivation",
        "definition": "The act of creating results by deriving them from other results."
      }, {
        "uuid": "6e9b6f50-d7fc-5e04-86d5-1f6e6ec7460b",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "equipmentDeployment",
        "definition": "The act of placing equipment that will not make observations at or within a sampling feature. Actions involving the deployment of instruments should use the more specific term Instrument deployment."
      }, {
        "uuid": "7e40cedf-2e77-5c37-a43a-081c076f6cf1",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "equipmentMaintenance",
        "definition": "The act of performing regular or periodic upkeep or servicing of field or laboratory equipment. Maintenance may be performed in the field, in a laboratory, or at a factory maintenance center."
      }, {
        "uuid": "c8660f2f-5ea2-52ba-91ea-98ddcdc1bcdd",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "equipmentProgramming",
        "definition": "The act of creating or modifying the data collection program running on a datalogger or other equipment deployed at a monitoring site."
      }, {
        "uuid": "6439d0dd-1a5a-518f-8e76-c112f77db32a",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "equipmentRetrieval",
        "definition": "The act of recovering a piece of equipment that made no observations from a deployment at a sampling feature or other location. For instruments, the more specific term Instrument retrieval should be used."
      }, {
        "uuid": "e9dfe2a6-6d44-5b8f-b9f6-6084a4e47306",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "estimation",
        "definition": "The act of creating results by estimation or professional judgement."
      }, {
        "uuid": "124361c3-bf98-5378-b169-c17eae8ffc44",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "expedition",
        "definition": "A field visit action in which many sites are visited over a continguous period of time, often involving serveral investigators, and typically having a specific purpose.  Expedition actions are typically parents to other related Actions."
      }, {
        "uuid": "491a3f0a-9007-596f-8332-986300eb5251",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "fieldActivity",
        "definition": "A generic, non-specific action type performed in the field at or on a sampling feature."
      }, {
        "uuid": "1a1e5be0-d9ce-5320-82c5-473b5ceafaa8",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "genericNonObservation",
        "definition": "A generic, non-specific action type that does not produce a result."
      }, {
        "uuid": "b3266baa-ebd8-5bdc-8f8c-6a807217d966",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "instrumentCalibration",
        "definition": "The act of calibrating an instrument either in the field or in a laboratory. The instrument may be an in situ field sensor or a laboratory instrument.  An instrument is the subclass of equipment that is capable of making an observation to produce a result."
      }, {
        "uuid": "ad209b2b-15d4-5318-8cc7-49a812f78ed7",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "instrumentDeployment",
        "definition": "The act of deploying an in situ instrument or sensor that creates an observation result.  This term is a specific form of the Observation actions category of actions, which is the only category of actions that can produce observation results."
      }, {
        "uuid": "16405d72-cdb2-523f-a1e4-392faa7cf88a",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "instrumentRetrieval",
        "definition": "The act of recovering an in situ instrument (which made observations) from a sampling feature. This action ends an instrument deployment action."
      }, {
        "uuid": "c75bdebd-4c81-51e1-9ac5-36f279be3c39",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "observation",
        "definition": "The general act of making an observation. This term should be used when a Result is generated but the more specific terms of Instrument deployment or Specimen analysis are not applicable."
      }, {
        "uuid": "52ecd2d0-8534-5605-90e2-df83d075e056",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "simulation",
        "definition": "The act of calculating results through the use of a simulation model."
      }, {
        "uuid": "e78afec8-0445-5a29-84ac-7881fc00ebed",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "siteVisit",
        "definition": "A field visit action in which a single site is visited for one or more other possible actions (i.e., specimen collection, equipment maintenance, etc.).  Site visit actions are typically parents to other related actions."
      }, {
        "uuid": "fb05c596-1ebe-5a9b-ba3a-1fb52d7ec102",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "specimenAnalysis",
        "definition": "The analysis of a specimen ex situ using an instrument, typically in a laboratory, for the purpose of measuring properties of that specimen."
      }, {
        "uuid": "9f57b4b1-af33-54bb-a2c6-2d69fbabb2a6",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "specimenCollection",
        "definition": "The collection of a specimen in the field."
      }, {
        "uuid": "cb5bd1bf-afdf-58f5-81eb-332d636cb74d",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "specimenFractionation",
        "definition": "The process of separating a specimen into multiple different fractions or size classes."
      }, {
        "uuid": "896aaf4e-1953-5553-80ba-5af669912f9a",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "specimenPreparation",
        "definition": "The processing of a specimen collected in the field to produce a sample suitable for analysis using a particular analytical procedure."
      }, {
        "uuid": "b1d7a793-d1d2-515c-a965-00d472ce0690",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "specimenPreservation",
        "definition": "The act of preserving a specimen collected in the field to produce a sample suitable for analysis using a particular analytical procedure."
      }, {
        "uuid": "0147bf2f-b6a7-5b50-be3e-39b6fba53d66",
        "broader": "3a10ad06-f3ab-562e-aeec-e230063eb26a",
        "label": "submersibleLaunch",
        "definition": "The act of deploying a submersible from a vessel or ship."
      }]
    }, {
      "uuid": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Aggregationstatistic",
      "definition": "",
      "children": [{
        "uuid": "6e5599f9-f063-55b3-8f8c-f3129ed30b57",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "average",
        "definition": "The values represent the average over a time interval, such as daily mean discharge or daily mean temperature."
      }, {
        "uuid": "6c3ca45b-d193-5f5f-81e5-45967b21494e",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "bestEasySystematicEstimator",
        "definition": "Best Easy Systematic Estimator BES = (Q1 +2Q2 +Q3)/4. Q1, Q2, and Q3 are first, second, and third quartiles. See Woodcock, F. and Engel, C., 2005: Operational Consensus Forecasts.Weather and Forecasting, 20, 101-111. (http://www.bom.gov.au/nmoc/bulletins/60/article_by_Woodcock_in_Weather_and_Forecasting.pdf) and Wonnacott, T. H., and R. J. Wonnacott, 1972: Introductory Statistics. Wiley, 510 pp."
      }, {
        "uuid": "ada5c75a-aa72-5839-9c1e-dcb2894f6516",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "categorical",
        "definition": "The values are categorical rather than continuous valued quantities."
      }, {
        "uuid": "7abb8ffd-2b01-5455-9960-0393fe6f03cc",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "confidenceInterval",
        "definition": "In statistics, a confidence interval (CI) is a type of interval estimate of a statistical parameter. It is an observed interval (i.e., it is calculated from the observations), in principle different from sample to sample, that frequently includes the value of an unobservable parameter of interest if the experiment is repeated. How frequently the observed interval contains the parameter is determined by the confidence level or confidence coefficient."
      }, {
        "uuid": "7a81ee85-d386-503b-b8ca-e8ab7209f632",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "constantOverInterval",
        "definition": "The values are quantities that can be interpreted as constant for all time, or over the time interval to a subsequent measurement of the same variable at the same site."
      }, {
        "uuid": "3ad07d51-efd4-5305-a011-8851444a9c2b",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "continuous",
        "definition": "A quantity specified at a particular instant in time measured with sufficient frequency (small spacing) to be interpreted as a continuous record of the phenomenon."
      }, {
        "uuid": "c65f73c1-9d14-544f-be7d-d2cf9d00eb56",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "cumulative",
        "definition": "The values represent the cumulative value of a variable measured or calculated up to a given instant of time, such as cumulative volume of flow or cumulative precipitation."
      }, {
        "uuid": "f31b385f-1335-5812-a597-1b881abbeff7",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "incremental",
        "definition": "The values represent the incremental value of a variable over a time interval, such as the incremental volume of flow or incremental precipitation."
      }, {
        "uuid": "a07af08f-9d88-59db-940d-0b902e94afc1",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "maximum",
        "definition": "The values are the maximum values occurring at some time during a time interval, such as annual maximum discharge or a daily maximum air temperature."
      }, {
        "uuid": "a2c32aed-3dc3-5cbc-b149-38223c75f604",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "median",
        "definition": "The values represent the median over a time interval, such as daily median discharge or daily median temperature."
      }, {
        "uuid": "f998b371-824e-5054-9853-64a6cfd8620a",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "minimum",
        "definition": "The values are the minimum values occurring at some time during a time interval, such as 7-day low flow for a year or the daily minimum temperature."
      }, {
        "uuid": "705604f3-7cc3-54ad-97e6-e09916978390",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "mode",
        "definition": "The values are the most frequent values occurring at some time during a time interval, such as annual most frequent wind direction."
      }, {
        "uuid": "4b06b0c6-9aed-5c18-9725-6b38bce35fe8",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "sporadic",
        "definition": "The phenomenon is sampled at a particular instant in time but with a frequency that is too coarse for interpreting the record as continuous. This would be the case when the spacing is significantly larger than the support and the time scale of fluctuation of the phenomenon, such as for example infrequent water quality samples."
      }, {
        "uuid": "0059500e-d6f5-59c2-bdfa-934f72c38fde",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "standardDeviation",
        "definition": "The values represent the standard deviation of a set of observations made over a time interval. Standard deviation computed using the unbiased formula SQRT(SUM((Xi-mean)^2)/(n-1)) are preferred. The specific formula used to compute variance can be noted in the methods description."
      }, {
        "uuid": "417fabd7-c14e-5e2a-9626-5f05c3d732f0",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "standardErrorOfMean",
        "definition": "The standard error of the mean (SEM) quantifies the precision of the mean. It is a measure of how far your sample mean is likely to be from the true population mean. It is expressed in the same units as the data."
      }, {
        "uuid": "2f32ad3c-b5a4-5184-9661-a2a06596e957",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "standardErrorOfTheMean",
        "definition": "The standard error of the mean (SEM) quantifies the precision of the mean. It is a measure of how far your sample mean is likely to be from the true population mean. It is expressed in the same units as the data."
      }, {
        "uuid": "a3f6ee78-7c2f-5701-853b-44f2e078e83b",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "unknown",
        "definition": "The aggregation statistic is unknown."
      }, {
        "uuid": "2baa6161-5b5f-5bf9-9c92-6c45aec4c89e",
        "broader": "a3ac98ce-f135-5ec5-8af6-3663dd5124c3",
        "label": "variance",
        "definition": "The values represent the variance of a set of observations made over a time interval. Variance computed using the unbiased formula SUM((Xi-mean)^2)/(n-1) are preferred. The specific formula used to compute variance can be noted in the methods description."
      }]
    }, {
      "uuid": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Annotationtype",
      "definition": "",
      "children": [{
        "uuid": "ae1a3271-bf43-5071-84d5-ba9a14504ed0",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "actionAnnotation",
        "definition": "An annotation or qualifying comment about an Action"
      }, {
        "uuid": "2c41340b-f2d5-5813-bf07-3b5b0b1b8f12",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "actionGroup",
        "definition": "A group of actions such as those that are part of a cruise, expedition, experiment, project, etc."
      }, {
        "uuid": "9444e69d-2688-539a-81f2-751520dadff0",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "categoricalResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a categorical Result"
      }, {
        "uuid": "19b108b0-ec82-58ca-977c-6fd4bd1de6da",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "dataSetAnnotation",
        "definition": "An annotation or qualifying comment about a DataSet"
      }, {
        "uuid": "8ae40986-1c29-58f3-b178-a831723a11b0",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "equipmentAnnotation",
        "definition": "An annotation or qualifying comment about a piece of Equipment"
      }, {
        "uuid": "fa0e1987-e158-5bec-be15-67dae763f521",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "measurementResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a measurement Result"
      }, {
        "uuid": "6699398c-e9f3-55a4-bd9e-624df3ecca81",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "methodAnnotation",
        "definition": "An annotation or qualifiying comment about a Method"
      }, {
        "uuid": "4a1c71a5-1d39-5ae0-b61a-0e2653b8ed9a",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "organizationAnnotation",
        "definition": "An annotation or qualifiying comment about an Organization"
      }, {
        "uuid": "87dee408-8936-5e5a-a1fa-8f5f3587bdf2",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "personAnnotation",
        "definition": "An annotation or qualifying comment about a Person"
      }, {
        "uuid": "c06f4dd5-27de-5d9d-b1b9-c090693f6d79",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "personGroup",
        "definition": "A group of people such as a research team, project team, etc."
      }, {
        "uuid": "5be48411-61a7-516d-89ec-f6b3556a136c",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "pointCoverageResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a point coverage Result"
      }, {
        "uuid": "5a30cc5a-3bd0-5580-ba61-cc90f3782d80",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "profileResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a profile Result"
      }, {
        "uuid": "3f8dcbc8-9de2-523f-b531-0dbeca33cd08",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "resultAnnotation",
        "definition": "An annotation or qualifying comment about a Result"
      }, {
        "uuid": "f092638c-ff9a-558c-91ad-339ed5fe0866",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "samplingFeatureAnnotation",
        "definition": "An annotation or qualifiying comment about a SamplingFeature"
      }, {
        "uuid": "7e6827ce-4f3b-563a-af7a-74a7cfe40522",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "sectionResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a section Result"
      }, {
        "uuid": "12e0e9fb-8357-5c3a-883d-5961a15a8d4b",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "siteAnnotation",
        "definition": "An annotation or qualifying comment about a Site"
      }, {
        "uuid": "b445cf3e-f06e-54a6-8f0e-62b25dba6c74",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "siteGroup",
        "definition": "A group of sites such as a transect, station, observatory, monitoring collection, etc."
      }, {
        "uuid": "ff95a6b0-e081-5dee-91a1-897f37000b1d",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "specimenAnnotation",
        "definition": "An annotation or qualifying comment about a Specimen"
      }, {
        "uuid": "90048d09-5d79-51c9-88db-16c58898499f",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "specimenGroup",
        "definition": "A group of specimens such as an analysis batch, profile, experiment, etc."
      }, {
        "uuid": "c0f815d1-382c-53b0-a034-96e94d3499a5",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "spectraResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a spectra Result"
      }, {
        "uuid": "685cf4d7-ff7f-552f-a718-fc28defb8c10",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "timeSeriesResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a time series Result"
      }, {
        "uuid": "786f4b64-78bb-54b5-b919-adb9e4f36f0f",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "trajectoryResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a trajectory Result"
      }, {
        "uuid": "173c8147-7d54-54f0-9e00-547d15743513",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "transectResultValueAnnotation",
        "definition": "An annotation or data qualifying comment applied to a data value from a transect Result"
      }, {
        "uuid": "67112e87-1c85-5b2a-b3d3-b5b95bfd6aa4",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "valueGroup",
        "definition": "A group of data values such as those from a profile, analysis, spectra, publication table, dataset, incident reports, etc."
      }, {
        "uuid": "8c189333-d0eb-5ea9-b660-a47fea9cbc48",
        "broader": "64ae2be6-e495-5909-bdf6-c9f1f3dca5e5",
        "label": "variableAnnotation",
        "definition": "An annotation or qualifying comment about a Variable"
      }]
    }, {
      "uuid": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Censorcode",
      "definition": "",
      "children": [{
        "uuid": "0b68aa72-b7fa-5345-b32c-06eb64ccc27b",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "greaterThan",
        "definition": "The value is known to be greater than the recorded value."
      }, {
        "uuid": "ae2cfaff-3667-55b6-859f-9c2a0a4e94a8",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "lessThan",
        "definition": "The value is known to be less than the recorded value."
      }, {
        "uuid": "f7330362-0d3f-583c-8e9a-914da53b3c43",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "nonDetect",
        "definition": "The value was reported as a non-detect. The recorded value represents the level at which the anlalyte can be detected."
      }, {
        "uuid": "f5ce6910-15ad-5668-b435-4c2f20908619",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "notCensored",
        "definition": "The reported value is not censored."
      }, {
        "uuid": "3dda65a4-8273-5317-8525-c2bce379549e",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "presentButNotQuantified",
        "definition": "The anlayte is known to be present, but was not quantified. The recorded value represents the level below which the analyte can no longer be quantified."
      }, {
        "uuid": "ff79a36f-ed05-55e0-b15c-c4eba2cdcaa0",
        "broader": "d3ec3bd8-db68-521f-b64b-c1b936fc6da0",
        "label": "unknown",
        "definition": "It is unknown whether the data value as reported is censored."
      }]
    }, {
      "uuid": "87926ec9-4ccc-56df-89ce-250f44650ecb",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Dataqualitytype",
      "definition": "",
      "children": [{
        "uuid": "38b78b06-6a1b-5406-9b7e-021373afe29b",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "accuracy",
        "definition": "The degree of closeness of a measurement of a quantity to that quantity's true value."
      }, {
        "uuid": "a84673bb-a4d8-5046-acf6-53d3073b6e17",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "methodDetectionLimit",
        "definition": "The minimum concentration of a substance that can be distinguished from the absence of that substance within a stated confidence limit (generally 1%) given a particular analytical method."
      }, {
        "uuid": "79ac9d1e-17cc-5a80-a777-d06a201fbb78",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "physicalLimitLowerBound",
        "definition": "This describes a numeric value below which measured values should be considered suspect. This numeric value should be empirically derived."
      }, {
        "uuid": "d458f584-1d7b-512b-afe9-145c087a7476",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "physicalLimitUpperBound",
        "definition": "This describes a numeric value above which measured values should be considered suspect. This numeric value should be empirically derived."
      }, {
        "uuid": "c508e656-f807-5ca6-9bc9-804e3a73cb39",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "precision",
        "definition": "The degree to which repeated measurements under unchanged conditions show the same results."
      }, {
        "uuid": "6c69b639-5c05-5bc7-a7ae-56f1970c7353",
        "broader": "87926ec9-4ccc-56df-89ce-250f44650ecb",
        "label": "reportingLevel",
        "definition": "The smallest concentration of analyte that can be reported by laboratory."
      }]
    }, {
      "uuid": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Datasettype",
      "definition": "",
      "children": [{
        "uuid": "9c493b26-5241-5973-8dc5-6d324f5931f6",
        "broader": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
        "label": "multiTimeSeries",
        "definition": "A Dataset that contains multiple time series Results. This corresponds to the YAML Observations Data Archive (YODA) multi-time series profile."
      }, {
        "uuid": "1e2b547a-f8bc-559b-82e1-25d2845a4ade",
        "broader": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
        "label": "multiVariableSpecimenMeasurements",
        "definition": "A dataset that contains multiple measurement Results derived from Specimens. This corresponds to the YAML Observations Data Archive (YODA) specimen time series profile."
      }, {
        "uuid": "b5358cea-e02c-5894-bfbb-cc73bfb52895",
        "broader": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
        "label": "other",
        "definition": "A set of Results that has been grouped into a Dataset because they are logically related. The group does not conform to any particular profile."
      }, {
        "uuid": "8fcd7d77-68f6-5012-8011-980eaf4e1610",
        "broader": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
        "label": "singleTimeSeries",
        "definition": "A Dataset that contains a single time series Result. This corresponds to the YAML Observations Data Archive (YODA) singe time series profile."
      }, {
        "uuid": "09f1e3bf-4c10-535f-a87f-75253b1a2679",
        "broader": "519b23ef-ae92-5958-88cd-756ba3e6c3d5",
        "label": "specimenTimeSeries",
        "definition": "A dataset that contains multiple measurement Results derived from Specimens. This corresponds to the YAML Observations Data Archive (YODA) specimen time series profile."
      }]
    }, {
      "uuid": "f5cd8d3d-3e1d-559f-b991-049d1cb46d73",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Directivetype",
      "definition": "",
      "children": [{
        "uuid": "4cb9cebb-0a5e-5203-a61a-ca0d47d5c071",
        "broader": "f5cd8d3d-3e1d-559f-b991-049d1cb46d73",
        "label": "fieldCampaign",
        "definition": "A sampling event conducted in the field during which instruments may be deployed and during which samples may be collected. Field campaigns typically have a focus such as characterizing a particular environment, quantifying a particular phenomenon, answering a particular research question, etc. and may last for hours, days, weeks, months, or even longer."
      }, {
        "uuid": "2f39fed9-03fc-5d47-8732-52547997c375",
        "broader": "f5cd8d3d-3e1d-559f-b991-049d1cb46d73",
        "label": "monitoringProgram",
        "definition": "Environmental monitoring that is conducted according to a formal plan that may reflect the overall objectives of an organization, references specific strategies that help deliver the objectives and details of specific projects or tasks, and that contains a listing of what is being monitored, how that monitoring is taking place, and the time-scale over which monitoring should take place."
      }, {
        "uuid": "09b65cad-1a49-591e-b9ed-0c0811174819",
        "broader": "f5cd8d3d-3e1d-559f-b991-049d1cb46d73",
        "label": "project",
        "definition": "A collaborative enterprise, involving research or design, the is carefully planned to achieve a particular aim."
      }]
    }, {
      "uuid": "32204399-b572-5d9e-a1ce-c959d396b041",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Elevationdatum",
      "definition": "",
      "children": [{
        "uuid": "647d8864-e55e-5683-bb49-24ff545f5ddb",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "AHD",
        "definition": "Australian Height Datum. Height datum used for standardised elevations across Australia. The datum surface is defined as that which passes through mean sea level at the 30 specified tide gauges and through points at zero AHD height vertically below the other basic junction points."
      }, {
        "uuid": "6fc73c6d-d9e9-5e7f-b7b6-15a75d0d6103",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "EGM96",
        "definition": "EGM96 (Earth Gravitational Model 1996) is a geopotential model of the Earth consisting of spherical harmonic coefficients complete to degree and order 360."
      }, {
        "uuid": "344bc958-d4db-55ca-a16f-b781454f614f",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "MSL",
        "definition": "Mean Sea Level"
      }, {
        "uuid": "712e5c39-4b78-57a2-b3a9-63a1707613e5",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "NAVD88",
        "definition": "North American Vertical Datum of 1988"
      }, {
        "uuid": "e6fa2317-5cdc-5d58-8e7d-83232feaa93c",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "NGVD29",
        "definition": "National Geodetic Vertical Datum of 1929"
      }, {
        "uuid": "f4598cef-15f9-5274-96f2-16a387b2b625",
        "broader": "32204399-b572-5d9e-a1ce-c959d396b041",
        "label": "Unknown",
        "definition": "The vertical datum is unknown"
      }]
    }, {
      "uuid": "9147666f-e5c6-5a4c-9301-9d296ddff199",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Equipmenttype",
      "definition": "",
      "children": [{
        "uuid": "f0a809e1-28e4-5ea4-adf8-3f1b565d16e8",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "antenna",
        "definition": "An electrical device that converts electric power into radio waves and vice versa."
      }, {
        "uuid": "d48e94b7-72e5-56d2-99c4-36872af3cfe5",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "automaticLevel",
        "definition": "A survey level that makes use of a compensator that ensures the line of sight remains horizontal once the operator has roughly leveled the instrument."
      }, {
        "uuid": "d6d977a3-4d94-533b-9da1-dafa6a5465b7",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "battery",
        "definition": "A device consisting of one or more electrochemical cells that convert stored chemical energy into electrical energy."
      }, {
        "uuid": "a254c05a-7cb4-590f-b1b4-157dcf9fdcfc",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "cable",
        "definition": "Two or more wires running side by side and bonded, twisted, or braided together to form a single assembly."
      }, {
        "uuid": "350e9b81-4d97-56bd-b4f8-dca68d5a3f46",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "camera",
        "definition": "A device used to create photographic images"
      }, {
        "uuid": "ce443b9f-d1d8-5f97-9756-e28e766b130c",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "chargeRegulator",
        "definition": "An electroinic device that limits the rate at which electric current is added to or drawn from electric batteries."
      }, {
        "uuid": "4d832d26-9ca9-5cdc-85e9-ad6266273efd",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "datalogger",
        "definition": "An electronic device that records data over time or in relation to location either with a built in instrument or sensor or via external instruments and sensors."
      }, {
        "uuid": "f7e728b4-53cb-5b25-9df3-6bd1a0c0d003",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "electronicDevice",
        "definition": "A generic electronic device"
      }, {
        "uuid": "c082836d-fc3f-530a-944a-a22bd8a24db4",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "enclosure",
        "definition": "A cabinet or box within which electrical or electronic equipment are mounted to protect them from the environment."
      }, {
        "uuid": "5981123e-c7ec-5942-832d-f966350c9ae4",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "fluorometer",
        "definition": "A device used to measure paramters of flouroescence, including its intensity and wavelength distribution of emission spectrum after excitation by a certain spectrum of light."
      }, {
        "uuid": "84613599-6a15-51f8-ab64-bd89424438d1",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "globalPositioningSystemReceiver",
        "definition": "A device that accurately calculates geographical location by receiving information from Global Positioning System satellites."
      }, {
        "uuid": "162ce005-2e3a-5d53-b49e-1c8dae0c34a2",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "interface",
        "definition": "A device used to couple multiple other devices."
      }, {
        "uuid": "3a01e6ac-169a-5dd1-81f0-e876db45c295",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "laboratoryInstrument",
        "definition": "Any type of equipment, apparatus or device designed, constructed and refined to use well proven physical principles, relationships or technology to facilitate or enable the pursuit, acquisition, transduction and storage of repeatable, verifiable data, usually consisting of sets numerical measurements made upon otherwise unknown, unproven quantities, properties, phenomena, materials, forces or etc."
      }, {
        "uuid": "5c1616c2-3661-5b4c-8916-a2812163010c",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "levelStaff",
        "definition": "A graduate wooden, fiberglass, or aluminum rod used to determine differences in elevation."
      }, {
        "uuid": "13ca5d8f-9fde-50f9-85da-ab37420aa205",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "mast",
        "definition": "A pole that supports sensors, instruments, or measurement peripherals."
      }, {
        "uuid": "87b9e909-9309-5b08-91d0-6b644efca596",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "measurementTower",
        "definition": "A free standing tower that supports measuring instruments or sensors."
      }, {
        "uuid": "5ef62aa2-deb8-5044-b2f9-261837906293",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "multiplexer",
        "definition": "A device that selects one of several analog or digital input signals and forwards the selected input into a single line."
      }, {
        "uuid": "e98d5967-17fa-5af6-adbb-2f624b48479e",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "powerSupply",
        "definition": "An electronic device that supplies electric energy to an electrical load. The primary function of a power supply is to convert one form of electrical energy to another (e.g., solar to chemical)."
      }, {
        "uuid": "ad3ba9a0-d11b-5945-8de5-fe3619fda7b0",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "pressureTransducer",
        "definition": "A sensor that measures pressure, typically of gases or liquids."
      }, {
        "uuid": "91fc68dd-e4c0-517c-b7ce-ea2577847d55",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "radio",
        "definition": "A device that transfers information via electromagnetic signals through the atmosphere or free space."
      }, {
        "uuid": "6536bea5-e1f9-5685-b619-b3beedc28acd",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "sampler",
        "definition": "A device used to collect specimens for later ex situ analysis."
      }, {
        "uuid": "46111b79-5a2b-5b04-bb24-0b6a3dc08a6b",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "sensor",
        "definition": "A device that detects events or changes in quantities and provides a corresponding output, generally as an electrical or optical signal."
      }, {
        "uuid": "523239e5-d322-54b7-a43e-48165a88b761",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "solarPanel",
        "definition": "A photovoltaic module that is electrically connected and mounted on a supporting structure.  Used to generate and supply electricity."
      }, {
        "uuid": "08cfb528-bfbc-5799-b94a-a3cb6040267b",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "stormBox",
        "definition": "An enclosure used to protect electronic equipment used for stormwater sampling."
      }, {
        "uuid": "fe548745-e27c-5abf-861f-5614b1d84574",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "totalStation",
        "definition": "An electronic and optical instrument used in modern surveying and building construction.  A total station is an electronic theodoloite integrated with an electronic distance meter to read slope distances from the instrument to a particular point."
      }, {
        "uuid": "15515099-1ca7-5a28-8b8e-a91545de1b18",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "tripod",
        "definition": "A portable, three-legged frame used as a platform for supporting the weight and maintaining the stability of some other object. Typically used as a data collection platform to which sensors are attached."
      }, {
        "uuid": "c43c15a8-1090-52f5-bcb0-63080f2ec88f",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "turbidimeter",
        "definition": "A water quality sensor that monitors light reflected off the particles suspended in water."
      }, {
        "uuid": "f45588b1-12ec-5859-a08c-a16580bea1a9",
        "broader": "9147666f-e5c6-5a4c-9301-9d296ddff199",
        "label": "waterQualitySonde",
        "definition": "A water quality monitoring instrument having multiple attached sensors."
      }]
    }, {
      "uuid": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Medium",
      "definition": "",
      "children": [{
        "uuid": "bd1805a6-8e11-531a-a51f-eb7ab3c45140",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "air",
        "definition": "Specimen collection of ambient air or sensor emplaced to measure properties of ambient air."
      }, {
        "uuid": "5fd61513-5982-507e-a5ff-a36030254d65",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "equipment",
        "definition": "An instrument, sensor or other piece of human-made equipment upon which a measurement is made, such as datalogger temperature or battery voltage."
      }, {
        "uuid": "c0a37d2b-03cc-5fc2-bece-407042c15cd8",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "gas",
        "definition": "Gas phase specimen or sensor emplaced to measure properties of a gas."
      }, {
        "uuid": "c2b07c49-ace6-5746-bfbf-8533b96b748e",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "habitat",
        "definition": "A habitat is an ecological or environmental area that is inhabited by a particular species of animal, plant, or other type of organism."
      }, {
        "uuid": "6b11498e-c413-5af0-92ab-52d9792df70a",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "ice",
        "definition": "Sample collected as frozen water or sensor emplaced to measure properties of ice."
      }, {
        "uuid": "5a3a6a63-5d31-517b-b729-f97854a2e22d",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "liquidAqueous",
        "definition": "Specimen collected as liquid water or sensor emplaced to measure properties of water in sampled environment."
      }, {
        "uuid": "51fd570d-1d57-59a9-b199-88d3c18f4648",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "liquidOrganic",
        "definition": "Specimen collected as an organic liquid."
      }, {
        "uuid": "6ed9645c-c473-507a-8cbc-c6de4b225add",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "mineral",
        "definition": "Specimen collected as a mineral."
      }, {
        "uuid": "69df7e17-4469-5342-8552-f809b898be45",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "notApplicable",
        "definition": "There is no applicable sampled medium.  "
      }, {
        "uuid": "b63afcfd-a2ea-5604-b965-583a11ebda4a",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "organism",
        "definition": "Data collected about a species at organism level."
      }, {
        "uuid": "7432bb73-5c42-5fe7-8207-ecb583f62129",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "other",
        "definition": "Other."
      }, {
        "uuid": "f06d487c-05c1-5cfb-ab1f-902315525d5f",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "particulate",
        "definition": "Specimen collected from particulates suspended in a paticulate-fluid mixture. Examples include particulates in water or air."
      }, {
        "uuid": "6a3ca054-29aa-55c9-9450-7ef0be49d4ef",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "regolith",
        "definition": "The entire unconsolidated or secondarily recemented cover that overlies more coherent bedrock, that has been formed by weathering, erosion, transport and/or deposition of the older material. The regolith thus includes fractured and weathered basement rocks, saprolites, soils, organic accumulations, volcanic material, glacial deposits, colluvium, alluvium, evaporitic sediments, aeolian deposits and ground water. Everything from fresh rock to fresh air."
      }, {
        "uuid": "cf355ca2-dd19-5ffd-bc97-dc256f79fda2",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "rock",
        "definition": "Specimen collected from a naturally occuring solid aggregate of one or more minerals."
      }, {
        "uuid": "d6c8c4e0-a292-527a-8f7f-93ab4e8680a1",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "sediment",
        "definition": "Specimen collected from material broken down by processes of weathering and erosion and subsequently transported by the action of wind, water, or ice, and/or by the force of gravity acting on the particles. Sensors may also be emplaced to measure sediment properties."
      }, {
        "uuid": "5104c82b-92a4-596f-85bf-6d5462c218d0",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "snow",
        "definition": "Observation in, of or sample taken from snow."
      }, {
        "uuid": "e158942e-e2c0-5aa8-80f3-c42cb2434c90",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "soil",
        "definition": "Specimen collected from soil or sensor emplaced to measure properties of soil. Soil includes the mixture of minerals, organic matter, gasses, liquids, and organisms that make up the upper layer of earth in which plants grow. "
      }, {
        "uuid": "e0377455-7414-555b-b514-30a3f2495e34",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "tissue",
        "definition": "Sample of a living organism's tissue or sensor emplaced to measure property of tissue."
      }, {
        "uuid": "2cb6ff7e-54d9-5453-9777-8f4d0f455075",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "unknown",
        "definition": "The sampled medium is unknown."
      }, {
        "uuid": "78516f1c-507e-5e64-928f-5e552a2883cb",
        "broader": "cf669b4b-7e9a-5328-ad77-1de42ee90040",
        "label": "vegetation",
        "definition": "The plants of an area considered in general or as communities, but not taxonomically."
      }]
    }, {
      "uuid": "5e17873d-7c2c-5576-b929-986a1f6dd020",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Methodtype",
      "definition": "",
      "children": [{
        "uuid": "1ca49231-7038-5608-ac48-3fee83f05d36",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "cruise",
        "definition": "A method for performing a cruise action."
      }, {
        "uuid": "96f6e086-4a27-51d6-a009-c7fab614562f",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "dataRetrieval",
        "definition": "A method for retrieving data from a datalogger deployed at a monitoring site."
      }, {
        "uuid": "16802371-d008-59f0-9b5b-1558451f7204",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "derivation",
        "definition": "A method for creating results by deriving them from other results."
      }, {
        "uuid": "5dc2f865-c7e0-5fd6-aac6-e00a8e6e6ff6",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "equipmentDeployment",
        "definition": "A method for deploying a piece of equipment that will not make observations at a sampling feature."
      }, {
        "uuid": "db75a48e-5471-50d9-a06b-47a1fe88105a",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "equipmentMaintenance",
        "definition": "A method for performing periodic upkeep or servicing of field or laboratory equipment. Maintenance may be performed in the field, in a laboratory, or at a factory maintenance center."
      }, {
        "uuid": "29417f90-f927-59c0-8e9c-623343d8a0ca",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "equipmentProgramming",
        "definition": "A method for creating or modifying the data collection program running on a datalogger or other equipment deployed at a monitoring site. "
      }, {
        "uuid": "4a6cc45c-7274-53b8-97e3-bb4c04c7674f",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "equipmentRetrieval",
        "definition": "A method for retrieving equipment from a sampling feature at which or on which it was deployed."
      }, {
        "uuid": "e28a94d6-8869-5269-a954-dece44fe033e",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "estimation",
        "definition": "A method for creating results by estimation or professional judgement."
      }, {
        "uuid": "b43c5113-596b-5309-a0cd-0a0b91435c8b",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "expedition",
        "definition": "A method for performing an expedition action."
      }, {
        "uuid": "f7fc352d-f7a3-5655-975f-7e61348ce0be",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "fieldActivity",
        "definition": "A method for performing an activity in the field at or on a sampling feature."
      }, {
        "uuid": "8a343051-5a29-56bf-b65b-4cd2f3da953b",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "genericNonObservation",
        "definition": "A method for completing a non-specific action that does not produce a result."
      }, {
        "uuid": "7244916f-0c86-5ebf-bcfc-4ff03fa51d18",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "instrumentCalibration",
        "definition": "A method for calibrating an instrument either in the field or in the laboratory. "
      }, {
        "uuid": "d4f3a121-3a82-5526-9965-ff08bda2be61",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "instrumentContinuingCalibrationVerification",
        "definition": "A method for verifying the instrument or meter calibration by measuring a calibration standard of known value as if it were a sample and comparing the measured result to the calibration acceptance criteria listed in the Standard Operating Procedure."
      }, {
        "uuid": "b48c2532-acc2-55fb-9624-b025ae684a1d",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "instrumentDeployment",
        "definition": "A method for deploying an instrument to make observations at a sampling feature."
      }, {
        "uuid": "c50db2f4-7348-52f2-80e9-35c38a210e0c",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "instrumentRetrieval",
        "definition": "A method for retrieving or recovering an instrument that has been deployed at a smpling feature."
      }, {
        "uuid": "6e81fdbd-7d62-567a-b4db-eb19e56b31fd",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "observation",
        "definition": "A method for creating observation results. This term should be used when a Result is generated but the more specific terms of Instrument deployment or Specimen analysis are not applicable."
      }, {
        "uuid": "eebd84c8-2472-5764-a338-e1241c7c0406",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "simulation",
        "definition": "A method for creating results by running a simulation model."
      }, {
        "uuid": "2e468d08-e996-509b-9ce2-892b6554d15e",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "siteVisit",
        "definition": "A method for performing a site visit action."
      }, {
        "uuid": "b7e11a3d-6f9f-5837-a26b-1cf223e653bb",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "specimenAnalysis",
        "definition": "A method for ex situ analysis of a specimen using an instrument, typically in a laboratory, for the purpose of measuring properties of a specimen."
      }, {
        "uuid": "ff3ee544-a368-50e3-a921-5c1cb7c3938c",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "specimenCollection",
        "definition": "A method for collecting a specimen for ex situ analysis."
      }, {
        "uuid": "24811c61-9018-57c1-adc0-8357c01fe5af",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "specimenFractionation",
        "definition": "A method for separating a specimen into multiple different fractions or size classes."
      }, {
        "uuid": "e67b6204-f350-5d6b-817e-dbda043e9c7e",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "specimenPreparation",
        "definition": "A method for processing a specimen collected in the field to produce a sample suitable for analysis using a particular analytical procedure."
      }, {
        "uuid": "135d7f1c-fd11-542e-a3ae-6b519b9567b7",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "specimenPreservation",
        "definition": "A method for preserving a specimen either in the field or in a laboratory prior to ex situ analysis."
      }, {
        "uuid": "adda222f-4296-5956-ade6-0ebc2b6fc0d6",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "submersibleLaunch",
        "definition": "A method for launching a submersible from a vessel or ship."
      }, {
        "uuid": "42a791d1-83f3-50a3-bd58-3319aea1cf00",
        "broader": "5e17873d-7c2c-5576-b929-986a1f6dd020",
        "label": "unknown",
        "definition": "The method type is unknown."
      }]
    }, {
      "uuid": "56dda090-779e-5540-ac24-9ad4bd569db9",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Propertydatatype",
      "definition": "",
      "children": [{
        "uuid": "2e1c8f94-2799-5694-b907-ea3a1f492763",
        "broader": "56dda090-779e-5540-ac24-9ad4bd569db9",
        "label": "boolean",
        "definition": "A boolean type is typically a logical type that can be either \"true\" or \"false\"."
      }, {
        "uuid": "d16c4b15-7cc5-5898-a085-b5db4a5cdca9",
        "broader": "56dda090-779e-5540-ac24-9ad4bd569db9",
        "label": "controlledList",
        "definition": "A predefined list of strings that a user can select from."
      }, {
        "uuid": "0b51bd7e-d6e2-5da2-8750-c0658dc0e8d8",
        "broader": "56dda090-779e-5540-ac24-9ad4bd569db9",
        "label": "floatingPointNumber",
        "definition": "A floating-point number represents a limited-precision rational number that may have a fractional part. "
      }, {
        "uuid": "ca75320d-c421-5348-b2f4-6cdc2d38e575",
        "broader": "56dda090-779e-5540-ac24-9ad4bd569db9",
        "label": "integer",
        "definition": "An integer data type can hold a whole number, but no fraction. Integers may be either signed (allowing negative values) or unsigned (nonnegative values only). "
      }, {
        "uuid": "be5926a1-4dda-5c12-a72c-20e75985e9e1",
        "broader": "56dda090-779e-5540-ac24-9ad4bd569db9",
        "label": "string",
        "definition": "An array of characters including letters, digits, punctuation marks, symbols, etc."
      }]
    }, {
      "uuid": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Qualitycode",
      "definition": "",
      "children": [{
        "uuid": "469fe14d-94f6-5642-88ba-15c2fdb63863",
        "broader": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
        "label": "bad",
        "definition": "A quality assessment has been made and enough of the data quality objectives have not been met that the observation has been assessed to be of bad quality."
      }, {
        "uuid": "534b6d10-a0ee-5277-bde9-e550d314b55a",
        "broader": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
        "label": "good",
        "definition": "A quality assessment has been made and all data quality objectives have been met."
      }, {
        "uuid": "77df1bae-1e09-5497-a254-84ee5b961dbf",
        "broader": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
        "label": "marginal",
        "definition": "A quality assessment has been made and one or more data quality objectives has not been met. The observation may be suspect and has been assessed to be of marginal quality."
      }, {
        "uuid": "00e2d039-5622-5f84-a3c3-cdbf6c53ccc9",
        "broader": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
        "label": "none",
        "definition": "No data quality assessment has been made."
      }, {
        "uuid": "1459b7e7-96e2-5308-8f98-30220ab25106",
        "broader": "b9f11d69-06cd-55eb-9cef-fc1baec6d7ba",
        "label": "unknown",
        "definition": "The quality of the observation is unknown."
      }]
    }, {
      "uuid": "84fa0e28-5962-53d7-b757-094020a6b0d6",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Relationshiptype",
      "definition": "",
      "children": [{
        "uuid": "541b81d6-45c3-594d-8388-0c4759a062ba",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "cites",
        "definition": "Use to indicate a relation to the work that the resource is citing/quoting."
      }, {
        "uuid": "3b917fba-afe6-57f7-9a64-607020d8af55",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "compiles",
        "definition": "One to many relationship that denotes that the one source resource has been assembled from the target resources through a process of integration and harmonization (as opposed to simple aggregation)."
      }, {
        "uuid": "fa3932a3-03f8-5b45-a7f9-618cb6342db0",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "continues",
        "definition": "Use to indicate the resource is a continuation of the work referenced by the related identifier."
      }, {
        "uuid": "60b1e6e8-bd06-52b7-840d-688b62a309b8",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "documents",
        "definition": "Use to indicate the relation to the work which is documentation."
      }, {
        "uuid": "5a8b58f7-5d3f-508c-a12b-a1e99379c0d6",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "hasPart",
        "definition": "Use to indicate the resource is a container of another resource."
      }, {
        "uuid": "539620b6-8cfe-5d4a-8c1c-7a03043bfc14",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isAttachedTo",
        "definition": "Used to indicate that one entity is attached to another. For example this term can be used to express the fact that a piece of equipment is attached to a related piece of equipment."
      }, {
        "uuid": "84a05b49-a834-585a-8bda-5540c80d9f67",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isChildOf",
        "definition": "Used to indicate that one entity is an immediate child of another entity. For example, this term can be used to express the fact that an instrument deployment Action is the child of a site visit Action."
      }, {
        "uuid": "67a04eca-5bb3-5601-8534-8184ca6112b0",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isCitationFor",
        "definition": "Used to indicate the relationship between a Citation and the entity for which it is the Citation."
      }, {
        "uuid": "88c75f6a-2a2c-5a02-8aae-b581c6b03dc9",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isCitedBy",
        "definition": "Use to indicate the relation to a work that cites/quotes this data."
      }, {
        "uuid": "1143b69d-4d77-51bb-b420-6bcff6298df9",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isCompiledBy",
        "definition": "Use to indicate the resource or data is compiled/created by using another resource or dataset."
      }, {
        "uuid": "54a27aee-bbca-5c25-9730-f72a4ce6332f",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isContinuedBy",
        "definition": "Use to indicate the resource is continued by the work referenced by the related identifier."
      }, {
        "uuid": "c53a5516-8a11-5558-8ed3-c787a3e45364",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isDerivedFrom",
        "definition": "Used to indicate the relation to the works from which the resource was derived."
      }, {
        "uuid": "3e43adcd-39d2-5e41-9763-116369c764f9",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isDocumentedBy",
        "definition": "Use to indicate the work is documentation about/explaining the resource referenced by the related identifier."
      }, {
        "uuid": "82160cb2-0ef5-54ae-a206-4f72c83c91fe",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isIdenticalTo",
        "definition": "Used to indicate the relation to a work that is identical to the resource."
      }, {
        "uuid": "1de48ce8-5a8c-5c3a-bdf8-80795e98626f",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isNewVersionOf",
        "definition": "Use to indicate the resource is a new edition of an old resource, where the new edition has been modified or updated."
      }, {
        "uuid": "0ef9c5b8-c5fd-5417-8d3b-4854cc1d2de3",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isOriginalFormOf",
        "definition": "Use to indicate the relation to the works which are variant or different forms of the resource."
      }, {
        "uuid": "b797a1ca-3cce-5ebf-9ed0-948df89c948f",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isPartOf",
        "definition": "Use to indicate the resource is a portion of another resource."
      }, {
        "uuid": "5b6a61c8-fb6a-59de-990f-5b04aa0d4c03",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isPreviousVersionOf",
        "definition": "Use to indicate the resource is a previous edition of a newer resource."
      }, {
        "uuid": "e039a888-c889-517e-ae08-87b15ab97289",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isReferencedBy",
        "definition": "Use to indicate the resource is used as a source of information by another resource."
      }, {
        "uuid": "14b59e5d-5243-5b0d-b44a-d5fceb6b3525",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isRelatedTo",
        "definition": "Used to indicate that one entity has a complex relationship with another entity that is not a simple, hierarchical parent-child relationship. For example, this term can be used to express the fact that an instrument cleaning Action is related to an instrument deployment action even though it may be performed as part of a separate site visit."
      }, {
        "uuid": "7422b7fc-b3a5-53f9-b6e0-f8844c43d162",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isRetrievalfor",
        "definition": "Use to indicate the action is a retrieval of a previous deployment."
      }, {
        "uuid": "9eaa2312-b3c0-5cca-b4d6-069abd887252",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isReviewedBy",
        "definition": "Used to indicate that the work is reviewed by another resource."
      }, {
        "uuid": "33a12884-ade3-5708-9b92-f07e8355ba7c",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isSourceOf",
        "definition": "Used to indicate the relation to the works that were the source of the resource."
      }, {
        "uuid": "f5fce6f9-83e0-5de0-9860-3831fe6fc923",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isSupplementedBy",
        "definition": "Use to indicate the relation to the work(s) which are supplements of the resource."
      }, {
        "uuid": "5ca4a79b-3fcb-5905-be15-634b8df667f6",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isSupplementTo",
        "definition": "Use to indicate the relation to the work to which the resource is a supplement."
      }, {
        "uuid": "b3325c8b-3bec-569c-94fa-182cb7993ecb",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "isVariantFormOf",
        "definition": "Use to indicate the resource is a variant or different form of another resource, e.g. calculated or calibrated form or different packaging."
      }, {
        "uuid": "2993422b-e94d-5261-b514-5b410e720002",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "references",
        "definition": "Use to indicate the relation to the work which is used as a source of information of the resource."
      }, {
        "uuid": "aee910f0-ed83-58cd-a3d5-edcc679bf613",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "Reviews",
        "definition": "Used to indicate that the work reviews another resource."
      }, {
        "uuid": "4f7594a5-998b-526d-8b37-652d0df27e7d",
        "broader": "84fa0e28-5962-53d7-b757-094020a6b0d6",
        "label": "wasCollectedAt",
        "definition": "Used to indicate that one entity was collected at the location of another entity. For example, thirs term can be used to express the fact that a specimen SamplingFeature was collected at a site SamplingFeature."
      }]
    }, {
      "uuid": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Resulttype",
      "definition": "",
      "children": [{
        "uuid": "020f1b6e-757d-5b96-8fcf-e44764eef9c7",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "categoryObservation",
        "definition": "A single ResultValue for a single Variable, measured on or at a single SamplingFeature, using a single Method."
      }, {
        "uuid": "22e416ca-4075-5816-85e7-05fd2097056e",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "countObservation",
        "definition": "A single ResultValue for a single Variable, counted on or at a single SamplingFeature, using a single Method, and having a specific ProcessingLevel."
      }, {
        "uuid": "8caa4e53-43ff-5f65-80dd-26cdfca46925",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "measurement",
        "definition": "A single ResultValue for a single Variable, measured on or at a SamplingFeature, using a single Method, with specific Units, and having a specific ProcessingLevel."
      }, {
        "uuid": "6d7e4861-29df-5e8e-ba3b-98c17aa47a5e",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "pointCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, with a fixed ValueDateTime, but measured over varying X,Y locations, where X and Y are horizontal coordinates."
      }, {
        "uuid": "88f1699c-0d7b-5f55-b8c9-dd706bacc646",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "profileCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over multiple locations along a depth profile with only one varying location dimension (e.g., Z, where Z is depth). ValueDateTime may be fixed or controlled."
      }, {
        "uuid": "a343f1ea-36c1-5732-b050-613e7db4fe30",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "sectionCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over varying X (horizontal) and Z (depth) coordinates. ValueDateTime may be fixed or controlled."
      }, {
        "uuid": "b161e5fc-6f99-5e45-9781-4dca0dda8b66",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "spectraCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over multiple wavelengths of light. ValueDateTime may be fixed or controlled."
      }, {
        "uuid": "24ea186d-c2ad-52bf-ae01-1abfc2bbe6db",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "temporalObservation",
        "definition": "A single ResultValue for a single Variable, measured on or at a SamplingFeature, using a single Method, with specific Units, and having a specific ProcessingLevel."
      }, {
        "uuid": "cfd650cd-c167-5b0e-8c70-6fbe2bcc28e4",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "timeSeriesCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature of fixed location, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over time."
      }, {
        "uuid": "4d5e5573-8645-550d-84b6-832f4b597ccd",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "trajectoryCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over varying X,Y, Z, and D locations, where X and Y are horizontal coordinates, Z is a vertical coordinate, and D is the distance along the trajectory. ValueDateTime may be fixed (DTS Temperature) or controlled (glider)."
      }, {
        "uuid": "f7f2c0c8-e22d-5dc6-bb79-a601dd018105",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "transectCoverage",
        "definition": "A series of ResultValues for a single Variable, measured on or at a single SamplingFeature, using a single Method, with specific Units, having a specific ProcessingLevel, but measured over multiple locations along a transect having varying location dimensions (e.g.,  X and/or Y, where X and Y are horizontal coordintes). ValueDateTime may be fixed or controlled."
      }, {
        "uuid": "aafb11b2-d9cb-510e-837e-1a2dceebb7e6",
        "broader": "1647c38f-1c9e-5978-be3c-967f2b4f34d2",
        "label": "truthObservation",
        "definition": "A single ResultValue for a single Variable, measured on or at a single SamplingFeature, using a single Method."
      }]
    }, {
      "uuid": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Samplingfeaturegeotype",
      "definition": "",
      "children": [{
        "uuid": "100b3471-d585-5430-8348-1d6b16d00472",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "lineString",
        "definition": "A subclass of a Curve using linear interpolation between points. A Curve is a 1-dimensional geometric object usually stored as a sequence of Points. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "61bf585b-fa97-5a4a-92a2-bbc4231e4c86",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "multiLineString",
        "definition": "A collection of individual lines, used as a single feature. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "584a35ae-8e7e-5de2-a46b-b7ac2d064b61",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "multiPoint",
        "definition": "A collection of individual points, used as a single feature. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "33f74a34-0db9-5d1d-abe5-37f3e1bc2dd8",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "multiPolygon",
        "definition": "A collection of individual polygons, used as a single feature. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "8278758a-e07c-565b-9959-b7dd0f6ee1df",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "notApplicable",
        "definition": "The sampling feature has no applicable geospatial feature type"
      }, {
        "uuid": "a78652f1-0005-59c9-b4ff-9386caafd913",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "point",
        "definition": "Topological 0-dimensional geometric primitive representing a position. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "cf63c0a2-0c3b-5676-ab48-a287cd4c3224",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "polygon",
        "definition": "A planar Surface defined by 1 exterior boundary and 0 or more interior boundaries. Each interior boundary defines a hole in the Polygon. Polygons are topologically closed. Polygons are a subclass of Surface that is planar. A Surface is a 2-dimensonal geometric object. Represented in 2D coordinates by FeatureGeometry."
      }, {
        "uuid": "f09c7761-c941-5d45-b861-d5ef13e5af07",
        "broader": "95dce1b8-0663-5321-aa9d-3ffe03c3baaf",
        "label": "volume",
        "definition": "A three dimensional space enclosed by some closed boundary."
      }]
    }, {
      "uuid": "84891a73-5a36-5fba-83d5-c926f0953bd0",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Samplingfeaturetype",
      "definition": "",
      "children": [{
        "uuid": "f58fa676-a2fe-586b-80c6-3da90aa16f27",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "borehole",
        "definition": "A narrow shaft bored into the ground, either vertically or horizontally. A borehole includes the hole cavity and walls surrounding that cavity.  "
      }, {
        "uuid": "eb1a1494-5fcd-5318-8e4b-5a503bcb06ed",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "crossSection",
        "definition": "The intersection of a body in three-dimensional space with a plane.  Represented as a polygon. "
      }, {
        "uuid": "f6a4be4d-cebb-5c5e-a5fe-1b2a6eb3d205",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "CTD",
        "definition": "A CTD (Conductivity, Temperature, and Depth) cast is a water column depth profile collected over a specific and relatively short date-time range, that can be considered as a parent specimen."
      }, {
        "uuid": "593cd41e-0f38-579e-b39a-516e17944c39",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "depthInterval",
        "definition": "A discrete segment along a longer vertical path, such as a borehole, soil profile or other depth profile, in which an observation or specimen is collected over the distance between the upper and lower depth limits of the interval. A Depth Interval is a sub-type of Interval."
      }, {
        "uuid": "5ab936e8-a45a-5664-b8f8-6e3974510e3a",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "ecologicalLandClassification",
        "definition": "Ecological land classification is a cartographical delineation of distinct ecological areas, identified by their geology, topography, soils, vegetation, climate conditions, living species, habitats, water resources, as well as anthropic factors. These factors control and influence biotic composition and ecological processes."
      }, {
        "uuid": "69e25106-a031-5f0b-ab20-ceb079001eb9",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "excavation",
        "definition": "An artificially constructed cavity in the earth that is deeper than the soil, larger than a well bore, and substantially open to the atmosphere. The diameter of an excavation is typically similar or larger than the depth. Excavations include building-foundation diggings, roadway cuts, and surface mines."
      }, {
        "uuid": "a3e60959-a50a-56ed-8664-3938a7159a9e",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "fieldArea",
        "definition": "A location at which field experiments or observations of ambient conditions are conducted. A field area may contain many sites and has a geographical footprint that can be represented by a polygon."
      }, {
        "uuid": "7a06db3d-3b80-5056-a92c-470e60ac3998",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "flightline",
        "definition": "A path along which an aircraft travels while measuring a phenomena of study."
      }, {
        "uuid": "01db2fc7-4291-5193-894d-72f533261d5e",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "interval",
        "definition": "A discrete segment along a longer path in which an observation or specimen is collected over the distance between the upper and lower bounds of the interval. A Depth Interval is a sub-type of Interval."
      }, {
        "uuid": "a45843e9-9a79-5452-a861-9892ef562741",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "observationWell",
        "definition": "A hole or shaft constructed in the earth intended to be used to locate, sample, or develop groundwater, oil, gas, or some other subsurface material. The diameter of a well is typically much smaller than the depth. Wells are also used to artificially recharge groundwater or to pressurize oil and gas production zones. Specific kinds of wells should be specified in the SamplingFeature description. For example, underground waste-disposal wells should be classified as waste injection wells."
      }, {
        "uuid": "5def3feb-2414-597c-bc7a-653c36699f10",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "profile",
        "definition": "A one-dimensional grid at fixed (x, y, t) coordinates within a four-dimensional (x, y, z, t) coordinate reference system. The grid axis is aligned with the coordinate reference system z-axis. Typically used to characterize or measure phenomena as a function of depth."
      }, {
        "uuid": "f92f142b-16ec-5113-80bc-48a9092e22a2",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "quadrat",
        "definition": "A small plot used to isolate a standard unit of area for study of the distribution of an item over a large area."
      }, {
        "uuid": "0312a065-c5a1-5e5b-a94d-ce4d77985d18",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "scene",
        "definition": "A two-dimensional visual extent within a physical environment."
      }, {
        "uuid": "c7b155f7-5ac0-5825-9f4f-def447fee071",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "shipsTrack",
        "definition": "A path along which a ship or vessel travels while measuring a phenomena of study.  Represented as a line connecting the ship's consecutive positions on the surface of the earth."
      }, {
        "uuid": "f49ed291-6bf8-5c3a-86aa-24c0d246f749",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "site",
        "definition": "A facility or location at which observations have been collected. A site may have instruments or equipment installed and may contain multiple other sampling features (e.g., a stream gage, weather station, observation well, etc.). Additionally, many specimen sampling features may be collected at a site. Sites are also often referred to as stations. A site is represented as a point, but it may have a geographical footprint that is not a point. The site coordinates serve as a reference for the site and offsets may be specified from this reference location."
      }, {
        "uuid": "1e82ac6d-f134-5c93-a808-6da41134fe50",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "soilPitSection",
        "definition": "Two-dimensional vertical face of a soil pit that is described and sampled."
      }, {
        "uuid": "91aea3ab-1ad3-58f9-a927-1d02e1bf1e5e",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "specimen",
        "definition": "A physical sample (object or entity) obtained for observations, typically performed ex situ, often in a laboratory.  "
      }, {
        "uuid": "937e0941-f65c-53a2-9b73-752ae405ff5d",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "streamGage",
        "definition": "A location used to monitor and test terrestrial bodies of water. Hydrometric measurements of water level, surface elevation (\"stage\") and/or volumetric discharge (flow) are generally taken, and observations of biota and water quality may also be made. "
      }, {
        "uuid": "18509cf0-513a-59c4-8680-2caef8e292e5",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "trajectory",
        "definition": "The path that a moving object follows through space as a function of time. A trajectory can be described by the geometry of the path or as the position of the object over time. "
      }, {
        "uuid": "07b43d26-383d-53f4-bdc1-6440562e180e",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "transect",
        "definition": "A path along which ocurrences of a phenomena of study are counted or measured."
      }, {
        "uuid": "c688be58-1d33-57ef-b3c7-aa26a6c824f3",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "traverse",
        "definition": "A field control network consisting of survey stations placed along a line or path of travel."
      }, {
        "uuid": "31d9e500-8189-5e76-a875-db70ce4d76e2",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "waterQualityStation",
        "definition": "A location used to monitor and test the quality of terrestrial bodies of water. Water quality stations may be locations at which physical water samples are collected for ex situ analysis.  Water qulaity stations may also have instruments and equipment for continuous, in situ measurement of water quality variables. "
      }, {
        "uuid": "7ed9e4f8-5293-5c7e-bf51-a6132249171e",
        "broader": "84891a73-5a36-5fba-83d5-c926f0953bd0",
        "label": "weatherStation",
        "definition": "A facility, either on land or sea, with instruments and equipment for measuring atmospheric conditions to provide information for weather forecasts and to study weather and climate."
      }]
    }, {
      "uuid": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Sitetype",
      "definition": "",
      "children": [{
        "uuid": "ae651596-1fb8-50f1-872f-8d2eb5760653",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "aggregateGroundwaterUse",
        "definition": "An Aggregate Groundwater Withdrawal/Return site represents an aggregate of specific sites where groundwater is withdrawn or returned which is defined by a geographic area or some other common characteristic. An aggregate groundwater site type is used when it is not possible or practical to describe the specific sites as springs or as any type of well including 'multiple wells', or when water-use information is only available for the aggregate. "
      }, {
        "uuid": "563cdb01-9592-5be4-8fb5-75adc114c534",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "aggregateSurfaceWaterUse",
        "definition": "An Aggregate Surface-Water Diversion/Return site represents an aggregate of specific sites where surface water is diverted or returned which is defined by a geographic area or some other common characteristic. An aggregate surface-water site type is used when it is not possible or practical to describe the specific sites as diversions, outfalls, or land application sites, or when water-use information is only available for the aggregate. "
      }, {
        "uuid": "1b16dd23-4654-5c70-afbf-26d569e78734",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "aggregateWaterUseEstablishment",
        "definition": "An Aggregate Water-Use Establishment represents an aggregate class of water-using establishments or individuals that are associated with a specific geographic location and water-use category, such as all the industrial users located within a county or all self-supplied domestic users in a county. An aggregate water-use establishment site type is used when specific information needed to create sites for the individual facilities or users is not available or when it is not desirable to store the site-specific information in the database. "
      }, {
        "uuid": "5377a362-a85d-5b23-a938-cd8c3de52370",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "animalWasteLagoon",
        "definition": "A facility for storage and/or biological treatment of wastes from livestock operations. Animal-waste lagoons are earthen structures ranging from pits to large ponds, and contain manure which has been diluted with building washwater, rainfall, and surface runoff. In treatment lagoons, the waste becomes partially liquefied and stabilized by bacterial action before the waste is disposed of on the land and the water is discharged or re-used."
      }, {
        "uuid": "e8ac2bc0-7f6c-531a-805b-dab618426a1d",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "atmosphere",
        "definition": "A site established primarily to measure meteorological properties or atmospheric deposition."
      }, {
        "uuid": "e7b6d549-b914-5ebb-b6ce-a60c706ae7ed",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "canal",
        "definition": "An artificial watercourse designed for navigation, drainage, or irrigation by connecting two or more bodies of water; it is larger than a ditch."
      }, {
        "uuid": "2fbaa9fe-5130-50ca-aa38-dcde6a2ba4ea",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "cave",
        "definition": "A natural open space within a rock formation large enough to accommodate a human. A cave may have an opening to the outside, is always underground, and sometimes submerged. Caves commonly occur by the dissolution of soluble rocks, generally limestone, but may also be created within the voids of large-rock aggregations, in openings along seismic faults, and in lava formations."
      }, {
        "uuid": "b2bc9c78-75e5-5286-b7f2-bbb17f9ee6c9",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "cistern",
        "definition": "An artificial, non-pressurized reservoir filled by gravity flow and used for water storage. The reservoir may be located above, at, or below ground level. The water may be supplied from diversion of precipitation, surface, or groundwater sources."
      }, {
        "uuid": "65a56ab3-a453-5f7d-93ed-c6c5f2241b22",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "coastal",
        "definition": "An oceanic site that is located off-shore beyond the tidal mixing zone (estuary) but close enough to the shore that the investigator considers the presence of the coast to be important. Coastal sites typically are within three nautical miles of the shore."
      }, {
        "uuid": "54af80a8-525b-5f8f-aae2-6f0c7b4dfdb1",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "combinedSewer",
        "definition": "An underground conduit created to convey storm drainage and waste products into a wastewater-treatment plant, stream, reservoir, or disposal site."
      }, {
        "uuid": "8d4a1425-2bd7-5f4e-85ce-dba522de1ae4",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "composite",
        "definition": "A Composite site represents an aggregation of specific sites defined by a geographic location at which multiple sampling features have been installed. For example, a composite site might consist of a location on a stream where a streamflow gage, weather station, and shallow groundwater well have been installed."
      }, {
        "uuid": "a892dd1d-387a-58c2-aca3-4a84d8867694",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "criticalZoneObservatories",
        "definition": "Critical Zone Observatories (CZOs) address pressing interdisciplinary scientific questions concerning geological, physical, chemical, and biological processes and their couplings that govern critical zone system dynamics."
      }, {
        "uuid": "df203bcf-8d90-506c-a81a-45ba6204a600",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "ditch",
        "definition": "An excavation artificially dug in the ground, either lined or unlined, for conveying water for drainage or irrigation; it is smaller than a canal."
      }, {
        "uuid": "7c33db70-0e10-57f0-b3d0-2b5c35bd260f",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "diversion",
        "definition": "A site where water is withdrawn or diverted from a surface-water body (e.g. the point where the upstream end of a canal intersects a stream, or point where water is withdrawn from a reservoir). Includes sites where water is pumped for use elsewhere."
      }, {
        "uuid": "f5eaa898-4948-5ae3-90c7-548978db0110",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "estuary",
        "definition": "A coastal inlet of the sea or ocean; esp. the mouth of a river, where tide water normally mixes with stream water (modified, Webster). Salinity in estuaries typically ranges from 1 to 25 Practical Salinity Units (psu), as compared oceanic values around 35-psu. See also: tidal stream and coastal."
      }, {
        "uuid": "5e3d3d70-2ca6-589c-812e-30f49a8ae6fe",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "facility",
        "definition": "A non-ambient location where environmental measurements are expected to be strongly influenced by current or previous activities of humans. *Sites identified with a \"facility\" primary site type must be further classified with one of the applicable secondary site types."
      }, {
        "uuid": "ee014615-203e-5471-aa65-52a4675ef907",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "fieldPastureOrchardOrNursery",
        "definition": "A water-using facility characterized by an area where plants are grown for transplanting, for use as stocks for budding and grafting, or for sale. Irrigation water may or may not be applied."
      }, {
        "uuid": "4dc4e68e-020b-5b9d-b7a6-b6a6652a32f0",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "glacier",
        "definition": "Body of land ice that consists of recrystallized snow accumulated on the surface of the ground and moves slowly downslope (WSP-1541A) over a period of years or centuries. Since glacial sites move, the lat-long precision for these sites is usually coarse."
      }, {
        "uuid": "32396b2c-82e0-53c3-8c3d-7883d2d579f1",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "golfCourse",
        "definition": "A place-of-use, either public or private, where the game of golf is played. A golf course typically uses water for irrigation purposes. Should not be used if the site is a specific hydrologic feature or facility; but can be used especially for the water-use sites."
      }, {
        "uuid": "93f7419e-faac-5136-b75a-abb5f8f1a30b",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "groundwaterDrain",
        "definition": "An underground pipe or tunnel through which groundwater is artificially diverted to surface water for the purpose of reducing erosion or lowering the water table. A drain is typically open to the atmosphere at the lowest elevation, in contrast to a well which is open at the highest point."
      }, {
        "uuid": "f8d529a7-8166-5ce7-be04-964ba7a36956",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "house",
        "definition": "A residential building for a single or small number of families."
      }, {
        "uuid": "8b620fd6-9d13-5be0-9a5c-b4443d68770b",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "hydroelectricPlant",
        "definition": "A facility that generates electric power by converting potential energy of water into kinetic energy. Typically, turbine generators are turned by falling water."
      }, {
        "uuid": "ba1ba36a-0173-584b-adff-5fdb26c2f1ca",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "laboratoryOrSamplePreparationArea",
        "definition": "A site where some types of quality-control samples are collected, and where equipment and supplies for environmental sampling are prepared. Equipment blank samples are commonly collected at this site type, as are samples of locally produced deionized water. This site type is typically used when the data are either not associated with a unique environmental data-collection site, or where blank water supplies are designated by Center offices with unique station IDs."
      }, {
        "uuid": "baf2da82-ccd7-5777-9f48-ab3fa5ac0860",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "lakeReservoirImpoundment",
        "definition": "An inland body of standing fresh or saline water that is generally too deep to permit submerged aquatic vegetation to take root across the entire body (cf: wetland). This site type includes an expanded part of a river, a reservoir behind a dam, and a natural or excavated depression containing a water body without surface-water inlet and/or outlet."
      }, {
        "uuid": "0ecea632-04e0-5977-b626-d15b08eccccf",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "land",
        "definition": "A location on the surface of the earth that is not normally saturated with water. Land sites are appropriate for sampling vegetation, overland flow of water, or measuring land-surface properties such as temperature. (See also: Wetland)."
      }, {
        "uuid": "7b333bb0-b28a-520e-b19f-ea536dc24878",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "landfill",
        "definition": "A typically dry location on the surface of the land where primarily solid waste products are currently, or previously have been, aggregated and sometimes covered with a veneer of soil. See also: Wastewater disposal and waste-injection well."
      }, {
        "uuid": "38b55fd3-a3af-593a-bfe5-ee2e07ea13e8",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "networkInfrastructure",
        "definition": "A site that is primarily associated with monitoring or telemetry network infrastructure. For example a radio repeater or remote radio base station."
      }, {
        "uuid": "13a3b7e9-1287-5555-9228-0b7b331a7f87",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "ocean",
        "definition": "Site in the open ocean, gulf, or sea. (See also: Coastal, Estuary, and Tidal stream)."
      }, {
        "uuid": "7f118668-c067-5404-92f4-8c7e617bc634",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "outcrop",
        "definition": "The part of a rock formation that appears at the surface of the surrounding land."
      }, {
        "uuid": "a49cb633-7e08-55ab-9c88-7b9c163997ff",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "outfall",
        "definition": "A site where water or wastewater is returned to a surface-water body, e.g. the point where wastewater is returned to a stream. Typically, the discharge end of an effluent pipe."
      }, {
        "uuid": "3c4919d0-59b9-50ca-bc75-6229a83c5577",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "pavement",
        "definition": "A surface site where the land surface is covered by a relatively impermeable material, such as concrete or asphalt. Pavement sites are typically part of transportation infrastructure, such as roadways, parking lots, or runways."
      }, {
        "uuid": "5b7f2e74-0bb2-5a7b-ab51-6fba86b44b3a",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "playa",
        "definition": "A dried-up, vegetation-free, flat-floored area composed of thin, evenly stratified sheets of fine clay, silt or sand, and represents the bottom part of a shallow, completely closed or undrained desert lake basin in which water accumulates and is quickly evaporated, usually leaving deposits of soluble salts."
      }, {
        "uuid": "0a114f95-d649-5693-9f59-100aa0715ec2",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "septicSystem",
        "definition": "A site within or in close proximity to a subsurface sewage disposal system that generally consists of: (1) a septic tank where settling of solid material occurs, (2) a distribution system that transfers fluid from the tank to (3) a leaching system that disperses the effluent into the ground."
      }, {
        "uuid": "4bd13a03-fcdf-5d0d-a78e-a2ee00a78999",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "shore",
        "definition": "The land along the edge of the sea, a lake, or a wide river where the investigator considers the proximity of the water body to be important. Land adjacent to a reservoir, lake, impoundment, or oceanic site type is considered part of the shore when it includes a beach or bank between the high and low water marks."
      }, {
        "uuid": "b50cc5af-d188-50f7-bf0a-6887f4363693",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "sinkhole",
        "definition": "A crater formed when the roof of a cavern collapses; usually found in limestone areas. Surface water and precipitation that enters a sinkhole usually evaporates or infiltrates into the ground, rather than draining into a stream."
      }, {
        "uuid": "0da5ac5e-ab76-5f78-974f-15b6da751f67",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "soilHole",
        "definition": "A small excavation into soil at the top few meters of earth surface. Soil generally includes some organic matter derived from plants. Soil holes are created to measure soil composition and properties. Sometimes electronic probes are inserted into soil holes to measure physical properties, and (or) the extracted soil is analyzed."
      }, {
        "uuid": "4f414ba8-a29e-508c-ae92-72d3c6893929",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "spring",
        "definition": "A location at which the water table intersects the land surface, resulting in a natural flow of groundwater to the surface. Springs may be perennial, intermittent, or ephemeral."
      }, {
        "uuid": "318ee6f6-a295-5e7e-991f-102ee3095614",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "stormSewer",
        "definition": "An underground conduit created to convey storm drainage into a stream channel or reservoir. If the sewer also conveys liquid waste products, then the \"combined sewer\" secondary site type should be used."
      }, {
        "uuid": "e6c62756-0130-544a-a6c9-8ea0639020b7",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "stream",
        "definition": "A body of running water moving under gravity flow in a defined channel. The channel may be entirely natural, or altered by engineering practices through straightening, dredging, and (or) lining. An entirely artificial channel should be qualified with the \"canal\" or \"ditch\" secondary site type."
      }, {
        "uuid": "f78ddf46-1680-5de4-a0ce-a9c4fab97baa",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "subsurface",
        "definition": "A location below the land surface, but not a well, soil hole, or excavation."
      }, {
        "uuid": "ed55b298-01ef-541a-9d40-76d9308435ac",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "thermoelectricPlant",
        "definition": "A facility that uses water in the generation of electricity from heat. Typically turbine generators are driven by steam. The heat may be caused by various means, including combustion, nuclear reactions, and geothermal processes."
      }, {
        "uuid": "b83f7cf9-dd44-5932-bdc3-66b9c5de1eb2",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "tidalStream",
        "definition": "A stream reach where the flow is influenced by the tide, but where the water chemistry is not normally influenced. A site where ocean water typically mixes with stream water should be coded as an estuary."
      }, {
        "uuid": "67616e42-9192-5107-a26a-db30f518510f",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "tunnelShaftMine",
        "definition": "A constructed subsurface open space large enough to accommodate a human that is not substantially open to the atmosphere and is not a well. The excavation may have been for minerals, transportation, or other purposes. See also: Excavation."
      }, {
        "uuid": "63953ee1-0773-555e-858f-2ec7852aed24",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "unknown",
        "definition": "Site type is unknown."
      }, {
        "uuid": "23db537b-6272-566e-ac30-768d46b85de1",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "unsaturatedZone",
        "definition": "A site equipped to measure conditions in the subsurface deeper than a soil hole, but above the water table or other zone of saturation."
      }, {
        "uuid": "c5b94ef7-c96f-550c-9747-8667ec26b087",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "volcanicVent",
        "definition": "Vent from which volcanic gases escape to the atmosphere. Also known as fumarole."
      }, {
        "uuid": "1dc869f8-d75f-561e-87eb-13de77edc965",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "wastewaterLandApplication",
        "definition": "A site where the disposal of waste water on land occurs. Use \"waste-injection well\" for underground waste-disposal sites."
      }, {
        "uuid": "9764c222-06f8-5656-8d26-54dc66b5dc84",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "wastewaterSewer",
        "definition": "An underground conduit created to convey liquid and semisolid domestic, commercial, or industrial waste into a treatment plant, stream, reservoir, or disposal site. If the sewer also conveys storm water, then the \"combined sewer\" secondary site type should be used."
      }, {
        "uuid": "1e07e19f-1f5c-516c-86c4-9858f7649ace",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "wastewaterTreatmentPlant",
        "definition": "A facility where wastewater is treated to reduce concentrations of dissolved and (or) suspended materials prior to discharge or reuse."
      }, {
        "uuid": "bbabe54e-2b46-54dd-8083-af6026d9ac7a",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "waterDistributionSystem",
        "definition": "A site located somewhere on a networked infrastructure that distributes treated or untreated water to multiple domestic, industrial, institutional, and (or) commercial users. May be owned by a municipality or community, a water district, or a private concern."
      }, {
        "uuid": "0f535c44-98c3-5a25-b8d4-badfaefc85a7",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "waterSupplyTreatmentPlant",
        "definition": "A facility where water is treated prior to use for consumption or other purpose."
      }, {
        "uuid": "bcaf4ad1-6df4-5c50-a432-afda8e59ac62",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "waterUseEstablishment",
        "definition": "A place-of-use (a water using facility that is associated with a specific geographical point location, such as a business or industrial user) that cannot be specified with any other facility secondary type. Water-use place-of-use sites are establishments such as a factory, mill, store, warehouse, farm, ranch, or bank. See also: Aggregate water-use-establishment."
      }, {
        "uuid": "7eaa21eb-42c5-542c-a0cb-652c3b532cd9",
        "broader": "bc9d1e2a-0971-5173-a4b7-5b8108490560",
        "label": "wetland",
        "definition": "Land where saturation with water is the dominant factor determining the nature of soil development and the types of plant and animal communities living in the soil and on its surface (Cowardin, December 1979). Wetlands are found from the tundra to the tropics and on every continent except Antarctica. Wetlands are areas that are inundated or saturated by surface or groundwater at a frequency and duration sufficient to support, and that under normal circumstances do support, a prevalence of vegetation typically adapted for life in saturated soil conditions. Wetlands generally include swamps, marshes, bogs and similar areas. Wetlands may be forested or unforested, and naturally or artificially created."
      }]
    }, {
      "uuid": "621f96eb-2422-5fc4-9137-7963e909e5c9",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Spatialoffsettype",
      "definition": "",
      "children": [{
        "uuid": "fd42181e-7204-543e-bc88-40f4e6c406ee",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "cartesianOffset",
        "definition": "Offset expressed using cartesian coordinates where X is distance along axis aligned with true north, Y is distace aligned with true east, and Z is distance aligned straight up. Depths are expressed a negative numbers. The origin of the coordinate system is typically defined in the site description. "
      }, {
        "uuid": "05de2584-c67d-508e-9c11-a641641a1dbc",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "depth",
        "definition": "Depth below the earth or water surface. Values are expressed as negative numbers and become more negative in the downward direction."
      }, {
        "uuid": "7b191de9-d761-5677-80b0-ae085fb37659",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "depthDirectional",
        "definition": "Depth below the earth or water surface along a non-vertical line. The first coordinate is the angle of the ray from north expressed in degrees. The second coordinate is the angle of the ray from horizontal expressed in negative degrees. The distance along the ray is expressed as a positive number that increases with distance along the ray. "
      }, {
        "uuid": "99d8ea31-299e-5b4b-8a03-d7fb91a720b3",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "depthInterval",
        "definition": "Depth interval below the earth or water surface. The mininum depth value is expressed first and then maximum depth value. Values are expresssed as negative numbers and become more negative in the downward direction."
      }, {
        "uuid": "cff8c430-90ff-587b-bcf0-138b8ad0b575",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "height",
        "definition": "Height above the earth or water surface. Values are positive and increase in the upward direction."
      }, {
        "uuid": "c8b764f3-1050-5e26-801c-35cfc1b99726",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "heightDirectional",
        "definition": "Height above the earth or water surface along a non-vertical line. The first coordinate is the angle of the ray from north expressed in degrees. The second coordinate is the angle of the ray from horizontal expressed in positive degrees. The distance along the ray is expressed as a positive number that increases with distance along the ray. "
      }, {
        "uuid": "129a7940-8302-57bd-b70e-6cb2056e2293",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "heightInterval",
        "definition": "Height interval above the earth or water surface. The minimum height value is expressed first and then the maximum height value. Values increase in the upward direction."
      }, {
        "uuid": "96552a50-0787-5347-bfe9-c6ed6ffdc9fc",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "longitudinalInterval",
        "definition": "Interval along a horizontal or longitudinal ray. The first coordinate is the angle from north expressed in degrees of the longitudinal array. The second coordinate is the minimum distance along the ray at which the longitudinal interval begins. The third coordinate is the maximium distance along the ray at which the longitudinal interval ends."
      }, {
        "uuid": "6c0bd52e-c86f-526c-9216-24d14483c35d",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "radialHorizontalOffset",
        "definition": "Offset expressed as a distance along a ray that originates from a central point. The angle of the ray is expressed as the first offset coordinate in degrees. The distance along the ray is expressed as the second offset coordinate."
      }, {
        "uuid": "74cafb8d-3508-5ecd-80a1-78a85e662ff0",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "radialHorizontalOffsetWithDepth",
        "definition": "Offset expressed as a distance along a ray that originates from a central point with a third coordinate that indicates the depth below the earth or water surface. The angle of the ray is expressed as the first offset coordinate in degrees. The distance along the ray is expressed as the second offset coordinate. The depth below the earth or water surface is expressed as the third coordinate."
      }, {
        "uuid": "c06159da-e317-56f1-9eba-1e17763f2f8e",
        "broader": "621f96eb-2422-5fc4-9137-7963e909e5c9",
        "label": "radialHorizontalOffsetWithHeight",
        "definition": "Offset expressed as a distance along a ray that originates from a central point with a third coordinate that indicates the height above the earth or water surface. The angle of the ray is expressed as the first offset coordinate in degrees. The distance along the ray is expressed as the second offset coordinate. The height above the earth or water surface is expressed as the third coordinate."
      }]
    }, {
      "uuid": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Speciation",
      "definition": "",
      "children": [{
        "uuid": "1b49b9eb-8e42-51f1-afbd-851cc52efd67",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ag",
        "definition": "Expressed as silver"
      }, {
        "uuid": "d682c280-1feb-5374-a4f8-e99c3c65c293",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Al",
        "definition": "Expressed as aluminium"
      }, {
        "uuid": "73b028e1-4455-5e18-8080-2fb3071fd9a5",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "As",
        "definition": "Expressed as arsenic"
      }, {
        "uuid": "b5cd5621-1d04-593a-88f8-2f41953b35ce",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "B",
        "definition": "Expressed as boron"
      }, {
        "uuid": "b4dfff13-d528-5c08-873e-c10f2f9c3676",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ba",
        "definition": "Expressed as barium"
      }, {
        "uuid": "98f36de1-2b46-5d39-85bb-748b29874663",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Be",
        "definition": "Expressed as beryllium"
      }, {
        "uuid": "3cd8dab4-bf40-5586-be33-0b81414f3c7a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Br",
        "definition": "Expressed as bromine"
      }, {
        "uuid": "d5c501af-1ca5-53f9-8ed4-ee3840b791e9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C",
        "definition": "Expressed as carbon"
      }, {
        "uuid": "4b24eaed-bbe3-591f-b642-8405f0afdaf4",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H10O4",
        "definition": "Expressed as dimethyl terephthalate"
      }, {
        "uuid": "1f9ce433-6765-5ad6-b31c-dbee3510ad1a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H4_CH3_4",
        "definition": "Expressed as tetramethylnaphthalene"
      }, {
        "uuid": "b7c0d55e-1fe9-535c-a6cb-b3baacba973d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H5_CH3_3",
        "definition": "Expressed as trimethylnaphthalene"
      }, {
        "uuid": "5a7a8933-c12f-5572-95f0-c7dcde9f17e8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H6_CH3_2",
        "definition": "Expressed as dimethylnaphthalene"
      }, {
        "uuid": "607cc9e0-8981-5b4b-9a88-b6f044cb1e9f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H7C2H5",
        "definition": "Expressed as ethylnaphthalene"
      }, {
        "uuid": "7ec640f2-6de4-508a-af50-dfa70e58faef",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H7CH3",
        "definition": "Expressed as methylnaphthalene"
      }, {
        "uuid": "bdefeec4-0ff6-5eb2-b5de-23a1e6feb744",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C10H8",
        "definition": "Expressed as naphthalene"
      }, {
        "uuid": "a2f74eed-b032-56a4-8270-dd2f0637e01a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H10",
        "definition": "Expressed as C12H10, e.g., acenaphthene, biphenyl"
      }, {
        "uuid": "88e8624c-7466-5d18-9957-f2d0531e4eaa",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H14O4",
        "definition": "Expressed as diethyl phthalate"
      }, {
        "uuid": "e6915c0f-790f-553f-a2fb-259c76ce175b",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H8",
        "definition": "Expressed as acenaphthylene"
      }, {
        "uuid": "23d2b0e1-8ca5-598e-a23d-f879bfa4e2a9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H8O",
        "definition": "Expressed as dibenzofuran"
      }, {
        "uuid": "451d1e66-8478-588c-9612-c4e19280f4ae",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H8S",
        "definition": "Expressed as dibenzothiophene"
      }, {
        "uuid": "6106d30a-85fa-51f5-89d2-a84b855a5dc8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C12H9N",
        "definition": "Expressed as carbazole"
      }, {
        "uuid": "b9a86001-2b78-587c-ae1b-3e2c987156ca",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C13H10",
        "definition": "Expressed as fluorene"
      }, {
        "uuid": "09b6adcd-2f9d-535d-8519-4679c7b913e3",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C13H10S",
        "definition": "Expressed as methyldibenzothiophene"
      }, {
        "uuid": "4179a62d-f51b-532e-8718-90e287d51780",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C14H10",
        "definition": "Expressed as phenanthrene"
      }, {
        "uuid": "5c39d986-078b-5088-ae5f-4bd2e84832af",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C14H12",
        "definition": "Expressed as methylfluorene"
      }, {
        "uuid": "3bbfea6b-b3fb-513b-a62f-dc855f127e09",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C15H12",
        "definition": "Expressed as C15H12, e.g., methylphenanthrene, Methylanthracene"
      }, {
        "uuid": "9aec4605-7afd-58e8-9099-480f9e2475d0",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C15H32",
        "definition": "Expressed as C15 n-alkane"
      }, {
        "uuid": "5e7d0016-747b-5ba2-999b-0c3e9e3624e2",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C16H10",
        "definition": "Expressed as C16H10, e.g., fluoranthene, pyrene"
      }, {
        "uuid": "8d018423-1cf0-5c24-ad50-7709282f785f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C16H14",
        "definition": "Expressed as dimethylphenanthrene"
      }, {
        "uuid": "33f1f610-48e6-5015-8c70-1a28a22f91a2",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C16H34",
        "definition": "Expressed as C16 n-alkane"
      }, {
        "uuid": "1e378fb1-64ef-5f32-b313-c3a193fd2a74",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C17H12",
        "definition": "Expressed as C17H12, e.g., benzo(a)fluorene, methylfluoranthene, methylpyrene"
      }, {
        "uuid": "4657ab79-b7ac-57eb-afda-30b49013233f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C17H36",
        "definition": "Expressed as C17 n-alkane"
      }, {
        "uuid": "2c439881-2eb0-55c3-b6f1-5610cc7ef875",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C18H12",
        "definition": "Expressed as C18H12, e.g., benz(a)anthracene, chrysene, triphenylene"
      }, {
        "uuid": "58dde4ed-4601-5f26-88f6-a89c5a7d48f4",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C18H18",
        "definition": "Expressed as retene"
      }, {
        "uuid": "f800cfcc-c258-554b-9b25-4b0ae90980f4",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C18H38",
        "definition": "Expressed as C18 n-alkane"
      }, {
        "uuid": "81c08f6e-bcd6-5b66-8902-57a29548f9ea",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C19H14",
        "definition": "Expressed as methylchrysene"
      }, {
        "uuid": "e299789c-b2cd-57a7-a2f0-a19a6fdc826a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C19H20O4",
        "definition": "Expressed as benzyl butyl pththalate"
      }, {
        "uuid": "e326be15-cc0d-59f1-bfdb-9b55cfb1f90b",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C19H40",
        "definition": "Expressed as C19 n-alkane"
      }, {
        "uuid": "4e0b81db-b96c-5034-b82b-95d08e26dd96",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C20H12",
        "definition": "Expressed as C20H12, e.g., benzo(b)fluoranthene, benzo(e)pyrene, perylene"
      }, {
        "uuid": "0d4b2447-08ad-5c4b-9899-1987b6268c0d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C20H42",
        "definition": "Expressed as C20 n-alkane"
      }, {
        "uuid": "b49eef7f-2f5f-574d-b189-756936cbb0c1",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C21H44",
        "definition": "Expressed as C21 n-alkane"
      }, {
        "uuid": "302655a1-4f21-553f-a33b-1f08c59ed147",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C22H14",
        "definition": "Expressed as Dibenz(a,h)anthracene"
      }, {
        "uuid": "960a28dc-72e2-5e0d-adb7-f59402932505",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C22H46",
        "definition": "Expressed as C22 n-alkane"
      }, {
        "uuid": "cf4b1e01-0ca5-5609-8842-771d9e2a27b3",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C23H48",
        "definition": "Expressed as C23 n-alkane"
      }, {
        "uuid": "6736ad51-2412-5d65-8a7c-9428852240f4",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C24H50",
        "definition": "Expressed as C24 n-alkane"
      }, {
        "uuid": "3d1b2f1e-c650-59cf-bb02-675f66417ee9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C25H52",
        "definition": "Expressed as C25 n-alkane"
      }, {
        "uuid": "e4f3a770-1b22-576b-a87d-c5315f3a6a4a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C26H54",
        "definition": "Expressed as C26 n-alkane"
      }, {
        "uuid": "a9fe01c7-56fd-5063-b07d-2ea920d146a4",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C27H56",
        "definition": "Expressed as C27 n-alkane"
      }, {
        "uuid": "d2ce6868-aca9-52e4-afe9-26ef3b1fa592",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C28H58",
        "definition": "Expressed as C28 n-alkane"
      }, {
        "uuid": "9f3ef2c9-79c1-5d0c-bde6-6d2b4064880a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C29H60",
        "definition": "Expressed as C29 n-alkane"
      }, {
        "uuid": "db615adc-17b8-5c74-8d83-93152f3e2cfe",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2Cl4",
        "definition": "Expressed as tetrachloroethylene"
      }, {
        "uuid": "ec2db8b6-126f-5cf4-a86f-0d9443582878",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2Cl6",
        "definition": "Expressed as hexachloroethane"
      }, {
        "uuid": "101dcbfd-6c7b-5900-ab1b-cb457ef17ba2",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H2Cl4",
        "definition": "Expressed as tetrachloroethane"
      }, {
        "uuid": "350c2cfb-091e-5933-b7de-faf1c1e1ceb3",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H3Cl",
        "definition": "Expressed as vinyl chloride"
      }, {
        "uuid": "c9d117a0-7607-5f54-9968-e61fada7e5d1",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H3Cl3",
        "definition": "Expressed as trichloroethane"
      }, {
        "uuid": "0b421fc8-3146-5c0c-98c4-09b4c38aacb7",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H4Cl2",
        "definition": "Expressed as dichloroethane"
      }, {
        "uuid": "4bfac99d-93f2-5ca4-98e5-26556a0b4a8d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H5Cl",
        "definition": "Expressed as chloroethane"
      }, {
        "uuid": "49c3bb9a-6d2f-50ea-b499-eb4e46eadb95",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H6",
        "definition": "Expressed as ethane"
      }, {
        "uuid": "9b07928e-8f87-54f4-b122-6419ea4e8b63",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2H6O2",
        "definition": "Expressed as Ethylene glycol"
      }, {
        "uuid": "91b74ea0-1cd7-537f-a131-5ac2601e8c7e",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C2HCl3",
        "definition": "Expressed as trichloroethylene"
      }, {
        "uuid": "adab307e-9610-5324-91fc-0f4cdd76ee9c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C31H64",
        "definition": "Expressed as C31 n-alkane"
      }, {
        "uuid": "d9a8ab38-acd4-53b0-9b94-366d55deae19",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C3H6O",
        "definition": "Expressed as acetone"
      }, {
        "uuid": "92921997-0e05-59f7-95f4-d6007352dad3",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C4Cl6",
        "definition": "Expressed as hexchlorobutadiene"
      }, {
        "uuid": "04d0932e-083d-52f7-b90a-fe6704c8873c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C4H8Cl2O",
        "definition": "Expressed as bis(chloroethyl) ether"
      }, {
        "uuid": "66b30339-65b5-51f2-beb3-b69d7fba5ff2",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C4H8O",
        "definition": "Expressed as butanone"
      }, {
        "uuid": "08d581c8-94c0-5b6d-86db-9c41b7f7e12c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C5Cl6",
        "definition": "Expressed as hexachlorocyclopentadiene"
      }, {
        "uuid": "0dd782d1-fa06-5efa-8098-6906feb5644f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6Cl6",
        "definition": "Expressed as hexachlorobenzene"
      }, {
        "uuid": "6f8d1915-30a8-5e66-afc8-62c38478cb30",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H3Cl3",
        "definition": "Expressed as trichlorobenzene"
      }, {
        "uuid": "11dacaac-a367-5334-a561-ea7187024c9f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H4_CH3_2",
        "definition": "Expressed as xylenes"
      }, {
        "uuid": "c575ce83-0820-5efb-a591-30377eb87a62",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H4Cl2",
        "definition": "Expressed as dichlorobenzene"
      }, {
        "uuid": "8e904af5-02cc-5b88-927c-509303c0c4db",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H4N2O5",
        "definition": "Expressed as dinitrophenol"
      }, {
        "uuid": "0ff59740-910e-5587-88eb-4c962ea3010c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H5Cl",
        "definition": "Expressed as chlorobenzene"
      }, {
        "uuid": "c0574c22-89c8-5e0f-9bef-93954968ff6a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H5NH2",
        "definition": "Expressed as aniline"
      }, {
        "uuid": "01f37fd5-2eb6-567f-a060-dd888b7fd26f",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H5NO2",
        "definition": "Expressed as nitrobenzene"
      }, {
        "uuid": "04cd1ec3-5a90-53ab-a1fa-06fecc82e26d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H5OH",
        "definition": "Expressed as phenol"
      }, {
        "uuid": "6793c463-1319-5e1c-b993-5652f36e6329",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6H6",
        "definition": "Expressed as benzene"
      }, {
        "uuid": "fbb1af27-d49c-59ee-b549-e62c59c50dba",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C6HCl5O",
        "definition": "Expressed as pentachlorophenol"
      }, {
        "uuid": "41428013-ae88-5aee-ba9c-a3b81388e44a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C7H6N2O4",
        "definition": "Expressed as dinitrotoluene"
      }, {
        "uuid": "c9c8fb71-e429-52e0-a064-55b11a7ca353",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C7H8",
        "definition": "Expressed as Toluene"
      }, {
        "uuid": "c6f64ea7-c4fb-53de-bd42-d2b1c841cfd2",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C8H10",
        "definition": "Expressed as ethylbenzene"
      }, {
        "uuid": "f1f52c42-104e-5c81-8bf1-799bc6241e08",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C8H8",
        "definition": "Expressed as styrene"
      }, {
        "uuid": "060dfc85-ffad-56b0-bd93-c26ff5f5a30d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "C9H14O",
        "definition": "Expressed as isophorone"
      }, {
        "uuid": "70b46811-fbbc-58f2-9d47-e1d1643822b1",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ca",
        "definition": "Expressed as calcium"
      }, {
        "uuid": "b1e6c54d-6e29-5640-b0eb-3b097217d14c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CaCO3",
        "definition": "Expressed as calcium carbonate"
      }, {
        "uuid": "af08228a-5476-565e-8f32-89db25011379",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Cd",
        "definition": "Expressed as cadmium"
      }, {
        "uuid": "8cd87718-0961-5306-94b7-dfcc692cf6e8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CH2Cl2",
        "definition": "Expressed as dichloromethane"
      }, {
        "uuid": "f327d7e0-491d-53e2-a61c-5bf88196d4ef",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CH3Br",
        "definition": "Expressed as bromomethane"
      }, {
        "uuid": "bef33cd8-f86b-5838-bf86-9404072dadef",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CH3Cl",
        "definition": "Expressed as chloromethane"
      }, {
        "uuid": "996bf5a6-93e3-57f4-b2ec-22de25495040",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CH3Hg",
        "definition": "Expressed at methylmercury"
      }, {
        "uuid": "7d4e619c-e1b3-5e07-be4a-5d5795e37c6d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CH4",
        "definition": "Expressed as methane"
      }, {
        "uuid": "b5f92f91-66e6-5813-ab01-d285f0ec7e7d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CHBr2Cl",
        "definition": "Expressed as dibromochloromethane"
      }, {
        "uuid": "224405e4-cfaf-5fa1-9bf0-8f18e2b86554",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CHBr3",
        "definition": "Expressed as bromoform"
      }, {
        "uuid": "c4bc83c7-232a-5cc3-85d2-d4c3ab61ea19",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CHBrCl2",
        "definition": "Expressed as bromodichloromethane"
      }, {
        "uuid": "ea7547b3-b6b7-58c2-8346-bedbf8f4a014",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CHCl3",
        "definition": "Expressed as chloroform"
      }, {
        "uuid": "e175be04-9179-59f0-9100-5f18999c51a0",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Cl",
        "definition": "Expressed as chlorine"
      }, {
        "uuid": "5334d779-cb5c-5c5d-8d14-0bb7703445a6",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CN-",
        "definition": "Expressed as cyanide"
      }, {
        "uuid": "7e0fec6a-39c6-59d6-83f3-f0fdbbf30ac9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Co",
        "definition": "Expressed as cobalt"
      }, {
        "uuid": "22e58278-a483-5225-be99-0f90f157fa0c",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CO2",
        "definition": "Expressed as carbon dioxide"
      }, {
        "uuid": "4ea6eb63-01b0-55e8-b7fc-6510ec9d1b40",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "CO3",
        "definition": "Expressed as carbonate"
      }, {
        "uuid": "5ba89178-d009-5011-aed2-f4d60b37cd95",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Cr",
        "definition": "Expressed as chromium"
      }, {
        "uuid": "f803c349-c295-53d4-b11d-a5ce218b31fa",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Cu",
        "definition": "Expressed as copper"
      }, {
        "uuid": "d76b3f68-c03f-52ab-b9d9-0caf887abe70",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "delta2H",
        "definition": "Expressed as deuterium"
      }, {
        "uuid": "62707456-98eb-5248-9bd0-9bf7a65f9952",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "deltaN15",
        "definition": "Expressed as nitrogen-15"
      }, {
        "uuid": "fb28f46c-ecd3-5cab-a05d-987bd033c032",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "deltaO18",
        "definition": "Expressed as oxygen-18"
      }, {
        "uuid": "c1a990bc-add7-5174-90ab-0c332c4a38b6",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "EC",
        "definition": "Expressed as electrical conductivity"
      }, {
        "uuid": "8d4b760f-2b36-5a62-b3ce-b414d30bac96",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "F",
        "definition": "Expressed as fluorine"
      }, {
        "uuid": "d7b5d7b9-8179-5aa3-9b95-ddcf52a31cdc",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Fe",
        "definition": "Expressed as iron"
      }, {
        "uuid": "1bc1220a-ecf6-5e4a-a1dc-988e48eb3f9d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "H2O",
        "definition": "Expressed as water"
      }, {
        "uuid": "6d4a94b1-743a-5b60-8168-d5dc27cb8a84",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "HCO3",
        "definition": "Expressed as hydrogen carbonate"
      }, {
        "uuid": "5f97669e-4585-5e7a-922e-7514c63250a5",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Hg",
        "definition": "Expressed as mercury"
      }, {
        "uuid": "dc090a90-1fa0-5708-bac2-9a400959a129",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "K",
        "definition": "Expressed as potassium"
      }, {
        "uuid": "4568e244-2061-5356-9a59-221e6134c116",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Mg",
        "definition": "Expressed as magnesium"
      }, {
        "uuid": "3b38629d-8037-5547-90eb-9bd8ce81b1ab",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Mn",
        "definition": "Expressed as manganese"
      }, {
        "uuid": "445520a3-ebcc-5cca-bcc5-486d38c3b9cd",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Mo",
        "definition": "Expressed as molybdenum"
      }, {
        "uuid": "11b462f4-ec68-5757-9aa2-122cb02fa7c8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "N",
        "definition": "Expressed as nitrogen"
      }, {
        "uuid": "50905f05-35c9-5932-afe8-b5673044ff0d",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Na",
        "definition": "Expressed as sodium"
      }, {
        "uuid": "9e68a089-9e71-5960-8c43-75f01201e8d1",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "NH4",
        "definition": "Expressed as ammonium"
      }, {
        "uuid": "66090e70-d438-5ccb-b93b-b82c4c59ab4a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ni",
        "definition": "Expressed as nickel"
      }, {
        "uuid": "c0556863-92cd-5cad-b855-c90ab5ada366",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "NO2",
        "definition": "Expressed as nitrite"
      }, {
        "uuid": "5a899389-6a6e-5b07-a081-8f078f2efdff",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "NO3",
        "definition": "Expressed as nitrate"
      }, {
        "uuid": "c8920dee-0ca8-5081-9614-ee26cd01af16",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "notApplicable",
        "definition": "Speciation is not applicable"
      }, {
        "uuid": "6a12ebbf-e305-53d3-aa88-146e2eb374d1",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "O2",
        "definition": "Expressed as oxygen (O2)"
      }, {
        "uuid": "112eec96-02a2-5038-979b-faa7208cde52",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "P",
        "definition": "Expressed as phosphorus"
      }, {
        "uuid": "a5f9701d-f229-5909-a889-96676dc3e8f8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Pb",
        "definition": "Expressed as lead"
      }, {
        "uuid": "98746571-c89f-5ee7-86d1-21598daae943",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "pH",
        "definition": "Expressed as pH"
      }, {
        "uuid": "b9632625-4f87-5193-9a88-660f791bb444",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "PO4",
        "definition": "Expressed as phosphate"
      }, {
        "uuid": "4a05d592-d05f-5270-a991-b40509f420bd",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ra",
        "definition": "Expressed as Radium. Also known as \"radium equivalent.\" The radium equivalent concept allows a single index or number to describe the gamma output from different mixtures of uranium (i.e., radium), thorium, and 40K in a material."
      }, {
        "uuid": "f1d467a9-8dd2-5983-8c38-abc7d1591336",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Re",
        "definition": "Expressed as rhenium"
      }, {
        "uuid": "e991be6a-74e9-506a-8d82-7dc4ad4a7dc0",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "S",
        "definition": "Expressed as Sulfur"
      }, {
        "uuid": "00b98122-872d-5a57-9453-f71a435ec096",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Sb",
        "definition": "Expressed as antimony"
      }, {
        "uuid": "7eb365e4-8703-5d2f-9918-cd2cdf9a3a52",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Se",
        "definition": "Expressed as selenium"
      }, {
        "uuid": "318eecb6-d1fa-51aa-9c47-a9cde9438e31",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Si",
        "definition": "Expressed as silicon"
      }, {
        "uuid": "35ebb721-0cd7-58a2-947a-89a517de2490",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "SiO2",
        "definition": "Expressed as silicate"
      }, {
        "uuid": "34b7d28a-368f-50a4-b546-04f8e277cb10",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Sn",
        "definition": "Expressed as tin"
      }, {
        "uuid": "70803a1d-ac5c-5808-89ec-869316570aa9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "SO4",
        "definition": "Expressed as Sulfate"
      }, {
        "uuid": "a258b3f3-3db1-5876-b753-eacfc672068a",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Sr",
        "definition": "Expressed as strontium"
      }, {
        "uuid": "b078dbc0-6501-5129-9235-89a82b398965",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "TA",
        "definition": "Expressed as total alkalinity"
      }, {
        "uuid": "e220a1fb-ac08-548a-9784-3677b7c6fcdb",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Th",
        "definition": "Expressed as thorium"
      }, {
        "uuid": "4a9d1b6a-6b73-562d-986b-81d9114a05b9",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Ti",
        "definition": "Expressed as titanium"
      }, {
        "uuid": "c45a8328-7564-571c-80bd-357c2b990597",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Tl",
        "definition": "Expressed as thallium"
      }, {
        "uuid": "3ebd969a-30e2-5fa9-a05f-c3a1bf3adc70",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "U",
        "definition": "Expressed as uranium"
      }, {
        "uuid": "1923ebdd-1705-50d4-98ac-833c87c2e2cf",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "unknown",
        "definition": "Speciation is unknown"
      }, {
        "uuid": "cea6a3a7-8eec-5447-b8be-d157840a1cc7",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "V",
        "definition": "Expressed as vanadium"
      }, {
        "uuid": "c266ec5a-da13-5aa9-9987-35bafad96ba8",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Zn",
        "definition": "Expressed as zinc"
      }, {
        "uuid": "1e01e289-8bc3-5bd1-9641-6dced51d0db5",
        "broader": "6a1ef8ee-d8ae-5951-9574-063ebecdcc55",
        "label": "Zr",
        "definition": "Expressed as zirconium"
      }]
    }, {
      "uuid": "36a1c0b3-a38f-577c-89f0-811435a046a1",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Specimentype",
      "definition": "",
      "children": [{
        "uuid": "838792a6-eb99-55b0-99db-273eab9eb002",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "automated",
        "definition": "Sample collected using an automated sampler."
      }, {
        "uuid": "353371af-f042-5b47-a9cb-a2bd4fff221c",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "biota",
        "definition": "A specimen consisting of all or part of a biological organism, including flora or fauna."
      }, {
        "uuid": "e573fd82-76e4-570a-b0be-189a205750e2",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "core",
        "definition": "Long cylindrical cores"
      }, {
        "uuid": "6dc76b29-c164-5b05-9238-dc68deaea21e",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreHalfRound",
        "definition": "Half-cylindrical products of along-axis split of a whole round"
      }, {
        "uuid": "59130d80-d784-50b9-a819-2ce03b7e5cc6",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "corePiece",
        "definition": "Material occurring between unambiguous (as curated) breaks in recovery."
      }, {
        "uuid": "feed0e06-cdb4-5f9d-87a0-64625441fce4",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreQuarterRound",
        "definition": "Quarter-cylindrical products of along-axis split of a half round."
      }, {
        "uuid": "0523fce0-1d6f-5c97-8617-24890ee25051",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreSection",
        "definition": "Arbitrarily cut segments of a core"
      }, {
        "uuid": "4e244043-4cd1-5f7b-bf04-7872fc25e3cc",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreSectionHalf",
        "definition": "Half-cylindrical products of along-axis split of a section or its component fragments through a selected diameter."
      }, {
        "uuid": "a6e6ac92-73bb-5e11-a353-fce947507cfd",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreSub-Piece",
        "definition": "Unambiguously mated portion of a larger piece noted for curatorial management of the material."
      }, {
        "uuid": "1ab41c00-f9b1-5c44-aa26-1c180351a0ac",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "coreWholeRound",
        "definition": "Cylindrical segments of core or core section material."
      }, {
        "uuid": "045c79b5-261b-5a27-8c6d-af19db5e216a",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "cuttings",
        "definition": "Loose, coarse, unconsolidated material suspended in drilling fluid."
      }, {
        "uuid": "a26a31db-1e59-592c-b4bb-b616e5b0c31b",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "dredge",
        "definition": "A group of rocks collected by dragging a dredge along the seafloor."
      }, {
        "uuid": "f4bdeab6-5105-52b8-8798-798f672a4641",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "foliageDigestion",
        "definition": "Sample that consists of a digestion of plant foliage"
      }, {
        "uuid": "dd46282f-23ae-5928-b1f4-4de4c65dfd78",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "foliageLeaching",
        "definition": "Sample that consists of leachate from foliage"
      }, {
        "uuid": "c36239dc-8ce4-57d4-80d0-ae3865b891dc",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "forestFloorDigestion",
        "definition": "Sample that consists of a digestion of forest floor material"
      }, {
        "uuid": "95bbdf42-c334-5fef-a099-71d7b933d7f5",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "grab",
        "definition": "A sample (sometimes mechanically collected) from a deposit or area, not intended to be representative of the deposit or area."
      }, {
        "uuid": "98fc7883-375b-5908-beaa-158a7419f96b",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "individualSample",
        "definition": "A sample that is an individual unit, including rock hand samples, a biological specimen, or a bottle of fluid."
      }, {
        "uuid": "631b1c3a-cebe-5895-a360-c257a2459714",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "litterFallDigestion",
        "definition": "Sample that consists of a digestion of litter fall"
      }, {
        "uuid": "7c1ceef5-c652-598b-a99b-5992e354555f",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "orientedCore",
        "definition": "Core that can be positioned on the surface in the same way that it was arranged in the borehole before extraction."
      }, {
        "uuid": "961294ea-58a3-5142-95dd-b152edaeb613",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "other",
        "definition": "Sample does not fit any of the existing type designations. It is expected that further detailed description of the particular sample be provided."
      }, {
        "uuid": "58ca7c56-1f28-5018-8cfd-c735adfe1c45",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "petriDishDryDeposition",
        "definition": "Sample from dry deposition in a petri dish"
      }, {
        "uuid": "e41f73b4-23a5-5adb-baec-31f72461ce08",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "precipitationBulk",
        "definition": "Sample from bulk precipitation"
      }, {
        "uuid": "f6caca21-7456-5302-ad2f-be083d6ce639",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "rockPowder",
        "definition": "A sample created from pulverizing a rock to powder."
      }, {
        "uuid": "b8b357f0-cc14-5622-8c58-5d2e0576c933",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "standardReferenceSpecimen",
        "definition": "Standard reference specimen"
      }, {
        "uuid": "924eaae3-bbe4-5cb0-b7ca-3fdcc6c8783d",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "terrestrialSection",
        "definition": "A sample of a section of the near-surface Earth, generally in the critical zone."
      }, {
        "uuid": "39b005a4-995e-5ff9-af67-28a31363d43c",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "theSpecimenTypeIsUnknown",
        "definition": "The specimen type is unknown"
      }, {
        "uuid": "57072355-9d0f-572f-be83-278f2c94e8cd",
        "broader": "36a1c0b3-a38f-577c-89f0-811435a046a1",
        "label": "thinSection",
        "definition": "Thin section"
      }]
    }, {
      "uuid": "6a67ec58-9df8-509f-8338-3a376aef009c",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Status",
      "definition": "",
      "children": [{
        "uuid": "d3c03aca-bff8-5f9e-9933-b1700eb4d8d3",
        "broader": "6a67ec58-9df8-509f-8338-3a376aef009c",
        "label": "complete",
        "definition": "Data collection is complete. No new data values will be added."
      }, {
        "uuid": "5e203932-0616-5bcc-b789-b59d41082dd3",
        "broader": "6a67ec58-9df8-509f-8338-3a376aef009c",
        "label": "ongoing",
        "definition": "Data collection is ongoing.  New data values will be added periodically."
      }, {
        "uuid": "410dc4c6-2af8-5c85-bc91-9ff2ff512a14",
        "broader": "6a67ec58-9df8-509f-8338-3a376aef009c",
        "label": "planned",
        "definition": "Data collection is planned. Resulting data values do not yet exist, but will eventually."
      }, {
        "uuid": "7a14e7d4-fd85-5faa-ade8-1f6a22b92146",
        "broader": "6a67ec58-9df8-509f-8338-3a376aef009c",
        "label": "unknown",
        "definition": "The status of data collection is unknown."
      }]
    }, {
      "uuid": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
      "broader": "4c20b77f-39d4-553e-9715-464e5862a8d7",
      "label": "Taxonomicclassifiertype",
      "definition": "",
      "children": [{
        "uuid": "18b0ceae-76ad-572e-b8bc-556fa367fef1",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "biology",
        "definition": "A taxonomy containing terms associated with biological organisms."
      }, {
        "uuid": "a87e0890-8b0c-5846-9b12-e5e17f4b0dca",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "chemistry",
        "definition": "A taxonomy containing terms associated with chemistry, chemical analysis or processes."
      }, {
        "uuid": "e3f47024-0235-511d-b753-aeafcad36f68",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "climate",
        "definition": "A taxonomy containing terms associated with the climate, weather, or atmospheric processes."
      }, {
        "uuid": "c06c3be7-2299-5ab8-915d-4741ff1cf04f",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "geology",
        "definition": "A taxonomy containing terms associated with geology or geological processes."
      }, {
        "uuid": "4ec58e96-7ad8-5667-a180-1576a0d8451f",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "hydrology",
        "definition": "A taxonomy containing terms associated with hydrologic variables or processes."
      }, {
        "uuid": "25c1c0ec-e1b8-5816-b303-997f2d82fa97",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "instrumentation",
        "definition": "A taxonomy containing terms associated with instrumentation and instrument properties such as battery voltages, data logger temperatures, often useful for diagnosis."
      }, {
        "uuid": "4b1787d5-4a25-5486-a7c2-4f931b039f4c",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "lithology",
        "definition": "A taxonomy containing terms associated with lithology."
      }, {
        "uuid": "f0d93f53-8af1-52bb-b022-e6d668459c5e",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "rock",
        "definition": "A taxonomy containing terms describing rocks."
      }, {
        "uuid": "19e4ba24-9f18-5b47-9a9d-40cf15cb27bc",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "soil",
        "definition": "A taxonomy containing terms associated with soil variables or processes"
      }, {
        "uuid": "810e4483-efa2-596b-89b3-20294e917b10",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "soilColor",
        "definition": "A taxonomy containing terms describing soil color."
      }, {
        "uuid": "3ab21462-f072-5cef-b680-7b2dfa2a7537",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "soilHorizon",
        "definition": "A taxonomy containing terms describing soil horizons."
      }, {
        "uuid": "71715c29-3d80-5373-b8b7-1d9d035bd22b",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "soilStructure",
        "definition": "A taxonomy containing terms describing soil structure."
      }, {
        "uuid": "d4b97864-559d-5057-9585-3714a1874400",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "soilTexture",
        "definition": "A taxonomy containing terms describing soil texture."
      }, {
        "uuid": "ebc67813-ab8c-5a35-8ed8-3877e590e4f8",
        "broader": "0cdf95b0-f566-50c1-82c2-5d3a0396937c",
        "label": "waterQuality",
        "definition": "A taxonomy containing terms associated with water quality variables or processes."
      }]
    }]
  }, {
    "uuid": "078fe853-7191-5edc-b990-3a7a68f04cc1",
    "broader": "65f4cf61-d55e-525d-80de-0b2fe9cbd1cd",
    "label": "ReSciColl Metadata Profile",
    "definition": "",
    "children": [{
      "uuid": "0f710406-b304-56a4-9e02-1a00874d36fa",
      "broader": "078fe853-7191-5edc-b990-3a7a68f04cc1",
      "label": "core",
      "definition": "Core metadata terms collections."
    }, {
      "uuid": "e3ee2e30-2ae6-57b2-94fe-e129ca62de07",
      "broader": "078fe853-7191-5edc-b990-3a7a68f04cc1",
      "label": "geologic",
      "definition": "Metadata terms for a geologic collection."
    }, {
      "uuid": "2b461b6d-1716-52b5-b516-dd2be4d1734e",
      "broader": "078fe853-7191-5edc-b990-3a7a68f04cc1",
      "label": "image",
      "definition": "Metadata terms for a image collection."
    }, {
      "uuid": "fa4bb3ce-dcd0-536b-b898-05a100448544",
      "broader": "078fe853-7191-5edc-b990-3a7a68f04cc1",
      "label": "invertebrate",
      "definition": "Metadata terms for a invertebrate collection."
    }, {
      "uuid": "fd9e0192-4bc1-5c63-af51-9214aa7e337a",
      "broader": "078fe853-7191-5edc-b990-3a7a68f04cc1",
      "label": "vertebrate",
      "definition": "Metadata terms for a vertebrate collection."
    }]
  }];
  _exports.default = _default;
});