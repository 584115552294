define("mdeditor/pods/components/object/md-taxonomy/classification/taxon/component", ["exports", "ember-cp-validations", "scroll-into-view-if-needed"], function (_exports, _emberCpValidations, _scrollIntoViewIfNeeded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'taxonomicName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'taxonomicLevel': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    init() {
      this._super(...arguments);

      this.collapse = this.preview && !this.parentItem;
    },

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.run.once(this, function () {
        this.set('model.commonName', Ember.getWithDefault(this, 'model.commonName', []));
        this.set('model.subClassification', Ember.getWithDefault(this, 'model.subClassification', []));
      });
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.model._edit) {
        this.startEditing();
        this.set('model._edit', false);
      }
    },

    spotlight: Ember.inject.service(),
    tagName: 'li',
    classNames: ['list-group-item', 'md-taxon'],
    classNameBindings: ['collapse'],
    isEditing: false,
    preview: false,
    level: Ember.computed('parent.level', function () {
      let parent = this.parentItem;
      return parent ? parent.get('level') + 1 : 0;
    }),
    padding: Ember.computed('level', function () {
      let pad = this.level + 0;
      return Ember.String.htmlSafe('padding-left: ' + pad + 'rem;');
    }),
    collapsible: Ember.computed('model.subClassification.[]', function () {
      return this.get('model.subClassification.length');
    }),
    taxonomicLevel: Ember.computed.alias('model.taxonomicLevel'),
    taxonomicName: Ember.computed.alias('model.taxonomicName'),
    taxonomicSystemId: Ember.computed.alias('model.taxonomicSystemId'),

    startEditing() {
      let id = 'body-' + this.elementId;
      let editor = 'editor-' + this.elementId;
      this.set('isEditing', true); // this.spotlight.setTarget('editor-' + this.elementId, this.stopEditing,this);

      this.spotlight.setTarget(id, this.stopEditing, this);
      (0, _scrollIntoViewIfNeeded.default)(document.getElementById(editor), {
        behavior: 'smooth' //scrollMode: 'if-needed',

      });
    },

    stopEditing() {
      this.set('isEditing', false);
    },

    deleteTaxa(taxa) {
      let parent = this.top || this.get('parentItem.model.subClassification');
      parent.removeObject(taxa);
    },

    addChild() {
      this.get('model.subClassification').pushObject({
        commonName: [],
        subClassification: [],
        _edit: true
      });
    },

    actions: {
      toggleCollapse(event) {
        event.stopPropagation();
        this.toggleProperty('collapse');
      },

      deleteTaxa(taxa) {
        this.deleteTaxa(taxa);
      },

      toggleEditing() {
        if (this.isEditing) {
          this.spotlight.close();
          this.set('isEditing', false);
          return;
        }

        this.startEditing();
      },

      addChild() {
        this.addChild();
      }

    }
  });

  _exports.default = _default;
});