define("mdeditor/pods/dictionary/show/edit/entity/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('entityId', params.entity_id);
      return this.setupModel();
    },

    breadCrumb: Ember.computed('entityId', function () {
      return {
        title: this.entityId
      };
    }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('setupModel', this.setupModel);
      this.controller.set('entityId', this.entityId);
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let entityId = this.entityId;
      let model = this.modelFor('dictionary.show.edit');
      let objects = model.get('json.dataDictionary.entity');
      let resource = entityId && Ember.isArray(objects) ? objects.objectAt(entityId) : undefined; //make sure the entity exists

      if (Ember.isEmpty(resource)) {
        this.flashMessages.warning('No Entity object found! Re-directing to list...');
        this.replaceWith('dictionary.show.edit.entity');
        return;
      }

      return resource;
    }

  });

  _exports.default = _default;
});