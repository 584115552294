define("mdeditor/pods/components/control/subbar-spatial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v8+XYLRG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"Geographic Extent Button Controls\"],[8],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-success btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"zoomAll\"]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"search\"],null],false],[0,\" Zoom All\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-warning btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"uploadData\"]],[8],[1,[28,\"fa-icon\",[\"upload\"],null],false],[0,\" Import Features\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-info btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"exportGeoJSON\"]],[8],[1,[28,\"fa-icon\",[\"download\"],null],false],[0,\" Export Features\"],[9],[0,\"\\n\"],[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\"],[\"btn btn-lg btn-danger btn-block md-btn-responsive\",[28,\"action\",[[23,0,[]],\"deleteAllFeatures\"],null]]],{\"statements\":[[0,\" \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\" Delete All\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-primary btn-block md-btn-responsive\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toList\"]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"list\"],null],false],[0,\" Back to List\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/subbar-spatial/template.hbs"
    }
  });

  _exports.default = _default;
});