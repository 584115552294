define("mdeditor/pods/record/show/edit/taxonomy/collection/itis/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iMoSS8Hu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Add Taxa from ITIS\"],[7,\"small\",true],[8],[0,\" Taxonomy Collection #\"],[1,[22,\"collectionId\"],false],[9],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"control/md-itis\",null,[[\"taxonomy\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Back to Collection\",\"arrow-left\",\"btn-primary btn-lg\",[28,\"route-action\",[\"toCollection\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/taxonomy/collection/itis/template.hbs"
    }
  });

  _exports.default = _default;
});