define("mdeditor/pods/components/control/md-button-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "af6ea2bN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"control/md-modal\",null,[[\"isShowing\",\"closeModal\",\"target\",\"renderInPlace\"],[[24,[\"isShowingModal\"]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"target\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"message\"]]],null],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"md-modal-buttons pull-right\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-danger\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[0,\"Confirm\"],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-button-modal/template.hbs"
    }
  });

  _exports.default = _default;
});