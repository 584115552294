define("mdeditor/mdeditor-sciencebase/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/sb-publisher.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/sb-publisher.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/sb-settings.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/sb-settings.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/sb-tree-label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/sb-tree-label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/publish/sciencebase.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/publish/sciencebase.hbs should pass TemplateLint.\n\n');
  });
});