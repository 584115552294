define("mdeditor/pods/components/control/md-record-table/buttons/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aofhSw4G",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showButton\"]]],null,{\"statements\":[[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\"],[\"btn btn-danger btn-block\",[28,\"action\",[[23,0,[]],\"deleteSelected\",[24,[\"selectedItems\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\" Delete Selected\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-record-table/buttons/filter/template.hbs"
    }
  });

  _exports.default = _default;
});