define("mdeditor/ember-json-tree/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/tree-branch.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/tree-branch.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/tree-label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/tree-label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/tree-leaf.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/tree-leaf.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/tree-search.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/tree-search.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/tree-view.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/tree-view.hbs should pass TemplateLint.\n\n');
  });
});