define("mdeditor/pods/components/object/md-transfer/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCMOusKT",
    "block": "{\"symbols\":[\"t\",\"&default\"],\"statements\":[[4,\"with\",[[24,[\"item\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isTable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[1,[23,1,[\"transferSize\"]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"if\",[[23,1,[\"onlineOption\",\"length\"]],[28,\"concat\",[\"yes(\",[23,1,[\"onlineOption\",\"length\"]],\")\"],null],\"no\"],null],false],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"if\",[[23,1,[\"offlineOption\",\"length\"]],[28,\"concat\",[\"yes(\",[23,1,[\"offlineOption\",\"length\"]],\")\"],null],\"no\"],null],false],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"if\",[[23,1,[\"distributionFormat\",\"length\"]],[28,\"concat\",[\"yes(\",[23,1,[\"distributionFormat\",\"length\"]],\")\"],null],\"no\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-transfer/preview/template.hbs"
    }
  });

  _exports.default = _default;
});