define("mdeditor/mixins/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    doCancel() {
      let controller = this.controller;
      let same = !controller.cancelScope || Ember.getOwner(this).lookup('controller:application').currentPath === Ember.get(controller, 'cancelScope.routeName');

      if (controller.onCancel) {
        Ember.run.once(() => {
          if (same) {
            controller.onCancel.call(controller.cancelScope || this);
          } else {
            controller.set('onCancel', null);
            controller.set('cancelScope', null);
          }

          this.refresh();
        });
      }
    }

  });

  _exports.default = _default;
});