define("mdeditor/pods/components/object/md-profile/custom/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    definitions: Ember.inject.service('profile'),
    schemas: Ember.inject.service(),
    notSelected: Ember.computed.setDiff('schemas.schemas', 'record.schemas'),
    selected: Ember.computed.alias('record.schemas'),
    // profileOptions: alias('profile.profiles'),
    actions: {
      setValue(selected) {
        this.record.set('profileId', selected ? selected.codeId : null);
      },

      selectItem(item) {
        // item.set('_animate', true);
        // item.set('_selected', true);
        Ember.run.later(this, function () {
          this.selected.pushObject(item);
          this.record.save();
        }, 250);
      },

      deselectItem(item) {
        // item.set('_selected', false);
        Ember.run.later(this, function () {
          this.selected.removeObject(item);
          this.record.save();
        }, 250);
      }

    }
  });

  _exports.default = _default;
});