define("mdeditor/pods/components/object/md-transfer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const Validations = buildValidations({
  //   // 'intervalAmount': [
  //   //   validator('presence', {
  //   //     presence: true,
  //   //     //disabled: computed.notEmpty('model.endDateTime'),
  //   //     ignoreBlank: true
  //   //   })
  //   // ],
  //   // 'startDateTime': [
  //   //   validator('presence', {
  //   //     presence: true,
  //   //     disabled: computed.notEmpty('model.endDateTime'),
  //   //     ignoreBlank: true
  //   //   })
  //   // ],
  //   // 'endDateTime': [
  //   //   validator('date', {
  //   //     onOrAfter: computed.alias('model.startDateTime'),
  //   //     isWarning: true
  //   //   }),
  //   //   validator('presence', {
  //   //     presence: true,
  //   //     disabled: computed.notEmpty('model.startDateTime'),
  //   //     ignoreBlank: true
  //   //   })
  //   // ]
  // });
  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      let model = Ember.get(this, 'model');
      Ember.run.once(function () {
        Ember.set(model, 'onlineOption', Ember.getWithDefault(model, 'onlineOption', []));
        Ember.set(model, 'offlineOption', Ember.getWithDefault(model, 'offlineOption', []));
        Ember.set(model, 'transferFrequency', Ember.getWithDefault(model, 'transferFrequency', {}));
        Ember.set(model, 'distributionFormat', Ember.getWithDefault(model, 'distributionFormat', [])); // set(model, 'presentationForm', getWithDefault(model,
        //   'presentationForm', []));
        // set(model, 'onlineResource', getWithDefault(model,
        //   'onlineResource', []));
        // set(model, 'identifier', getWithDefault(model, 'identifier', []));
        // set(model, 'graphic', getWithDefault(model, 'graphic', []));
      });
    },

    tagName: 'form',

    /**
     * The profile path for the component
     *
     * @property profilePath
     * @type {String}
     */
    // startDateTime: computed('model.startDateTime', {
    //   get(){
    //     return get(this, 'model.startDateTime');
    //   },
    //   set(key, value) {
    //     once(this,function() {
    //       set(this, 'model.startDateTime', value);
    //       return value;
    //     });
    //   }
    // }),
    // endDateTime: computed('model.endDateTime', {
    //   get(){
    //     return get(this, 'model.endDateTime');
    //   },
    //   set(key, value) {
    //     once(this,function() {
    //       set(this, 'model.endDateTime', value);
    //       return value;
    //     });
    //   }
    // }),
    formatUri: Ember.computed.alias('model.distributionFormat.firstObject.formatSpecification.title'),
    timeUnit: Ember.computed(function () {
      return [{
        name: 'year',
        value: 'year'
      }, {
        name: 'month',
        value: 'month'
      }, {
        name: 'day',
        value: 'day'
      }, {
        name: 'hour',
        value: 'hour'
      }, {
        name: 'minute',
        value: 'minute'
      }, {
        name: 'second',
        value: 'second'
      }];
    }),
    formatTemplate: Ember.Object.extend(
    /*Validations, */
    {
      init() {
        this._super(...arguments);

        this.set('formatSpecification', {});
        this.set('formatSpecification.onlineResource', [{}]);
      }

    })
  });

  _exports.default = _default;
});