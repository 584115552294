define("mdeditor/services/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      let store = this.store;
      this.set('contacts', store.peekAll('contact'));
    },

    store: Ember.inject.service(),
    contacts: Ember.A(),
    organizations: Ember.computed('contacts.[]', function () {
      let orgs = this.contacts.filterBy('json.isOrganization');
      return orgs;
    }),
    individuals: Ember.computed('contacts.[]', function () {
      let ind = this.contacts.rejectBy('json.isOrganization');
      return ind;
    }),
    organizationsCodes: Ember.computed('contactsCodes.[]', function () {
      let orgs = this.contactsCodes.filterBy('icon', 'users');
      return orgs;
    }),
    individualsCodes: Ember.computed('contactsCodes.[]', function () {
      let ind = this.contactsCodes.rejectBy('icon', 'users');
      return ind;
    }),

    /**
     * mapped is a re-mapped array of code objects.
     *
     * @property mapped
     * @type {Array}
     * @category computed
     * @requires mdCodeName
     */
    contactsCodes: Ember.computed('contacts.@each.name', function () {
      //let codeId = this.get('valuePath');
      //let codeName = this.get('namePath');
      //let tooltip = this.get('tooltipPath');
      let codelist = [];
      let icons = this.icons;
      let defaultIcon = this.defaultIcon;
      let mdCodelist = this.contacts.sortBy('title');
      mdCodelist.forEach(function (item) {
        let newObject = Ember.Object.create({
          codeId: item.get('contactId'),
          codeName: item.get('title'),
          tooltip: item.get('combinedName'),
          icon: item.get('icon') || icons.get(defaultIcon)
        });
        codelist.pushObject(newObject);
      });
      return codelist;
    })
  });

  _exports.default = _default;
});