define("mdeditor/pods/dictionary/show/edit/citation/identifier/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    model(params) {
      this.set('identifierId', params.identifier_id);
      return this.setupModel();
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('dictionary.show.edit.citation.index'));
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let identifierId = this.identifierId;
      let model = this.modelFor('dictionary.show.edit.citation');
      let identifiers = model.get('json.dataDictionary.citation.identifier');
      let identifier = identifierId && Ember.isArray(identifiers) ? identifiers.get(identifierId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(identifier)) {
        this.flashMessages.warning('No identifier found! Re-directing to citation...');
        this.replaceWith('dictionary.show.edit.citation.index');
        return;
      }

      return identifier;
    }

  });

  _exports.default = _default;
});