define("mdeditor/services/codelist", ["exports", "mdcodes/resources/js/mdcodes.js"], function (_exports, _mdcodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Codelist Service
     *
     * This service provides controlled value lists for use in the editor. The
     * service may be customized by modifing the object in init. The existing
     * property names should be maintained.
     *
     * @module mdeditor
     * @submodule service
     * @class codelist
     */
    init() {
      this._super(...arguments);

      let codelist = this; //remap codelist names to be more generic

      Object.keys(_mdcodes.default).forEach(function (key) {
        if (key === 'default') {
          return;
        }

        const list = _mdcodes.default[key];
        const name = key.replace(/^iso_|adiwg_/, '');
        codelist[name] = list; //remove deprecated codes

        codelist[name]['codelist'] = list.codelist.rejectBy('deprecated');
      });
    },

    /**
     * Custom Profiles service
     *
     * @property customProfiles
     */
    customProfiles: Ember.inject.service('custom-profile'),

    /**
     * The profiles codelist, updates when number of Custom Profiles change.
     *
     * @property profile
     * @type {Object}
     * @category computed
     * @required customProfiles.profiles{[],@each.title}
     */
    profile: Ember.computed('customProfiles.profiles.{[],@each.title}', function () {
      return {
        codelist: this.customProfiles.profiles.map(itm => {
          return {
            code: itm.id,
            codeName: itm.title,
            description: itm.description
          };
        })
      };
    }),

    /**
     * Codelist item title overrides
     *
     * @property codeOverrides
     * @type {Object}
     * @category computed
     * @required profile
     */
    codeOverrides: Ember.computed('profile', function () {
      return {
        scope: {
          dataset: "geographicDataset"
        }
      };
    })
  });

  _exports.default = _default;
});