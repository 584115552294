define("mdeditor/pods/components/input/md-select/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * A select list control for displaying and selecting options
     * provided in an array or promise array.
     *
     * ```handlebars
     * \{{input/md-select
     *  label="Measure Type"
     *  showValidations=true
     *  model=this
     *  path="measureType"
     *  valuePath="value"
     *  namePath="name"
     *  objectArray=typeOptions
     *  tooltip=true
     *  tooltipPath="tip"
     *  searchEnabled=false
     *  disabled=measureDisabled
     *  placeholder="The type of measurement."
     *  profilePath=(concat profilePath ".measure.type")
     * }}
     * ```
     *
     * @class md-select
     * @constructor
     */
    init() {
      this._super(...arguments);

      let model = this.model;
      let path = this.path;

      if (Ember.isNone(model) !== Ember.isNone(path)) {
        (true && !(false) && Ember.assert("You must supply both model and path to ".concat(this.toString(), " or neither.")));
      }

      if (!Ember.isBlank(model)) {
        if (this.get("model.".concat(path)) === undefined) {
          Ember.debug("model.".concat(path, " is undefined in ").concat(this.toString(), ".")); //Ember.run.once(()=>model.set(path, ""));
        }

        Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(path)));
        Ember.defineProperty(this, 'validation', Ember.computed.alias("model.validations.attrs.".concat(path)).readOnly());
        Ember.defineProperty(this, 'required', Ember.computed('validation.options.presence.{presence,disabled}', 'disabled', function () {
          return !this.disabled && this.get('validation.options.presence.presence') && !this.get('validation.options.presence.disabled');
        }).readOnly());
        Ember.defineProperty(this, 'notValidating', Ember.computed.not('validation.isValidating').readOnly());
        Ember.defineProperty(this, 'hasContent', Ember.computed.notEmpty('value').readOnly());
        Ember.defineProperty(this, 'hasWarnings', Ember.computed.notEmpty('validation.warnings').readOnly());
        Ember.defineProperty(this, 'isValid', Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly());
        Ember.defineProperty(this, 'shouldDisplayValidations', Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly());
        Ember.defineProperty(this, 'showErrorClass', Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly());
        Ember.defineProperty(this, 'showErrorMessage', Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly());
        Ember.defineProperty(this, 'showWarningMessage', Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly());
      }
    },

    classNames: ['md-select'],
    classNameBindings: ['formGroup', 'required'],
    attributeBindings: ['data-spy'],
    formGroup: Ember.computed.notEmpty('label'),
    icons: Ember.inject.service('icon'),

    /**
     * An array or promise array containing the options for the
     * select list.
     * At a minimum the array elements should provide attributes for the
     * name value pairs displayed as select list options.
     * Tooltips may also be included.
     * Other attributes in the array elements will be ignored.
     *
     * ```javascript
     * {
     *   name: 'displayed',
     *   value: 'option',
     *   type: 'xtra info',
     *   tip: 'tooltip'
     * }
     * ```
     *
     * @property objectArray
     * @type Array
     * @required
     */

    /**
     * The initial value of the select. Type must match the type of the attribute
     * identified by the path option.
     *
     * @property value
     * @type Any
     * @required
     */
    value: null,

    /**
     * Path in the model to be used for the select list's option value. Both
     * `model` and `path` must be supplied together.
     *
     * @property path
     * @type String
     */

    /**
     * The model to be used to compute the value alias, generally used for
     * validations. Both `model` and `path` must be supplied together.
     *
     * @property model
     * @type String
     */

    /**
     * Name of the attribute in the objectArray to be used for the
     * select list's option value.
     *
     * @property valuePath
     * @type String
     * @required
     */

    /**
     * Name of the attribute in the objectArray to be used for the
     * select list's option name or display text.
     *
     * @property namePath
     * @type String
     * @required
     */

    /**
     * Indicates if icons should be rendered.
     *
     * @property icon
     * @type Boolean
     * @default false
     */
    icon: false,

    /**
     * Indicates if value is required.
     *
     * @property required
     * @type Boolean
     * @default false
     */
    required: false,

    /**
     * The default icon.
     *
     * @property defaultIcon
     * @type {String}
     * @default defaultList
     * @required
     */
    defaultIcon: 'defaultList',

    /**
     * Indicates if tooltips should be rendered for the options.
     *
     * @property tooltip
     * @type Boolean
     * @default false
     */
    tooltip: false,

    /**
     * Name of the attribute in the objectArray to be used for the
     * select list's tooltip.  If null, no tooltip will be
     * generated.
     *
     * @property tooltipPath
     * @type String
     * @default null
     */
    tooltipPath: null,

    /**
     * Whether to render a button to clear the selection.
     *
     * @property allowClear
     * @type Boolean
     * @default false
     */
    allowClear: false,

    /**
     * Whether to render the search input.
     *
     * @property searchEnabled
     * @type Boolean
     * @default true
     */
    searchEnabled: true,

    /**
     * Whether to disable the select.
     *
     * @property disabled
     * @type Boolean
     * @default false
     */
    disabled: false,

    /**
     * Whether to close the selection list after a selection has been made.
     *
     * @property closeOnSelect
     * @type Boolean
     * @default true
     */
    closeOnSelect: true,

    /**
     * The string to display when no option is selected.
     *
     * @property placeholder
     * @type String
     * @default 'Select one option'
     */
    placeholder: "Select one option",

    /**
     * Form label for select list
     *
     * @property label
     * @type String
     * @default null
     */
    label: null,
    ariaLabel: Ember.computed('label', function () {
      return this.label;
    }),

    /**
     * Indicates whether to allow the user to enter a new option
     * not contained in the select list.
     *
     * @property create
     * @type Boolean
     * @default false
     */
    create: false,

    /**
     * If set, removes the option with the specified id from the list. By default,
     * it will hide options with a null id.
     *
     * @property filterId
     * @type {String|Number|null}
     * @default null
     * @optional
     */
    filterId: null,

    /**
     * The component to render
     *
     * @property theComponent
     * @type Ember.computed
     * @return String
     */
    theComponent: Ember.computed('create', function () {
      return this.create ? 'power-select-with-create' : 'power-select';
    }),

    /**
     * Callback after value is updated, usually an action is passed.
     *
     * @method change
     */
    change() {},

    /**
     * The currently selected item in the codelist
     *
     * @property selectedItem
     * @type Ember.computed
     * @return PromiseObject
     */
    selectedItem: Ember.computed('value', function () {
      let value = this.value;
      return _emberData.default.PromiseObject.create({
        promise: this.codelist.then(function (arr) {
          return arr.find(item => {
            return item['codeId'] === value;
          });
        })
      });
    }),

    /**
     * codelist is an array of code objects re-mapped from the input 'objectArray'.
     * Values from the input object array are mapped according the path parameters
     * provided.
     *
     * @property codelist
     * @type Ember.computed
     * @return PromiseArray
     */
    codelist: Ember.computed('objectArray', function () {
      const objArray = this.objectArray;
      let inList = new Ember.RSVP.Promise(function (resolve, reject) {
        // succeed
        resolve(objArray); // or reject

        reject(new Error('Couldn\'t create a promise.'));
      });
      let codeId = this.valuePath;
      let codeName = this.namePath;
      let tooltip = this.tooltipPath;
      let icons = this.icons;
      let defaultIcon = this.defaultIcon;
      let outList = Ember.A();
      return _emberData.default.PromiseArray.create({
        promise: inList.then(function (arr) {
          arr.forEach(function (item) {
            let newObject = {
              codeId: Ember.get(item, codeId),
              codeName: Ember.get(item, codeName),
              tooltip: false,
              icon: icons.get(item[codeName].toString()) || icons.get(defaultIcon)
            };

            if (tooltip) {
              newObject.tooltip = Ember.get(item, tooltip);
            }

            outList.pushObject(newObject);
          });
          return outList;
        })
      });
    }),

    /**
     * Creates a new codelist entry. The codeId and codeName are both set to the
     * passed value.
     *
     * @method createCode
     * @param  {String} code The code
     * @return {Object}      Returns a new codelist object
     */
    createCode(code) {
      return {
        codeId: code,
        codeName: code,
        tooltip: false
      };
    },

    /**
     * Set the value on the select.
     *
     * @method setValue
     * @param {Object} selected The object with the value(codeName) to set.
     */
    setValue(selected) {
      let val = selected ? selected.codeId : null;
      this.set('value', val);
      this.change();
    },

    actions: {
      // do the binding to value
      setValue(selected) {
        this.setValue(selected);
      },

      create(selected) {
        let code = this.createCode(selected);
        this.codelist.pushObject(code);
        this.setValue(code);
      }

    }
  });

  _exports.default = _default;
});