define("mdeditor/pods/record/show/edit/keywords/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    keyword: Ember.inject.service(),

    async beforeModel() {
      console.log('firing beforeModel on edit/keywords route');
      await this.keyword.fetchSciencebaseVocab();
    },

    actions: {
      addKeyword(model, obj) {
        let k = obj ? obj : {};
        model.pushObject(k);
      },

      deleteKeyword(model, obj) {
        if (typeof obj === 'number') {
          model.removeAt(obj);
        } else {
          model.removeObject(obj);
        }
      }

    }
  });

  _exports.default = _default;
});