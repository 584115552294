define("mdeditor/pods/components/layout/md-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tjz4Adhy",
    "block": "{\"symbols\":[\"slider\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"close huge-text\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleVisibility\"]],[8],[0,\"\\n  Close\\n  \"],[1,[28,\"fa-icon\",[\"caret-right\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"md-slider-body\"],[8],[0,\"\\n\"],[4,\"from-elsewhere\",null,[[\"name\"],[[24,[\"name\"]]]],{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"text-info\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[1,[28,\"component\",[[23,1,[\"body\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/layout/md-slider/template.hbs"
    }
  });

  _exports.default = _default;
});