define("mdeditor/pods/record/show/edit/lineage/lineageobject/step/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.set('stepId', params.step_id);
      this.set('lineageId', this.paramsFor('record.show.edit.lineage.lineageobject').lineage_id);
      return this.setupModel();
    },

    breadCrumb: Ember.computed('stepId', function () {
      return {
        title: 'Step ' + Ember.get(this, 'stepId'),
        linkable: true
      };
    }),
    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      this.controller.set('parentModel', this.modelFor('record.show.edit'));
      this.controller.set('stepId', Ember.get(this, 'stepId'));
      this.controllerFor('record.show.edit').setProperties({
        onCancel: this.setupModel,
        cancelScope: this
      });
    },

    setupModel() {
      let stepId = Ember.get(this, 'stepId');
      let lineageId = Ember.get(this, 'lineageId');
      let model = this.modelFor('record.show.edit');
      let steps = model.get('json.metadata.resourceLineage.' + lineageId + '.processStep');
      let step = stepId && Ember.isArray(steps) ? steps.get(stepId) : undefined; //make sure the identifier exists

      if (Ember.isEmpty(step)) {
        Ember.get(this, 'flashMessages').warning('No Process Step found! Re-directing...');
        this.replaceWith('record.show.edit.lineage.lineageobject');
        return;
      }

      return step;
    },

    actions: {
      parentModel() {
        return this.modelFor('record.show.edit');
      }

    }
  });

  _exports.default = _default;
});