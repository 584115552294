define("mdeditor/pods/components/object/md-documentation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwfRYYps",
    "block": "{\"symbols\":[\"citation\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[1,[28,\"object/md-resource-type-array\",null,[[\"plain\",\"value\",\"required\",\"data-spy\",\"profilePath\"],[false,[24,[\"model\",\"resourceType\"]],true,\"Resource Types\",[28,\"concat\",[[24,[\"profilePath\"]],\".resourceType\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"with\",[[24,[\"model\",\"citation\",\"firstObject\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-citation\",null,[[\"model\",\"profilePath\",\"simpleIdentifier\"],[[23,1,[]],[28,\"concat\",[[24,[\"profilePath\"]],\".citation\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-documentation/template.hbs"
    }
  });

  _exports.default = _default;
});