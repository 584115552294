define("mdeditor/pods/settings/validation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import EmberObject from '@ember/object';
  var _default = Ember.Route.extend({
    model() {
      return this.store.findAll('schema');
    },

    schemas: Ember.inject.service()
  });

  _exports.default = _default;
});