define("mdeditor/pods/components/object/md-srs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oS0GP0UB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input/md-codelist\",null,[[\"create\",\"label\",\"tooltip\",\"icon\",\"disabled\",\"allowClear\",\"showValidations\",\"mdCodeName\",\"path\",\"model\",\"placeholder\",\"profilePath\",\"class\"],[true,\"Reference System Type\",true,false,false,true,true,\"referenceSystemType\",\"refType\",[23,0,[]],\"Select type of reference system used.\",[28,\"concat\",[[24,[\"profilePath\"]],\".referenceSystemType\"],null],[28,\"if\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"isInvalid\"],null],\"required\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"referenceSystemIdentifier\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"layout/md-card\",null,[[\"collapsible\",\"collapsed\",\"data-spy\",\"shadow\",\"profilePath\"],[false,false,false,false,[28,\"concat\",[[24,[\"profilePath\"]],\".referenceSystemIdentifier\"],null]]],{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[28,\"concat\",[\"text-info\",[28,\"if\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"isInvalid\"],null],\" required\"],null]],null]],[8],[0,\"Reference System Identifier\"],[9],[0,\"\\n\\n  \"],[1,[28,\"object/md-identifier\",null,[[\"model\",\"profilePath\",\"data-spy\"],[[24,[\"model\",\"referenceSystemIdentifier\"]],[28,\"concat\",[[24,[\"profilePath\"]],\".referenceSystemIdentifier\"],null],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-srs/template.hbs"
    }
  });

  _exports.default = _default;
});