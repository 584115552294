define("mdeditor/services/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.get('router.currentRouteName');
    },

    router: Ember.inject.service(),
    showSlider: false,
    fromName: 'md-slider-content',
    routeObserver: Ember.observer('router.currentRouteName', function () {
      this.toggleSlider(false);
      this.set('fromName', 'md-slider-content');
    }),

    onClose() {},

    toggleSlider(state) {
      if (state === undefined) {
        this.toggleProperty('showSlider');
        return;
      }

      this.set('showSlider', !!state);
    }

  });

  _exports.default = _default;
});