define("mdeditor/pods/components/object/md-taxonomy/collection/system/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZY5vcspp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input/md-textarea\",null,[[\"value\",\"required\",\"rows\",\"profilePath\",\"label\",\"placeholder\",\"data-spy\",\"showValidations\"],[[24,[\"model\",\"modifications\"]],false,3,[28,\"concat\",[[24,[\"profilePath\"]],\".modifications\"],null],\"Modifications\",\"Description of any modifications or exceptions made to the classification system used.\",\"Modifications\",true]]],false],[0,\"\\n\\n\"],[0,\"  \"],[1,[28,\"object/md-citation\",null,[[\"model\",\"simpleIdentifier\",\"profilePath\",\"embedded\",\"data-spy\"],[[24,[\"model\",\"citation\"]],true,[28,\"concat\",[[24,[\"profilePath\"]],\".citation\"],null],false,true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-taxonomy/collection/system/template.hbs"
    }
  });

  _exports.default = _default;
});