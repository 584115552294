define("mdeditor/pods/components/input/md-datetime/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Datetime control with dropdown calendar.
     * Based on Bootstrap datetime picker.
     *
     * @class md-datetime
     * @constructor
     */
    init() {
      this._super(...arguments);

      let model = this.model;
      let valuePath = this.valuePath;

      if (Ember.isBlank(model) !== Ember.isBlank(valuePath)) {
        (true && !(false) && Ember.assert("You must supply both model and valuePath to ".concat(this.toString(), " or neither.")));
      }

      if (!Ember.isBlank(model)) {
        if (this.get("model.".concat(valuePath)) === undefined) {
          Ember.debug("model.".concat(valuePath, " is undefined in ").concat(this.toString(), "."));
        }

        Ember.defineProperty(this, '_date', Ember.computed("model.".concat(valuePath), {
          get() {
            let val = Ember.get(this, "model.".concat(valuePath));
            return val ? (0, _moment.default)(val, this.get('altFormat' || null)) : null;
          },

          set(key, value) {
            let formatted = this.formatValue(value, "model.".concat(valuePath));
            return formatted;
          }

        }));
        Ember.defineProperty(this, 'validation', Ember.computed.alias("model.validations.attrs.".concat(valuePath)).readOnly());
        Ember.defineProperty(this, 'required', Ember.computed('validation.options.presence.{presence,disabled}', function () {
          return this.get('validation.options.presence.presence') && !this.get('validation.options.presence.disabled');
        }).readOnly());
        Ember.defineProperty(this, 'notValidating', Ember.computed.not('validation.isValidating').readOnly());
        Ember.defineProperty(this, 'hasContent', Ember.computed.notEmpty('date').readOnly());
        Ember.defineProperty(this, 'hasWarnings', Ember.computed.notEmpty('validation.warnings').readOnly());
        Ember.defineProperty(this, 'isValid', Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly());
        Ember.defineProperty(this, 'shouldDisplayValidations', Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly());
        Ember.defineProperty(this, 'showErrorClass', Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly());
        Ember.defineProperty(this, 'showErrorMessage', Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly());
        Ember.defineProperty(this, 'showWarningMessage', Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly());
      } else {
        Ember.defineProperty(this, '_date', Ember.computed('date', {
          get() {
            let val = this.date;
            return val ? (0, _moment.default)(val, this.get('altFormat' || null)) : null;
          },

          set(key, value) {
            let formatted = this.formatValue(value, 'date');
            return formatted;
          }

        }));
      }
    },

    classNames: ['md-datetime', 'md-input-input'],
    classNameBindings: ['label:form-group', 'required'],

    /**
     * Datetime string passed in, edited, and returned.
     *
     * @property date
     * @type String
     * @default null
     * @return String
     */
    date: null,

    /**
     * Format of date string for property 'date'.
     *
     * @property format
     * @type String
     * @default 'YYYY-MM-DD'
     */
    format: 'YYYY-MM-DD',

    /**
     * The string to display when no datetime is selected.
     *
     * @property placeholder
     * @type String
     * @default 'Enter date or datetime'
     */
    placeholder: "Enter date or datetime",

    /**
     * Form label for datetime input.
     *
     * @property label
     * @type String
     * @default null
     */
    label: null,

    /**
     * On show, will set the picker to the current date/time
     *
     * @property useCurrent
     * @type Boolean
     * @default 'day'
     */
    useCurrent: 'day',

    /**
     * Show the Today button in the icon toolbar.
     *
     * @property showTodayButton
     * @type Boolean
     * @default true
     */
    showTodayButton: true,

    /**
     * Show the clear button in the icon toolbar.
     *
     * @property showClear
     * @type Boolean
     * @default true
     */
    showClear: true,

    formatValue(value, target) {
      if (Ember.isBlank(value)) {
        Ember.run.once(this, function () {
          Ember.set(this, target, null);
        });
        return value;
      }

      let mom = (0, _moment.default)(value);

      if (this.altFormat) {
        let alt = mom.format(this.altFormat);
        Ember.run.once(this, function () {
          Ember.set(this, target, alt);
        });
        return alt;
      } //utc.add(utc.utcOffset(), 'minutes');


      if (mom && mom.toISOString() !== this.get(target)) {
        Ember.run.once(this, function () {
          Ember.set(this, target, mom.toISOString());
        });
      }

      return mom;
    },

    /**
     * Icons to be used by the datetime picker and calendar.
     * Icons can be set for time, date, up, down, previous, next, clear,
     * and close.
     * The default icons are chosen from Font Awesome icons
     *
     * @property calendarIcons
     * @type Object
     * @default 'calendarIcons'
     */
    calendarIcons: Ember.computed(function () {
      return {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: "fa fa-angle-double-left",
        next: "fa fa-angle-double-right",
        close: "fa fa-times",
        clear: "fa fa-trash"
      };
    })
  });

  _exports.default = _default;
});