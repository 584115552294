define("mdeditor/models/setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultValues = void 0;
  const defaultValues = {
    mdTranslatorAPI: 'https://mdtranslator.herokuapp.com/api/v3/translator',
    fiscalStartMonth: '10'
  };
  _exports.defaultValues = defaultValues;

  const theModel = _emberData.default.Model.extend({
    /**
     * Setting model
     *
     * @class setting
     * @constructor
     * @extends DS.Model
     * @module mdeditor
     * @submodule data-models
     */
    settings: Ember.inject.service(),

    init() {
      this._super(...arguments); //this.on('didUpdate', this, this.wasUpdated);


      this.on('didLoad', this, this.wasLoaded); //this.on('didUpdate', this, this.wasLoaded);

      this.updateSettings;
    },

    //cleaner: inject.service(),
    compressOnSave: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    showSplash: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    autoSave: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    showDelete: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    showCopy: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    lastVersion: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    dateUpdated: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    characterSet: _emberData.default.attr('string', {
      defaultValue: 'UTF-8'
    }),
    country: _emberData.default.attr('string', {
      defaultValue: 'USA'
    }),
    language: _emberData.default.attr('string', {
      defaultValue: 'eng'
    }),
    importUriBase: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    mdTranslatorAPI: _emberData.default.attr('string', {
      defaultValue: defaultValues.mdTranslatorAPI
    }),
    fiscalStartMonth: _emberData.default.attr('string', {
      defaultValue: defaultValues.fiscalStartMonth
    }),
    repositoryDefaults: _emberData.default.attr('json'),
    publishOptions: _emberData.default.attr('json', {
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    customSchemas: _emberData.default.attr('json', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locale: Ember.computed.alias('defaultLocale'),

    wasLoaded() {
      this.settings.setup();
    },

    updateSettings: Ember.observer('hasDirtyAttributes', function () {
      if (this.hasDirtyAttributes) {
        Ember.run.once(this, function () {
          this.save();
        });
      }
    })
  });

  _exports.default = theModel;
});