define("mdeditor/pods/dictionary/show/edit/domain/edit/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IU7jGM/5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Domain \"],[4,\"if\",[[24,[\"model\",\"codeName\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"em\",true],[8],[1,[24,[\"model\",\"codeName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    #\"],[1,[22,\"domainId\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"small\"],[8],[0,\"for \"],[7,\"em\",true],[8],[1,[24,[\"parentModel\",\"title\"]],false],[9],[9],[0,\"\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"object/md-domain\",null,[[\"class\",\"model\",\"profilePath\",\"editCitation\",\"editDomainItem\"],[\"\",[24,[\"model\"]],\"dictionary.domain\",[28,\"route-action\",[\"editCitation\"],null],[28,\"route-action\",[\"editDomainItem\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-extra\",[28,\"component\",[\"control/subbar-link\"],[[\"text\",\"icon\",\"route\"],[\"Back to Domains\",\"arrow-left\",\"dictionary.show.edit.domain.index\"]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-dict-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"scrollInit\",\"setScrollTo\"],[[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/dictionary/show/edit/domain/edit/index/template.hbs"
    }
  });

  _exports.default = _default;
});