define("mdeditor/models/custom-profile", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { regex } from 'mdeditor/models/schema';
  // [{
  //   "id": "full",
  //   "namespace": "org.adiwg.profile",
  //   "alternateId": [""],
  //   "title": "Full",
  //   "description": "Evey supported component",
  //   "version": "0.0.0",
  //   "components": {
  //     "record": {},
  //     "contact": {},
  //     "dictionary": {}
  //   },
  //   "nav": {
  //     "record": [{
  //       "title": "",
  //       "target": "",
  //       "tip": ""
  //     }]
  //   }
  // }]
  const Validations = (0, _emberCpValidations.buildValidations)({
    'alias': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.notEmpty('model.title'),
      message: 'A title must be provided.'
    }),
    'title': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.notEmpty('model.Alias'),
      message: 'A title must be provided.'
    }),
    'profileId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      isWarning: true,
      message: 'No profile definition is assigned.'
    }),
    'schemas': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      isWarning: true,
      message: 'No schemas have been assigned.'
    }) // 'uri': [
    // validator('presence', {
    //   presence: true,
    //   ignoreBlank: true
    // }),
    // validator('format', {
    //   regex: regex,
    //   isWarning: false,
    //   message: 'This field should be a valid, resolvable URL.'
    // })
    // ]

  });

  var _default = _emberData.default.Model.extend(Validations, {
    /**
     * Custom Profile model
     *
     * @class custom-profile
     * @constructor
     * @extends DS.Model
     * @module mdeditor
     * @submodule data-models
     */
    init() {
      this._super(...arguments);

      this.updateSettings;
    },

    definitions: Ember.inject.service('profile'),
    uri: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    profileId: _emberData.default.attr('string'),
    //remoteVersion: DS.attr('string'),
    profileTitle: Ember.computed.or('alias', 'title'),
    identifier: Ember.computed.alias('id').readOnly(),
    components: Ember.computed.alias('profile.components').readOnly(),
    //localVersion: alias('version'),
    //hasUpdate: computed('localVersion', 'remoteVersion', checkVersion),
    schemas: _emberData.default.hasMany('schemas'),
    definition: Ember.computed('profileId', function () {
      return this.definitions.profiles.findBy('identifier', this.profileId);
    }),

    /* eslint-disable ember/no-observers */
    updateSettings: Ember.observer('hasDirtyAttributes', 'title', 'uri', 'alias', 'description', 'hasUpdate', 'schemas.[]', 'profileId', function () {
      if (this.isNew || this.isEmpty || this.isDeleted) {
        return;
      }

      if (this.hasDirtyAttributes) {
        this.set('dateUpdated', new Date());
        Ember.run.once(this, function () {
          this.save();
        });
      }
    })
    /* eslint-enable ember/no-observers */

  });

  _exports.default = _default;
});