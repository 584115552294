define("mdeditor/instance-initializers/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('route', 'settings', 'service:settings');
    appInstance.inject('controller', 'settings', 'service:settings');
  }

  var _default = {
    name: 'settings',
    initialize
  };
  _exports.default = _default;
});