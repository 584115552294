define("mdeditor/validators/array-valid", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ArrayValid = _base.default.extend({
    /**
    * Validation that checks validity of all array members
    *
    * @module mdeditor
    * @submodule validator
    * @class array-valid
    * @extends BaseValidator
    * @example
    *   validator('array-valid')
    */
    validate(value
    /*, options, model, attribute*/
    ) {
      let check = value.some(itm => {
        return Ember.get(itm, 'validations.isInvalid');
      });
      return check ? 'At least one item is invalid.' : true;
    }

  });

  ArrayValid.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @property getDependentsFor
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor(attribute
    /*, options */
    ) {
      return ["model.".concat(attribute, ".@each")];
    }

  });
  var _default = ArrayValid;
  _exports.default = _default;
});