define("mdeditor/pods/components/layout/md-nav-secondary/component", ["exports", "ember-resize/mixins/resize-aware"], function (_exports, _resizeAware) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resizeAware.default, {
    customProfile: Ember.inject.service('custom-profile'),
    resizeService: Ember.inject.service('resize'),
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,
    navPadding: 5,
    navWidth: 0,

    /**
    * Array of nav links. If not supplied, the links will be pulled from the
    * active profile.
    *
    * @property navLinks
    * @type {Array}
    * @default "undefined"
    * @optional
    */

    /**
     * translated "more" text
     *
     * @property more
     * @type {String}
     */
    more: 'More',
    links: Ember.computed('customProfile.active', 'model', 'navWidth', function () {
      const active = this.customProfile.getActiveProfile();
      const modelName = this.get('model.constructor.modelName');
      const nav = this;
      let links = this.navLinks || Ember.get(active, 'definition.nav.' + modelName) || this.customProfile.defaultProfile.definition.nav[modelName];
      return links.map((lnk, index) => {
        let link = Ember.Object.create(lnk);
        link.setProperties({
          nav: nav,
          index: index
        });
        Ember.defineProperty(link, 'navWidth', Ember.computed.alias('nav.navWidth'));
        Ember.defineProperty(link, 'isOverflow', Ember.computed('navWidth', 'width', function () {
          return this.navWidth < this.linkWidth + this.nav.offset;
        }));
        return link;
      });
    }),
    sortedLinks: Ember.computed('links', function () {
      let sorted = this.links.sortBy('index');
      return sorted;
    }),
    hasOverflow: Ember.computed('navWidth', 'linkWidth', function () {
      return this.navWidth < this.linkWidth;
    }),
    linkWidth: Ember.computed('links.@each.width', function () {
      return this.links.reduce((a, b) => a + b.width, this.navPadding);
    }),

    /**
     * Width to be added to linkWidth to make sure the last link will fit.
     * Calcuated as the smaller of the maximum link width or 150.
     *
     * @property offset
     * @type {Number}
     */
    offset: Ember.computed('links.@each.width', function () {
      return Math.min(Math.max(...this.links.mapBy('width'), 1), 150);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._handleDebouncedResizeEvent();
    },

    debouncedDidResize(width) {
      this.set('navWidth', width || this.navWidth);
    }

  });

  _exports.default = _default;
});