define("mdeditor/pods/components/object/md-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZHrmgYQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"record\"]],\"validations\"],null],\"attrs\"],null],\"config\"],null],\"isInvalid\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[10,\"role\",\"alert\"],[8],[1,[28,\"fa-icon\",[\"exclamation\"],null],false],[0,\"\\n    \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"record\"]],\"validations\"],null],\"attrs\"],null],\"config\"],null],\"message\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"object/md-profile/form\",null,[[\"record\"],[[24,[\"record\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/object/md-profile/template.hbs"
    }
  });

  _exports.default = _default;
});