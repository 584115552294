define("mdeditor/pods/record/show/edit/distribution/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jaDQuTZy",
    "block": "{\"symbols\":[\"dist\",\"index\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"section-header\"],[8],[0,\"\\n  Editing Distribution\\n  \"],[1,[28,\"control/md-status\",null,[[\"model\"],[[24,[\"parentModel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"json\",\"metadata\",\"resourceDistribution\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"object/md-distribution\",null,[[\"model\",\"index\",\"profilePath\",\"addDistribution\",\"deleteDistribution\",\"editDistributor\"],[[23,1,[]],[23,2,[]],\"record.distribution\",[28,\"route-action\",[\"addDistribution\"],null],[28,\"route-action\",[\"deleteDistribution\"],null],[28,\"route-action\",[\"editDistributor\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"No distribution sections found.\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addDistribution\"]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"plus\"],null],false],[0,\" Add Distribution Section\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-subbar-buttons\",[28,\"component\",[\"control/md-button\"],[[\"text\",\"icon\",\"class\",\"click\"],[\"Add Distribution\",\"plus\",\"btn-success btn-lg\",[28,\"route-action\",[\"addDistribution\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"md-scroll-spy-record-edit\",[28,\"component\",[\"control/md-scroll-spy\"],[[\"refresh\",\"scrollInit\",\"setScrollTo\"],[[24,[\"refreshSpy\"]],[24,[\"scrollTo\"]],[28,\"route-action\",[\"setScrollTo\"],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/record/show/edit/distribution/index/template.hbs"
    }
  });

  _exports.default = _default;
});