define("mdeditor/pods/components/control/md-crud-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AO6Dg846",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"btn-group-vertical center-block\"],[10,\"role\",\"group\"],[10,\"aria-label\",\"CRUD Button Controls\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"doSave\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-success\"],[12,\"disabled\",[28,\"if\",[[24,[\"model\",\"hasDirtyHash\"]],false,true],null]],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-floppy-o\"],[8],[9],[0,\" Save\\n      \"],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-warning\"],[12,\"disabled\",[28,\"if\",[[24,[\"model\",\"canRevert\"]],false,true],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-undo\"],[8],[9],[0,\" Cancel\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showCopy\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-info\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"copy\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-clone\"],[8],[9],[0,\" Copy\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showDelete\"]]],null,{\"statements\":[[4,\"control/md-button-confirm\",null,[[\"class\",\"onConfirm\",\"tooltip\",\"tipClass\"],[\"btn btn-lg btn-danger\",[28,\"action\",[[23,0,[]],\"delete\"],null],\"Permanently delete this record?\",\"danger\"]],{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\" Delete\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n  \"],[1,[28,\"ember-tooltip\",null,[[\"tooltipClass\",\"text\",\"side\"],[\"ember-tooltip md-tooltip primary\",\"Manage the record\",\"top\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mdeditor/pods/components/control/md-crud-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});