define("mdeditor/pods/components/object/md-taxonomy/classification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-group', 'md-classification'],
    dragging: null,
    preview: false
  });

  _exports.default = _default;
});