define("mdeditor/pods/record/show/route", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    //breadCrumb: {},
    afterModel(model) {
      const name = model.get('title');
      const crumb = {
        title: name
      };
      this.set('breadCrumb', crumb);
    },

    model(params) {
      return this.store.peekRecord('record', params.record_id);
    },

    actions: {
      destroyRecord: function destroyRecord() {
        let model = this.currentRouteModel();
        model.destroyRecord().then(() => {
          this.flashMessages.success("Deleted Record: ".concat(model.get('title')));
          this.replaceWith('records');
        });
      },
      copyRecord: function copyRecord() {
        this.flashMessages.success("Copied Record: ".concat(this.currentRouteModel().get('title')));
        this.transitionTo('record.new.id', (0, _emberCopy.copy)(this.currentRouteModel()));
      }
    }
  });

  _exports.default = _default;
});