define("mdeditor/pods/dictionary/show/edit/domain/edit/index/route", ["exports", "mdeditor/mixins/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTo.default, {
    beforeModel() {
      this.set('domainId', this.paramsFor('dictionary.show.edit.domain.edit').domain_id);
    },

    setupController: function setupController() {
      // Call _super for default behavior
      this._super(...arguments);

      let parent = this.controllerFor('dictionary.show.edit.domain.edit');
      this.controller.set('parentModel', this.modelFor('dictionary.show.edit'));
      this.controller.set('domainId', Ember.get(parent, 'domainId'));
      this.controllerFor('dictionary.show.edit').setProperties({
        onCancel: parent.get('setupModel'),
        cancelScope: this
      });
    },
    actions: {
      editCitation(scrollTo) {
        this.transitionTo('dictionary.show.edit.domain.edit.citation').then(function () {
          this.setScrollTo(scrollTo);
        }.bind(this));
      },

      editDomainItem(id) {
        this.transitionTo('dictionary.show.edit.domain.edit.item', id).then(function () {
          this.setScrollTo('md-domainitem-' + id);
        }.bind(this));
      }

    }
  });

  _exports.default = _default;
});