define("mdeditor/components/tree-leaf", ["exports", "ember-json-tree/components/tree-leaf"], function (_exports, _treeLeaf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _treeLeaf.default;
    }
  });
});