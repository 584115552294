define("mdeditor/pods/components/object/md-maintenance/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatMaint = void 0;

  const formatMaint = function formatMaint(model) {
    Ember.setProperties(model, {
      'date': Ember.getWithDefault(model, 'date', []),
      'scope': Ember.getWithDefault(model, 'scope', []),
      'note': Ember.getWithDefault(model, 'note', []),
      'contact': Ember.getWithDefault(model, 'contact', [])
    });
    return model;
  };

  _exports.formatMaint = formatMaint;
  const theComp = Ember.Component.extend({
    /**
     * mdEditor class for input and edit of mdJSON 'maintenance' objects.
     *
     * @class md-maintenance
     * @constructor
     *   myClass = new MyClass()
     */
    tagName: 'form',

    /**
     * The string representing the path in the profile object.
     *
     * @property profilePath
     * @type {String}
     * @default "false"
     * @required
     */

    /**
     * The object to use as the data model.
     *
     * @property model
     * @type {Object}
     * @required
     */

    /**
     * Setup default values for the model.
     *
     * @method didReceiveAttrs
     */
    didReceiveAttrs() {
      this._super(...arguments);

      Ember.run.once(this, function () {
        this.set('model', Ember.getWithDefault(this, 'model', {}));
        formatMaint(this.model);
      });
    },

    scopes: Ember.computed('scope', {
      get() {
        let scope = Ember.get(this, 'model.scope');
        return scope ? scope.mapBy('scopeCode') : [];
      },

      set(key, value) {
        let map = value.map(itm => {
          return {
            scopeCode: itm
          };
        });
        Ember.set(this, 'model.scope', map);
        return value;
      }

    })
  });
  _exports.default = theComp;
});